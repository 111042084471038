angular.module("2cp").controller("app.pois",
    [
        "$scope", "$q", "$timeout", "$filter",
        "Poi", "PoiColumns", '$translate',
        function ($scope, $q, $timeout, $filter, Poi, PoiColumns, $translate) {
            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showPoiDetailsModal = function (creationModal, rowEntity) {
                $scope.poiDetails = rowEntity ? rowEntity : {};
                $scope.poiCreationModal = creationModal;

                if (!creationModal) {
                    $scope.forbiddenGrid.api.grid.clearAllFilters();
                    $scope.forbiddenGrid.api.callGridData.get().then(function () {
                        var ids = [];
                        if ($scope.poiDetails.forbidden_pois) {
                            ids = $scope.poiDetails.forbidden_pois.map(function (forbiddenPoi) {
                                return forbiddenPoi.poi_id;
                            });
                        }

                        $timeout(function () {
                            $scope.forbiddenGrid.options.data.forEach(function (forbiddenPoi) {
                                if (ids.indexOf(forbiddenPoi.droppoint_id) > -1)
                                    $scope.forbiddenGrid.api.selection.selectRow(forbiddenPoi);
                            });
                        }, 0);
                    });
                }
                $("#poiDetailsModal").foundation('open');
            };

            $scope.showPoiEditForm = function(rowEntity) {
                $timeout(function() {
                    $scope.poiDetails = rowEntity;
                    $scope.poiDetails.tour_marker = $scope.poiDetails.tour_marker == 1;
                    $("#poiEditDetailsModal").foundation('open');

                    $scope.PoiGrid.api.grid.clearAllFilters();
                }, 0);
            };

            // Save update
            $scope.save = function(updatedRow) {
                $scope.PoiGrid.api.rowEdit.setRowsDirty([updatedRow]);
                $('.reveal').foundation('close')
            };

            // Add new Poi
            $scope.add = function(updatedRow) {
                $scope.PoiGrid.api.callGridEditing.addRow(updatedRow);
                $('.reveal').foundation('close')
            };

            $scope.removePoiPhoto = function(photo) {
                var index = $scope.poiDetails.photos.indexOf(photo);
                $scope.poiDetails.photos.splice(index,1);
            };

            $scope.addPoiPhoto = function(photo) {
                var newPoiPhoto = {"poi_id": $scope.poiDetails.poi_id,"photo": photo};

                if($scope.poiDetails.photos) {
                    $scope.poiDetails.photos.push(newPoiPhoto);
                } else {
                    $scope.poiDetails.photos = [];
                    $scope.poiDetails.photos.push(newPoiPhoto);

                }
            };

            $scope.removeSelected = function() {
                $scope.PoiGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.gridOptions = {
                columnDefs: PoiColumns.build(),
                callGridData: {
                    id: "poi_id",
                    get: function (params) {
                        return Poi.getPois(params);
                    },
                    update: function(id, data) {
                        return Poi.update(id, data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.poi_update_success'), "success");
                                data.photos = result.photos;
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });

                    },
                    create: function(data) {
                        return Poi.create(data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.poi_create_success'), "success");
                                data.photos = result.photos;
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    },
                    delete: function(ids) {
                        return Poi.delete(ids)
                            .then(function(result) {
                                $.notify($translate.instant('notify.poi_delete_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: $scope.showPoiEditForm
                },
                onRegisterApi: function(gridApi) {
                    $scope.PoiGrid = gridApi.grid;
                }
            };
        }
    ]
);