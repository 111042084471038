angular.module("2cp").controller("tenants.vouchers",
    [
        "$scope", "$timeout", "Vouchers", "VoucherColumns", "Payments", "PaymentColumns",
        "PermissionService", "CSV", "Times", '$translate', 'VehicleTypes',
        function ($scope, $timeout, Vouchers, VoucherColumns, Payments, PaymentColumns,
                  PermissionService, CSV, Times, $translate, VehicleTypes) {
            $scope.timezone = Times.getZone();
            $scope.voucherDetails = {};
            $scope.vehicleTypes = [];
            $scope.voucherGridOptions = {
                columnDefs: VoucherColumns.
                addOptionsByName({'vehicleType' : {'selectOptions': $scope.vehicleTypes}}).
                build(),

                callGridData: {
                    id: "voucher_id",
                    get: function(data){
                        if(data.vehicleType){
                            data.vehicleType = '"' + data.vehicleType + '"';
                        }
                        return Vouchers.getVouchers(data).then(function(response){
                            if (!PermissionService.hasPermissions(['updateVouchers'])) {
                                $scope.voucherGrid.api.callGridEditing.disableCellEdits();
                            }
                            return response;
                        })
                    },
                    create: function (data) {
                        if(data.valid_from) {
                            data.valid_from = Times.getUTCDateTimeString(data.valid_from, $scope.timezone);
                        }
                        if(data.valid_until) {
                            data.valid_until = Times.getUTCDateTimeString(data.valid_until, $scope.timezone);
                        }
                        $('.reveal').foundation('close');
                        return Vouchers.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.voucher_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        if(data.valid_from) {
                            data.valid_from = Times.getUTCDateTimeString(data.valid_from, $scope.timezone);
                        }
                        if(data.valid_until) {
                            data.valid_until = Times.getUTCDateTimeString(data.valid_until, $scope.timezone);
                        }
                        return Vouchers.update(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.voucher_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Vouchers.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.voucher_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridIcon: {
                    iconClass: function () {
                        return 'fa-list-ul';
                    },
                    clickCallback: function (rowEntity) {
                        $scope.voucherDetails = rowEntity;
                        $scope.paymentGrid.api.callGridData.get();
                        $("#paymentsModal").foundation('open');
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "vouchers."
                },
                onRegisterApi: function (gridApi) {
                    $scope.voucherGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function () {
                var r = true;
                var rows = $scope.voucherGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelected = function () {
                $scope.voucherGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.showDetailsModal = function (creationModal, rowEntity) {
                $scope.voucherDetails = rowEntity;
                $scope.creationModal = creationModal;

                $("#detailsModal").foundation('open');
            };

            $scope.detailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close')

                if ($scope.creationModal)
                    $scope.voucherGrid.api.callGridEditing.addRow(updatedRow);
                else
                    $scope.voucherGrid.api.rowEdit.setRowsDirty([updatedRow]);
            };

            $scope.importCSV = function (file) {
                if (file === null)
                    return;

                var reader = new FileReader();
                reader.onload = function (e) {
                    var text = reader.result;
                    var items = CSV.parse(text);

                    console.log(items);

                    Vouchers.create({batch: items}).then(function (result) {
                        $.notify($translate.instant('notify.voucher_batch_create_success_1') + result.length + $translate.instant('notify.voucher_batch_create_success_2') + items.length, "success");
                        if (result.length < items.length)
                            $.notify($translate.instant('notify.voucher_batch_create_failed_2'), 'warn');

                        $scope.voucherGrid.api.callGridData.get();

                        return result;
                    }).catch(function (e) {
                        console.error(e);
                    });
                };
                reader.readAsText(file);
            };

            if(PermissionService.hasPermissions(['viewPayments'])) {
                $scope.paymentGridOptions = {
                    columnDefs: PaymentColumns.build(),

                    callGridData: {
                        id: "payment_id",
                        get: function (params) {
                            params.voucher_id = $scope.voucherDetails.voucher_id;
                            return Payments.getPayments(params)
                                .catch(function (e) {
                                    console.error(e);
                                });
                        }
                    },

                    onRegisterApi: function (gridApi) {
                        $scope.paymentGrid = gridApi.grid;
                    }
                };
            }


            //Set vehicle types for filtering and for in the details modal
            VehicleTypes.getVehicleTypes({limit: 999}).then(function(response){
                for(var i = 0; i < response.data.length; i++){
                    $scope.vehicleTypes.push({
                        'value': response.data[i].name,
                        'label': response.data[i].name,
                        'id': response.data[i].vehicle_type_id
                    });
                }
                for(i = 0; i < $scope.voucherGrid.columns.length; i++){
                    if($scope.voucherGrid.columns[i].name === 'vehicleType'){
                        $scope.voucherGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
            });
        }
    ]
);