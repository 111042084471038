angular.module("2cp").directive("callGridData", [
    function () {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            compile: function(tElem, tAttrs) {
                return {
                    pre: function ($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;

                        //Initialize defaults
                        if(typeof(grid.options.callGridData) === "undefined")
                            grid.options.callGridData = {};
                        if(typeof(grid.options.callGridData.params) === "undefined")
                            grid.options.callGridData.params = [];
                        if(typeof(grid.options.callGridData.loadImmediately) === "undefined")
                            grid.options.callGridData.loadImmediately = true;

                        grid.callGridData = {
                            loading: false,
                            noData: false
                        };
                    },
                    post: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;

                        //Register public api functions
                        var methods = {
                            callGridData: {
                                get: function () {
                                    var grid = this;
                                    var options = grid.options;
                                    var dataOptions = options.callGridData;
                                    var numberOfItems = 0;

                                    grid.callGridData.loading = true;
                                    grid.callGridData.noData = false;
                                    return dataOptions.get(dataOptions.params)
                                        .then(function (result) {
                                            if(result.total || result.total === 0) {
                                                numberOfItems = result.total;
                                                options.data = result.data;
                                            }
                                            else {
                                                numberOfItems = result.length;
                                                options.data = result;
                                            }

                                            grid.callGridData.noData = numberOfItems === 0;
                                        })
                                        .finally(function() {
                                            grid.options.totalItems = numberOfItems;
                                            grid.callGridData.loading = false;
                                        });
                                },
                                create: function(data) {
                                    var grid = this;
                                    var options = grid.options;
                                    var dataOptions = options.callGridData;
                                    var createFunction = dataOptions.create(data);
                                    createFunction.then(function(){
                                        grid.options.totalItems++;
                                    });
                                    return createFunction;
                                },
                                update: function (id, data) {
                                    var grid = this;
                                    var options = grid.options;
                                    var dataOptions = options.callGridData;
                                    return dataOptions.update(id, data);
                                },
                                delete: function (ids) {
                                    var grid = this;
                                    var options = grid.options;
                                    var dataOptions = options.callGridData;
                                    var deleteFunction = dataOptions.delete(ids);
                                    deleteFunction.then(function(){
                                        grid.options.totalItems -= ids.length;
                                    });
                                    return deleteFunction;
                                }
                            }
                        };
                        grid.api.registerMethodsFromObject(methods);

                        //Fetch the first data
                        if(grid.options.callGridData.loadImmediately)
                            grid.api.callGridData.get();
                    }
                };
            }
        };
    }]);