angular.module("2cp")
// Filter for voucher types
    .filter('pushMessageRead', ['Times', function (Times) {
        return function (input, scope) {
            if (scope.row.entity.total_receivers > 1) {
                return scope.row.entity.receivers_read + ' out of ' + scope.row.entity.total_receivers;
            } else {
                var datetime = Times.getTenantDateTimeString(input, Times.getZone());
                if(!datetime){
                    return 'Not yet read';
                }
                return datetime;
            }
        };
    }])
    .filter('pushMessageReceivers', function () {
        return function (input, scope) {
            if(scope.row.entity.total_receivers > 1){
                return 'All';
            }else{
                return input;
            }
        };
    });