'use strict';

angular.module('api').factory('Bookings', [
    'clApi',
    '$q',
    function (api, $q) {
        var cache = api.getOrCreateSessionCache('Bookings');

        return {
            parameters: [
                'username',
                'droppoint_id',
                'start_datetime',
                'end_datetime',
                'status',
                'amount',
                'vehicleType',
                'booking_id',
                'created_at',
                'updated_at',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getBookings: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('bookings', params, options).then(function (result) {

                    return result.data;
                });
            },

            getBooking: function (id) {
                return api.get('bookings/' + id).then(function (result) {
                    return result.data;
                });
            },

            getBookingCost: function (params) {
                return api.post('bookings/cost', params).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('bookings/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('bookings', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('bookings/' + id));
                });

                return $q.all(promises);
            },

            endBooking: function(id) {
                return api.get('bookings/' + id + '/end').then(function (result) {
                    return result.data;
                });
            },

            endExpiredBookings: function() {
                return api.get('bookings/endAll').then(function (result) {
                    return result.data;
                });
            },

			possibleVehicleTypes: function(droppoint_id, user_id, data){
            	if(!data){
            		data = {}
				}
				data.user_id = user_id;
            	data.droppoint_id = droppoint_id;
            	return api.post('bookings/possibleVehicleTypes', data).then(function(result){
            		return result.data
				});
			}

        };
    },
]);
