'use strict';

angular.module('api').factory('Partners', [
    'clApi',
    '$q',
    'Address',
    function (api, $q, Address) {
        var cache = api.getOrCreateSessionCache('Partners', 10000);

        return {
            parameters: [
                'partner_id',
                'name',
                'short_name',
                'telephone_number',
                'fax_number',
                'mobile_number',
                'email',
                'address_id',
                'created_at',
                'updated_at',
                'deleted_at',
                'street',
                'country',
                'state',
                'city',
                'house_number',
                'postal_code'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getPartners: function (params, options) {
                options = api.createIfNotExist(options);
                //options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('partners', params, options).then(function (result) {
                    for(var i = 0; i < result.data.data.length; i++){
                        result.data.data[i] = Address.setAddress(result.data.data[i]);
                    }
                    return result.data;
                });
            },

            getPartner: function (id) {
                return api.get('partners/' + id).then(function (result) {
                    return result.data;
                });
            },

            attachTenants: function(partnerId, tenantIds) {
                if(angular.isArray(tenantIds))
                    tenantIds = tenantIds.join(',');

                return api.post('partners/' + partnerId + '/attachTenants', {
                    tenant_ids: tenantIds
                }).then(function(result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('partners', params).then(function (result) {
                    result.data = Address.setAddress(result.data);
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('partners/' + id, params).then(function (result) {
                    result.data = Address.setAddress(result.data);
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('partners/' + id));
                });

                return $q.all(promises);
            },

            getSettings: function(id){
                return api.get('partners/' + id + '/settings').then(function(result){
                    return result.data;
                });
            },

            updateSettings: function(id, params){
                return api.put('partners/' + id + '/settings', params).then(function (result) {
                    return result.data;
                });
            },

            updateRegistrationFields: function(id, params){
                return api.put('partners/' + id + '/registrationFields', params).then(function (result) {
                    return result.data;
                });
            },
        };
    },
]);
