'use strict';

angular.module('api').factory('Maintenance', [
    'clApi',
    '$q',
    function (api, $q) {
        var Maintenance = {
            PRIORITY: [
                'low',
                'medium',
                'high',
                'emergency',
            ],

            TYPES: [
                1, 2, 3, 4, 5, 6, 7, 8, 9
            ],

            STATUS: [
                'open',
                'accepted',
                'closed',
                'overdue',
            ],
            
            RECUR_UNITS: [
                'weeks',
                'days',
                'hours',
                'minutes'
            ],

            PARAMETERS: [
                'status',
                'priority',
                'datetime_planned',
                'maintenance_task_type_id',
                'subject',
                'reporterUsername',
                'description',
                'datetime_closed',
                'created_at',
                'resolution',
                'reporterEmail',
                'vehicleLabel',
                'droppointLabel',
                'lockDeviceLabel',
                'recurrence',
                'recur_unit',
                'date_accepted',
                'maintenance_task_id',
            ],

            getParameters: function () {
                return this.PARAMETERS;
            },

            getTasks: function (params) {
                var setData = this.setData;
                return api.get('maintenance', params).then(function (result) {
                    var maintenanceTasks = result.data;
                    for (var i = 0; i < maintenanceTasks.data.length; i++) {
                        maintenanceTasks.data[i] = setData(maintenanceTasks.data[i]);
                    }
                    return maintenanceTasks;
                });
            },

            create: function (params) {
                var setData = this.setData;
                return api.post('maintenance', params).then(function (result) {
                    result.data = setData(result.data);
                    return result.data;
                });
            },

            update: function (id, params) {
                if (typeof (params.droppoint_id) === 'undefined')
                    params.droppoint_id = null;
                if (typeof (params.lock_id) === 'undefined')
                    params.lock_id = null;
                if (typeof (params.resolution) === 'undefined')
                    params.resolution = '';
                var setData = this.setData;
                return api.put('maintenance/' + id, params).then(function (result) {
                    result.data = setData(result.data);
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function(id) {
                    promises.push(api.delete('maintenance/' + id).then(function(result) {
                        return result.data;
                    }));
                });

                return $q.all(promises);
            },

            setData: function (task) {
                if (task.reporter) {
                    task.reporterUsername = task.reporter.username;
                    task.reporterEmail = task.reporter.email;
                }
                if (task.vehicle) {
                    task.vehicleLabel = task.vehicle.label;
                }
                if (task.droppoint) {
                    task.droppointLabel = task.droppoint.label;
                }
                if (task.lock_device) {
                    task.lockDeviceLabel = task.lock_device.label;
                }
                return task;
            },
        };

        return Maintenance;
    },
]);
