'use strict';

/* Directives */
function createFormat(format) {
    switch (format) {
        case "date":
            return {
                "format": "yyyy-mm-dd",
                "hasTime": false,
                'startView': 3
            }
        case "noYear":
            return {
                "format": "mm-dd",
                "hasTime": false,
                'startView': 3
            }
        case "datetime":
            return {
                "format": "yyyy-mm-dd hh:ii:ss",
                "hasTime": true,
                'startView': 3
            }
        case "time":
            return {
                "format": "hh:ii:ss",
                "hasTime": true,
                'startView': 1
            }
        default:
            return {
                "format": "yyyy-mm-dd hh:ii:ss",
                "hasTime": true,
                'startView': 3
            }
    }
}

// Datepicker for in the header
function HeaderDatePicker(dateFormat) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element) {
            $(".ui-grid-filter-container:nth-child(1) .from-to").attr("placeholder", "from")
            $(".ui-grid-filter-container:nth-child(2) .from-to").attr("placeholder", "to")
            $(element).fdatepicker({
                format: dateFormat.format,// "yyyy-mm-dd",
                pickTime: dateFormat.hasTime,
                startView: dateFormat.startView,
                iso: false
            })
        }
    }
}

// Datepicker for in the grid
function GridDatePicker(dateFormat) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element, attrs, ngModel) {
            $(element).fdatepicker({
                format: dateFormat.format,
                pickTime: dateFormat.hasTime,
                startView: dateFormat.startView,
                iso: false,
            }).on('show', function () {
                /* Angular initializes $modelValue with NaN. To prevent taking
                 this value to the date picker, we check if the $modelValueThe is
                 a number (NaN). */
                if (typeof ngModel.$modelValue != "number") {
                    // Get the ISO UTC value from the model
                    var value = ngModel.$modelValue

                    // Convert from UTC to tenant timezone and change the ISO
                    // format to a value that datepicker can read.
                    var converted = null;
                    if (dateFormat.format == 'hh:ii:ss') {
                        converted = convertToTenantTimezone(value).format("HH:mm:ss");
                    }else{
                        converted = convertToTenantTimezone(value).format("YYYY-MM-DD HH:mm:ss");
                    }

                    console.log("value", value)
                    console.log("converted", converted)

                    $(element).fdatepicker("update", converted)
                    $(element).unbind("blur")
                }
            }).on('hide', function (ev) {
                // Somehow, we get a date object, that's shifted to the
                // browser's timezone. So we convert that one to UTC,
                // then we tell it that that is actually the time in the
                // tenant's timezone, and then we convert that to UTC again,
                // so that we get the correct format in the database.
                var utcDate = convertToUTC(forceTimezone(convertToUTC(ev.date)));
                var isoDate = null;
                if (dateFormat.format == 'hh:ii:ss') {
                    isoDate = utcDate.format("HH:mm:ss");
                }else{
                    isoDate = utcDate.format("YYYY-MM-DD HH:mm:ss");
                }
                var databaseDate = isoDate;//.replace(/\+.*/, "Z")

                // Store the date. We have to trigger input to let Angular
                // know that we have updated the value of the input.
                $(ev.target).val(isoDate).trigger('input');
                angular.element(ev.target).scope().stopEdit()
            })
        }
    }
}

// Normal datepicker
function DatePicker(dateFormat) {
    return {
        require: 'ngModel',
        restrict: 'A',
        link: function (scope, element) {
            $(element).fdatepicker({
                format: dateFormat.format,
                pickTime: dateFormat.hasTime,
                startView: dateFormat.startView,
                iso: false
            })
        }
    }
}

angular
    .module('2cp')
    .directive('gridDatePicker', function () {
        var format = createFormat("date");
        return GridDatePicker(format);
    })
    .directive('gridDateTimePicker', function () {
        var format = createFormat("datetime");
        return GridDatePicker(format);
    })
    .directive("headerDatePicker", function () {
        var format = createFormat("date");
        return HeaderDatePicker(format);
    })
    .directive("headerDateTimePicker", function () {
        var format = createFormat("datetime");
        return HeaderDatePicker(format);
    })
    .directive("datePicker", function () {
        var format = createFormat("date");
        return DatePicker(format);
    })
    .directive("noYearDatePicker", function () {
        var format = createFormat("noYear");
        return DatePicker(format);
    })
    .directive("dateTimePicker", function () {
        var format = createFormat("datetime");
        return DatePicker(format);
    })
    .directive("timePicker", function () {
        var format = createFormat("time");
        return DatePicker(format);
    })
    .directive("headerTimePicker", function () {
        var format = createFormat("time");
        return HeaderDatePicker(format);
    })
    .directive('gridTimePicker', function () {
        var format = createFormat("time");
        return GridDatePicker(format);
    })