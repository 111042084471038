'use strict';

angular.module('api').factory('Tours', [
    '$http',
    'clApi',
    '$q',
    function ($http, api, $q) {
        return {
            parameters: [
                'tour_id',
                'name',
                'description',
                'category',
                'waypoints',
            ],
            getParameters: function () {
                return this.parameters;
            },

            getTours: function (params) {
                var options = api.createIfNotExist(options);
                return api.get('tours', params, options).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('tours/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('tours', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (faqIds) {
                var promises = [];
                faqIds.forEach(function (id) {
                    promises.push(api.delete('tours/' + id));
                });

                return $q.all(promises);
            },

        };
    }
]);
