'use strict';

angular.module('api').factory('Times', [
	function () {
		var tenantTimezone = null;

		return {
			setZone: function (zone) {
				tenantTimezone = zone;
			},

			getZone: function(){
				return tenantTimezone;
			},

			getUTCTimeString: function (oldTime) {
				if(oldTime) {
                    var timeElements = oldTime.split(':');
                    var timezone = tenantTimezone;
                    var time = moment().tz(timezone);
                    time.set({'hour': timeElements[0], 'minute': timeElements[1], 'second': 0});
                    var copyTime = time.clone();
                    copyTime.tz(timezone);
                    copyTime.add(time.utcOffset() - copyTime.utcOffset(), 'minutes');
                    var utctime = convertToUTC(copyTime);
                    return utctime.hour() + ':' + utctime.minute() + ':' + utctime.second();
                }
			},

			getTenantTimeString: function (oldTime) {
				var timezone = tenantTimezone;
				if (oldTime) {
                    var timeElements = oldTime.split(':');
					var time = moment();
                    time.set({'hour': timeElements[0], 'minute': timeElements[1], 'second': 0});
					var copyTime = time.clone();
					copyTime.tz('UTC');
					copyTime.add(time.utcOffset() - copyTime.utcOffset(), 'minutes');
					copyTime.tz(timezone);
					var hour = copyTime.hour();
					if (hour < 10) {
						hour = '0' + hour;
					}
					var minute = copyTime.minute();
					if (minute < 10) {
						minute = '0' + minute;
					}
					var second = copyTime.second();
					if (second < 10) {
						second = '0' + second;
					}
					return hour + ':' + minute + ':' + second;
				}
			},

			getUTCDateTimeString: function (oldTime) {
				var timezone = tenantTimezone;
				var time = moment.tz(oldTime, timezone);
				var copyTime = time.clone();
				copyTime.tz(timezone);
				copyTime.add(time.utcOffset() - copyTime.utcOffset(), 'minutes');
				var utctime = convertToUTC(copyTime)
				return utctime.format('YYYY-MM-DD HH:mm:ss');
			},

			getTenantDateTimeString: function (oldTime) {
				var timezone = tenantTimezone;
				if (oldTime) {
					var time = moment(oldTime, "YYYY-MM-DD HH:mm:ss Z");
					var copyTime = time.clone();
					copyTime.tz('UTC');
					copyTime.add(time.utcOffset() - copyTime.utcOffset(), 'minutes');
					copyTime.tz(timezone);
					return copyTime.format('YYYY-MM-DD HH:mm:ss');
				}
			}
		}
	}
])
