angular.module("2cp").service("ColumnDefBuilder", [
    function() {
        var ColumnDefBuilder = function(columnDefs) {
            this.columnDefs = typeof(columnDefs) !== "undefined" ? angular.copy(columnDefs) : [];
        };
        ColumnDefBuilder.prototype.addColumnNames = function(columnNames) {
            var self = this;
            columnNames.forEach(function(name) {
                self.columnDefs.push({
                    name: name
                });
            });

            return this;
        };
        ColumnDefBuilder.prototype.addDefaultOptions = function(defaultOptions) {
            defaultOptions = angular.copy(defaultOptions); //copy to not mess with original object
            this.columnDefs.forEach(function(columnDef) {
                angular.merge(columnDef, defaultOptions);
            });

            return this;
        };
        ColumnDefBuilder.prototype.addOptionsByName = function(options) {
            options = angular.copy(options); //copy to not mess with original object
            this.columnDefs.forEach(function(columnDef) {
                if(columnDef.name in options)
                    angular.merge(columnDef, options[columnDef.name]);
            });

            return this;
        };
        ColumnDefBuilder.prototype.removeColumnsByName = function(columnNames){
            var self = this;
            for(var i = 0; i < columnNames.length; i++){
                for(var j = 0; j < self.columnDefs.length; j++){
                    if(self.columnDefs[j].name == columnNames[i]){
                        self.columnDefs.splice(j, 1);
                        break;
                    }
                }
            }
            return this;
        };
        ColumnDefBuilder.prototype.addTranslateProperties = function(translationKey) {
            if(translationKey === null || typeof(translationKey) === "undefined")
                return this;

            this.columnDefs.forEach(function(columnDef) {
                columnDef.displayName = translationKey + columnDef.name;
                columnDef.headerCellFilter = "translate";
            });

            return this;
        };
        ColumnDefBuilder.prototype.addFiltering = function(filters) {
            return this;
        };
        ColumnDefBuilder.prototype.copy = function(filters) {
            this.columnDefs = angular.copy(this.columnDefs);
            return this;
        };
        ColumnDefBuilder.prototype.build = function() {
            return this.columnDefs;
        };
        return ColumnDefBuilder;
    }
]);