angular.module("2cp")
// Product type filter
    .filter('paymentProductType', ["Payments", function (Payments) {
        return function (value) {
            if (value == Payments.PRODUCT_TYPE.PARK)
                return "tenants.payments.productTypeCell.park";
            else if (value == Payments.PRODUCT_TYPE.RENT)
                return "tenants.payments.productTypeCell.rent";
            else if (value == Payments.PRODUCT_TYPE.VEHICLE_RESERVE)
                return "tenants.payments.productTypeCell.reserve";
            else
                return "tenants.payments.productTypeCell.unknown";
        };
    }])
    .filter('reasonType', function () {
        return function (value) {
            switch (value) {
                case 'App\\ActionPayment':
                    return 'Action';
                case 'App\\BalanceUpgrade':
                    return 'Balance Upgrade';
                case 'App\\SubscriptionPayment':
                    return 'Subscription Payment';
                case 'App\\SubscriptionBalanceUpgrade':
                    return 'Subscription Balance Upgrade';
                default:
                    return 'Unknown';
            }
        };
    })
    .filter('payableType', function () {
        return function (value) {
            switch (value) {
                case 'App\\Rental':
                    return 'Rental';
                case 'App\\Booking':
                    return 'Booking';
                case 'App\\Parking':
                    return 'Parking';
                default:
                    return 'Unknown';
            }
        };
    })
    .filter('paymentSubscriptionName', function () {
        return function (value) {
            if (value) {
                return value;
            } else {
                return 'Unknown';
            }
        };
    })
    .filter('paymentTypes', function () {
        return function (value) {
            switch (value) {
                case 'subscriptionPayment':
                    return 'Subscription Payment';
                case 'balance':
                    return 'Balance Upgrade';
                case 'action':
                    return 'Action';
                case 'subscriptionBalance':
                    return 'Subscription Balance Upgrade';
                default:
                    return 'Unknown';
            }
        }
    })
    .filter('paymentTypeTranslation', function(){
        return function(value){
            return 'tenants.payments.types.' + value;
        }
    })
    .filter('moneyFilter', ['Tenants', '$filter', function (Tenants, $filter) {
        var currencySymbol = null;

        Tenants.getTenant(calllock.tenantId).then(function (tenant) {
            currencySymbol = tenant.currency.symbol;
        });

        function moneyFilter(input) {
            if (input === null) {
                return 'Unknown';
            }
            return $filter('currency')(input / 100, currencySymbol);
        }

        moneyFilter.$stateful = true;
        return moneyFilter;
    }]);

