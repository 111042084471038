'use strict';

angular.module('api').factory('Zones', [
    'clApi',
    '$q',
    function (api, $q) {
        var cache = api.getOrCreateSessionCache('Zones');

        return {
            parameters: [
                'zone_id',
                'label',
                'longitude',
                'latitude',
                'radius',
                'created_at',
                'updated_at'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getZones: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('zones', params, options).then(function (result) {
                    return result.data;
                });
            },

            getZone: function (id) {
                return api.get('zones/' + id).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('zones/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('zones', params).then(function (result) {
                    return result.data;
                });
            },
            
            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('zones/' + id));
                });

                return $q.all(promises);
            },
        };
    },
]);
