'use strict';

angular.module('api').factory('Vouchers', [
    '$q',
    'clApi',
    function ($q, api) {
        var cache = api.getOrCreateSessionCache('Vouchers');
        return {
            parameters: [
                'voucher_code',
                'valid_from',
                'valid_until',
                'claims_left',
                'updated_at',
                'discount_percentage',
                'discount_time',
                'created_at',
                'claimed',
                'voucher_id',
                'vehicleType'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getVouchers: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('vouchers', params, options).then(function (result) {
                    return result.data;
                });
            },

            getVoucher: function (id) {
                return api.get('vouchers/' + id).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('vouchers/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('vouchers', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('vouchers/' + id));
                });

                return $q.all(promises);
            },
        };
    },
]);
