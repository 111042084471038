angular.module("2cp").factory("PermissionService",
    [
        function () {
            return {
                hasPermissions: function(permissions){
                    for(var i = 0; i < permissions.length; i++){
                        var hasPerm = false;
                        for(var j = 0; j < calllock.perms.length; j++){
                            if(calllock.perms[j].name == permissions[i]){
                                hasPerm = true;
                                break;
                            }
                        }
                        if(!hasPerm){
                            return false;
                        }
                    }
                    return true;
                },
                isSuperAdmin: function(){
                    var roles = [];
                    roles = roles.concat(calllock.roles);
                    for(var i = 0; i < roles.length; i++){
                        if(roles[i].name == 'super-admin'){
                            return true;
                        }
                    }
                    return false;
                },
            };
        }
    ]
);