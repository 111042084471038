angular.module("2cp").controller("tenants.maintenance",
    [
        "$scope", "$q", "$timeout", "Maintenance", "$filter",
        "Droppoints", "Vehicles", "Locks", "MaintenanceColumns",
        'MaintenanceTaskRemarkColumns', "PermissionService", '$translate',
        'Times',
        function ($scope, $q, $timeout, Maintenance, $filter, Droppoints, Vehicles, Locks,
                  MaintenanceColumns, MaintenanceTaskRemarkColumns, PermissionService, $translate, Times) {
            var solutionId = null;
            var solutionData = null;
            $scope.buttonName = "close_task";
            
            $scope.recurUnits = Maintenance.RECUR_UNITS;
            
            function commitUpdate(id, data) {
                if(data.datetime_planned) {
                    data.datetime_planned = Times.getUTCDateTimeString(data.datetime_planned);
                }
                delete data.tenant_id;
                return Maintenance.update(id, data)
                    .then(function (result) {
                        data.datetime_planned = Times.getTenantDateTimeString(data.datetime_planned);
                        $.notify($translate.instant('notify.task_update_success'), "success");
                        return result;
                    })
                    .catch(function (e) {
                        console.error(e);
                    });
            };
            $scope.gridOptions = {
                columnDefs: MaintenanceColumns.build(),

                callGridData: {
                    id: "maintenance_task_id",
                    get: function (params) {
                        return Maintenance.getTasks(params).then(function (result) {
                            if(!PermissionService.hasPermissions(['updateTasks'])){
                                $scope.maintenanceGrid.api.callGridEditing.disableCellEdits();
                            }
                            result.data.forEach(function (item) {
                                item.datetime_planned = Times.getTenantDateTimeString(item.datetime_planned);
                                if (item.remarks.length === 0)
                                    return;
                                item.resolution = item.remarks[item.remarks.length - 1].remark;
                            });
                            return result;
                        });
                    },
                    create: function (data) {
                        $('.reveal').foundation('close');
                        if(data.datetime_planned) {
                            data.datetime_planned = Times.getUTCDateTimeString(data.datetime_planned);
                        }
                        return Maintenance.create(data)
                            .then(function (result) {
                                result.datetime_planned = Times.getTenantDateTimeString(data.datetime_planned);
                                $.notify($translate.instant('notify.task_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        if (data.status === "closed") {
                            solutionId = id;
                            solutionData = data;
                            $("#resolutionModal").foundation('open');
                            return $q(function (resolve) {
                                resolve();
                            });
                        }
                        else {
                            return commitUpdate(id, data);
                        }
                    },
                    delete: function (ids) {
                        return Maintenance.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.task_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridIcon: {
                    iconClass: [function (rowEntity) {
                        return 'fa-pencil-square-o';
                    }],
                    clickCallback: function (rowEntity) {
                        $scope.showDetailsDialog(rowEntity);
                    }
                },
                onRegisterApi: function (gridApi) {
                    $scope.maintenanceGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function () {
                var r = true;

                var rows = $scope.maintenanceGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;
                return r && PermissionService.isSuperAdmin();
            };

            $scope.showCloseTaskButton = function () {
                var r = true;

                var rows = $scope.maintenanceGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;
                else {
                    var status = rows[0].status;
                    if(rows[0].status === "closed")
                        $scope.buttonName = "reopen_task";
                    else
                        $scope.buttonName = "close_task";

                    rows.forEach(function (row) {
                        if (row.status != status) {
                            r = false;
                        }
                    })
                }
                return r;
            };

            $scope.showAcceptButton = function(){
                var r = true;

                var rows = $scope.maintenanceGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;
                else {
                    rows.forEach(function (row) {
                        if (row.status == 'closed' || row.status == 'accepted') {
                            r = false;
                        }
                    })
                }
                return r;
            };
            
            $scope.allowVehicles = PermissionService.hasPermissions(['viewVehicles']);
            $scope.allowDroppoints = PermissionService.hasPermissions(['viewDroppoints']);
            $scope.allowLockDevices = PermissionService.hasPermissions(['viewLockDevices']);

            $scope.taskDetails = {};
            $scope.priorities = Maintenance.PRIORITY;
            $scope.types = Maintenance.TYPES;

            //Details dialog
            $scope.showCreateDialog = function () {
                $scope.newTask = true;
                $scope.taskDetails = {};
                $("#maintenanceDetailsModal").foundation('open');
            };
            $scope.showDetailsDialog = function (rowEntity) {
                $scope.newTask = false;
                $scope.taskDetails = rowEntity;

                if($scope.taskDetails._old_vehicle)
                    $scope.taskDetails.vehicle = $scope.taskDetails._old_vehicle;
                $scope.taskDetails._old_vehicle = $scope.taskDetails.vehicle;
                if($scope.taskDetails._old_droppoint)
                    $scope.taskDetails.droppoint = $scope.taskDetails._old_droppoint;
                $scope.taskDetails._old_droppoint = $scope.taskDetails.droppoint;
                if($scope.taskDetails._old_lock_device)
                    $scope.taskDetails.lock_device = $scope.taskDetails._old_lock_device;
                $scope.taskDetails._old_lock_device = $scope.taskDetails.lock_device;

                $scope.taskDetails.vehicle = $scope.taskDetails.vehicle ? $scope.taskDetails.vehicle.label : null;
                $scope.taskDetails.droppoint = $scope.taskDetails.droppoint ? $scope.taskDetails.droppoint.label : null;
                $scope.taskDetails.lock_device = $scope.taskDetails.lock_device ? $scope.taskDetails.lock_device.label : null;
                $("#maintenanceDetailsModal").foundation('open');
            };
            $scope.save = function () {
                if ($scope.newTask) {
                    $scope.maintenanceGrid.api.callGridEditing.addRow($scope.taskDetails);
                }
                else {
                    if($scope.taskDetails.vehicle){
                        $scope.taskDetails.vehicleLabel = $scope.taskDetails.vehicle;
                    }
                    if($scope.taskDetails.droppoint){
                        $scope.taskDetails.droppointLabel = $scope.taskDetails.droppoint;
                    }
                    if($scope.taskDetails.lock_device){
                        $scope.taskDetails.lockDeviceLabel = $scope.taskDetails.lock_device;
                    }
                    $scope.maintenanceGrid.api.rowEdit.setRowsDirty([$scope.taskDetails]);
                }

                $("#maintenanceDetailsModal").foundation('close')
            };

            //Resolution dialogs
            $scope.addSolution = function () {
                console.log("Running Close task")
                var rows = $scope.maintenanceGrid.api.selection.getSelectedRows();
                $("#resolutionModal").foundation('close');
                var text = $("#solution").val();
                if (solutionData && solutionId) {
                    solutionData.resolution = text;
                    commitUpdate(solutionId, solutionData).then(function() {
                        $scope.maintenanceGrid.api.callGridData.get();
                    });
                    solutionId = null;
                    solutionData = null;
                }
                else {
                    rows.forEach(function (row) {
                        row.resolution = text;
                        if ($scope.buttonName === "close_task") {
                            console.log("Entered for buttonName = closed task");
                            row.status = "closed";
                        } else {
                            console.log("Entered else");
                            row.status = "open";
                        }
                        commitUpdate(row.maintenance_task_id, row).then(function() {
                            $scope.maintenanceGrid.api.callGridData.get();
                        });
                    });
                }
            };
            $scope.removeSelected = function () {
                $scope.maintenanceGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.acceptSelected = function(){
                var rows = $scope.maintenanceGrid.api.selection.getSelectedRows();
                for(var i = 0; i < rows.length; i++){
                    rows[i].status = 'accepted';
                    rows[i].date_accepted = moment().utc().format('YYYY-MM-DD HH:mm:ss');
                    $scope.maintenanceGrid.api.rowEdit.setRowsDirty([rows[i]]);
                }
            };

            //Autocomplete stuff
            if($scope.allowVehicles) {
                Vehicles.getVehicles({limit: 1000}).then(function (vehicles) {
                    vehicles = vehicles.data;
                    $scope.vehicleSuggestions = vehicles;
                    $scope.vehicleData = function (item, input) {
                        return {
                            label: item.label,
                            value: item.vehicle_id
                        }
                    };
                });
            }

            $scope.vehicleSelected = function(evt){
                $timeout(function(){
                    $scope.taskDetails.vehicle_id = evt.text.value;
                    $scope.taskDetails.vehicle = evt.text.label;
                });
            };

            if($scope.allowDroppoints) {
                Droppoints.getDroppoints({limit: 1000}).then(function (droppoints) {
                    droppoints = droppoints.data;
                    $scope.droppointSuggestions = droppoints;
                    $scope.droppointData = function (item, input) {
                        return {
                            label: item.label,
                            value: item.droppoint_id
                        }
                    };
                });
            }

            $scope.droppointSelected = function(evt){
                $timeout(function(){
                    $scope.taskDetails.droppoint_id = evt.text.value;
                    $scope.taskDetails.droppoint = evt.text.label;
                });
            };

            if($scope.allowLockDevices) {
                Locks.getLocks({limit: 1000}).then(function (locks) {
                    locks = locks.data;
                    $scope.lockDeviceSuggestions = locks;
                    $scope.lockDeviceData = function (item, input) {
                        return {
                            label: item.label,
                            value: item.lock_device_id
                        }
                    };
                });
            }

            $scope.lockDeviceSelected = function(evt){
                $timeout(function(){
                    $scope.taskDetails.lock_device_id = evt.text.value;
                    $scope.taskDetails.lock_device = evt.text.label;
                });
            };
        }]
);