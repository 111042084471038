angular.module("2cp", [
    "api",
    "pascalprecht.translate",
    "ui.grid",
    "ui.grid.selection",
    "ui.grid.saveState",
    "ui.grid.moveColumns",
    "ui.grid.resizeColumns",
    "ui.grid.pagination",
    "ui.grid.autoResize",
    "ui.grid.selection",
    "ui.grid.edit",
    "ui.grid.rowEdit",
    "ui.grid.exporter",
    "angular-cache",
    "ngFileUpload",
    "LocalStorageModule",
    "color.picker",
]).config(["$httpProvider", "$locationProvider", "clApiConfigProvider", '$provide', function ($httpProvider, $locationProvider, apiConfigProvider, $provide) {
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.headers.common["X-Requested-With"] = 'XMLHttpRequest';

    $locationProvider.html5Mode({
        enabled: true,
        rewriteLinks: false
    });

    $provide.decorator('GridOptions', ['$delegate', '$translate', '$filter', '$timeout', function ($delegate, $translate, $filter, $timeout) {
        var gridOptions;
        gridOptions = angular.copy($delegate);
        gridOptions.initialize = function (options) {
            var initOptions;
            initOptions = $delegate.initialize(options);
            initOptions.exporterMenuPdf = false;
            initOptions.exporterHeaderFilter = $translate.instant;
            initOptions.exporterAllDataFn = function(){
                initOptions.callGridData.params.limit = initOptions.totalItems;
                initOptions.paginationPageSize = initOptions.totalItems;
                initOptions.paginationCurrentPage = 1;
                initOptions.paginationPageSizes.push(initOptions.totalItems);
                initOptions.paginationPageSizes.sort(function(a, b){
                    return a - b;
                });
                return $timeout(function(){
                    return initOptions.callGridData.get(initOptions.callGridData.params).then(function(response){
                        initOptions.data = response.data;
                    });
                }, 0)
            };
            initOptions.exporterFieldCallback = function (grid, row, col, input) {
                if (col.cellFilter) { // check if any filter is applied on the column
                    var filters = col.cellFilter.split('|'); // get all the filters applied
                    angular.forEach(filters, function (filter) {
                        var rowCopy = row;
                        var filterName = filter.match(/(".*?"|[^":\s]+)(?=\s*:|\s*$)/g)[0]; // fetch filter name
                        var filterParams = filter.match(/(".*?"|[^":\s]+)(?=\s*:|\s*$)/g).splice(1); //fetch all the filter parameters
                        filterParams.unshift(input); // insert the input element to the filter parameters list
                        var filterFn = $filter(filterName); // filter function
                        //If parameter is 'this' it should pass an object containing the row
                        for (var i = 0; i < filterParams.length; i++) {
                            if (filterParams[i] === 'this') {
                                filterParams[i] = {row: rowCopy};
                            }
                        }
                        // call the filter, with multiple filter parameters.
                        //'Apply' will call the function and pass the array elements as individual parameters to that function.
                        input = filterFn.apply(this, filterParams);
                    });
                    return input;
                }
                else
                    return input;
            };
            return initOptions;
        };
        return gridOptions;
    }]);

    apiConfigProvider.useAuthentication = false;
}]).run(["clApi", function (api) {

    if (typeof(calllock) !== "undefined")
        api.tenantId = calllock.tenantId;
}]).factory('errorInterceptor', ['$q', '$translate', function ($q, $translate) {
    return {
        responseError: function (response) {
            if (response && response.status >= 300) {
                var toTranslate = 'notify.' + response.data.exception_id;
                var translated = $translate.instant(toTranslate);
                if(toTranslate === translated){
                    $.notify(response.data.message);
                }else{
                    $.notify(translated);
                }
            }
            return $q.reject(response);
        }
    };
}]).config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('errorInterceptor');
}]);

// This fixes the popup size.
// You might want to move this to a nicer place later.
$("document").ready(function () {
    $(".reveal").on("open.zf.reveal", function () {
        $(window).trigger('resize');
    })
});