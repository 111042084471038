angular.module("2cp").factory("SubscriptionColumns",
    [
        "$translate", "$filter", 'Subscriptions',
        "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, Subscriptions,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                name: {
                    visible: true,
                    enableCellEdit: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                },
                payment_frequency: {
                    visible: true,
                    selectOptions: {
                        keys: Subscriptions.SUBSCRIPTION_FREQUENCIES,
                        filter: 'subscriptionFrequencies'
                    },
                },
                valid_from: {
                    visible: true,
                    enableCellEdit: true,
                    //cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                valid_until: {
                    visible: true,
                    enableCellEdit: true,
                    //cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                created_at:{
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at:{
                    visible: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                renew: {
                    visible: true,
                    cellFilter: 'subscriptionBooleanInput',
                    enableCellEdit: true,
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'subscriptionBoolean'
                    }
                },
                pay_upfront: {
                    cellFilter: 'subscriptionBooleanInput',
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'subscriptionBoolean'
                    }
                },
                hidden: {
                    visible: true,
                    cellFilter: 'subscriptionBooleanInput',
                    enableCellEdit: true,
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'subscriptionBoolean'
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Subscriptions.SUBSCRIPTION_PARAMETERS)
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.subscriptions.")
                .copy();


            return builder;
        }
    ]
);