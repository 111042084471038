'use strict';

angular.module('api').factory('Rentals', [
    '$http',
    '$log',
    'clApi',
    function ($http, $log, api) {
        return {

            statuses: [
                'start_pending',
                'operator_started',
                'started',
                'end_pending',
                'operator_ended',
                'ended',
                'ended_error'
            ],

            parameters:[
                'start_datetime',
                'end_datetime',
                'duration',
                'startLabel',
                'endLabel',
                'payment_amount',
                'payment_user_identifier_user_username',
                'rental_id',
                'created_at',
                'updated_at',
                'status',
                'vehicle_id',
                'vehicleLabel',
            ],

            getParameters: function () {
                return this.parameters;
            },

            startRental: function (vehicleLabel, params) {
				if(!params)
					params = {};
				params.vehicleLabel = vehicleLabel;
                params.startNow = false;

                return api.post('rentals', params).then(function (result) {
                    return result.data;
                });
            },

            startOperatorRental: function (vehicleLabel, params) {
				if(!params)
					params = {};
				params.vehicleLabel = vehicleLabel;
                params.startNow = false;

                return api.post('rentals/operatorStart', params).then(function (result) {
                    return result.data;
                });
            },

            confirmStartRent: function(rental_id, user_id, tenant_id){
                var params = {
                    rental_id: rental_id,
                    user_id: user_id,
                    tenant_id: tenant_id
                };
                return api.post('rentals/confirmStartRent', params).then(function(result){
                    return result.data;
                });
            },

            confirmOperatorStart: function(rental_id, user_id, tenant_id){
                var params = {
                    rental_id: rental_id,
                    user_id: user_id,
                    tenant_id: tenant_id
                };
                return api.post('rentals/confirmOperatorStart', params).then(function(result){
                    return result.data;
                });
            },

            getRentals: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('rentals', params, options).then(function (result) {
                    var data = result.data;
                    for(var i = 0; i < data.data.length; i++){
                        if(data.data[i].vehicle) {
                            data.data[i].vehicleLabel = data.data[i].vehicle.label;
                        }
                        if(data.data[i].payment) {
                            data.data[i].payment_amount = data.data[i].payment.payment.amount;
                            data.data[i].payment_user_identifier_user_username = data.data[i].payment.payment.user_identifier.user.username;
                        }
                    }
                    return data;
                });
            },

            getRental: function (params) {

            },

            endRental: function (tenantId, vehicleId, droppointDeviceId, voucher, data) {
				if(!data)
					data = {};
				data.tenant_id = tenantId;
				data.vehicle_id = vehicleId;
				if(droppointDeviceId) {
                    data.droppoint_device_id = droppointDeviceId;
                }
				data.voucher_code = voucher;

                return api.post('rentals/end', data).then(function (result) {
                    return result.data;
                });
            },
        };
    },
]);
