function scrollToSection(selector) {
    var position = $(selector).offset().top
    $('html, body').stop().animate({scrollTop: position}, '500', 'swing');
}

function makeFloating(selector, stopAt) {
    if ($(selector).length > 0) {
        var panel = $(selector)
        var footer = $(stopAt)
        var doc = $(document)

        // Calculate offsets
        var panelOffset = panel.position().top

        // Reset some stuff on resize
        $(window).resize(function() {
            panel.css('transform', 'initial')
            panelOffset = panel.position().top
            $(document).trigger('scroll')
        });

        $(document).on('scroll', function() {
            // Get current scroll offset
            var pageOffset = doc.scrollTop()

            // Overlap of div at the bottom we need to correct later
            var bottomOverlap = $(window).scrollTop() + $(window).height() - footer.position().top

            // Offset of panel container
            var panelOffset = panel.parent().offset().top

            // Calculate diff between page offset and panel offset
            var diff = pageOffset - panelOffset

            // Set fixed/relative
            if (diff > 0) {
                panel.css({
                    'position' : 'fixed',
                    'top' : 0
                })

                // Fix bottom overlap
                if (bottomOverlap > 0) {
                    panel.css({
                        'bottom': bottomOverlap
                    })
                }
            } else {
                panel.css({
                    'position': 'relative'
                })
            }
        })
    }
}

$('document').ready(function() {
    makeFloating('.news-preview', 'footer')
})