angular.module("2cp").factory("ZoneColumns",
    [
        "$translate", "$filter", "uiGridConstants", "$timeout",
        "Zones", "Users", "UserIdentifiers",
        "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants, $timeout,
                  Zones, Users, UserIdentifiers,
                  ColumnDefBuilder) {
            var columnOptions = {
                zone_id: {
                    enableFiltering: true,
                    visible: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                label: {
                    enableFiltering: true,
                    visible: true
                },
                radius: {
                    enableFiltering: true,
                    visible: true
                },
                longitude: {
                    enableFiltering: true
                },
                latitude: {
                    enableFiltering: true
                },
                hidden: {
                    cellFilter: 'locationBooleanInput',
                    enableCellEdit: true,
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'locationBoolean'
                    },
                    enableFiltering: true
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Zones.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.zones.')
                .copy();

            return builder;
        }
    ]
);