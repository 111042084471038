angular.module("2cp")
// Filter for lock mode
    .filter('subscriptionFrequencies', function () {
        return function (input) {
            input = input.charAt(0).toUpperCase() + input.slice(1);
            return input;
        }
    })
    .filter('subscriptionBoolean', function () {
        return function (input) {
            if (input == 1) {
                return 'True';
            }
            if (input == 0) {
                return 'False';
            }
            return '';
        }
    })
    .filter('subscriptionBooleanInput', function () {
        return function (input) {
            if (input == 1) {
                return 'True';
            }
            if (input == 0) {
                return 'False';
            }
            return '';
        }
    })
    .filter('vehicleType', ['VehicleTypes', function (VehicleTypes) {
        var types = null;
        var busy = false;

        vehicleType.$stateful = true;

        function vehicleType(input){
            var name = 'Loading...';
            if(types){
                for (var i = 0; i < types.length; i++) {
                    if (types[i].vehicle_type_id == input) {
                        name = types[i].name;
                    }
                }
            }else{
                if(busy === false){
                    busy = true;
                    VehicleTypes.getVehicleTypes({'limit': 100}).then(function(response){
                        types = response.data;
                        busy = false;
                    });
                }
            }
            return name;
        }

        return vehicleType;

    }])
;