/**
 * columnDefs can have the following new properties:
 *
 * dateTimeOptions: { //if this option is present, we assume the column contains datetime
 *  directive: ... //directive to apply to the editable cell template
 * }
 *
 * selectOptions: { //if this option is present, we assume the column has a select dropdown
 *  keys: ... //array of keys of the select options
 *  filter: ... //filter to apply to all the keys
 * }
 */
angular.module("2cp").directive("callGridPagination", [
    "$rootScope", "$location", "$timeout", "uiGridConstants",
    function ($rootScope, $location, $timeout, uiGridConstants) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            compile: function(tElem, tAttrs) {
                return {
                    pre: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;
                        grid.options.useExternalPagination = true;
                        grid.options.paginationPageSizes = [25, 50, 75];
                        grid.options.paginationPageSize = 50;
                        grid.options.callGridData.params['limit'] = 50;

                    },
                    post: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;

                        //Start at page one
                        //grid.options.callGridData.params.page = 1;
                        grid.options.totalItems = grid.options.numberOfItems;


                        //Listen to pagination changes
                        grid.api.pagination.on.paginationChanged($scope, function(currentPage, pageSize) {
                            grid.options.callGridData.params.page = currentPage;
                            grid.options.callGridData.params['limit'] = pageSize;
                            grid.api.callGridData.get()
                                .then(function() {
                                    if(grid.options.numberOfItems) {
                                        grid.options.totalItems = grid.options.numberOfItems;
                                    }
                                });
                        });
                    }
                };
            }
        };
    }]);