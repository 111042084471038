'use strict';

angular.module('api').factory('Permissions', [
    '$q',
    'clApi',
    function ($q, api) {
        var permissions = {
            PERMISSION_TYPE: [],

            parameters: [
                'id',
                'permission_type',
                'name',
                'display_name',
                'description',
            ],

            getParameters: function () {
                return this.parameters;
            },
            
            getTypes: function(){
                return this.PERMISSION_TYPE;
            },

            isSuperAdmin: function () {
                return api.get('permissions').then(function (result) {
                    return result.data.partner_0 && result.data.partner_0[0] && result.data.partner_0[0] === '*RW';
                });
            },

            getAvailable: function (params) {
                return api.get('permissions/available', params).then(function (result) {
                    for(var i = 0; i < result.data.data.length; i++){
                        if(permissions.PERMISSION_TYPE.indexOf(result.data.data[i].permission_type) === -1){
                            permissions.PERMISSION_TYPE.push(result.data.data[i].permission_type);
                        }
                    }
                    return result.data;
                });
            },
        };
        return permissions;
    },
]);
