angular.module("2cp").directive("awesomplete", [
    function () {
        return {
            restrict: 'A',
            scope: {
                awesompleteDataFunction: '=',
                awesompleteSuggestions: '=',
                awesompleteComplete: '='
            },
            link: function($scope, iElem, iAttrs) {
                iElem.addClass('awesomplete');
                iElem = iElem[0];

                var a = new Awesomplete(iElem, {
                    list: $scope.awesompleteSuggestions,
                    autoFirst: true,
                    data: $scope.awesompleteDataFunction
                });

                //Clicking on the scrollbar triggers a blur event. To prevent this,
                //we overwrite the onmousedown function and return false.
                a.ul.onmousedown = function(evt) {
                    return false;
                }

                iElem.addEventListener('awesomplete-open', function(evt) {
                    a.ul.scrollTop = 0;
                });
                iElem.addEventListener('awesomplete-highlight', function(evt){
                    var selectedItem = a.ul.querySelector('[aria-selected=true]');
                    if(selectedItem)
                        selectedItem.scrollIntoView(false);
                });
                iElem.addEventListener('awesomplete-selectcomplete', function(evt) {
                    $(iElem).trigger('input');
                    if($scope.awesompleteComplete) {
                        $scope.awesompleteComplete(evt);
                    }
                });

                //Watch for data changes
                $scope.$watch("awesompleteDataFunction", function(newValue, oldValue) {
                    a.data = newValue;
                });
                $scope.$watch("awesompleteSuggestions", function(newValue, oldValue) {
                    a.list = newValue;
                });
            }
        };
    }]);