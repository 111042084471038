angular.module("2cp").controller("app.project-info",
    [
        "$scope", "$q", "$timeout", "$log", "$filter", "Tenants", '$translate',
        function ($scope, $q, $timeout, $log, $filter, Tenants, $translate) {
            $scope.save = function() {
                $scope.saving = true;
                $scope.tenant.project_info = CKEDITOR.instances.editor.getData();
                Tenants.update(calllock.tenantId, $scope.tenant).then(function() {
                    $.notify($translate.instant('notify.project_update_success'), 'success');
                }).catch(function() {
                    $log.error(e);
                }).finally(function() {
                    $scope.saving = false;
                });
            };

            $scope.tenant = null;
            Tenants.getTenant(calllock.tenantId).then(function(result) {
                $scope.tenant = result;
                CKEDITOR.instances.editor.setData($scope.tenant.project_info);
            });
        }
    ]
);