angular.module("2cp").directive("callGridState", ["translateFilter", "$window", "localStorageService", "$timeout", "$log",
    function (translateFilter, $window, localStorageService, $timeout, $log) {
        return {
            restrict: 'A',
            replace: true,
            priority: 10, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function($scope, $elm, $attrs, uiGridCtrl) {
                var grid = uiGridCtrl.grid;
                var localStorageKey = 'callGridState-' + $attrs.callGridState;

                //Set grid options
                grid.options.saveWidths = true;
                grid.options.saveOrder = true;
                grid.options.saveScroll = false;
                grid.options.saveFocus = false;
                grid.options.saveVisible = true;
                grid.options.saveSort = false;
                grid.options.saveFilter = false;
                grid.options.savePinning = true;
                grid.options.saveGrouping = true;
                grid.options.saveGroupingExpandedStates = true;
                grid.options.saveTreeView = false;
                grid.options.saveSelection = false;

                //Saving
                var previousOnBeforeUnload = $window.onbeforeunload;
                $window.onbeforeunload = function (evt) {
                    if(previousOnBeforeUnload)
                        previousOnBeforeUnload();

                    $log.debug('Saving state of grid: ' + $attrs.callGridState);
                    localStorageService.set(localStorageKey, grid.api.saveState.save());
                };

                //Restoring
                var defaultState = null;
                var state = localStorageService.get(localStorageKey);
                if(state) {
                    $timeout(function() {
                        //Save default state
                        defaultState = grid.api.saveState.save();

                        //Restore state
                        $log.debug('Restoring state of grid: ' + $attrs.callGridState);
                        grid.api.saveState.restore($scope, state);
                    }, 0);
                }

                //Add grid menu item for resetting to default
                grid.options.gridMenuCustomItems = [
                    {
                        title: 'Reset columns to default',
                        action: function ($event) {
                            $log.debug('Reset state of grid: ' + $attrs.callGridState);
                            localStorageService.remove(localStorageKey);

                            if(defaultState !== null)
                                grid.api.saveState.restore($scope, defaultState);
                        },
                        order: 210
                    }
                ];
            }
        };
    }]);