/**
 * columnDefs can have the following new properties:
 *
 * dateTimeOptions: { //if this option is present, we assume the column contains datetime
 *  directive: ... //directive to apply to the editable cell template
 * }
 *
 * selectOptions: { //if this option is present, we assume the column has a select dropdown
 *  keys: ... //array of keys of the select options
 *  filter: ... //filter to apply to all the keys
 * }
 */
angular.module("2cp").directive("callGridFiltering", [
    "$rootScope", "$location", "$timeout", "Dropdowns", "uiGridConstants", "$filter",
    function ($rootScope, $location, $timeout, Dropdowns, uiGridConstants, $filter) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            compile: function (tElem, tAttrs) {
                return {
                    pre: function ($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;
                        grid.options.enableFiltering = true;
                        grid.options.useExternalFiltering = true;

                        //Initialize defaults
                        if (typeof(grid.options.callGridFiltering) === "undefined")
                            grid.options.callGridFiltering = {};
                        if (typeof(grid.options.callGridFiltering.queryParameterPrefix) === "undefined")
                            grid.options.callGridFiltering.queryParameterPrefix = "";
                        if (typeof(grid.options.callGridFiltering.updateUrl) === "undefined")
                            grid.options.callGridFiltering.updateUrl = true;

                        grid.callGridFiltering = {
                            instantUpdateOnNextChange: false
                        };
                    },
                    post: function ($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;

                        //Respond to columnDef custom options
                        grid.options.columnDefs.forEach(function (columnDef) {
                            if (columnDef.dateTimeOptions) { //Respond to dateTimeOptions
                                columnDef.filterHeaderTemplate = '<div ' +
                                    'class="ui-grid-filter-container" style="width: 50%; float: left;" ' +
                                    'ng-repeat="colFilter in col.filters"><input type="text" ' +
                                    'ng-model="colFilter.term" ' + columnDef.dateTimeOptions.headerDirective + ' class="from-to ui-grid-filter-input">' +
                                    "<div role=\"button\" class=\"ui-grid-filter-button\" ng-click=\"removeFilter(colFilter, $index)\" ng-if=\"!colFilter.disableCancelFilterButton\" ng-disabled=\"colFilter.term === undefined || colFilter.term === null || colFilter.term === ''\" ng-show=\"colFilter.term !== undefined && colFilter.term !== null && colFilter.term !== ''\">" +
                                    "<i class=\"ui-grid-icon-cancel\" ui-grid-one-bind-aria-label=\"aria.removeFilter\"> &nbsp; </i>" +
                                    "</div></div>"

                                columnDef.editableCellTemplate = Dropdowns.createDateTemplate(
                                    columnDef.name,
                                    columnDef.dateTimeOptions.cellDirective,
                                    columnDef.cellFilter
                                );

                                columnDef.filters = [
                                    {
                                        condition: uiGridConstants.filter.GREATER_THAN
                                    },
                                    {
                                        condition: uiGridConstants.filter.LESS_THAN
                                    }
                                ];
                            }
                            else if (columnDef.selectOptions) { //Respond to selectOptions
                                function getDictionaryValues(dict) {
                                    return Object.keys(dict).map(function (key) {
                                        return dict[key];
                                    });
                                }

                                columnDef.editDropdownOptionsArray = getDictionaryValues(columnDef.selectOptions.keys);
                                columnDef.editableCellTemplate = Dropdowns.createCellTemplate(columnDef.selectOptions.filter);
                                columnDef.filter = Dropdowns.createFilter( // Does this set the header filter template?
                                    columnDef.editDropdownOptionsArray,
                                    columnDef.selectOptions.filter
                                );
                            }
                            else if (columnDef.awesomplete) { //Response to autocomplete options
                                columnDef.filterHeaderTemplate =
                                    '<div ' +
                                    'class="ui-grid-filter-container" ' +
                                    'ng-repeat="colFilter in col.filters" ' +
                                    'ng-class="{\'ui-grid-filter-cancel-button-hidden\' : colFilter.disableCancelFilterButton === true }"> ' +

                                    '<input ' +
                                    'type="text" ' +
                                    'class="ui-grid-filter-input ui-grid-filter-input-{{$index}}" ' +
                                    'ng-model="colFilter.term" ' +
                                    'ng-attr-placeholder="{{colFilter.placeholder || \'\'}}" ' +
                                    'aria-label="{{colFilter.ariaLabel || aria.defaultFilterLabel}}" ' +
                                    'awesomplete ' +
                                    'awesomplete-suggestions="col.colDef.awesomplete.suggestions" ' +
                                    'awesomplete-data-function="col.colDef.awesomplete.dataFunction" ' +
                                    '/>' +

                                    '<div ' +
                                    'role="button" ' +
                                    'class="ui-grid-filter-button" ' +
                                    'ng-click="removeFilter(colFilter, $index)" ' +
                                    'ng-if="!colFilter.disableCancelFilterButton" ' +
                                    'ng-disabled="colFilter.term === undefined || colFilter.term === null || colFilter.term === \'\'" ' +
                                    'ng-show="colFilter.term !== undefined && colFilter.term !== null && colFilter.term !== \'\'"> ' +
                                    '<i ' +
                                    'class="ui-grid-icon-cancel" ' +
                                    'ui-grid-one-bind-aria-label="aria.removeFilter"> ' +
                                    '&nbsp; ' +
                                    '</i> ' +
                                    '</div> ';
                            }
                        });

                        /**
                         * Try to convert data types. E.g., in the url an int is also represented
                         * as a string. So we convert "7" to 7.
                         * @param filters The filters to convert. Will do the conversion in place.
                         * @returns {*} The filters.
                         */
                        function convertFilterTypes(filters) {
                            //Try to parse filters and convert to number if necessary
                            for (var key in filters) {
                                var value = filters[key];
                                if (value === null)
                                    continue;

                                var number = Number(value);
                                if (!isNaN(number))
                                    filters[key] = number;
                            }

                            return filters;
                        }

                        /**
                         * Get the filters from the url.
                         * @returns {{}}
                         */
                        function getUrlFilters() {
                            var prefix = grid.options.callGridFiltering.queryParameterPrefix;
                            var r = {};

                            //Get the filters and remove the prefix
                            for (var key in $location.search()) {
                                if (key.startsWith(prefix))
                                    r[key.slice(prefix.length)] = $location.search()[key];
                            }

                            r = convertFilterTypes(r);

                            return r;
                        }

                        function setUrlFilters(filters) {
                            var prefix = grid.options.callGridFiltering.queryParameterPrefix;

                            var urlFilters = {};

                            //Add a prefix to the key if it does not have it
                            for (var key in filters) {
                                if (prefix === "" || !key.startsWith(prefix)) {
                                    urlFilters[prefix + key] = filters[key];
                                }
                            }

                            //Merge with existing other parameters. We create a copy of
                            //$location.search because otherwise it would apply the filters to the
                            //url immediately.
                            urlFilters = angular.extend(angular.copy($location.search()), urlFilters);

                            //Actually set URL but only if there is a change. This is to prevent
                            //multiple locationChange events.
                            //console.log($location.search());
                            //console.log(urlFilters);
                            //console.log(angular.equals($location.search(), urlFilters));
                            if (!angular.equals($location.search(), urlFilters))
                                $location.search(urlFilters);
                        }

                        /**
                         * Get the current filters from the grid.
                         * @returns {{}}
                         */
                        function getGridFilters() {
                            var filters = {};
                            grid.columns.forEach(function (column) {
                                if (column.filters.length > 1) {
                                    if (typeof(column.filters[0].term) !== "undefined" && column.filters[0].term) {
                                        var datetime = new Date(column.filters[0].term);
                                        var fieldName = column.name + "_min";

                                        if (!isNaN(datetime.getTime())) {
                                            datetime = $filter('utcdate')(datetime);
                                            filters[fieldName] = column.filters[0].term;
                                        } else {
                                            if (/^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(column.filters[0].term)) {
                                                filters[fieldName] = column.filters[0].term;
                                            } else {
                                                filters[fieldName] = null;
                                            }
                                        }
                                    }
                                    else {
                                        var fieldName = column.name + "_min";
                                        filters[fieldName] = null;
                                    }
                                    if (typeof(column.filters[1].term) !== "undefined" && column.filters[1].term) {
                                        var datetime = new Date(column.filters[1].term);
                                        var fieldName = column.name + "_max";

                                        if (!isNaN(datetime.getTime())) {
                                            datetime = $filter('utcdate')(datetime);
                                            filters[fieldName] = column.filters[1].term;
                                        } else {
                                            if (/^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(column.filters[1].term)) {
                                                filters[fieldName] = column.filters[1].term;
                                            } else {
                                                filters[fieldName] = null;
                                            }
                                        }
                                    }
                                    else {
                                        var fieldName = column.name + "_max";
                                        filters[fieldName] = null;
                                    }
                                }
                                else if (typeof(column.filters[0].term) !== "undefined") {
                                    filters[column.name] = column.filters[0].term;
                                }
                            });

                            //Make sure no filters are an empty string
                            for (var key in filters) {
                                if (filters[key] === "") {
                                    filters[key] = null;
                                }
                            }
                            filters = convertFilterTypes(filters);

                            return filters;
                        }

                        /**
                         * Set the grid filters programmatically.
                         * @param filters The fitlers, in the form { <filter_name>: <value> } or
                         * { <filter_name>: [<value>,<value] } in the case of multiple filters.
                         * @param loadDataInstantly When true, immediately load the new data.
                         * This defaults to false.
                         */
                        function setGridFilters(filters, loadDataInstantly) {
                            $timeout(function () {
                                if (loadDataInstantly)
                                    grid.callGridFiltering.instantUpdateOnNextChange = true;

                                //If the columns we set the filter on are all not visible,
                                //the data will not update automatically and we have to do it manually.
                                var manualUpdate = true;

                                grid.columns.forEach(function (column) {
                                    var filterToApply = filters[column.field];
                                    if (typeof(filterToApply) === "undefined")
                                        return;

                                    if (!angular.isArray(filterToApply))
                                        filterToApply = [filterToApply];

                                    for (var i = 0; i < filterToApply.length; i++) {
                                        column.filters[i].term = filterToApply[i];
                                    }

                                    // Because of the cached columns this does not work anymore
                                    // if (column.visible)
                                    //     manualUpdate = false;
                                });

                                if (manualUpdate)
                                    loadData(getGridFilters());
                            }, 0);
                        }

                        /**
                         * Load new data into the grid.
                         * @param filters
                         */
                        function loadData(filters) {
                            //Load the data in the grid using the filters
                            grid.options.callGridData.params = angular.extend({}, grid.options.callGridData.params, filters);
                            grid.api.callGridData.get();
                        }

                        //Listen to filter changes and if necessary reload the data
                        grid.api.core.on.filterChanged($scope, function () {
                            if (grid.callGridFiltering.filterTimeout)
                                $timeout.cancel(grid.callGridFiltering.filterTimeout);

                            grid.callGridFiltering.filterTimeout = $timeout(function () {
                                grid.callGridFiltering.instantUpdateOnNextChange = false;

                                var gridFilters = getGridFilters();

                                if (grid.options.callGridFiltering.updateUrl)
                                    setUrlFilters(gridFilters);

                                loadData(gridFilters);
                            }, grid.callGridFiltering.instantUpdateOnNextChange ? 0 : 500);
                        });

                        grid.clearAllFilters = function (refreshRows, clearConditions, clearFlags) {
                            if (clearConditions === undefined) {
                                clearConditions = false;
                            }
                            if (clearFlags === undefined) {
                                clearFlags = false;
                            }

                            this.columns.forEach(function (column) {
                                column.filters.forEach(function (filter) {
                                    filter.term = undefined;

                                    if (clearConditions) {
                                        filter.condition = undefined;
                                    }

                                    if (clearFlags) {
                                        filter.flags = undefined;
                                    }
                                });
                            });
                            grid.options.columnDefs.forEach(function (columnDef) {
                                if (grid.options.callGridData.params[columnDef.name]) {
                                    delete grid.options.callGridData.params[columnDef.name];
                                }
                            });
                            $location.search(getGridFilters());

                        };

                        //Listen to URL changes.
                        //The location change event always gets called at page load. We use that to initialise
                        //the data. Normally the callGridData class does that but here it is done instead.
                        var initialLocationChange = true;
                        grid.options.callGridData.loadImmediately = false; //disable callGridData initial load
                        $rootScope.$on('$locationChangeSuccess', function ($event) {
                            //Do we need to listen to url updates?
                            if (!grid.options.callGridFiltering.updateUrl || !initialLocationChange)
                                return;

                            var filters = getUrlFilters();
                            //Update values in grid filters
                            grid.columns.forEach(function (column) {
                                if (column.filters.length > 1) {
                                    if (column.name + "_min" in filters)
                                        column.filters[0].term = filters[column.name + "_min"];
                                    if (column.name + "_max" in filters)
                                        column.filters[1].term = filters[column.name + "_max"];
                                }
                                else {
                                    if (column.name in filters)
                                        column.filters[0].term = filters[column.name];
                                }
                            });
                            //If this is the initial url change, load the data
                            loadData(filters);
                            initialLocationChange = false;
                        });

                        var methods = {
                            callGridFiltering: {
                                setGridFilters: setGridFilters
                            }
                        }
                        grid.api.registerMethodsFromObject(methods);
                    }
                }
            }
        };
    }]);