angular.module("2cp").controller("tenants.locations",
    [
        "$scope", "ZoneColumns", "Zones", "$window", "Droppoints", "DroppointColumns",
        "DroppointDeviceColumns", "DroppointDevices", "$timeout", "PermissionService", "Tenants", "Address", 'Times', '$translate',
        function ($scope, ZoneColumns, Zones, $window, Droppoints, DroppointColumns,
                  DroppointDeviceColumns, DroppointDevices, $timeout, PermissionService, Tenants, Address, Times, $translate) {
            $scope.timezone = Times.getZone();
            $scope.countries = Address.getCountries();
            /************************************************************************************
             *********************************** Zones ******************************************
             ************************************************************************************/
            $scope.zonesGridOptions = {
                columnDefs: ZoneColumns.build(),

                callGridData: {
                    id: "zone_id",
                    get: function (data) {
                        return Zones.getZones(data).then(function (response) {
                            for (var i = 0; i < response.data.length; i++) {
                                response.data[i].hidden = (response.data[i].hidden == 1);
                            }
                            if (!PermissionService.hasPermissions(['updateZones'])) {
                                $scope.zonesGrid.api.callGridEditing.disableCellEdits();
                            }
                            return response;
                        })
                    },
                    create: function (data) {
                        $('.reveal').foundation('close');
                        return Zones.create(data)
                            .then(function (result) {
                                $scope.availableZones.push(result);
                                $.notify($translate.instant('notify.zone_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        return Zones.update(id, data)
                            .then(function (result) {
                                for (var i = 0; i < $scope.availableZones.length; i++) {
                                    if ($scope.availableZones[i].zone_id == id) {
                                        $scope.availableZones[i] = result;
                                        break;
                                    }
                                }
                                $.notify($translate.instant('notify.zone_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Zones.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.zone_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showZoneDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "zones."
                },
                onRegisterApi: function (gridApi) {
                    $scope.zonesGrid = gridApi.grid;
                }
            };

            $scope.showZonesDeleteButton = function () {
                var r = true;
                var rows = $scope.zonesGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelectedZones = function () {
                $scope.zonesGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.zonePermission = function () {
                var className = '';
                if (PermissionService.hasPermissions(['viewZones'])) {
                    className = 'is-active';
                }
                return className;
            };

            $scope.droppointPermission = function () {
                var className = '';
                if (!($scope.zonePermission() == 'is-active')) {
                    if (PermissionService.hasPermissions(['viewDroppoints'])) {
                        className = 'is-active';
                    }
                    return className;
                } else {
                    return className;
                }
            };

            $scope.droppointDevicePermission = function () {
                var className = '';
                if (!($scope.droppointPermission() == 'is-active' || $scope.zonePermission() == 'is-active')) {
                    if (PermissionService.hasPermissions(['viewDroppointDevices'])) {
                        className = 'is-active';
                    }
                    return className;
                } else {
                    return className;
                }
            };


            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showZoneDetailsModal = function (creationModal, rowEntity) {
                $scope.zoneDetails = rowEntity;
                $scope.zoneCreationModal = creationModal;

                $("#zoneDetailsModal").foundation('open');
            };

            $scope.zoneDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');

                if (!updatedRow.hidden) {
                    updatedRow.hidden = false;
                }

                if ($scope.zoneCreationModal)
                    $scope.zonesGrid.api.callGridEditing.addRow(updatedRow);
                else
                    $scope.zonesGrid.api.rowEdit.setRowsDirty([updatedRow]);
            };

            /************************************************************************************
             ******************************** Droppoints ****************************************
             ************************************************************************************/
            $scope.weekdays = [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday'
            ];
            $scope.types = Droppoints.OPENING_TYPES;

            $scope.updateDroppoints = PermissionService.hasPermissions(['updateDroppoints']);
            $scope.getEntries = function(day){
                var entries = [];
                if($scope.droppointDetails && $scope.droppointDetails.opening_schedules && $scope.droppointDetails.opening_schedules[0]){
                    for(var i = 0; i < $scope.droppointDetails.opening_schedules[0].entries.length; i++){
                        if($scope.droppointDetails.opening_schedules[0].entries[i].weekday == day){
                            entries.push($scope.droppointDetails.opening_schedules[0].entries[i]);
                        }
                    }
                }
                return entries;
            };

            $scope.showEntryDetailsModal = function (day) {
                $scope.entryDetails = {};
                $scope.entryDetails.weekday = day;
                $("#entryDetailsModal").foundation('open');
            };

            $scope.entryDetailsCreated = function (entry) {
                entry.start_time = Times.getUTCTimeString(entry.start_time, $scope.timezone);
                entry.end_time = Times.getUTCTimeString(entry.end_time, $scope.timezone);
                Droppoints.addOpeningEntry($scope.droppointDetails.droppoint_id, entry).then(function(result){
                    $scope.droppointDetails.opening_schedules[0] = setScheduleTimes(result);
                    $.notify($translate.instant('notify.droppoint_schedule_entry_create_success'), "success");
                }).catch(function(){
                }).finally(function(){
                    $("#droppointDetailsModal").foundation('open');
                });
            };

            $scope.removeEntry = function(){
                Droppoints.removeOpeningEntry($scope.droppointDetails.droppoint_id, {opening_schedule_entry_id: $scope.entryDetails.opening_schedule_entry_id}).then(function(result){
                    $scope.droppointDetails.opening_schedules[0] = setScheduleTimes(result);
                    $.notify($translate.instant('notify.droppoint_schedule_entry_delete_success'), "success");
                }).catch(function(){
                }).finally(function(){
                    $("#droppointDetailsModal").foundation('open');
                });
            };

            $scope.showDroppoint = function(){
                $('#droppointDetailsModal').foundation('open');
            };

            $scope.showEntryDeleteModal = function(entry){
                $scope.entryDetails = entry;
                $("#deleteEntryModal").foundation('open');
            };

            function setScheduleTimes(schedule){
                for(var j = 0; j < schedule.entries.length; j++){
                   schedule.entries[j].start_time = Times.getTenantTimeString(schedule.entries[j].start_time, $scope.timezone);
                   schedule.entries[j].end_time = Times.getTenantTimeString(schedule.entries[j].end_time, $scope.timezone);
                }
                return schedule;
            }

            $scope.droppointsGridOptions = {
                columnDefs: DroppointColumns.build(),

                callGridData: {
                    id: "droppoint_id",
                    get: function (params) {
                        params.with = 'openingSchedules.entries';
                        return Droppoints.getDroppoints(params).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateDroppoints', 'viewZones'])) {
                                $scope.droppointsGrid.api.callGridEditing.disableCellEdits();
                            }
                            $scope.timezone = Times.getZone();
                            var result = response.data;
                            for (var i = 0; i < result.length; i++) {
                                result[i].hidden = (result[i].hidden == 1);
                                if(result[i].opening_schedules && result[i].opening_schedules[0]){
                                    result[i].opening_schedules[0] = setScheduleTimes(result[i].opening_schedules[0]);
                                }
                            }
                            return result;
                        })
                    },
                    create: function (data) {
                        $('.reveal').foundation('close');
                        return Droppoints.create(data)
                            .then(function (result) {
                                $scope.availableDroppoints.push(result);
                                $.notify($translate.instant('notify.droppoint_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        return Droppoints.update(id, data)
                            .then(function (result) {
                                for (var i = 0; i < $scope.availableDroppoints.length; i++) {
                                    if ($scope.availableDroppoints[i].zone_id == id) {
                                        $scope.availableDroppoints[i] = result;
                                        break;
                                    }
                                }
                                $.notify($translate.instant('notify.droppoint_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Droppoints.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.droppoint_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showDroppointDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "droppoints."
                },
                onRegisterApi: function (gridApi) {
                    $scope.droppointsGrid = gridApi.grid;
                }
            };

            $scope.showDroppointDeleteButton = function () {
                var r = true;
                var rows = $scope.droppointsGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelectedDroppoints = function () {
                $scope.droppointsGrid.api.callGridEditing.removeSelectedRows();
            };


            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showDroppointDetailsModal = function (creationModal, rowEntity) {
                $scope.droppointDetails = rowEntity ? rowEntity : {};
                $scope.addressDetails = $scope.droppointDetails.address ? $scope.droppointDetails.address : {};

                $scope.droppointCreationModal = creationModal;

                if (!creationModal) {
                    $scope.forbiddenGrid.api.grid.clearAllFilters();
                    $scope.forbiddenGrid.api.callGridData.get().then(function () {
                        var ids = [];
                        if ($scope.droppointDetails.forbidden_droppoints) {
                            ids = $scope.droppointDetails.forbidden_droppoints.map(function (forbiddenDroppoint) {
                                return forbiddenDroppoint.droppoint_id;
                            });
                        }

                        $timeout(function () {
                            $scope.forbiddenGrid.options.data.forEach(function (forbiddenDroppoint) {
                                if (ids.indexOf(forbiddenDroppoint.droppoint_id) > -1)
                                    $scope.forbiddenGrid.api.selection.selectRow(forbiddenDroppoint);
                            });
                        }, 0);
                    });
                } else {
                    $('.tabs-droppoint-details').foundation('selectTab', $('#detailsPanel'));
                }

                $("#droppointDetailsModal").foundation('open');
            };

            $scope.droppointDetailsUpdated = function (updatedRow) {
                if (!$scope.addressDetails.street && !$scope.addressDetails.house_number && !$scope.addressDetails.postal_code && !$scope.addressDetails.country && !$scope.addressDetails.state && !$scope.addressDetails.city) {
                    $scope.addressDetails.street = null;
                    $scope.addressDetails.house_number = null;
                    $scope.addressDetails.postal_code = null;
                    $scope.addressDetails.country = null;
                    $scope.addressDetails.state = null;
                    $scope.addressDetails.city = null;
                } else {
                    if ($scope.addressDetails.street && $scope.addressDetails.house_number && $scope.addressDetails.postal_code &&
                        $scope.addressDetails.country && $scope.addressDetails.state && $scope.addressDetails.city) {
                    } else {
                        $.notify($translate.instant('notify.address_all_fields'));
                        return;
                    }
                }

                //Merge address details into user details
                updatedRow.address = {};
                updatedRow.address.street = $scope.addressDetails.street;
                updatedRow.address.house_number = $scope.addressDetails.house_number;
                updatedRow.address.postal_code = $scope.addressDetails.postal_code;
                updatedRow.address.country = $scope.addressDetails.country;
                updatedRow.address.state = $scope.addressDetails.state;
                updatedRow.address.city = $scope.addressDetails.city;

                if (!updatedRow.hidden) {
                    updatedRow.hidden = false;
                }

                if ($scope.droppointCreationModal) {
                    $scope.droppointsGrid.api.callGridEditing.addRow(updatedRow);
                } else {
                    updatedRow = Address.setAddress(updatedRow);
                    $scope.droppointsGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }

                $('.reveal').foundation('close');
            };

            $scope.availableZones = [];
            if (PermissionService.hasPermissions(['viewZones'])) {
                Zones.getZones({limit: 9999}).then(function (response) {
                    $scope.availableZones = response.data;
                });
            }

            /************************************************************************************
             ****************************** DroppointDevices ************************************
             ************************************************************************************/
            $scope.droppointDevicesGridOptions = {
                columnDefs: DroppointDeviceColumns.build(),

                callGridData: {
                    id: "droppoint_device_id",
                    get: function (data) {
                        return DroppointDevices.getDroppointDevices(data).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateDroppointDevices', 'viewDroppoints'])) {
                                $scope.droppointDevicesGrid.api.callGridEditing.disableCellEdits();
                            }
                            return response;
                        })
                    },
                    create: function (data) {
                        $('.reveal').foundation('close');
                        return DroppointDevices.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.droppoint_device_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        return DroppointDevices.update(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.droppoint_device_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return DroppointDevices.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.droppoint_device_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showDroppointDeviceDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "droppoint_devices."
                },
                onRegisterApi: function (gridApi) {
                    $scope.droppointDevicesGrid = gridApi.grid;
                }
            };

            $scope.showDroppointDeviceDeleteButton = function () {
                var r = true;
                var rows = $scope.droppointDevicesGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelectedDroppointDevices = function () {
                $scope.droppointDevicesGrid.api.callGridEditing.removeSelectedRows();
            };


            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showDroppointDeviceDetailsModal = function (creationModal, rowEntity) {
                $scope.droppointDeviceDetails = rowEntity;
                $scope.droppointDeviceCreationModal = creationModal;

                $("#droppointDeviceDetailsModal").foundation('open');
            };

            $scope.droppointDeviceDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close')

                if ($scope.droppointDeviceCreationModal)
                    $scope.droppointDevicesGrid.api.callGridEditing.addRow(updatedRow);
                else
                    $scope.droppointDevicesGrid.api.rowEdit.setRowsDirty([updatedRow]);
            };

            $scope.availableDroppoints = [];
            if (PermissionService.hasPermissions(['viewDroppoints'])) {
                Droppoints.getDroppoints({limit: 9999}).then(function (response) {
                    $scope.availableDroppoints = response.data;
                });
            }

            /************************************************************************************
             **************************** Forbidden Droppoints **********************************
             ************************************************************************************/
            $scope.forbiddenGridOptions = {
                columnDefs: DroppointColumns.copy().build(),
                callGridData: {
                    id: "droppoint_id",
                    get: Droppoints.getDroppoints
                },
                onRegisterApi: function (gridApi) {
                    $scope.forbiddenGrid = gridApi.grid;
                }
            };
            $scope.setForbidden = function (forbiddenIds) {
                $scope.saving = true;

                Droppoints.setForbidden($scope.droppointDetails.droppoint_id, forbiddenIds).then(function () {
                    $.notify($translate.instant('notify.droppoint_forbidden_success'), "success");
                }).catch(function (e) {
                    console.error(e);
                }).finally(function () {
                    $scope.saving = false;
                    $scope.droppointsGrid.api.callGridData.get();
                    $('.reveal').foundation('close');
                });
            };
            $scope.getSelectedForbiddenIds = function () {
                var selectedIds = [];
                var selectedRows = $scope.forbiddenGrid.api.selection.getSelectedRows();
                var idKey = $scope.forbiddenGrid.options.callGridData.id;
                selectedRows.forEach(function (row) {
                    selectedIds.push(row[idKey]);
                });
                return selectedIds;
            };


        },
    ]
);