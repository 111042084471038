angular.module("2cp").factory("PushMessageColumns",
    [
        "$translate", "$filter", "uiGridConstants",
        "OperatorPushMessages", "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants,
                  OperatorPushMessages, ColumnDefBuilder) {
            var columnOptions = {
                message_title:{
                    visible: true
                },
                message_text:{
                    visible: true
                },
                receiver:{
                    visible: true,
                    cellFilter: 'pushMessageReceivers:this'
                },
                sender:{
                    visible: true
                },
                read_at:{
                    visible: true,
                    enableFiltering: false,
                    cellFilter: 'pushMessageRead:this'
                },
                created_at: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(OperatorPushMessages.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('app.pushMessages.')
                .copy();

            return builder;
        }
    ]
);