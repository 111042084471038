angular.module("2cp").controller("tenants.system",
    [
        "$scope", "$timeout", "$window", "Locks", "Chains", "Zones", "$translate", "LockColumns", "ChainColumns",
        function ($scope, $timeout, $window, Locks, Chains, Zones, $translate, LockColumns, ChainColumns) {
            $scope.lockGridOptions = {
                columnDefs: LockColumns.build(),

                callGridData: {
                    id: "lock_id",
                    get: Locks.getLocks,
                    update: function(id, data) {
                        return Locks.update(id, data)
                            .then(function(result) {
                                $.notify("Update successful!", "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "locks."
                },
                onRegisterApi: function(gridApi) {
                    $scope.lockGrid = gridApi.grid;
                }
            };

            $scope.chainGridOptions = {
                columnDefs: ChainColumns.build(),

                callGridData: {
                    id: "chain_id",
                    get: Chains.getChains,
                    update: function(id, data) {
                        return Chains.update(id, data)
                            .then(function(result) {
                                $.notify("Update successful!", "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "chains."
                },
                onRegisterApi: function(gridApi) {
                    $scope.chainGrid = gridApi.grid;
                }
            };
        }
    ]
);