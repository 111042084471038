'use strict';

angular.module('api').factory('Logs', [
    '$q',
    'clApi',
    function ($q, api) {

        return {
            parameters: [
                'user_api_log_id',
                'url',
                'response_code',
                'exception_message',
                'user_id',
                'parameters',
                'response_time',
                'source_ip',
                'source_type',
                'username',
                'created_at'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getLogs: function (params, options) {
                return api.get('logs', params, options).then(function (result) {
                    return result.data;
                });
            }
        };
    },
]);
