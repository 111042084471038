angular.module("2cp").factory("SubscriptionFreeColumns",
    [
        "$translate", "$filter", 'Subscriptions',
        "ColumnDefBuilder",
        function ($translate, $filter, Subscriptions,
                  ColumnDefBuilder) {
            var columnOptions = {
                minutes_per_day: {
                    visible: true,
                    enableCellEdit: true
                },
                weekday: {
                    visible: true,
                    selectOptions: {
                        keys: Subscriptions.getWeekdays(),
                        filter: 'weekOptions'
                    },
                    cellFilter: 'weekday'
                },
                created_at:{
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at:{
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Subscriptions.FREE_PARAMETERS)
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.subscriptions.")
                .copy();


            return builder;
        }
    ]
);