var en = {
    yes: "Yes",
    no: "No",
    loading: "Loading",
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'Create',
    send: 'Send',
    back: 'Back',
    choose_one: 'Choose one...',
    details: 'Details',
    general: 'General',
    import_csv: 'Import CSV',
    example_csv: 'Download Example CSV',
    required: 'Required',
    auth: {
        passwordResetSent: 'Password reset sent to email.',
        passwordResetSendingFailed: 'Failed sending password reset.',
        email: 'E-mail address',
        reset: 'Reset',
        username: 'Username',
        password: 'Password',
        forgot: 'Forgot password',
        log_in: 'Log in',
        resendLink: 'Click here to resend'
    },
    app : {
        faq: {
            faq_id: 'Faq ID',
            question: "Question",
            answer: "Answer",
            tenant_id: "Tenant ID",
            faq: 'FAQ',
        },
        pushMessages:{
            operator_push_message_id: 'Message ID',
            message_title: 'Title',
            message_text: 'Text',
            sender: 'Sender',
            receiver: 'Receiver',
            read_at: 'Read',
            created_at: 'Sent'
        },
        appColors:{
            description: 'Fill in custom colors for the app (old app only). They should be in hex form (000000). If the colors are left empty, the original color will be used.',
            primary: 'Primary',
            text: 'Text',
            click_to_pick: 'Click here to pick a color',
            clear: 'Clear',
        },
        pois: {
            new_poi: 'Add new Poi details',
            edit_poi: 'Edit Poi details',
            create_poi: 'Create Poi',
            longitude: "Longitude",
            latitude: "Latitude",
            longitude_holder: "Longitude e.g. 5.2991845",
            latitude_holder: "Latitude e.g. 51.7258506",
            poi_id: 'Poi ID',
            tour_marker: 'Tour only marker',
            name: 'Name',
            name_holder: 'Name of the Poi',
            title: 'Title for poi text',
            tilte_holder: 'Title e.g. Highlights',
            site: 'Website (with http/https)',
            site_holder: 'Website e.g. https://www.example.com',
            phone: 'Phone number',
            phone_holder: 'Phone number e.g. 0123456789',
            text: 'Poi text',
            text_holder: 'short text about poi',
            photo: 'Photo\'s from poi (multi selectable)',
            photo_holder: 'select multiple photos',
            type: 'Type',
            reservation_link: 'Reservation link',
            reservation_link_holder: 'Set link to the reservation service of this poi',
        },
        poi: {
            poi_id: 'id',
            type: 'Type',
            text: 'Text',
            photos: 'Photos',
            title: 'Title',
            name: 'Name',
            latitude: 'Latitude',
            longitude: 'Longitude',
            website: 'Website',
            phone: 'Phone',
            rating: 'Rating',
            raters: 'Raters',
        },
        tours: {
            tour: "Tour",
            create_tour: 'Create tour',
            name: "Name",
            tour_id: "Tour ID",
            description: "Description",
            headline: "Headline",
            waypoints: "Waypoints",
            delete_waypoint: "Delete last waypoint",
            tour_name: "Tour name",
            tour_length: "Tour length",
            tour_description: "Tour description",
            tour_created_success: "Tour created",
            tour_update_success: "Tour updated",
            tour_delete_success: "Tour deleted",
            photo: 'Photos from tour',
            photo_holder: 'select put link for photo',
        }

    },
    logs:{
        user_api_log_id: 'Log ID',
        url: 'URL',
        response_code: 'Response Code',
        exception_message: 'Error Message',
        user_id: 'User ID',
        parameters: 'Input Parameters',
        response_time: 'Response Time (ms)',
        source_ip: 'Client IP',
        source_type: 'Client Type',
        username: 'Username',
        created_at: 'Called at',
        response: 'Response'
    },
    partners: {
        partner_id: "Partner ID",
        name: "Name",
        short_name: "Short Name",
        telephone_number: "Telephone Number",
        fax_number: "Fax Number",
        mobile_number: "Mobile Number",
        email: "E-mail",
        from_email: "From E-mail",
        from_name: "From Name",
        email_settings: "Email Sending Settings",
        currency_id: "Currency ID",
        time_zone: "Time Zone",
        address_id: "Address ID",
        created_at: "Created at",
        updated_at: "Updated at",
        deleted_at: "Deleted at",
        address: 'Address',
        city: 'City',
        street: 'Street',
        country: 'Country',
        state: 'State',
        house_number: 'House Number',
        postal_code: 'Postal Code',
        create_partner: 'Create Partner',
        delete_partners: 'Delete partners',
        delete_message: 'Do you want to delete the selected partner(s)?',
        details: 'Partner Details',
        attach_tenants: 'Attach Tenants',
        settings: 'Settings',
        registration_fields: 'Registration Fields',
        vehicle_types_tab: 'Vehicle Types',
        vehicleTypes: {
            name: 'Name',
            vehicle_type_id: 'Vehicle Type ID',
            created_at: 'Created At',
            updated_at: 'Updated At',
            delete_vehicle_types: 'Delete vehicle types',
            delete_vehicle_types_message: 'Do you want to delete the selected vehicle types?'
        },
    },
    tenants: {
        tenant_id: "Tenant ID",
        partner_id: "Partner ID",
        legacy: "Legacy",
        name: "Name",
        short_name: "Short Name",
        telephone_number: "Telephone Number",
        fax_number: "Fax Number",
        mobile_number: "Mobile Number",
        email: "E-mail",
        from_email: "From E-mail",
        from_name: "From Name",
        email_settings: "Email Sending Settings",
        currency_id: "Currency",
        time_zone: "Time Zone",
        address_id: "Address ID",
        created_at: "Created at",
        updated_at: "Updated at",
        deleted_at: "Deleted at",
        logo_url: 'Logo',
        header_url: 'Header',
        contact: 'Contact enabled?',
        logo_url_holder: 'Logo image url (192x192)',
        header_url_holder: 'Header image url (300x155)',
        city: 'City',
        street: 'Street',
        country: 'Country',
        state: 'State',
        house_number: 'House Number',
        postal_code: 'Postal Code',
        all_or_none: 'Fill in all fields or no fields',
        create_tenant: 'Create Tenant',
        delete_tenants: 'Delete Tenants',
        delete_message: 'Do you want to delete the selected tenant(s)',
        details: 'Tenant Details',
        settings_tab: 'Settings',
        payment_provider: 'Payment Provider',
        payment_provider_key: 'Payment Provider Key',
        payment_provider_holder: 'Payment Provider name',
        payment_provider_key_holder: 'Payment provider api key',
        publishable_key: 'Publishable Key',
        publishable_key_holder: 'Publishable api Key',
        address: 'Address',
        latitude: 'Latitude',
        longitude: 'Longitude',
        latitude_holder: 'Latitude e.g. 5.234242',
        longitude_holder: 'Longitude e.g. 51.3453354',
        payment_option: 'Default Payment Option',
        paymentOptionCell: {
            balance: 'Balance',
            after_pay: 'After Pay',
            on_credit: 'On Credit',
            ideal: 'iDeal'
        },
        vehicles: {
            chain_id: "Chain ID",
            condition: "Vehicle Condition",
            created: "Created",
            serial: "Serial",
            brand_prefix: "Brand Prefix",
            user_identifier_id: "Identifier",
            key_tag: "Key Tag",
            last_changed: "Last Changed",
            lock_id: "Lock ID",
            lock_label: 'Lock Label',
            renting_status: "Renting Status",
            since: "Since",
            stand_id: "Stand ID",
            tenant_id: "Tenant ID",
            label: "Vehicle Label",
            status: "Vehicle Status",
            tag: "Vehicle Tag",
            vehicle_id: "Vehicle ID",
            voucher_id: "Voucher ID",
            droppointLabel: "Droppoint",
            droppoint_id: 'Droppoint ID',
            zone_id: "Zone ID",
            vehicle_type_id: "Vehicle Type ID",
            vehicleType: 'Vehicle Type',
            created_at: 'Created at',
            updated_at: 'Updated at',

            vehicleStatusCell: {
                notActive: "Not active",
                active: "Active",
                stolen: "Stolen",
                unknown: "Unknown"
            },
            conditionCell: {
                healthy: "Healthy",
                defect: "Damaged",
                missing: "Missing",
                unknown: "Unknown"
            },
            rentingStatusCell: {
                inService: "In Service",
                inLockAvailable: "In Lock (Available)",
                rentedWithVoucher: "Rented with Voucher",
                rentedWithIdentifier: "Rented with Identifier",
                inLockKeyMissing: "In Lock (Key Missing)",
                takenByOperator: "Taken by Operator",
                unknown: "Unknown"
            },
            set_location: 'Set Location',
            mark_repaired: 'Mark as repaired',
            mark_stolen: 'Mark as stolen',
            delete_vehicles: 'Delete vehicles',
            delete_message: 'Do you want to delete the selected vehicle(s)',
            vehicle_details: 'Vehicle Details',
            maintenance: 'Maintenance',
            rentals: 'Rentals',
            lock_devices: 'Lock Devices',
            vehicle_status: 'Vehicle Status',
            rental_status: 'Rental Status',
            user_label: 'User Label',
            relations: 'Relations',
            zone_label: 'Zone Label',
            chain_label: 'Chain Label',
            save_details: 'Save vehicle details',
            select_locks: 'Select Locks to Attach',
            save_locks: 'Save attached locks',
            vehicle_location: 'Vehicle Location',
            no_lock_error: 'No Lock attached to this vehicle. Attach a lock to this vehicle before setting the location.',
            location: 'Location',
            droppoint_device: 'Droppoint Device',
            warning: 'Warning',
            label_warning1: 'Are you sure you want to update the label of this vehicle?',
            label_warning2: 'This will affect the selecting of vehicles in several cases of which most important, the qr-codes of the vehicles used for renting.',

        },
        maintenance:{
            maintenance_task_id: "Task ID",
            created_at: "Created",
            description: "Description",
            priority: "Priority",
            status: "Status",
            datetime_planned: 'Date Planned',
            datetime_closed: 'Date Closed',
            maintenance_task_type_id: 'Task Type',
            vehicle: 'Vehicle',
            no_vehicle_permission: 'Not enough permissions to set vehicle',
            droppoint: 'Droppoint',
            no_droppoint_permission: 'Not enough permissions to set droppoint',
            lock: 'Lock Device',
            no_lock_permission: 'Not enough permissions to set lock device',
            resolution: 'Resolution',
            subject: 'Subject',
            reporterEmail: 'Reporter E-mail',
            reporterEmail_holder: 'E-mail',
            reporterUsername: 'Reporter Username',
            reporterUsername_holder: 'Username',
            vehicleLabel: 'Vehicle Label',
            droppointLabel: 'Droppoint Label',
            lockDeviceLabel: 'Lock Device Label',
            priorityCell: {
                low: "Low",
                medium: "Medium",
                high: "High",
                emergency: "Emergency"
            },
            statusCell: {
                open: "Open",
                accepted: 'Accepted',
                closed: 'Closed',
                overdue: 'Overdue',
                unknown: 'Unknown'
            },
            typeCell: {
                1: 'Bike maintenance',
                2: 'System maintenance',
                3: 'Too few free vehicles',
                4: 'Too few free space',
                5: 'Bike defect',
                6: 'Lock defect',
                7: 'Bike stolen',
                8: 'Other',
                9: 'Lock Check Battery'
            },
            type: 'Type',
            recur_unit: 'Recurrence Unit',
            recurrence: 'Recurrence Amount',
            date_accepted: 'Date Accepted',
            create_task: 'Create task',
            accept_task: 'Accept task',
            reopen_task: 'Re-open task',
            close_task: 'Close task',
            delete_tasks: 'Delete tasks',
            delete_message: 'Do you want to delete the selected task(s)',
            accept_tasks: 'Accept tasks',
            accept_message: 'Do you want to accept the selected task(s)',
            solution: 'Solution',
            solution_message: 'What was the solution?',
            reopen_reason: 'Reason for re-opening',
            reopen_message: 'Why does the task need to be re-opened?',
            store_solution: 'Store solution',
            accept: 'Accept',
            maintenance_task: 'Maintenance Task',
            remarks: 'Remarks',
            recur_every: 'Recur every',
            amount: 'Amount',
            at: 'at',
            date_error: 'Please insert a valid date'
        },
        vouchers: {
            voucher_id: "Voucher ID",
            voucher_code: "Voucher Code",
            valid_from: "Valid from",
            valid_from_holder: "Date e.g. '2017-01-17 05:25:00'",
            valid_until: "Valid until",
            valid_until_holder: "Date e.g. '2017-01-17 05:25:00'",
            claims_left: "Claims Left",
            claims_left_holder: "Amount of vouchers to be claimed e.g. '50'",
            discount_percentage: "Discount Percentage",
            discount_percentage_holder: "Discount percentage e.g. '25'",
            discount_time: "Discount Time",
            discount_time_holder: "Discount minutes e.g. '25'",
            created_at: "Created at",
            updated_at: "Updated at",
            tenant_id: "Tenant ID",
            vehicleType: "Vehicle Type",
            claimed: "Claimed Vouchers",
            delete_vouchers: 'Delete vouchers',
            delete_message: 'Do you want to delete the selected voucher(s)',
            payments: 'Payments'
        },
        payments: {
            // paymentTransactionId: "Payment ID",
            payment_transaction_id: "PSP Reference",
            product_type: "Product type",
            start_time: "Start time",
            // endTime: "End time",
            end_time: "Date",
            // costs: "Costs",
            costs: "Payment Amount",
            payment_tag: "Payment tag",
            tenant_id: "Tenant ID",
            user_id: "User ID",
            // userId: "Userlabel",
            user_label: "User Label",
            vehicle_label: "Vehicle Label",
            identifier_id: "Identifier ID",
            departure_lock_id: "Departure lock ID",
            return_lock_id: "Return lock ID",
            vehicle_id: "Vehicle ID",
            productTypeCell: {
                park: "Park vehicle",
                rent: "Rent vehicle",
                reserve: "Vehicle reservation",
                unknown: "Unknown"
            },
            payment_id: "Payment ID",
            amount: "Amount",
            username: "Username",
            status: "Status",
            name: 'Subscription',
            reason_type: "Reason",
            payable_type: "Action",
            created_at: "Created At",
            updated_at: "Updated At",
            paymentType: 'Payment Option',
            approve: 'Approve',
            approve_message: 'Approve all selected payments?',
            reject: 'Reject',
            reject_message: 'Reject all selected payments?',
            types: {
                balance: 'Balance',
                after_pay: 'After Pay',
                on_credit: 'On Credit',
                credit: 'Credit Card',
                ideal: 'iDeal'
            }
        },
        users: {
            user_id: "User ID",
            username: 'Username',
            first_name: 'First Name',
            last_name: 'Last Name',
            email: 'Email',
            balance: 'Balance',

            address_id: 'Address Id',
            birth_date: 'Birthdate',
            birth_date_error: 'Please insert a valid date',
            blocked: 'Blocked',
            blockedForCurrent: 'Blocked for this Tenant',
            city: 'City',
            city_id: 'City Id',
            company: 'Company',
            country: 'Country',
            created_at: 'Created At',
            deleted_at: 'Deleted At',
            gender: 'Gender',
            house_number: 'House Number',
            initials: 'Initials',
            mobile_number: 'Mobile Number',
            passport: 'Passport',
            postal_code: 'Postal Code',
            preposition: 'Preposition',
            state: 'State',
            state_id: 'State Id',
            street: 'Street',
            telephone_number: 'Telephone Number',
            updated_at: 'Updated At',
            vat_number: 'Vat Number',
            registered: 'Registered',
            created: 'Created',
            changed: 'Last changed',
            new_password: 'New Password',
            password: 'Password',
            add_user: 'Add user',
            register: 'Register',
            block_user: 'Block User',
            create_payment_details: 'Create Payment Details',
            upgrade_balance: 'Upgrade Balance',
            delete_users: 'Delete users',
            delete_message: 'Do you want to delete the selected user(s)',
            unregister_users: 'Unregister users',
            unregister_message: 'Do you want to unregister the selected user',
            block_users: 'Block users',
            block_message: 'Block user(s) for current tenant or for all tenants?',
            current_tenant: 'Current Tenant',
            all_tenants: 'All Tenants',
            register_users: 'Register Users',
            user_details: 'User Details',
            payments: 'Payments',
            rentals: 'Rentals',
            settings: 'Settings',
            subscriptions: 'Subscriptions',
            amount: 'Amount',
            amount_holder: 'Amount in cents e.g. 400',

            card: 'Card Number',
            card_holder: 'Card Number e.g. 4242 4242 4242 4242',
            cvc: 'CVC',
            cvc_holder: 'CVC e.g. 159',
            billing_postal: 'Billing Postal Code',
            billing_postal_holder: 'Postal Code',
            expiration: 'Expiration (MM/YY)',
            expiration_month: 'Month e.g. 12',
            expiration_year: 'Year e.g. 18',
            tenant_id: 'Tenant ID',

            blocked: 'Blocked',
            blocked_all: 'Blocked for all tenants',

            roles: "Roles",
            blockTypeCell: {
                notBlocked: "Not blocked",
                blocked: "Blocked",
                unknown: "Unknown",
                notRegistered: 'Not Registered'
            },
            registeredTypeCell: {
                notRegistered: "Not Registered",
                registered: "Registered",
                unknown: "Unknown",
            },
            userTypeCell: {
                personal: "Personal",
                business: "Business",
                temporary: "Temporary",
                unknown: "Unknown"
            },
            genderTypeCell: {
                male: "Male",
                female: "Female",
                transgender: "Transgender",
                unknown: "Unknown"
            },
            subscriptionTypeCell: {
                prepaidManualUpgrade: "Prepaid manual upgrade",
                prepaidAutoUpgrade: "Prepaid auto upgrade",
                onCreditPayment: "On credit payment",
                externalPayment: "External payment",
                unknown: "Unknown"
            },
            payment_option: 'Payment Option',
            paymentOptionCell: {
                balance: 'Balance',
                after_pay: 'After Pay',
                on_credit: 'On Credit',
                ideal: 'iDeal'
            },
            login_info: 'Login Information',
            contact_info: 'Contact Information',
            personal_info: 'Personal Information',
            unregister: 'Unregister'
        },
        system: {
            chain_id: "Chain Label",
            identifier_id: "Identifier ID",
            lock_id: "Lock ID",
            lock_number: "Lock Number",
            mode: "Mode",
            position: "Lock Position",
            rent_with_key: "Rent with Key",
            stand_id: "Stand ID",
            tenant_id: "Tenant ID",
            usage_status: "Lock Status",
            vehicle_id: "Vehicle ID",
            zone_id: "Zone ID",
            tenant_label: "Tenant Label",
            vehicle_label: "Vehicle Label",
            identifier_label: "Identifier Label",

            modeCell: {
                unknown: "Unknown",
                parking: "Parking",
                renting: "Renting"
            },

            positionCell: {
                unknown: "Unknown",
                left: "Left",
                right: "Right"
            },

            usageStatusCell: {
                unknown: "Unknown",
                free: "Free",
                occupied: "Occupied",
                occupiedUnknownTag: "Occupied (unknown tag)"
            }
        },
        events: {
            event_id: "Event ID",
            log_date_time: "Date Time",
            tenant_id: "Tenant ID",
            event_type: "Event Type",
            chain_id: "Chain ID",
            zone_id: "Zone ID",
            ip_address: "IP Address",
            lock_number: "Lock number",
            occur_time: "Occur Time",
            identifier_type: "Identifier Type",
            departure_lock_number: "Departure Lock",
            identifier_tag: "Identifier Tag",
            rejection_code: "Rejection Code",
            vehicle_label: "Vehicle Label",
            vehicle_tag: "Vehicle Tag",
            user_label: "User Label",
            identifier_label: "Identifier Label",
            pay_amount: "Pay Amount",
            system_count: "System Count",
            failure_moment: "Failure Moment",
            actual_count: "Actual Count",
            subscription_type: "Subscription Type",
            key_tag: "Key Tag",
            duration: "Duration",
            type: {
                lock_defect: "Lock Defect",
                lock_repaired: "Lock Repaired",
                vehicle_damaged: "Vehicle Damaged",
                vehicle_repaired: "Vehicle Repaired",
                vehicle_blocked: "Vehicle Blocked",
                request_parking_start: "Request Parking Start",
                request_parking_end: "Request Parking End",
                request_renting_start: "Request Renting Start",
                request_renting_end: "Request Renting End",
                process_timeout: "Process Timeout",
                parking_started: "Parking Started",
                parking_ended: "Parking Ended",
                renting_started: "Rent Started",
                renting_ended: "Rent Ended",
                renting_cancelled: "Rent Cancelled",
                placed_with_servicecard: "Placed with ServiceCard",
                removed_with_servicecard: "Removed with ServiceCard"
            }
        },
        chains: {
            chain_id: "Chain ID",
            short_description: "Short description",
            description: "Description",
            gps_latitude: "GPS latitude",
            gps_longitude: "GPS longitude",
            street: "Street",
            house_number: "House number",
            postal_code: "Postal code",
            city: "City",
            stands: "Stands"
        },
        subscriptions:{
            subscription_id: 'Subscription ID',
            name: 'Name',
            length: 'Length',
            length_holder: 'Multiple of payment frequency',
            cost: 'Cost',
            cost_holder: 'Cost in cents',
            payment_frequency: 'Payment Frequency',
            valid_from: 'Valid From',
            valid_from_holder: "Date e.g. '2017-01-17 05:25:00'",
            valid_until: 'Valid Until',
            valid_until_holder: "Date e.g. '2017-01-17 05:25:00'",
            created_at: "Created At",
            updated_at: "Updated At",
            renew: 'Renew',
            pay_upfront: 'Pay Upfront',
            discount_subscription_entry_id: 'Discount Entry ID',
            free_subscription_entry_id: 'Free Entry ID',
            balance_subscription_entry_id: 'Balance Entry ID',
            start_time: 'Start Time',
            end_time: 'End Time',
            discount_rental: 'Rental Discount',
            discount_rental_holder: '% Rental Discount',
            discount_booking: 'Booking Discount',
            discount_booking_holder: '% Booking Discount',
            weekday: 'Weekday',
            vehicleType: 'Vehicle Type',
            minutes_per_day: 'Minutes per Day',
            minutes_per_day_holder: 'Free minutes per day',
            amount: 'Amount',
            amount_holder: 'Cents added to balance',
            frequency: 'Frequency',
            max_cost_per_day: 'Max Cost per Day',
            max_cost_per_day_holder: 'Leave empty for no max cost',
            userRenew: 'User Renew',
            userValidFrom: 'User Valid From',
            userValidUntil: 'User Valid Until',
            user_id: 'User ID',
            users: 'Users',
            periods: 'Periods',
            nr: 'Nr.',
            start: 'Start',
            end: 'End',
            create_period: 'Create new period',
            delete_subscriptions: 'Delete subscriptions',
            delete_subscriptions_message: 'Do you want to delete the selected subscription(s)',
            period_id: "period ID",
            start_date: 'Start Date',
            start_date_holder: "Date e.g. '01-17'",
            end_date: 'End Date',
            end_date_holder: "Date e.g. '01-17'",
            entries: 'Subscription Entries',
            discount: 'Discount',
            balance: 'Balance',
            free: 'Free',
            delete_period: 'Delete Period',
            delete_period_message: 'Do you want to delete the selected period',
            delete_free: 'Delete free entries',
            delete_free_message: 'Do you want to delete the selected free entries',
            delete_balance: 'Delete balance entries',
            delete_balance_message: 'Do you want to delete the selected balance entries',
            delete_discount: 'Delete discount entries',
            delete_discount_message: 'Do you want to delete the selected discount entries',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
            unlimited: 'Unlimited',
            content: 'Content',
            description: 'Description',
            hidden: 'Hidden'
        },
        zones: {
            zone_id: "Zone ID",
            created_at: "Created At",
            updated_at: "Updated At",
            label: "Label",
            radius: "Radius",
            longitude: "Longitude",
            longitude_holder: "Longitude e.g. '5.2991845'",
            latitude: "Latitude",
            latitude_holder: "Latitude e.g. '51.7258506'",
            hidden: 'Hidden',
            zones: 'Zones',
            create_zone: 'Create Zone',
            radius_holder: 'Radius in meters',
            delete_zones: 'Delete zones',
            delete_message: 'Do you want to delete the selected zone(s)'
        },
        tenant: {
            tenantId: "Tenant ID",
        },
        // Settings translations
        settings: {
            select_setting: 'Please select a setting to change from the left menu',
            roles: {
                role_id: "Role ID",
                name: "Name",
                display_name: "Display name",
                description: "Description",
                created_at: "Created at",
                updated_at: "Last updated",
                entity_id: "Entity ID",
                roles: 'Roles',
                details: 'Role Details',
                created: 'Created',
                last_changed: 'Last Changed',
                create_role: 'Create Role',
            },
            permissions: {
                id: "Permission ID",
                permission_type: "Permission type",
                name: "Name",
                display_name: "Display name",
                description: "Description",
                permissionTypeCell: {
                    resource: "Resource",
                    permission: "Permission",
                    view: "View"
                },
                permissions: 'Permissions'
            },
            operators: {
                permission_id: "Permission ID",
                permission_type: "Permission type",
                name: "Name",
                display_name: "Display name",
                description: "Description",
                permissionTypeCell: {
                    resource: "Resource",
                    permission: "Permission"
                },
                operator_create_title: 'Operator creation',
                new: 'New user',
                existing: 'Existing user',
                promote: 'Promote',
                operator: 'Operator',
                add_operator: 'Add Operator',
                demote: 'Demote'
            },
            registration_fields:{
                address: 'Address',
                birth_date: 'Birthdate',
                company: 'Company',
                first_name: 'First Name',
                gender: 'Gender',
                initials: 'Initials',
                last_name: 'Last Name',
                mobile_number: 'Mobile Number',
                passport: 'Passport',
                telephone_number: 'Telephone Number',
                preposition: 'Preposition',
                vat_number: 'VAT Number',
                field_name: 'Field Name',
                visible: 'Visible',
                required: 'Required'
            }
        },
        lockDevices: {
            lock_device_id: "Lock Device ID",
            label: 'Label',
            created_at: "Created at",
            updated_at: "Updated at",
            device_type: "Device Type",
            battery_level: 'Battery Level',
            status: 'Status',
            lockDeviceTypeCell: {
                bluetoothLockDevice: 'Bluetooth Lock Device',
                unknown: 'Unknown'
            },
            delete_locks: 'Delete locks',
            delete_message: 'Do you want to delete the selected lock(s)',
            lock_details: 'Lock Device Details',
            created: 'Created',
            last_changed: 'Last Changed',
            claim_code: 'Axa Claim Code',
            claim_code_holder: "Claim code e.g. '3049ACED9F0C446E9D594891B6E39790'",
            lock_uid: 'Axa Lock Uid',
            lock_uid_holder: "Axa lock uid e.g. '79BFE2EC8F57F4D1C628'",
            maintenance_tab: 'Maintenance'
        },
        droppoints: {
            droppoints: 'Droppoints',
            droppoint_id: "Droppoint ID",
            label: "Label",
            zoneLabel: 'Zone Label',
            created_at: "Created at",
            updated_at: "Updated at",
            zone_id: "Zone ID",
            longitude: "Longitude",
            latitude: "Latitude",
            longitude_holder: "Longitude e.g. 5.2991845",
            latitude_holder: "Latitude e.g. 51.7258506",
            maximum_vehicles: "Maximum Vehicles",
            maximum_vehicles_holder: "Maximum amount",
            minimum_vehicles_for_booking: "Minimum Vehicles for Booking",
            minimum_vehicles_for_booking_holder: "Minimum amount for booking",
            amount_of_vehicles: "Amount of Vehicles",
            free_spots: "Free Parking Spots",
            amount_of_bookings: "Amount of Bookings",
            free_vehicles: "Free Vehicles",
            prebooking_time: "Pre-Booking time",
            prebooking_time_holder: "Amount of minutes you may book a vehicle in advance.",
            city: 'City',
            street: 'Street',
            country: 'Country',
            state: 'State',
            house_number: 'House Number',
            postal_code: 'Postal Code',
            hidden: 'Hidden',
            closed_for_rental: 'Closed for rental',
            closed_for_return: 'Closed for return',
            closed_for_actions: 'Closed for actions',
            not_allowed: 'Not allowed droppoints',
            opening_schedule: 'Opening Schedule',
            zone: 'Zone',
            excluded_droppoints: 'Excluded droppoints',
            excluded_text: 'Select all droppoints that you may not end a rental after starting from droppoint',
            day: 'Day',
            start: 'Start',
            end: 'End',
            type: 'Type',
            action: 'Action',
            create_entry: 'Create new entry',
            delete_droppoints: 'Delete droppoints',
            delete_message: 'Do you want to delete the selected droppoint(s)',
            start_time: 'Start Time',
            end_time: 'End Time',
            delete_entry: 'Delete schedule entry',
            delete_entry_message: 'Do you want to delete the entry',
            create_droppoint: 'Create Droppoint',
        },
        droppointDevices: {
            droppoint_devices: 'Droppoint Devices',
            droppoint_device_id: "Droppoint Device ID",
            label: "Label",
            created_at: "Created at",
            updated_at: "Updated at",
            droppoint_id: "Droppoint ID",
            radius: "Radius",
            radius_holder: 'Radius in meters',
            uid: "Axa UID",
            uid_holder: "Axa uid e.g. '79BFE2EC8F57F4D1C628'",
            droppoint: 'Droppoint',
            delete_devices: 'Delete droppoint devices',
            delete_message: 'Do you want to delete the selected droppoint device(s)',
            create_droppoint_device: 'Create Droppoint Device'
        },
        tariffs: {
            tariff_id: "Tariff ID",
            origin_id: "Origin",
            origin_type: "Origin Type",
            destination_id: "Destination",
            destination_type: "Destination Type",
            bluetooth_fine: "Bluetooth Fine",
            tariff_time_entry_id: "Time Entry ID",
            start_minute: "Start Minute",
            start_minute_holder: "Start minute e.g. '0'",
            cost_per_step: "Cost per Step",
            cost_per_step_holder: "Cost per minute in cents e.g. '30'",
            step: "Step",
            step_holder: "Length of step in minutes",
            entry_owner_id: "Tariff ID",
            booking_type_id: "Booking Type ID",
            max_cost_per_day: "Max Cost per Day",
            max_cost_per_day_holder: "Amount in cents",
            start_time: "Start Time",
            end_time: "End Time",
            weekday: "Weekdays",
            start_date: 'Start Date',
            end_date: 'End Date',
            vehicleType: 'Vehicle Type',
            copy: 'copy',
            rental_tariffs: 'Rental Tariffs',
            booking_tariffs: 'Booking Tariffs',
            rental_tariff: 'Rental Tariff',
            booking_tariff: 'Booking Tariff',
            time_entries: 'Time Entries',
            vehicle_type_id: 'Vehicle Type ID',
            validity: 'Validity',
            location: 'Location',
            time_entry: 'Time Entry',
            delete_rental: 'Delete tariffs',
            delete_rental_message: 'Do you want to delete the selected tariff(s)',
            delete_booking: 'Delete booking tariffs',
            delete_booking_message: 'Do you want to delete the selected booking tariff(s)',
            delete_time_entries: 'Delete time entries',
            delete_time_entries_message: 'Dp you want to delete the selected entry(ies)'
        },
        bookings:{
            droppoint_id: "Droppoint ID",
            booking_id: "Booking ID",
            created_at: "Created At",
            updated_at: "Updated At",
            start_datetime: "Booking Start",
            end_datetime: "Booking End",
            status: "Payment Status",
            amount: "Payment Amount",
            username: "Username",
            droppoint: 'Droppoint',
            user: 'User',
            duration: 'Duration',
            duration_holder: 'Durtation in minutes e.g. 50',
            end: 'End',
            check_expired: 'Check Expired',
            vehicleType: 'Vehicle Type'
        },
        rentals: {
            rental_id: 'Rental ID',
            created_at: 'Created at',
            updated_at: 'Updated at',
            start_datetime: 'Rental Start',
            end_datetime: 'Rental End',
            status: 'Status',
            vehicle_id: 'Vehicle ID',
            vehicleLabel: 'Vehicle',
            endLabel: 'End Droppoint',
            startLabel: 'Start Droppoint',
            payment_amount: 'Amount in cents',
            payment_user_identifier_user_username: 'Username',
            duration: 'Duration',
            end: 'End'
        }
    },
    profile: {
        new_password: 'New password',
        confirm_password: 'Confirm new password',
        passwordUpdate: 'New password set.',
        passwordUpdateFailed: 'Failed setting new password. Please try again.'
    },
    messages: {
        type: {
            answer: "Answer",
            question: "Question"
        },
        reply_question: 'Reply to question',
        question: 'Question',
        answer: 'Answer',
        reply: 'Reply',
        mark_answered: 'Mark as answered',
        username: 'Username',
        first_name: 'First name',
        last_name: 'Last name',
        user_id: 'User id',
        telephone: 'Telephone number',
        mobile: 'Mobile number',
        birth_date: 'Birth Date',
        gender: 'Gender',
        country: 'Country',
        city: 'City',
        message_history: 'Message history for',
        user_info: 'User info',
        send_push: 'Send Push Message',
        message_title: 'Message Title',
        receiver: 'Receiver',
        message_text: 'Message Text',
        leave_blank: 'Leave blank for every user from this tenant',
        push_messages: 'Push Messages',
        direct_messages: 'Direct Messages',
        unanswered_questions: 'Unanswered questions',
        questions: 'Questions',
        asked_by: 'Asked by',
        created_at: 'Created at',
        archive: 'Archive',
        write_answer: 'Write your answer',
        message_history_tab: 'Message history',
        delete_messages: 'Delete messages',
        delete_message: 'Do you want to delete the selected messages(s)',
    },
    overview:{
        select: 'Select a tab on the left.'
    },
    project_info:{
        loading: 'Loading',
    },
    terms_of_use:{
        loading: 'Loading'
    },
    settings:{
        settings_vehicle: 'Settings per Vehicle Type',
        max_booked: 'Max Booked',
        max_rented: 'Max Rented',
        start_cost: 'Start Cost',
        min_balance: 'Minimum Balance',
        vehicle_type: 'Vehicle Type',
        buffer: 'Pay Buffer Time',
        vouchers: 'Vouchers Only',
        float: 'Free Floating',
        rest_time: 'Mandatory Rest Time',
        subscription: 'Force Subscription',
        general_settings: 'General Settings',
        max_booked_holder: 'Maximum vehicles booked at the same time',
        max_rented_holder: 'Maximum vehicles rented at the same time',
        start_cost_holder: 'Amount of cents added to each rent',
        min_balance_holder: 'Minimum amount of cents needed to be in balance before renting',
        buffer_holder: 'Re-think time for the user',
        rest_time_holder: 'Time between rents in minutes',
        inherit_partner: 'Inherit Partner Setting',
        inherit_partner_message: "If 'Yes' is selected the setting will be used from the partner, if 'No' is selected then this will override the partner setting for this specific tenant.",
        inherit_partner_empty_message: "If left empty, the partner setting will be used.",
        inherit_tenant: 'Inherit Tenant Setting',
        inherit_tenant_message: "If 'Yes' is selected the setting will be used from the tenant, if 'No' is selected then this will override the tenant setting for this specific user.",
        inherit_tenant_empty_message: "If left empty, the tenant setting will be used.",
        edit_tenant: 'Edit Tenant',
        manage_partners: 'Manage partners',
        hide_booking: 'Hide Booking',
        hide_from_app: 'Hide Tenant from App',
        email_domains: 'Allowed email domains',
        email_domains_placeholder: "Emails separated by a ';'"
    },
    dashboard:{
        current_rentals: 'Active Rentals',
        today_started: 'Rentals started today',
        today_ended: 'Rentals ended today',
        vehicles_booked: 'Vehicles Booked',
        vehicles_available: 'Vehicles available',
        daily_revenue: 'Daily revenue',
        vehicles_maintenance: 'Vehicles in maintenance',
        vehicles_damaged: 'Vehicles damaged',
        spots_free: 'Parking spots free',
        locks_no_vehicle: 'Locks without vehicle',
        locks_booked: 'Locks booked'
    },
    exceptions:{
        UserAlreadyExistsException: 'Username or email is already in use.',
    },
    notify:{
        faq_update_success: 'FAQ update succeeded',
        faq_update_failed: 'FAQ update failed',
        faq_create_success: 'FAQ creation succeeded',
        faq_create_failed: 'FAQ creation failed',
        faq_delete_success: 'FAQ deletion succeeded',
        faq_delete_failed: 'FAQ deletion failed',

        poi_update_success: 'Poi update succeeded',
        poi_update_failed: 'Poi update failed',
        poi_create_success: 'Poi creation succeeded',
        poi_create_failed: 'Poi creation failed',
        poi_delete_failed: 'Poi delete failed',
        poi_delete_success: 'Poi delete succeeded',

        app_colors_update_success: 'Color update succeeded',
        app_colors_update_failed: 'Color update failed',

        message_create_success: 'Reply sent',
        message_create_failed: 'Reply NOT sent',

        push_message_create_success: 'Message sent',
        push_message_create_failed: 'Message NOT sent',
        push_message_delete_success: 'Messages deletion succeeded',
        push_message_delete_failed: 'Messages deletion failed',

        project_update_success: 'Project info updated',
        project_update_failed: 'Project info update failed',

        terms_update_success: 'Terms of use updated',
        terms_update_failed: 'Terms of use update failed',

        partner_get_failed: 'Partners not retrieved',
        partner_create_success: 'Partner created',
        partner_create_failed: 'Partner creation failed',
        partner_update_success: 'Partner updated',
        partner_update_failed: 'Partner update failed',
        partner_delete_success: 'Partner(s) deleted',
        partner_delete_failed: 'Partner deletion failed',
        attach_tenants_success: 'Attached tenants',
        attach_tenants_failed: 'Unable to attach tenants',

        address_all_fields: "Fill in all address fields or no address fields at all.",

        settings_update_success: 'Settings updated',
        settings_update_failed: 'Settings update failed',

        tenant_get_failed: 'Tenants not retrieved',
        tenant_create_success: 'Tenant created',
        tenant_create_failed: 'Tenant creation failed',
        tenant_update_success: 'Tenant updated',
        tenant_update_failed: 'Tenant update failed',
        tenant_delete_success: 'Tenant(s) deleted',
        tenant_delete_failed: 'Tenant deletion failed',

        booking_create_success: 'Booking created',
        booking_create_failed: 'Booking creation failed',
        booking_end_success: 'Booking ended',
        booking_end_failed: 'Booking NOT ended',
        booking_expired_end_success: ' expired bookings ended',
        booking_expired_end_failed: 'Expired bookings NOT ended',

        zone_create_success: 'Zone created',
        zone_create_failed: 'Zone creation failed',
        zone_update_success: 'Zone updated',
        zone_update_failed: 'Zone update failed',
        zone_delete_success: 'Zone(s) deleted',
        zone_delete_failed: 'Zone deletion failed',

        droppoint_create_success: 'Droppoint created',
        droppoint_create_failed: 'Droppoint creation failed',
        droppoint_update_success: 'Droppoint updated',
        droppoint_update_failed: 'Droppoint update failed',
        droppoint_delete_success: 'Droppoint(s) deleted',
        droppoint_delete_failed: 'Droppoint deletion failed',

        droppoint_forbidden_success: 'Forbidden droppoint(s) set',
        droppoint_forbidden_failed: 'Unable to set forbidden droppoint(s)',

        droppoint_schedule_entry_create_success: 'Droppoint Schedule Entry added',
        droppoint_schedule_entry_create_failed: 'Droppoint Schedule Entry addition failed',
        droppoint_schedule_entry_delete_success: 'Droppoint Schedule Entry removed',
        droppoint_schedule_entry_delete_failed: 'Droppoint Schedule Entry removal failed',

        droppoint_device_create_success: 'Droppoint Device created',
        droppoint_device_create_failed: 'Droppoint Device creation failed',
        droppoint_device_update_success: 'Droppoint Device updated',
        droppoint_device_update_failed: 'Droppoint Device update failed',
        droppoint_device_delete_success: 'Droppoint Device(s) deleted',
        droppoint_device_delete_failed: 'Droppoint Device deletion failed',

        lock_device_create_success: 'Lock Device created',
        lock_device_create_failed: 'Lock Device creation failed',
        lock_device_update_success: 'Lock Device updated',
        lock_device_update_failed: 'Lock Device update failed',
        lock_device_delete_success: 'Lock Device(s) deleted',
        lock_device_delete_failed: 'Lock Device deletion failed',
        lock_device_batch_create_success_1: 'Imported ',
        lock_device_batch_create_success_2: ' out of ',
        lock_device_batch_create_failed_1: 'Import of lock devices failed',
        lock_device_batch_create_failed_2: 'Check that the locks can be claimed.',

        task_create_success: 'Maintenance task created',
        task_create_failed: 'Maintenance task creation failed',
        task_update_success: 'Maintenance task updated',
        task_update_failed: 'Maintenance task update failed',
        task_delete_success: 'Maintenance task(s) deleted',
        task_delete_failed: 'Maintenance task deletion failed',

        rent_end_success: 'Rent ended',
        rent_end_failed: 'Rent end failed',
        rent_get_failed: 'Rentals not retrieved',

        operator_get_failed: 'Operators not retrieved',
        operator_create_success: 'Operator created',
        operator_create_failed: 'Operator creation failed',
        operator_update_success: 'Operator updated',
        operator_update_failed: 'Operator update failed',
        operator_delete_success: 'Operator(s) demoted',
        operator_delete_failed: 'Operator demotion failed',
        operator_promote_success: 'Operator(s) promoted',
        operator_promote_failed: 'Operator promotion failed',

        roles_get_failed: 'Roles not retrieved',
        roles_create_success: 'Role created',
        roles_create_failed: 'Role creation failed',
        roles_update_success: 'Role updated',
        roles_update_failed: 'Role update failed',
        roles_delete_success: 'Role(s) deleted',
        roles_delete_failed: 'Role deletion failed',

        permissions_get_failed: 'Permissions not retrieved',

        subscription_get_failed: 'Subscriptions not retrieved',
        subscription_create_success: 'Subscription created',
        subscription_create_failed: 'Subscription creation failed',
        subscription_update_success: 'Subscription updated',
        subscription_update_failed: 'Subscription update failed',
        subscription_delete_success: 'Subscription(s) deleted',
        subscription_delete_failed: 'Subscription deletion failed',

        subscription_period_create_success: 'Period created',
        subscription_period_create_failed: 'Period creation failed',
        subscription_period_update_success: 'Period updated',
        subscription_period_update_failed: 'Period update failed',
        subscription_period_delete_success: 'Period(s) deleted',
        subscription_period_delete_failed: 'Period deletion failed',

        discount_entry_create_success: 'Discount Entry created',
        discount_entry_create_failed: 'Discount Entry creation failed',
        discount_entry_update_success: 'Discount Entry updated',
        discount_entry_update_failed: 'Discount Entry update failed',
        discount_entry_delete_success: 'Discount Entry(ies) deleted',
        discount_entry_delete_failed: 'Discount Entry deletion failed',

        free_entry_create_success: 'Free Entry created',
        free_entry_create_failed: 'Free Entry creation failed',
        free_entry_update_success: 'Free Entry updated',
        free_entry_update_failed: 'Free Entry update failed',
        free_entry_delete_success: 'Free Entry(ies) deleted',
        free_entry_delete_failed: 'Free Entry deletion failed',

        balance_entry_create_success: 'Balance Entry created',
        balance_entry_create_failed: 'Balance Entry creation failed',
        balance_entry_update_success: 'Balance Entry updated',
        balance_entry_update_failed: 'Balance Entry update failed',
        balance_entry_delete_success: 'Balance Entry(ies) deleted',
        balance_entry_delete_failed: 'Balance Entry deletion failed',

        tariff_rental_create_success: 'Rental Tariff created',
        tariff_rental_create_failed: 'Rental Tariff creation failed',
        tariff_rental_update_success: 'Rental Tariff updated',
        tariff_rental_update_failed: 'Rental Tariff update failed',
        tariff_rental_delete_success: 'Rental Tariff(s) deleted',
        tariff_rental_delete_failed: 'Rental Tariff deletion failed',

        tariff_entry_get_failed: 'Tariff Entries not retrieved',
        tariff_entry_create_success: 'Tariff Entry created',
        tariff_entry_create_failed: 'Tariff Entry creation failed',
        tariff_entry_update_success: 'Tariff Entry updated',
        tariff_entry_update_failed: 'Tariff Entry update failed',
        tariff_entry_delete_success: 'Tariff Entry(s) deleted',
        tariff_entry_delete_failed: 'Tariff Entry deletion failed',

        tariff_booking_create_success: 'Booking Tariff created',
        tariff_booking_create_failed: 'Booking Tariff creation failed',
        tariff_booking_update_success: 'Booking Tariff updated',
        tariff_booking_update_failed: 'Booking Tariff update failed',
        tariff_booking_delete_success: 'Booking Tariff(s) deleted',
        tariff_booking_delete_failed: 'Booking Tariff deletion failed',

        user_delete_admin_failed: 'You cannot delete the super-admin',
        user_unregister_success: 'User has been unregistered',
        user_unregister_failed: 'User has NOT been unregistered',
        user_block_current_success: 'User blocked for current tenant',
        user_block_current_failed: 'User NOT blocked for current tenant',
        user_register_success: 'User registered for current tenant',
        user_register_failed: 'User NOT registered for current tenant',
        user_no_payment_details: 'User has no payment details yet for the current tenant.',
        user_payment_create_success: 'User payment details created',
        user_payment_create_failed: 'User payment details creation failed',
        user_balance_add_success: 'Balance added',
        user_balance_add_failed: 'Balance NOT added',
        user_subscriptions_success: 'User has been given subscriptions',
        user_subscriptions_failed: 'User has NOT been given subscriptions',

        user_create_success: 'User created',
        user_create_failed: 'User creation failed',
        user_update_success: 'User updated',
        user_update_failed: 'User update failed',
        user_delete_success: 'User(s) deleted',
        user_delete_failed: 'User deletion failed',

        user_batch_create_success_1: 'Imported ',
        user_batch_create_success_2: ' out of ',
        user_batch_create_failed_1: 'Import of users failed',
        user_batch_create_failed_2: 'Check that the username and email values are unique and do not yet exist in the system.',

        vehicle_get_failed: 'Vehicles not retrieved',
        vehicle_create_success: 'Vehicle created',
        vehicle_create_failed: 'Vehicle creation failed',
        vehicle_update_success: 'Vehicle updated',
        vehicle_update_failed: 'Vehicle update failed',
        vehicle_delete_success: 'Vehicle(s) deleted',
        vehicle_delete_failed: 'Vehicle deletion failed',

        vehicle_location_success: 'Location is set',
        vehicle_no_lock: 'Vehicle has no lock device',
        vehicle_location_failed: 'Location is NOT set',
        vehicle_attach_locks_success: 'Updated vehicle locks',
        vehicle_attach_locks_failed: 'Update failed of vehicle locks',

        vehicle_batch_create_success_1: 'Imported ',
        vehicle_batch_create_success_2: ' out of ',
        vehicle_batch_create_failed_1: 'Import of vehicles failed',
        vehicle_batch_create_failed_2: 'Check that the label is unique, and the status and condition values are valid.',

        voucher_create_success: 'Voucher created',
        voucher_create_failed: 'Voucher creation failed',
        voucher_update_success: 'Voucher updated',
        voucher_update_failed: 'Voucher update failed',
        voucher_delete_success: 'Voucher(s) deleted',
        voucher_delete_failed: 'Voucher deletion failed',

        voucher_batch_create_success_1: 'Imported ',
        voucher_batch_create_success_2: ' out of ',
        voucher_batch_create_failed_1: 'Import of vouchers failed',
        voucher_batch_create_failed_2: 'There were errors, check all values are valid.',

        payment_get_failed: 'Payments NOT retrieved',
        payments_approved: 'Payments approved',
        payments_rejected: 'Payments rejected',

        vehicle_type_create_success: 'Vehicle Type created',
        vehicle_type_update_success: 'Vehicle Type updated',
        vehicle_type_delete_success: 'Vehicle Type deleted',

        registration_fields_update_success: 'Registration fields updated.',
        registration_fields_update_failed: 'Registration fields not updated due to unknown error.',

        AddressFieldsNotCompleteException: "Not all address fields are present. The following fields are required: street, house_number, postal_code, city, state, country.",
        AlreadyHasSubscriptionException: "User already has this subscription active, or will hav it active in the future.",
        AlreadyRegisteredWithTenantException: "Already registered this user with this tenant.",
        BookingTooEarlyException: "Duration of booking is higher than prebooking time.",
        CannotDeleteSameUserException: "A user cannot delete himself.",
        CantUpdateOperatorRoleException: "You cannot update the operator role.",
        DroppointClosedException: "The droppoint is closed.",
        DroppointDevicesConnectedException: "There are droppoint devices connected to this",
        DroppointForbiddenException: "Droppoint is forbidden because of the starting droppoint's forbidden droppoints.",
        DroppointNotFoundException: "Droppoint not found.",
        DroppointsConnectedException: "There are droppoints connected to this",
        FreeFloatNotAllowedException: "You are not allowed to free float.",
        FreeTimeDoubleSpentException: "The time was spent more than once",
        IncorrectTenantException: "The object you are trying to create/modify/delete does not belong to your tenant.",
        InvalidNumberOfRentalsException: "The rental could not be uniquely identified",
        IsNotOperatorException: "The user himself is not an operator for this tenant.",
        KeyCloudAuthorizationException: "KeySafe authentication is wrong",
        KeyCloudLockNotFoundException: "Lock not found",
        KeyCloudNoConnectionException: "Could not connect to KeyCloud Api",
        KeyCloudNotClaimedException: "Lock is not claimed yet.",
        KeyCloudServerException: "KeyCloudServer failed",
        KeyCloudValidationFailedException: "Validation failed",
        KeyCloudWrongClaimCodeException: "Claim code does not match Lock uid",
        KeyCloudWrongPositionException: "Conflict, the provided position is not a slot position in the lock",
        LockDeviceNotFoundException: "LockDevice is not found.",
        LockNotConnectedToDroppointException: "Lock device is not connected to a droppoint device",
        LockStillConnectedException: "Lock device is still connected to a bike.",
        MaxVehiclesBookedException: "Maximum amount of vehicles are booked for this droppoint.",
        NameNotAllowedException: "You are not allowed to use this name.",
        NoApplicableTariffsException: "No suitable accessory found",
        NoEndingLockFoundException: "No ending lock was found",
        NoFreeSpotsException: "There are no free parking spots.",
        NoIdProvidedException: "There is no id provided for this object.",
        NoPaymentDetailsException: "No payment details found for this user-tenant combination.",
        NoRentalTypeSpecifiedException: "No rental type was given.",
        NoSuitableAccessoryFoundException: "No suitable accessory found",
        NotAllowedToEditFreeFloatException: "You are not allowed to edit the free float locations.",
        NotAuthorizedException: "User not authorized.",
        NoTenantIdException: "No tenant id given",
        NotEnoughBalanceException: "User does not have enough balance.",
        NotImplementedException: "Function not implemented",
        NotOwnRentalException: "The rental you are trying to end is not your own rental.",
        NoVehiclesException: "This droppoint has no vehicles.",
        NoVehicleTypeException: "This droppoint has no vehicles of the given type.",
        OriginNotEqualToDestinationException: "The origin and the destination do not belong to the same tenant.",
        ParameterInvalidException: "One or more of the supplied parameters is invalid or missing.",
        PaymentDetailsAlreadyExistException: "PaymentDetails already exist for this user-tenant combination.",
        PaymentsUnapprovedException: "User has unapproved payments.",
        RentAlreadyEndedException: "The rent is already ended.",
        RentAlreadyStartedException: "The rent is already started.",
        RestTimeNotOverException: "The mandatory rest time is not over yet.",
        RoleCannotDeleteOperatorException: "Cannot delete operators",
        ScheduleEntryNotFoundException: "Schedule entry not found.",
        ScheduleNotFoundException: "There is no opening schedule.",
        SubscriptionNeededException: "An active subscription is needed to perform this action.",
        SubscriptionNotValidException: "The subscription is not valid at the given date.",
        SubscriptionsNotFoundException: "Subscriptions not found.",
        SuperAdminRemoveException: "You cannot remove the super admin user.",
        TenantNotFoundException: "Tenant is not found.",
        TenantsConnectedException: "There are tenants connected to this",
        TokenNotFilledInException: "Credit card token is needed from the app",
        TooManyBookingsException: "There are already too many bookings active on the user.",
        TooManyRentalsException: "There are already too many rentals active on the user.",
        UserAlreadyExistsException: "User with this username or email already exists.",
        UserBlockedException: "User is blocked.",
        UserLabelExistsException: "User label already exists",
        UserNotRegisteredAtTenantException:  "User is not registered at tenant.",
        UsersConnectedException: "There are users connected to this",
        UserVerificationException: "User has not been verified.",
        VehicleAlreadyBeingRentedException: "Vehicle is already being rented.",
        VehicleDisabledException: "Vehicle is disabled.",
        VehicleLabelExistsException: "Vehicle label already exists",
        VehicleMaintenanceException: "Vehicle is under maintenance.",
        VehicleNotFoundException: "Vehicle is not found.",
        VehicleNotRentableException: "This vehicle can not be rented",
        VehiclesBookedException: "All vehicles are booked for this droppoint.",
        VehiclesConnectedException: "There are vehicles connected to this",
        VehicleTypeNotFoundException: 'Vehicle type is not found',
        VehicleTypesBookedException: 'All vehicles of this type are booked for this droppoint.',
        VoucherDataInvalidException: "Voucher details invalid. (Tenant does not exist or Voucher Code is already in use for this tenant).",
        VoucherNotValidException: "Voucher is not valid anymore or has already been used by the user.",
        ZoneNotFoundException: "Zone not found."

    }
};
var nl = {
    yes: "Yes",
    no: "No",
    loading: "Loading",
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    save: 'Save',
    cancel: 'Cancel',
    delete: 'Delete',
    create: 'Create',
    send: 'Send',
    back: 'Back',
    choose_one: 'Choose one...',
    details: 'Details',
    general: 'General',
    import_csv: 'Import CSV',
    example_csv: 'Download Example CSV',
    required: 'Required',
    auth: {
        passwordResetSent: 'Password reset sent to email.',
        passwordResetSendingFailed: 'Failed sending password reset.',
        email: 'E-mail address',
        reset: 'Reset',
        username: 'Username',
        password: 'Password',
        forgot: 'Forgot password',
        log_in: 'Log in'
    },
    app : {
        faq: {
            faq_id: 'Faq ID',
            question: "Question",
            answer: "Answer",
            tenant_id: "Tenant ID",
            faq: 'FAQ',
        },
        pushMessages:{
            operator_push_message_id: 'Message ID',
            message_title: 'Title',
            message_text: 'Text',
            sender: 'Sender',
            receiver: 'Receiver',
            read_at: 'Read',
            created_at: 'Sent'
        },
        pois: {
            new_poi: 'Add new Poi details',
            create_poi: 'Create Poi',
            edit_poi: 'Edit Poi details',
            longitude: "Longitude",
            latitude: "Latitude",
            longitude_holder: "Longitude e.g. 5.2991845",
            latitude_holder: "Latitude e.g. 51.7258506",
            poi_id: 'Poi ID',
            tour_marker: 'Tour only marker',
            name: 'Name',
            name_holder: 'Name of the Poi',
            title: 'Title for poi text',
            tilte_holder: 'Title e.g. Highlights',
            site: 'Website (with http/https)',
            site_holder: 'Website e.g. https://www.example.com',
            phone: 'Phone number',
            phone_holder: 'Phone number e.g. 0123456789',
            text: 'Poi text',
            text_holder: 'short text about tours',
            photo: 'Photo\'s from poi (multi selectable)',
            photo_holder: 'select multiple photos',
            type: 'Type',
            reservation_link: 'Reservation link',
            reservation_link_holder: 'Set link to the reservation service of this poi',
        },
        tours: {
            tour: "Tour",
            create_tour: 'Create tour',
            name: "Name",
            tour_id: "Tour ID",
            description: "Description",
            headline: "Headline",
            waypoints: "Waypoints",
            delete_waypoint: "Delete last waypoint",
            tour_name: "Tour name",
            tour_length: "Tour length",
            tour_description: "Tour description",
            tour_created_success: "Tour created",
            tour_update_success: "Tour updated",
            tour_delete_success: "Tour deleted",
            photo: 'Photo\'s from tour',
            photo_holder: 'select put link for photo',
        }
    },
    logs:{
        user_api_log_id: 'Log ID',
        url: 'URL',
        response_code: 'Response Code',
        exception_message: 'Error Message',
        user_id: 'User ID',
        parameters: 'Input Parameters',
        response_time: 'Response Time (ms)',
        source_ip: 'Client IP',
        source_type: 'Client Type',
        username: 'Username',
        created_at: 'Called at',
        response: 'Response'
    },
    partners: {
        partner_id: "Partner ID",
        name: "Name",
        short_name: "Short Name",
        telephone_number: "Telephone Number",
        fax_number: "Fax Number",
        mobile_number: "Mobile Number",
        email: "E-mail",
        currency_id: "Currency ID",
        time_zone: "Time Zone",
        address_id: "Address ID",
        created_at: "Created at",
        updated_at: "Updated at",
        deleted_at: "Deleted at",
        city: 'City',
        street: 'Street',
        country: 'Country',
        state: 'State',
        house_number: 'House Number',
        postal_code: 'Postal Code',
        create_partner: 'Create Partner',
        delete_partners: 'Delete partners',
        delete_message: 'Do you want to delete the selected partner(s)?',
        details: 'Partner Details',
        attach_tenants: 'Attach Tenants',
        settings: 'Settings',
        registration_fields: 'Registration Fields'
    },
    tenants: {
        tenant_id: "Tenant ID",
        partner_id: "Partner ID",
        legacy: "Legacy",
        name: "Name",
        short_name: "Short Name",
        telephone_number: "Telephone Number",
        fax_number: "Fax Number",
        mobile_number: "Mobile Number",
        email: "E-mail",
        currency_id: "Currency",
        time_zone: "Time Zone",
        address_id: "Address ID",
        created_at: "Created at",
        updated_at: "Updated at",
        deleted_at: "Deleted at",
        logo_url: 'Logo',
        header_url: 'Header',
        contact: 'Contact enabled?',
        logo_url_holder: 'Logo image url (192x192)',
        header_url_holder: 'Header image url (300x155)',
        city: 'City',
        street: 'Street',
        country: 'Country',
        state: 'State',
        house_number: 'House Number',
        postal_code: 'Postal Code',
        all_or_none: 'Fill in all fields or no fields',
        create_tenant: 'Create Tenant',
        delete_tenants: 'Delete Tenants',
        delete_message: 'Do you want to delete the selected tenant(s)',
        details: 'Tenant Details',
        settings_tab: 'Settings',
        payment_provider: 'Payment Provider',
        payment_provider_key: 'Payment Provider Key',
        payment_provider_holder: 'Payment Provider name',
        payment_provider_key_holder: 'Payment provider api key',
        address: 'Address',
        latitude: 'Latitude',
        longitude: 'Longitude',
        latitude_holder: 'Latitude e.g. 5.234242',
        longitude_holder: 'Longitude e.g. 51.3453354',
        vehicles: {
            chain_id: "Chain ID",
            condition: "Vehicle Condition",
            created: "Created",
            serial: "Serial",
            brand_prefix: "Brand Prefix",
            user_identifier_id: "Identifier",
            key_tag: "Key Tag",
            last_changed: "Last Changed",
            lock_id: "Lock ID",
            renting_status: "Renting Status",
            since: "Since",
            stand_id: "Stand ID",
            tenant_id: "Tenant ID",
            label: "Vehicle Label",
            status: "Vehicle Status",
            tag: "Vehicle Tag",
            vehicle_id: "Vehicle ID",
            voucher_id: "Voucher ID",
            droppointLabel: "Droppoint",
            zone_id: "Zone ID",
            vehicle_type_id: "Vehicle Type ID",
            created_at: 'Created at',
            updated_at: 'Updated at',

            vehicleStatusCell: {
                notActive: "Not active",
                active: "Active",
                stolen: "Stolen",
                unknown: "Unknown"
            },
            conditionCell: {
                healthy: "Healthy",
                defect: "Damaged",
                missing: "Missing",
                unknown: "Unknown"
            },
            rentingStatusCell: {
                inService: "In Service",
                inLockAvailable: "In Lock (Available)",
                rentedWithVoucher: "Rented with Voucher",
                rentedWithIdentifier: "Rented with Identifier",
                inLockKeyMissing: "In Lock (Key Missing)",
                takenByOperator: "Taken by Operator",
                unknown: "Unknown"
            },
            set_location: 'Set Location',
            mark_repaired: 'Mark as repaired',
            mark_stolen: 'Mark as stolen',
            delete_vehicles: 'Delete vehicles',
            delete_message: 'Do you want to delete the selected vehicle(s)',
            vehicle_details: 'Vehicle Details',
            maintenance: 'Maintenance',
            rentals: 'Rentals',
            lock_devices: 'Lock Devices',
            vehicle_status: 'Vehicle Status',
            rental_status: 'Rental Status',
            user_label: 'User Label',
            relations: 'Relations',
            zone_label: 'Zone Label',
            chain_label: 'Chain Label',
            save_details: 'Save vehicle details',
            select_locks: 'Select Locks to Attach',
            save_locks: 'Save attached locks',
            vehicle_location: 'Vehicle Location',
            no_lock_error: 'No Lock attached to this vehicle. Attach a lock to this vehicle before setting the location.',
            location: 'Location',
            droppoint_device: 'Droppoint Device',
            warning: 'Warning',
            label_warning1: 'Are you sure you want to update the label of this vehicle?',
            label_warning2: 'This will affect the selecting of vehicles in several cases of which most important, the qr-codes of the vehicles used for renting.',
        },
        maintenance:{
            maintenance_task_id: "Task ID",
            created_at: "Created",
            description: "Description",
            priority: "Priority",
            status: "Status",
            datetime_planned: 'Date Planned',
            datetime_closed: 'Date Closed',
            maintenance_task_type_id: 'Task Type',
            vehicle: 'Vehicle',
            no_vehicle_permission: 'Not enough permissions to set vehicle',
            droppoint: 'Droppoint',
            no_droppoint_permission: 'Not enough permissions to set droppoint',
            lock: 'Lock Device',
            no_lock_permission: 'Not enough permissions to set lock device',
            resolution: 'Resolution',
            subject: 'Subject',
            reporterEmail: 'Reporter E-mail',
            reporterEmail_holder: 'E-mail',
            reporterUsername: 'Reporter Username',
            reporterUsername_holder: 'Username',
            vehicleLabel: 'Vehicle Label',
            droppointLabel: 'Droppoint Label',
            lockDeviceLabel: 'Lock Device Label',
            priorityCell: {
                low: "Low",
                medium: "Medium",
                high: "High",
                emergency: "Emergency"
            },
            statusCell: {
                open: "Open",
                accepted: 'Accepted',
                closed: 'Closed',
                overdue: 'Overdue',
                unknown: 'Unknown'
            },
            typeCell: {
                1: 'Bike maintenance',
                2: 'System maintenance',
                3: 'Too few free vehicles',
                4: 'Too few free space',
                5: 'Bike defect',
                6: 'Lock defect',
                7: 'Bike stolen',
                8: 'Other',
                9: 'Lock Check Battery'
            },
            type: 'Type',
            recur_unit: 'Recurrence Unit',
            recurrence: 'Recurrence Amount',
            date_accepted: 'Date Accepted',
            create_task: 'Create task',
            accept_task: 'Accept task',
            reopen_task: 'Re-open task',
            close_task: 'Close task',
            delete_tasks: 'Delete tasks',
            delete_message: 'Do you want to delete the selected task(s)',
            accept_tasks: 'Accept tasks',
            accept_message: 'Do you want to accept the selected task(s)',
            solution: 'Solution',
            solution_message: 'What was the solution?',
            reopen_reason: 'Reason for re-opening',
            reopen_message: 'Why does the task need to be re-opened?',
            store_solution: 'Store solution',
            accept: 'Accept',
            maintenance_task: 'Maintenance Task',
            remarks: 'Remarks',
            recur_every: 'Recur every',
            amount: 'Amount',
            at: 'at',
            date_error: 'Please insert a valid date'
        },
        vouchers: {
            voucher_id: "Voucher ID",
            voucher_code: "Voucher Code",
            valid_from: "Valid from",
            valid_from_holder: "Date e.g. '2017-01-17 05:25:00'",
            valid_until: "Valid until",
            valid_until_holder: "Date e.g. '2017-01-17 05:25:00'",
            claims_left: "Claims Left",
            claims_left_holder: "Amount of vouchers to be claimed e.g. '50'",
            discount_percentage: "Discount Percentage",
            discount_percentage_holder: "Discount percentage e.g. '25'",
            discount_time: "Discount Time",
            discount_time_holder: "Discount minutes e.g. '25'",
            created_at: "Created at",
            updated_at: "Updated at",
            tenant_id: "Tenant ID",
            vehicle_type_id: "Vehicle Type ID",
            claimed: "Claimed Vouchers",
            delete_vouchers: 'Delete vouchers',
            delete_message: 'Do you want to delete the selected voucher(s)',
            payments: 'Payments'
        },
        payments: {
            // paymentTransactionId: "Payment ID",
            payment_transaction_id: "PSP Reference",
            product_type: "Product type",
            start_time: "Start time",
            // endTime: "End time",
            end_time: "Date",
            // costs: "Costs",
            costs: "Payment Amount",
            payment_tag: "Payment tag",
            tenant_id: "Tenant ID",
            user_id: "User ID",
            // userId: "Userlabel",
            user_label: "User Label",
            vehicle_label: "Vehicle Label",
            identifier_id: "Identifier ID",
            departure_lock_id: "Departure lock ID",
            return_lock_id: "Return lock ID",
            vehicle_id: "Vehicle ID",
            productTypeCell: {
                park: "Park vehicle",
                rent: "Rent vehicle",
                reserve: "Vehicle reservation",
                unknown: "Unknown"
            },
            payment_id: "Payment ID",
            amount: "Amount",
            username: "Username",
            status: "Status",
            name: 'Subscription',
            reason_type: "Reason",
            payable_type: "Action",
            created_at: "Created At",
            updated_at: "Updated At"
        },
        users: {
            user_id: "User ID",
            username: 'Username',
            first_name: 'First Name',
            last_name: 'Last Name',
            email: 'Email',
            balance: 'Balance',

            address_id: 'Address Id',
            birth_date: 'Birthdate',
            birth_date_error: 'Please insert a valid date',
            blocked: 'Blocked',
            blockedForCurrent: 'Blocked for this Tenant',
            city: 'City',
            city_id: 'City Id',
            company: 'Company',
            country: 'Country',
            created_at: 'Created At',
            deleted_at: 'Deleted At',
            gender: 'Gender',
            house_number: 'House Number',
            initials: 'Initials',
            mobile_number: 'Mobile Number',
            passport: 'Passport',
            postal_code: 'Postal Code',
            preposition: 'Preposition',
            state: 'State',
            state_id: 'State Id',
            street: 'Street',
            telephone_number: 'Telephone Number',
            updated_at: 'Updated At',
            vat_number: 'Vat Number',
            registered: 'Registered',
            created: 'Created',
            changed: 'Last changed',
            new_password: 'New Password',
            password: 'Password',
            add_user: 'Add user',
            register: 'Register',
            block_user: 'Block User',
            create_payment_details: 'Create Payment Details',
            upgrade_balance: 'Upgrade Balance',
            delete_users: 'Delete users',
            delete_message: 'Do you want to delete the selected user(s)',
            block_users: 'Block users',
            block_message: 'Block user(s) for current tenant or for all tenants?',
            current_tenant: 'Current Tenant',
            all_tenants: 'All Tenants',
            register_users: 'Register Users',
            user_details: 'User Details',
            payments: 'Payments',
            rentals: 'Rentals',
            settings: 'Settings',
            subscriptions: 'Subscriptions',
            amount: 'Amount',
            amount_holder: 'Amount in cents e.g. 400',

            card: 'Card Number',
            card_holder: 'Card Number e.g. 4242 4242 4242 4242',
            cvc: 'CVC',
            cvc_holder: 'CVC e.g. 159',
            billing_postal: 'Billing Postal Code',
            billing_postal_holder: 'Postal Code',
            expiration: 'Expiration (MM/YY)',
            expiration_month: 'Month e.g. 12',
            expiration_year: 'Year e.g. 18',
            tenant_id: 'Tenant ID',

            blocked: 'Blocked',
            blocked_all: 'Blocked for all tenants',

            roles: "Roles",
            blockTypeCell: {
                notBlocked: "Not blocked",
                blocked: "Blocked",
                unknown: "Unknown",
                notRegistered: 'Not Registered'
            },
            registeredTypeCell: {
                notRegistered: "Not Registered",
                registered: "Registered",
                unknown: "Unknown",
            },
            userTypeCell: {
                personal: "Personal",
                business: "Business",
                temporary: "Temporary",
                unknown: "Unknown"
            },
            genderTypeCell: {
                male: "Male",
                female: "Female",
                transgender: "Transgender",
                unknown: "Unknown"
            },
            subscriptionTypeCell: {
                prepaidManualUpgrade: "Prepaid manual upgrade",
                prepaidAutoUpgrade: "Prepaid auto upgrade",
                onCreditPayment: "On credit payment",
                externalPayment: "External payment",
                unknown: "Unknown"
            },
            login_info: 'Login Information',
            contact_info: 'Contact Information',
            personal_info: 'Personal Information'
        },
        system: {
            chain_id: "Chain Label",
            identifier_id: "Identifier ID",
            lock_id: "Lock ID",
            lock_number: "Lock Number",
            mode: "Mode",
            position: "Lock Position",
            rent_with_key: "Rent with Key",
            stand_id: "Stand ID",
            tenant_id: "Tenant ID",
            usage_status: "Lock Status",
            vehicle_id: "Vehicle ID",
            zone_id: "Zone ID",
            tenant_label: "Tenant Label",
            vehicle_label: "Vehicle Label",
            identifier_label: "Identifier Label",

            modeCell: {
                unknown: "Unknown",
                parking: "Parking",
                renting: "Renting"
            },

            positionCell: {
                unknown: "Unknown",
                left: "Left",
                right: "Right"
            },

            usageStatusCell: {
                unknown: "Unknown",
                free: "Free",
                occupied: "Occupied",
                occupiedUnknownTag: "Occupied (unknown tag)"
            }
        },
        events: {
            event_id: "Event ID",
            log_date_time: "Date Time",
            tenant_id: "Tenant ID",
            event_type: "Event Type",
            chain_id: "Chain ID",
            zone_id: "Zone ID",
            ip_address: "IP Address",
            lock_number: "Lock number",
            occur_time: "Occur Time",
            identifier_type: "Identifier Type",
            departure_lock_number: "Departure Lock",
            identifier_tag: "Identifier Tag",
            rejection_code: "Rejection Code",
            vehicle_label: "Vehicle Label",
            vehicle_tag: "Vehicle Tag",
            user_label: "User Label",
            identifier_label: "Identifier Label",
            pay_amount: "Pay Amount",
            system_count: "System Count",
            failure_moment: "Failure Moment",
            actual_count: "Actual Count",
            subscription_type: "Subscription Type",
            key_tag: "Key Tag",
            duration: "Duration",
            type: {
                lock_defect: "Lock Defect",
                lock_repaired: "Lock Repaired",
                vehicle_damaged: "Vehicle Damaged",
                vehicle_repaired: "Vehicle Repaired",
                vehicle_blocked: "Vehicle Blocked",
                request_parking_start: "Request Parking Start",
                request_parking_end: "Request Parking End",
                request_renting_start: "Request Renting Start",
                request_renting_end: "Request Renting End",
                process_timeout: "Process Timeout",
                parking_started: "Parking Started",
                parking_ended: "Parking Ended",
                renting_started: "Rent Started",
                renting_ended: "Rent Ended",
                renting_cancelled: "Rent Cancelled",
                placed_with_servicecard: "Placed with ServiceCard",
                removed_with_servicecard: "Removed with ServiceCard"
            }
        },
        chains: {
            chain_id: "Chain ID",
            short_description: "Short description",
            description: "Description",
            gps_latitude: "GPS latitude",
            gps_longitude: "GPS longitude",
            street: "Street",
            house_number: "House number",
            postal_code: "Postal code",
            city: "City",
            stands: "Stands"
        },
        subscriptions:{
            subscription_id: 'Subscription ID',
            name: 'Name',
            length: 'Length',
            length_holder: 'Multiple of payment frequency',
            cost: 'Cost',
            cost_holder: 'Cost in cents',
            payment_frequency: 'Payment Frequency',
            valid_from: 'Valid From',
            valid_from_holder: "Date e.g. '2017-01-17 05:25:00'",
            valid_until: 'Valid Until',
            valid_until_holder: "Date e.g. '2017-01-17 05:25:00'",
            created_at: "Created At",
            updated_at: "Updated At",
            renew: 'Renew',
            pay_upfront: 'Pay Upfront',
            discount_subscription_entry_id: 'Discount Entry ID',
            free_subscription_entry_id: 'Free Entry ID',
            balance_subscription_entry_id: 'Balance Entry ID',
            start_time: 'Start Time',
            end_time: 'End Time',
            discount_rental: 'Rental Discount',
            discount_rental_holder: '% Rental Discount',
            discount_booking: 'Booking Discount',
            discount_booking_holder: '% Booking Discount',
            weekday: 'Weekday',
            vehicle_type_id: 'Vehicle Type',
            minutes_per_day: 'Minutes per Day',
            minutes_per_day_holder: 'Free minutes per day',
            amount: 'Amount',
            amount_holder: 'Cents added to balance',
            frequency: 'Frequency',
            max_cost_per_day: 'Max Cost per Day',
            max_cost_per_day_holder: 'Leave empty for no max cost',
            userRenew: 'User Renew',
            userValidFrom: 'User Valid From',
            userValidUntil: 'User Valid Until',
            user_id: 'User ID',
            users: 'Users',
            periods: 'Periods',
            nr: 'Nr.',
            start: 'Start',
            end: 'End',
            create_period: 'Create new period',
            delete_subscriptions: 'Delete subscriptions',
            delete_subscriptions_message: 'Do you want to delete the selected subscription(s)',
            period_id: "period ID",
            start_date: 'Start Date',
            start_date_holder: "Date e.g. '01-17'",
            end_date: 'End Date',
            end_date_holder: "Date e.g. '01-17'",
            entries: 'Subscription Entries',
            discount: 'Discount',
            balance: 'Balance',
            free: 'Free',
            delete_period: 'Delete Period',
            delete_period_message: 'Do you want to delete the selected period',
            delete_free: 'Delete free entries',
            delete_free_message: 'Do you want to delete the selected free entries',
            delete_balance: 'Delete balance entries',
            delete_balance_message: 'Do you want to delete the selected balance entries',
            delete_discount: 'Delete discount entries',
            delete_discount_message: 'Do you want to delete the selected discount entries',
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            yearly: 'Yearly',
            unlimited: 'Unlimited',
            content: 'Content',
            description: 'Description',
            hidden: 'Hidden'
        },
        zones: {
            zone_id: "Zone ID",
            created_at: "Created At",
            updated_at: "Updated At",
            label: "Label",
            radius: "Radius",
            longitude: "Longitude",
            longitude_holder: "Longitude e.g. '5.2991845'",
            latitude: "Latitude",
            latitude_holder: "Latitude e.g. '51.7258506'",
            hidden: 'Hidden',
            zones: 'Zones',
            create_zone: 'Create Zone',
            radius_holder: 'Radius in meters',
            delete_zones: 'Delete zones',
            delete_message: 'Do you want to delete the selected zone(s)'
        },
        tenant: {
            tenantId: "Tenant ID",
        },
        // Settings translations
        settings: {
            select_setting: 'Please select a setting to change from the left menu',
            roles: {
                role_id: "Role ID",
                name: "Name",
                display_name: "Display name",
                description: "Description",
                created_at: "Created at",
                updated_at: "Last updated",
                entity_id: "Entity ID",
                roles: 'Roles',
                details: 'Role Details',
                created: 'Created',
                last_changed: 'Last Changed',
                create_role: 'Create Role',
            },
            permissions: {
                id: "Permission ID",
                permission_type: "Permission type",
                name: "Name",
                display_name: "Display name",
                description: "Description",
                permissionTypeCell: {
                    resource: "Resource",
                    permission: "Permission",
                    view: "View"
                },
                permissions: 'Permissions'
            },
            operators: {
                permission_id: "Permission ID",
                permission_type: "Permission type",
                name: "Name",
                display_name: "Display name",
                description: "Description",
                permissionTypeCell: {
                    resource: "Resource",
                    permission: "Permission"
                },
                operator_create_title: 'Operator creation',
                new: 'New user',
                existing: 'Existing user',
                promote: 'Promote',
                operator: 'Operator',
                add_operator: 'Add Operator',
                demote: 'Demote'
            }
        },
        lockDevices: {
            lock_device_id: "Lock Device ID",
            label: 'Label',
            created_at: "Created at",
            updated_at: "Updated at",
            device_type: "Device Type",
            battery_level: 'Battery Level',
            status: 'Status',
            lockDeviceTypeCell: {
                bluetoothLockDevice: 'Bluetooth Lock Device',
                unknown: 'Unknown'
            },
            delete_locks: 'Delete locks',
            delete_message: 'Do you want to delete the selected lock(s)',
            lock_details: 'Lock Device Details',
            created: 'Created',
            last_changed: 'Last Changed',
            claim_code: 'Axa Claim Code',
            claim_code_holder: "Claim code e.g. '3049ACED9F0C446E9D594891B6E39790'",
            lock_uid: 'Axa Lock Uid',
            lock_uid_holder: "Axa lock uid e.g. '79BFE2EC8F57F4D1C628'",
            maintenance_tab: 'Maintenance'
        },
        droppoints: {
            droppoints: 'Droppoints',
            droppoint_id: "Droppoint ID",
            label: "Label",
            zoneLabel: 'Zone Label',
            created_at: "Created at",
            updated_at: "Updated at",
            zone_id: "Zone ID",
            longitude: "Longitude",
            latitude: "Latitude",
            longitude_holder: "Longitude e.g. 5.2991845",
            latitude_holder: "Latitude e.g. 51.7258506",
            maximum_vehicles: "Maximum Vehicles",
            maximum_vehicles_holder: "Maximum amount",
            minimum_vehicles_for_booking: "Minimum Vehicles for Booking",
            minimum_vehicles_for_booking_holder: "Minimum amount for booking",
            amount_of_vehicles: "Amount of Vehicles",
            free_spots: "Free Parking Spots",
            amount_of_bookings: "Amount of Bookings",
            free_vehicles: "Free Vehicles",
            prebooking_time: "Pre-Booking time",
            prebooking_time_holder: "Amount of minutes you may book a vehicle in advance.",
            city: 'City',
            street: 'Street',
            country: 'Country',
            state: 'State',
            house_number: 'House Number',
            postal_code: 'Postal Code',
            hidden: 'Hidden',
            closed_for_rental: 'Closed for rental',
            closed_for_return: 'Closed for return',
            closed_for_actions: 'Closed for actions',
            not_allowed: 'Not allowed droppoints',
            opening_schedule: 'Opening Schedule',
            zone: 'Zone',
            excluded_droppoints: 'Excluded droppoints',
            excluded_text: 'Select all droppoints that you may not end a rental after starting from droppoint',
            day: 'Day',
            start: 'Start',
            end: 'End',
            type: 'Type',
            action: 'Action',
            create_entry: 'Create new entry',
            delete_droppoints: 'Delete droppoints',
            delete_message: 'Do you want to delete the selected droppoint(s)',
            start_time: 'Start Time',
            end_time: 'End Time',
            delete_entry: 'Delete schedule entry',
            delete_entry_message: 'Do you want to delete the entry',
            create_droppoint: 'Create Droppoint',
        },
        droppointDevices: {
            droppoint_devices: 'Droppoint Devices',
            droppoint_device_id: "Droppoint Device ID",
            label: "Label",
            created_at: "Created at",
            updated_at: "Updated at",
            droppoint_id: "Droppoint ID",
            radius: "Radius",
            radius_holder: 'Radius in meters',
            uid: "Axa UID",
            uid_holder: "Axa uid e.g. '79BFE2EC8F57F4D1C628'",
            droppoint: 'Droppoint',
            delete_devices: 'Delete droppoint devices',
            delete_message: 'Do you want to delete the selected droppoint device(s)',
            create_droppoint_device: 'Create Droppoint Device'
        },
        tariffs: {
            tariff_id: "Tariff ID",
            origin_id: "Origin",
            origin_type: "Origin Type",
            destination_id: "Destination",
            destination_type: "Destination Type",
            bluetooth_fine: "Bluetooth Fine",
            tariff_time_entry_id: "Time Entry ID",
            start_minute: "Start Minute",
            start_minute_holder: "Start minute e.g. '0'",
            cost_per_step: "Cost per Step",
            cost_per_step_holder: "Cost per minute in cents e.g. '30'",
            step: "Step",
            step_holder: "Length of step in minutes",
            entry_owner_id: "Tariff ID",
            booking_type_id: "Booking Type ID",
            max_cost_per_day: "Max Cost per Day",
            max_cost_per_day_holder: "Amount in cents",
            start_time: "Start Time",
            end_time: "End Time",
            weekday: "Weekdays",
            start_date: 'Start Date',
            end_date: 'End Date',
            vehicleType: 'Vehicle Type',
            copy: 'copy',
            rental_tariffs: 'Rental Tariffs',
            booking_tariffs: 'Booking Tariffs',
            rental_tariff: 'Rental Tariff',
            booking_tariff: 'Booking Tariff',
            time_entries: 'Time Entries',
            vehicle_type_id: 'Vehicle Type ID',
            validity: 'Validity',
            location: 'Location',
            time_entry: 'Time Entry',
            delete_rental: 'Delete tariffs',
            delete_rental_message: 'Do you want to delete the selected tariff(s)',
            delete_booking: 'Delete booking tariffs',
            delete_booking_message: 'Do you want to delete the selected booking tariff(s)',
            delete_time_entries: 'Delete time entries',
            delete_time_entries_message: 'Dp you want to delete the selected entry(ies)'
        },
        bookings:{
            droppoint_id: "Droppoint ID",
            booking_id: "Booking ID",
            created_at: "Created At",
            updated_at: "Updated At",
            start_datetime: "Booking Start",
            end_datetime: "Booking End",
            status: "Payment Status",
            amount: "Payment Amount",
            username: "Username",
            droppoint: 'Droppoint',
            user: 'User',
            duration: 'Duration',
            duration_holder: 'Durtation in minutes e.g. 50',
            end: 'End',
            check_expired: 'Check Expired'
        },
        rentals: {
            rental_id: 'Rental ID',
            created_at: 'Created at',
            updated_at: 'Updated at',
            start_datetime: 'Rental Start',
            end_datetime: 'Rental End',
            status: 'Status',
            vehicle_id: 'Vehicle ID',
            endLabel: 'End Droppoint',
            startLabel: 'Start Droppoint',
            payment_amount: 'Amount in cents',
            payment_user_identifier_user_username: 'Username',
            duration: 'Duration',
            end: 'End'
        }
    },
    profile: {
        new_password: 'New password',
        confirm_password: 'Confirm new password',
        passwordUpdate: 'New password set.',
        passwordUpdateFailed: 'Failed setting new password. Please try again.'
    },
    messages: {
        type: {
            answer: "Answer",
            question: "Question"
        },
        reply_question: 'Reply to question',
        question: 'Question',
        answer: 'Answer',
        reply: 'Reply',
        mark_answered: 'Mark as answered',
        username: 'Username',
        first_name: 'First name',
        last_name: 'Last name',
        user_id: 'User id',
        telephone: 'Telephone number',
        mobile: 'Mobile number',
        birth_date: 'Birth Date',
        gender: 'Gender',
        country: 'Country',
        city: 'City',
        message_history: 'Message history for',
        user_info: 'User info',
        send_push: 'Send Push Message',
        message_title: 'Message Title',
        receiver: 'Receiver',
        message_text: 'Message Text',
        leave_blank: 'Leave blank for every user from this tenant',
        push_messages: 'Push Messages',
        direct_messages: 'Direct Messages',
        unanswered_questions: 'Unanswered questions',
        questions: 'Questions',
        asked_by: 'Asked by',
        created_at: 'Created at',
        archive: 'Archive',
        write_answer: 'Write your answer',
        message_history_tab: 'Message history'
    },
    overview:{
        select: 'Select a tab on the left.'
    },
    project_info:{
        loading: 'Loading',
    },
    terms_of_use:{
        loading: 'Loading'
    },
    settings:{
        settings_vehicle: 'Settings per Vehicle Type',
        max_booked: 'Max Booked',
        max_rented: 'Max Rented',
        start_cost: 'Start Cost',
        min_balance: 'Minimum Balance',
        vehicle_type: 'Vehicle Type',
        buffer: 'Pay Buffer Time',
        vouchers: 'Vouchers Only',
        float: 'Free Floating',
        rest_time: 'Mandatory Rest Time',
        subscription: 'Force Subscription',
        general_settings: 'General Settings',
        max_booked_holder: 'Maximum vehicles booked at the same time',
        max_rented_holder: 'Maximum vehicles rented at the same time',
        start_cost_holder: 'Amount of cents added to each rent',
        min_balance_holder: 'Minimum amount of cents needed to be in balance before renting',
        buffer_holder: 'Re-think time for the user',
        rest_time_holder: 'Time between rents in minutes',
        inherit_partner: 'Inherit Partner Setting',
        inherit_partner_message: "If 'Yes' is selected the setting will be used from the partner, if 'No' is selected then this will override the partner setting for this specific tenant.",
        inherit_partner_empty_message: "If left empty, the partner setting will be used.",
        inherit_tenant: 'Inherit Tenant Setting',
        inherit_tenant_message: "If 'Yes' is selected the setting will be used from the tenant, if 'No' is selected then this will override the tenant setting for this specific user.",
        inherit_tenant_empty_message: "If left empty, the tenant setting will be used.",
        bike: 'Bike',
        ebike: 'E-bike',
        edit_tenant: 'Edit Tenant',
        manage_partners: 'Manage partners',
    },
    dashboard:{
        current_rentals: 'Active Rentals',
        today_started: 'Rentals started today',
        today_ended: 'Rentals ended today',
        vehicles_booked: 'Vehicles Booked',
        vehicles_available: 'Vehicles available',
        daily_revenue: 'Daily revenue',
        vehicles_maintenance: 'Vehicles in maintenance',
        vehicles_damaged: 'Vehicles damaged',
        spots_free: 'Parking spots free',
        locks_no_vehicle: 'Locks without vehicle',
        locks_booked: 'Locks booked'
    },
    exceptions:{
        UserAlreadyExistsException: 'Username or email is already in use.',
    },
    notify:{
        faq_update_success: 'FAQ update succeeded',
        faq_update_failed: 'FAQ update failed',
        faq_create_success: 'FAQ creation succeeded',
        faq_create_failed: 'FAQ creation failed',
        faq_delete_success: 'FAQ deletion succeeded',
        faq_delete_failed: 'FAQ deletion failed',

        poi_update_success: 'Poi update succeeded',
        poi_update_failed: 'Poi update failed',
        poi_create_success: 'Poi creation succeeded',
        poi_delete_failed: 'Poi delete failed',
        poi_delete_success: 'Poi delete succeeded',
        poi_create_failed: 'Poi creation failed',

        message_create_success: 'Reply sent',
        message_create_failed: 'Reply NOT sent',

        push_message_create_success: 'Message sent',
        push_message_create_failed: 'Message NOT sent',

        project_update_success: 'Project info updated',
        project_update_failed: 'Project info update failed',

        terms_update_success: 'Terms of use updated',
        terms_update_failed: 'Terms of use update failed',

        partner_get_failed: 'Partners not retrieved',
        partner_create_success: 'Partner created',
        partner_create_failed: 'Partner creation failed',
        partner_update_success: 'Partner updated',
        partner_update_failed: 'Partner update failed',
        partner_delete_success: 'Partner(s) deleted',
        partner_delete_failed: 'Partner deletion failed',
        attach_tenants_success: 'Attached tenants',
        attach_tenants_failed: 'Unable to attach tenants',

        address_all_fields: "Fill in all address fields or no address fields at all.",

        settings_update_success: 'Settings updated',
        settings_update_failed: 'Settings update failed',

        tenant_get_failed: 'Tenants not retrieved',
        tenant_create_success: 'Tenant created',
        tenant_create_failed: 'Tenant creation failed',
        tenant_update_success: 'Tenant updated',
        tenant_update_failed: 'Tenant update failed',
        tenant_delete_success: 'Tenant(s) deleted',
        tenant_delete_failed: 'Tenant deletion failed',

        booking_create_success: 'Booking created',
        booking_create_failed: 'Booking creation failed',
        booking_end_success: 'Booking ended',
        booking_end_failed: 'Booking NOT ended',
        booking_expired_end_success: ' expired bookings ended',
        booking_expired_end_failed: 'Expired bookings NOT ended',

        zone_create_success: 'Zone created',
        zone_create_failed: 'Zone creation failed',
        zone_update_success: 'Zone updated',
        zone_update_failed: 'Zone update failed',
        zone_delete_success: 'Zone(s) deleted',
        zone_delete_failed: 'Zone deletion failed',

        droppoint_create_success: 'Droppoint created',
        droppoint_create_failed: 'Droppoint creation failed',
        droppoint_update_success: 'Droppoint updated',
        droppoint_update_failed: 'Droppoint update failed',
        droppoint_delete_success: 'Droppoint(s) deleted',
        droppoint_delete_failed: 'Droppoint deletion failed',

        droppoint_forbidden_success: 'Forbidden droppoint(s) set',
        droppoint_forbidden_failed: 'Unable to set forbidden droppoint(s)',

        droppoint_schedule_entry_create_success: 'Droppoint Schedule Entry added',
        droppoint_schedule_entry_create_failed: 'Droppoint Schedule Entry addition failed',
        droppoint_schedule_entry_delete_success: 'Droppoint Schedule Entry removed',
        droppoint_schedule_entry_delete_failed: 'Droppoint Schedule Entry removal failed',

        droppoint_device_create_success: 'Droppoint Device created',
        droppoint_device_create_failed: 'Droppoint Device creation failed',
        droppoint_device_update_success: 'Droppoint Device updated',
        droppoint_device_update_failed: 'Droppoint Device update failed',
        droppoint_device_delete_success: 'Droppoint Device(s) deleted',
        droppoint_device_delete_failed: 'Droppoint Device deletion failed',

        lock_device_create_success: 'Lock Device created',
        lock_device_create_failed: 'Lock Device creation failed',
        lock_device_update_success: 'Lock Device updated',
        lock_device_update_failed: 'Lock Device update failed',
        lock_device_delete_success: 'Lock Device(s) deleted',
        lock_device_delete_failed: 'Lock Device deletion failed',
        lock_device_batch_create_success_1: 'Imported ',
        lock_device_batch_create_success_2: ' out of ',
        lock_device_batch_create_failed_1: 'Import of lock devices failed',
        lock_device_batch_create_failed_2: 'Check that the locks can be claimed.',

        task_create_success: 'Maintenance task created',
        task_create_failed: 'Maintenance task creation failed',
        task_update_success: 'Maintenance task updated',
        task_update_failed: 'Maintenance task update failed',
        task_delete_success: 'Maintenance task(s) deleted',
        task_delete_failed: 'Maintenance task deletion failed',

        rent_end_success: 'Rent ended',
        rent_end_failed: 'Rent end failed',
        rent_get_failed: 'Rentals not retrieved',

        operator_get_failed: 'Operators not retrieved',
        operator_create_success: 'Operator created',
        operator_create_failed: 'Operator creation failed',
        operator_update_success: 'Operator updated',
        operator_update_failed: 'Operator update failed',
        operator_delete_success: 'Operator(s) demoted',
        operator_delete_failed: 'Operator demotion failed',
        operator_promote_success: 'Operator(s) promoted',
        operator_promote_failed: 'Operator promotion failed',

        roles_get_failed: 'Roles not retrieved',
        roles_create_success: 'Role created',
        roles_create_failed: 'Role creation failed',
        roles_update_success: 'Role updated',
        roles_update_failed: 'Role update failed',
        roles_delete_success: 'Role(s) deleted',
        roles_delete_failed: 'Role deletion failed',

        permissions_get_failed: 'Permissions not retrieved',

        subscription_get_failed: 'Subscriptions not retrieved',
        subscription_create_success: 'Subscription created',
        subscription_create_failed: 'Subscription creation failed',
        subscription_update_success: 'Subscription updated',
        subscription_update_failed: 'Subscription update failed',
        subscription_delete_success: 'Subscription(s) deleted',
        subscription_delete_failed: 'Subscription deletion failed',

        subscription_period_create_success: 'Period created',
        subscription_period_create_failed: 'Period creation failed',
        subscription_period_update_success: 'Period updated',
        subscription_period_update_failed: 'Period update failed',
        subscription_period_delete_success: 'Period(s) deleted',
        subscription_period_delete_failed: 'Period deletion failed',

        discount_entry_create_success: 'Discount Entry created',
        discount_entry_create_failed: 'Discount Entry creation failed',
        discount_entry_update_success: 'Discount Entry updated',
        discount_entry_update_failed: 'Discount Entry update failed',
        discount_entry_delete_success: 'Discount Entry(ies) deleted',
        discount_entry_delete_failed: 'Discount Entry deletion failed',

        free_entry_create_success: 'Free Entry created',
        free_entry_create_failed: 'Free Entry creation failed',
        free_entry_update_success: 'Free Entry updated',
        free_entry_update_failed: 'Free Entry update failed',
        free_entry_delete_success: 'Free Entry(ies) deleted',
        free_entry_delete_failed: 'Free Entry deletion failed',

        balance_entry_create_success: 'Balance Entry created',
        balance_entry_create_failed: 'Balance Entry creation failed',
        balance_entry_update_success: 'Balance Entry updated',
        balance_entry_update_failed: 'Balance Entry update failed',
        balance_entry_delete_success: 'Balance Entry(ies) deleted',
        balance_entry_delete_failed: 'Balance Entry deletion failed',

        tariff_rental_create_success: 'Rental Tariff created',
        tariff_rental_create_failed: 'Rental Tariff creation failed',
        tariff_rental_update_success: 'Rental Tariff updated',
        tariff_rental_update_failed: 'Rental Tariff update failed',
        tariff_rental_delete_success: 'Rental Tariff(s) deleted',
        tariff_rental_delete_failed: 'Rental Tariff deletion failed',

        tariff_entry_get_failed: 'Tariff Entries not retrieved',
        tariff_entry_create_success: 'Tariff Entry created',
        tariff_entry_create_failed: 'Tariff Entry creation failed',
        tariff_entry_update_success: 'Tariff Entry updated',
        tariff_entry_update_failed: 'Tariff Entry update failed',
        tariff_entry_delete_success: 'Tariff Entry(s) deleted',
        tariff_entry_delete_failed: 'Tariff Entry deletion failed',

        tariff_booking_create_success: 'Booking Tariff created',
        tariff_booking_create_failed: 'Booking Tariff creation failed',
        tariff_booking_update_success: 'Booking Tariff updated',
        tariff_booking_update_failed: 'Booking Tariff update failed',
        tariff_booking_delete_success: 'Booking Tariff(s) deleted',
        tariff_booking_delete_failed: 'Booking Tariff deletion failed',

        user_delete_admin_failed: 'You cannot delete the super-admin',
        user_block_current_success: 'User blocked for current tenant',
        user_block_current_failed: 'User NOT blocked for current tenant',
        user_register_success: 'User registered for current tenant',
        user_register_failed: 'User NOT registered for current tenant',
        user_no_payment_details: 'User has no payment details yet for the current tenant.',
        user_payment_create_success: 'User payment details created',
        user_payment_create_failed: 'User payment details creation failed',
        user_balance_add_success: 'Balance added',
        user_balance_add_failed: 'Balance NOT added',
        user_subscriptions_success: 'User has been given subscriptions',
        user_subscriptions_failed: 'User has NOT been given subscriptions',

        user_create_success: 'User created',
        user_create_failed: 'User creation failed',
        user_update_success: 'User updated',
        user_update_failed: 'User update failed',
        user_delete_success: 'User(s) deleted',
        user_delete_failed: 'User deletion failed',

        user_batch_create_success_1: 'Imported ',
        user_batch_create_success_2: ' out of ',
        user_batch_create_failed_1: 'Import of users failed',
        user_batch_create_failed_2: 'Check that the username and email values are unique and do not yet exist in the system.',

        vehicle_get_failed: 'Vehicles not retrieved',
        vehicle_create_success: 'Vehicle created',
        vehicle_create_failed: 'Vehicle creation failed',
        vehicle_update_success: 'Vehicle updated',
        vehicle_update_failed: 'Vehicle update failed',
        vehicle_delete_success: 'Vehicle(s) deleted',
        vehicle_delete_failed: 'Vehicle deletion failed',

        vehicle_location_success: 'Location is set',
        vehicle_no_lock: 'Vehicle has no lock device',
        vehicle_location_failed: 'Location is NOT set',
        vehicle_attach_locks_success: 'Updated vehicle locks',
        vehicle_attach_locks_failed: 'Update failed of vehicle locks',

        vehicle_batch_create_success_1: 'Imported ',
        vehicle_batch_create_success_2: ' out of ',
        vehicle_batch_create_failed_1: 'Import of vehicles failed',
        vehicle_batch_create_failed_2: 'Check that the label is unique, and the status and condition values are valid.',

        voucher_create_success: 'Voucher created',
        voucher_create_failed: 'Voucher creation failed',
        voucher_update_success: 'Voucher updated',
        voucher_update_failed: 'Voucher update failed',
        voucher_delete_success: 'Voucher(s) deleted',
        voucher_delete_failed: 'Voucher deletion failed',

        voucher_batch_create_success_1: 'Imported ',
        voucher_batch_create_success_2: ' out of ',
        voucher_batch_create_failed_1: 'Import of vouchers failed',
        voucher_batch_create_failed_2: 'There were errors, check all values are valid.',

        payment_get_failed: 'Payments NOT retrieved',
    }
};

angular.module("2cp").config(["$translateProvider", function ($translateProvider) {
    // add translation table
    $translateProvider
        .translations("en", en)
        .translations("nl", nl)
        .preferredLanguage("en");

    $translateProvider.useSanitizeValueStrategy('escapeParameters');
}]);