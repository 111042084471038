angular.module("2cp").factory("TenantColumns",
    [
        "$translate", "$filter", "$timeout", "Tenants", "ColumnDefBuilder", "Address",
        function ($translate, $filter, $timeout, Tenants, ColumnDefBuilder, Address) {

            var columnOptions = {
                tenant_id: {
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                },
                legacy: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                name: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                short_name: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                telephone_number: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                fax_number: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                mobile_number: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                email: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                partner_id: {
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                },
                currency_id: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true,
                    cellFilter: 'currencies:this'
                },
                time_zone: {
                    visible: true,
                    enableCellEdit: false,
                    enableFiltering: true
                },
                address_id: {
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                },
                logo_url: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                header_url: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: true
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                deleted_at: {
                    visible: false,
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                country: {
                    selectOptions: {
                        keys: Address.getCountries(),
                        filter: 'countryFilter'
                    },
                },
                contact_enabled: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Tenants.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.")
                .copy();

            return builder;
        }
    ]
);