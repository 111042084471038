angular.module("2cp").controller("logs",
    [
        "$scope", 'Logs', 'LogColumns',
        function ($scope, Logs, LogColumns) {
            $scope.logDetails = {};
            $scope.logGridOptions = {
                columnDefs: LogColumns.build(),

                callGridData: {
                    id: "user_api_log_id",
                    get: Logs.getLogs
                },

                onRegisterApi: function(gridApi) {
                    $scope.logGrid = gridApi.grid;
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showDetailsModal(rowEntity);
                    }
                }
            };

            $scope.showDetailsModal = function (rowEntity) {
                $scope.logDetails = rowEntity;
                console.log(rowEntity);
                if($scope.logDetails.parameters)
                    $scope.logDetails.parameters = angular.fromJson($scope.logDetails.parameters);
                if($scope.logDetails.response)
                    $scope.logDetails.response = angular.fromJson($scope.logDetails.response);

                $("#detailsModal").foundation('open');
            };
        }
    ]
);