angular.module("2cp").controller("tenants.dashboard",
    ["$scope", "$timeout", "LockDevices", "Vehicles", "Events", "$translate",
        "$interval", "Bookings", "Rentals", "Droppoints", "PermissionService", "Times", "Payments", "Tenants",
        function ($scope, $timeout, LockDevices, Vehicles, Events, $translate,
                  $interval, Bookings, Rentals, Droppoints, PermissionService, Times, Payments, Tenants) {
            $scope.rentals = {
                current: null,
                today: null,
                cancelled: null
            }

            $scope.vehicles = {
                available: null,
                maintenance: null,
                booked: {
                    value: null,
                    sum: 0
                },
                damaged: null
            }

            $scope.locks = {
                free: null,
                error: null,
                booked: {
                    value: null,
                    sum: 0
                },
            }

            $scope.revenue = {
                daily: null
            }

            Tenants.getTenant(calllock.tenantId).then(function(result){
                $scope.tenant = result;
            });

            function initialize() {
                loadData();
                $interval(loadData, 60000);
                //google.maps.event.addDomListener(window, 'load', setupMap);
            }

            function setupMap() {
                var mapCanvas = document.getElementById('map');
                var calllockLatLng = new google.maps.LatLng(51.8329516, 5.7459238);
                var mapOptions = {
                    center: calllockLatLng,
                    zoom: 13,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }
                var map = new google.maps.Map(mapCanvas, mapOptions);
                var pinColor = "00E500";
                var pinImage = new google.maps.MarkerImage("http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + pinColor,
                    new google.maps.Size(21, 34),
                    new google.maps.Point(0, 0),
                    new google.maps.Point(10, 34));
                var marker = new google.maps.Marker({
                    position: calllockLatLng,
                    map: map,
                    icon: pinImage,
                    title: 'Calllock.com'
                });
            }

            function loadData() {
                console.log("Updating dashboard data");
                rentals.update();
                vehicles.update();
                locks.update();
                revenue.update();
            }

            var rentals = {
                update: function () {
                    if (PermissionService.hasPermissions(['viewTodayStartedRentals'])) {
                        rentals.updateRentalsStartedToday();
                    }
                    if (PermissionService.hasPermissions(['viewTodayEndRentals'])) {
                        rentals.updateRentalsEndedToday();
                    }
                    if (PermissionService.hasPermissions(['viewCurrentRentals'])) {
                        rentals.updateActiveRentals();
                    }
                },
                updateRentalsStartedToday: function () {
                    $scope.today = calllock.today;
                    $scope.tomorrow = calllock.tomorrow;
                    Rentals.getRentals({
                        start_datetime_min: $scope.today,
                        start_datetime_max: $scope.tomorrow
                    }).then(function (response) {
                        $scope.todayStartRentals = response.total;
                    }, function (error) {
                    });
                },
                updateRentalsEndedToday: function () {
                    $scope.today = calllock.today;
                    $scope.tomorrow = calllock.tomorrow;
                    Rentals.getRentals({
                        end_datetime_min: $scope.today,
                        end_datetime_max: $scope.tomorrow
                    }).then(function (response) {
                        $scope.todayEndRentals = response.total;
                    }, function (error) {
                    });
                },
                updateActiveRentals: function () {
                    Rentals.getRentals({end_datetime: "0000-00-00 00:00:00"}).then(function (response) {
                        $scope.activeRentals = response.total;
                    }, function (error) {
                    });
                }
            };

            var vehicles = {
                update: function () {
                    if (PermissionService.hasPermissions(['viewVehiclesAvailable'])) {
                        vehicles.updateAvailable();
                    }
                    if (PermissionService.hasPermissions(['viewVehiclesInMaintenance'])) {
                        vehicles.updateMaintenance();
                    }
                    if (PermissionService.hasPermissions(['viewVehiclesBooked'])) {
                        vehicles.updateBooked();
                    }
                    if (PermissionService.hasPermissions(['viewVehiclesDamaged'])) {
                        vehicles.updateDamaged();
                    }
                },
                updateAvailable: function () {
                    Droppoints.getDroppoints({limit: 9999}).then(function (response) {
                        var totalVehicles = 0;
                        for (var i = 0; i < response.data.length; i++) {
                            totalVehicles += response.data[i].free_vehicles;
                        }
                        $scope.availableVehicles = totalVehicles;
                    }, function (error) {
                    });
                },
                updateMaintenance: function () {
                    Vehicles.getVehicles({"condition": Vehicles.CONDITION.DEFECT})
                        .then(function (result) {
                            return $timeout(function () {
                                $scope.vehicles.maintenance = result.total;
                            }, 0);
                        }, function (error) {
                        });
                },
                updateBooked: function () {
                    var now = new Date();
                    now = convertToTenantTimezone(now).format("YYYY-MM-DD HH:mm:ss");
                    Bookings.getBookings({end_datetime_min: now, booking_type_id: 4}).then(function (response) {
                        $scope.bookedVehicles = response.total;
                    }, function (error) {
                    });
                },
                updateDamaged: function () {
                    Vehicles.getVehicles({"condition": Vehicles.CONDITION.DEFECT})
                        .then(function (result) {
                            return $timeout(function () {
                                $scope.vehicles.damaged = result.total;
                            }, 0);
                        }, function (error) {
                        });
                }
            };

            var locks = {
                update: function () {
                    if (PermissionService.hasPermissions(['viewLocksFree'])) {
                        locks.updateFree();
                    }
                    if (PermissionService.hasPermissions(['viewLocksInError'])) {
                        locks.updateError();
                    }
                    if (PermissionService.hasPermissions(['viewLocksBooked'])) {
                        locks.updateBooked();
                    }
                },
                updateFree: function () {
                    Droppoints.getDroppoints({limit: 9999}).then(function (response) {
                        var totalSpots = 0;
                        for (var i = 0; i < response.data.length; i++) {
                            totalSpots += response.data[i].free_spots;
                        }
                        $scope.freeSpots = totalSpots;
                    }, function (error) {
                    });
                },
                updateError: function () {
                    LockDevices.getFreeLocks().then(function (result) {
                        $scope.freeLocks = result.total;
                    }, function (error) {
                    });
                },
                updateBooked: function () {
                    var now = new Date();
                    now = convertToTenantTimezone(now).format("YYYY-MM-DD HH:mm:ss");
                    Bookings.getBookings({end_datetime_min: now, booking_type_id: 5}).then(function (response) {
                        $scope.bookedLocks = response.total;
                    }, function (error) {
                    });
                }
            };

            var revenue = {
                update: function () {
                    if (PermissionService.hasPermissions(['viewDailyRevenue'])) {
                        revenue.updateDailyRevenue();
                    }
                },

                updateDailyRevenue: function(){
                    var dailyRevenue = 0;
                    var now = moment();
                    now = moment(Times.getTenantDateTimeString(now, Times.getZone()));
                    now.startOf('day');
                    var tomorrow = now.clone();
                    tomorrow.add(1, 'days');
                    Payments.getPayments({
                        'reason_type': 'action',
                        'status': 'approved',
                        'updated_at_min': now.format('YYYY-MM-DD HH:mm:ss'),
                        'updated_at_max': tomorrow.format('YYYY-MM-DD HH:mm:ss')
                    }).then(function(result){
                        for(var i = 0; i < result.data.length; i++){
                            dailyRevenue += result.data[i].amount / 100;
                        }
                        $scope.dailyRevenue = dailyRevenue;
                    });
                },
            };

            initialize();
        }
    ]);