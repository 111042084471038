'use strict';

angular.module('api').factory('VehicleTypes', [
    '$q',
    'clApi',
    function ($q, api) {
        return {
            parameters: [
                'name',
                'created_at',
                'updated_at',
                'vehicle_type_id'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getVehicleTypes: function (params, options) {
                return api.get('vehicleTypes', params, options).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('vehicleTypes/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('vehicleTypes', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (ids, data) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('vehicleTypes/' + id, data));
                });

                return $q.all(promises);
            },
        };
    },
]);
