angular.module("2cp").directive('callGridEditIcon', [
    function () {
        return {
            restrict: 'A',
            replace: true,
            priority: 100, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function ($scope, $elm, $attrs, uiGridCtrl) {
                var grid = uiGridCtrl.grid;

                grid.options.columnDefs.unshift({
                    name: "callGridEditIcon",
                    displayName: "",
                    enableCellEdit: false,
                    enableHiding: false,
                    enableColumnMoving: false,
                    maxWidth: 30,
                    headerCellTemplate: "<div></div>",
                    cellTemplate: '<div class="ui-grid-edit-icon"><i ng-click="grid.options.callGridEditIcon.clickCallback(row.entity)" class="fa fa-pencil-square-o vertical-center"></i></div>'
                });
            }
        };
    }]);