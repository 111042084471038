'use strict';

angular.module('api').factory('Faq', [
    '$http',
    'clApi',
    '$q',
    function ($http, api, $q) {

        return {
            parameters: [
                'faq_id',
                'question',
                'answer',
                'tenant_id'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getFaq: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('app/faqs', params, options).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('app/faqs/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('app/faqs', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (faqIds) {
                var promises = [];
                faqIds.forEach(function (id) {
                    promises.push(api.delete('app/faqs/' + id));
                });

                return $q.all(promises);
            },
        };
    }
]);
