/**
 * columnDefs can have the following new properties:
 *
 * dateTimeOptions: { //if this option is present, we assume the column contains datetime
 *  directive: ... //directive to apply to the editable cell template
 * }
 *
 * selectOptions: { //if this option is present, we assume the column has a select dropdown
 *  keys: ... //array of keys of the select options
 *  filter: ... //filter to apply to all the keys
 * }
 */
angular.module("2cp").directive("callGridSorting", [
    "$rootScope", "$location", "$timeout", "uiGridConstants",
    function ($rootScope, $location, $timeout, uiGridConstants) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            compile: function(tElem, tAttrs) {
                return {
                    pre: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;
                        grid.options.useExternalSorting = true;
                    },
                    post: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;

                        function getSortString(columns) {
                            var sortStr = "";
                            var first = true;
                            columns.forEach(function(column) {
                                if(typeof(column.sort) === "undefined")
                                    return;

                                var directionStr = "";
                                if(typeof(column.sort.direction) !== "undefined") {
                                    if (column.sort.direction === uiGridConstants.DESC) {
                                        directionStr = "-";
                                    }

                                    sortStr += (first ? "" : ",") + (directionStr + column.name);
                                    first = false;
                                }
                            });

                            //By setting to null, the sort string won't be added as a query parameter in the request
                            if(sortStr === "")
                                sortStr = null;

                            return sortStr;
                        }

                        //Use the default sort options from the columnDefs
                        grid.options.callGridData.params.sort = getSortString(grid.options.columnDefs);

                        //Listen to sorting changes
                        grid.api.core.on.sortChanged($scope, function(grid, sortColumns) {
                            grid.options.callGridData.params.sort = getSortString(grid.columns);
                            grid.api.callGridData.get();
                        });
                    }
                };
            }
        };
    }]);