'use strict';

angular.module('api').factory('Messages', [
    '$http',
    'clApi',
    '$q',
    function ($http, api, $q) {

        var entitiesUrl = "app/messages";
        var entityUrl = entitiesUrl + "/";

        return {
            parameters: [
                'app_message_id',
                'user_id',
                'tenant_id',
                'type',
                'body',
                'is_answered'
            ],

            getParameters: function () {
                return this.parameters;
            },

            get: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get(entitiesUrl, params, options).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put(entityUrl + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post(entitiesUrl, params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (faqIds) {
                var promises = [];
                faqIds.forEach(function (id) {
                    promises.push(api.delete(entityUrl + id));
                });

                return $q.all(promises);
            },
        };
    }
]);
