angular.module("2cp").factory("PermissionColumns",
    [
        "$translate", "$filter",
        "Permissions", 
        "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter,
                  Permissions,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                display_name: {
                    visible: true,
                    enableFiltering: true,
                },
                description: {
                    visible: true
                },
                permission_type: {
                    visible: true,
                    //cellFilter: "permissionType | translate",
                    enableFiltering: true
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Permissions.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.settings.permissions.')
                .copy();

            return builder;
        }
    ]
);