'use strict';

angular.module('api').factory('DroppointDevices', [
    'clApi',
    '$q',
    function (api, $q) {
        var cache = api.getOrCreateSessionCache('Zones');

        return {
            parameters: [
                'droppoint_id',
                'label',
                'uid',
                'radius',
                'droppoint_device_id',
                'created_at',
                'updated_at',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getDroppointDevices: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);
                return api.get('droppointDevices', params, options).then(function (result) {
                    var devices = result.data.data;
                    for(var i = 0; i < devices.length; i++){
                        devices[i].radius = devices[i].device.radius;
                        devices[i].uid = devices[i].device.uid;
                    }
                    return result.data;
                });
            },

            getDroppointDevice: function (id) {
                return api.get('droppointDevices/' + id).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('droppointDevices/' + id, params).then(function (result) {
                    result.data.radius = result.data.device.radius;
                    result.data.uid = result.data.device.uid;
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('droppointDevices', params).then(function (result) {
                    result.data.radius = result.data.device.radius;
                    result.data.uid = result.data.device.uid;
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('droppointDevices/' + id));
                });

                return $q.all(promises);
            },

			findFromUids: function(uids, tenant_id) {
				if(!angular.isArray(uids))
					uids = [uids];

				return api.get('droppointDevices/findFromUids', {
					"uids[]": uids,
					'tenant_id': tenant_id
				}).then(function(result) {
					return result.data;
				});
			}
        };
    },
]);
