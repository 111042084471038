angular.module("2cp").controller("tenants.bookings",
    [
        "$scope", "BookingColumns", "Bookings", "Users", "Droppoints", "PermissionService",
        '$translate', 'VehicleTypes',
        function ($scope, BookingColumns, Bookings, Users, Droppoints, PermissionService,
                  $translate, VehicleTypes) {
            $scope.vehicleTypes = [];
            $scope.bookingsGridOptions = {
                columnDefs: BookingColumns.
                addOptionsByName({'vehicleType' : {'selectOptions': $scope.vehicleTypes}}).
                build(),

                callGridData: {
                    id: "booking_id",
                    get: function(data){
                        if(data.vehicleType){
                            data.vehicleType = '"' + data.vehicleType + '"';
                        }
                        data.booking_type_id = '"' + 4 + '"';
                        return Bookings.getBookings(data).then(function(result){
                            return result;
                        });
                    },
                    create: function (data) {
                        $scope.saving = true;
                        data.booking_type_id = 4;
                        $('.reveal').foundation('close');
                        return Bookings.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.booking_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            })
                            .finally(function() {
                                $scope.saving = false;
                            });
                    },

                },
                callGridFiltering: {
                    queryParameterPrefix: "bookings."
                },
                onRegisterApi: function (gridApi) {
                    $scope.bookingsGrid = gridApi.grid;
                }
            };

            $scope.endBooking = function () {
                $scope.saving = true;
                var selectedRows = $scope.bookingsGrid.api.selection.getSelectedRows();
                selectedRows.forEach(function (row) {
                    Bookings.endBooking(row.booking_id).then(function () {
                        $.notify($translate.instant('notify.booking_end_success'), "success");
                    }).catch(function () {
                        console.error(e);
                    }).finally(function() {
                        $scope.saving = false;
                    })
                });
            };

            $scope.endExpiredBookings = function () {
                $scope.saving = true;
                Bookings.endExpiredBookings().then(function (response) {
                    $.notify(response + $translate.instant('notify.booking_expired_end_success'), "success");
                }).catch(function () {
                    console.error(e);
                }).finally(function() {
                    $scope.saving = false;
                });
            };

            $scope.showEndButton = function () {
                var r = true;
                var rows = $scope.bookingsGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.showDetailsModal = function (creationModal, rowEntity) {
                $scope.bookingDetails = rowEntity;
                $scope.creationModal = creationModal;

                $("#detailsModal").foundation('open');
            };

            $scope.detailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close')

                if ($scope.creationModal) {
                    $scope.bookingsGrid.api.callGridEditing.addRow(updatedRow);
                }
                else
                    $scope.bookingsGrid.api.rowEdit.setRowsDirty([updatedRow]);
            };

            if (PermissionService.hasPermissions(['viewUsers'])) {
                Users.getUsers({limit: 9999999}).then(function (response) {
                    $scope.users = response.data;
                });
            }
            if (PermissionService.hasPermissions(['viewDroppoints'])) {
                Droppoints.getDroppoints({limit: 9999999}).then(function (response) {
                    $scope.droppoints = response.data;
                });
            }

            //Set vehicle types for filtering and for in the details modal
            VehicleTypes.getVehicleTypes({limit: 999}).then(function(response){
                for(var i = 0; i < response.data.length; i++){
                    $scope.vehicleTypes.push({
                        'value': response.data[i].name,
                        'label': response.data[i].name,
                        'id': response.data[i].vehicle_type_id
                    });
                }
                for(i = 0; i < $scope.bookingsGrid.columns.length; i++){
                    if($scope.bookingsGrid.columns[i].name === 'vehicleType'){
                        $scope.bookingsGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
            });
        },
    ]
);