angular.module("2cp").factory("UserColumns",
    [
        "$translate", "$filter",
        "Users",
        "ColumnDefBuilder", "uiGridConstants", "Address",
        function ($translate, $filter,
                  Users,
                  ColumnDefBuilder, uiGridConstants, Address) {

            var columnOptions = {
                user_id: {
                    enableCellEdit: false
                },
                username: {
                    visible: true,
                    enableFiltering: true
                },
                first_name: {
                    visible: true,
                    enableFiltering: true
                },
                last_name: {
                    visible: true,
                    enableFiltering: true
                },
                balance: {
                    cellFilter: "userBalance",
                    visible: true
                },
                email: {
                    enableFiltering: true
                },
                vat_number: {
                    enableFiltering: true
                },
                passport: {
                    enableFiltering: true
                },
                // user_type: {
                //     visible: true,
                //     cellFilter: "userType | translate",
                //     selectOptions: {
                //         keys: Users.USER_TYPE,
                //         filter: "userType"
                //     },
                //     cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                //         var value = grid.getCellValue(row, col);
                //         if (value == Users.USER_TYPE.PERSONAL)
                //             return "green";
                //         else if (value == Users.USER_TYPE.BUSINESS)
                //             return "blue";
                //         else if (value == Users.USER_TYPE.TEMPORARY)
                //             return "yellow";
                //         else
                //             return "orange";
                //     }
                // },
                gender: {
                    cellFilter: "gender | translate",
                    selectOptions: {
                        keys: Users.GENDER_TYPE,
                        filter: "gender"
                    },
                    cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        var value = grid.getCellValue(row, col);
                        if (value == Users.GENDER_TYPE.MALE)
                            return "blue";
                        else if (value == Users.GENDER_TYPE.FEMALE)
                            return "green";
                        else
                            return "orange";
                    }
                },
                subscription_valid_from: {
                    cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: false,
                    enableFiltering: true
                },
                subscription_valid_to: {
                    cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: false,
                    enableFiltering: true
                },
                birthdate: {
                    cellFilter: 'tenantdate:"DD-MM-YYYY"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableFiltering: true
                },
                blocked: {
                    cellFilter: "blocked | translate",
                    selectOptions: {
                        keys: Users.BLOCK_STATUS,
                        filter: "blocked"
                    },
                    cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        var value = grid.getCellValue(row, col);
                        if (value == Users.BLOCK_STATUS.NOT_BLOCKED)
                            return "green";
                        else if (value == Users.BLOCK_STATUS.BLOCKED)
                            return "red";
                        else
                            return "orange";
                    },
                    enableFiltering: true
                },
                blockedForCurrent: {
                    cellFilter: "blockedForCurrent | translate",
                    cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                        var value = grid.getCellValue(row, col);
                        if (value == Users.BLOCK_STATUS.NOT_BLOCKED)
                            return "green";
                        else if (value == Users.BLOCK_STATUS.BLOCKED)
                            return "red";
                        else
                            return "orange";
                    },
                    selectOptions: {
                        keys: Users.BLOCK_STATUS,
                        filter: "blocked"
                    },
                    enableFiltering: true,
                    visible: true
                },
                created_at: {
                    visible: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: false,
                    enableFiltering: true,
                    sort: {
                        direction: uiGridConstants.DESC,
                        priority: 0
                    }
                },
                updated_at: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: false,
                    enableFiltering: true
                },
                deleted_at: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: false,
                    enableFiltering: true
                },
                city: {
                    enableFiltering: true
                },
                country: {
                    enableFiltering: true,
                    selectOptions: {
                        keys: Address.getCountries(),
                        filter: 'countryFilter'
                    },
                },
                state: {
                    enableFiltering: true
                },
                company: {
                    visible: true,
                    enableFiltering: true
                },
                street: {
                    enableFiltering: true
                },
                house_number: {
                    enableFiltering: true
                },
                postal_code: {
                    visible: true,
                    enableFiltering: true
                },
                registered: {
                    enableSorting: false,
                    enableFiltering: true,
                    cellTemplate: '<div class="ui-grid-icon"><i class="fa vertical-center" ng-class="grid.getCellValue(row, col) ? \'fa-check\' : \'fa-times\'"></i></div>',
                    selectOptions: {
                        keys: Users.BLOCK_STATUS,
                        filter: "registered"
                    },
                }
                // subscription_type: {
                //     visible: true,
                //     cellFilter: "subscriptionType | translate",
                //     selectOptions: {
                //         keys: Users.SUBSCRIPTION_TYPE,
                //         filter: "subscriptionType"
                //     },
                //     cellClass: function (grid, row, col, rowRenderIndex, colRenderIndex) {
                //         var value = grid.getCellValue(row, col);
                //         if (value == Users.SUBSCRIPTION_TYPE.PREPAID_MANUAL_UPGRADE)
                //             return "blue";
                //         else if (value == Users.SUBSCRIPTION_TYPE.PREPAID_AUTO_UPGRADE)
                //             return "blue";
                //         else if (value == Users.SUBSCRIPTION_TYPE.ON_CREDIT_PAYMENT)
                //             return "green";
                //         else if (value == Users.SUBSCRIPTION_TYPE.EXTERNAL_PAYMENT)
                //             return "green";
                //         else
                //             return "orange";
                //     }
                // }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Users.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.users.')
                .copy();

            return builder;
        }
    ]
);