'use strict';

angular.module('api').factory('Currencies', [
    'clApi',
    function (api) {
        return {
            parameters: [
                'currency_id',
                'name',
                'symbol',
                'currency_code'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getCurrencies: function (params, options) {
                options = api.createIfNotExist(options);
                return api.get('currencies', params, options).then(function (result) {
                    return result.data;
                });
            },

        };
    },
]);
