'use strict';

angular.module('api').factory('UserIdentifiers', [
    '$q',
    'clApi',
    '$filter',
    function ($q, api, $filter) {
        return {
            parameters: [
                'user_identifier_id',
                'label',
                'tag',
                'blocked',
                'type',
                'user_id',
                'tenant_id',
                'external_payment_tag',
                'parking_locks',
                'rented_vehicles',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getAll: function (params) {
                return api.get('user_identifiers', params).then(function (result) {
                    return result.data;
                });
            },

            get: function (id, params) {
                return api.get('user_identifiers/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('users/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('users', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (userIds) {
                var promises = [];
                userIds.forEach(function (id) {
                    promises.push(api.delete('users/' + id));
                });

                return $q.all(promises);
            },
        };
    },
]);
