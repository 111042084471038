angular.module("2cp").controller("tenants.payments",
	[
	"$scope", "$timeout", "Payments", "PaymentColumns", "$translate",
        function ($scope, $timeout, Payments, PaymentColumns, $translate) {
            $scope.paymentGridOptions = {
                columnDefs: PaymentColumns.build(),

                callGridData: {
                    id: "payment_id",
                    get: Payments.getPayments
                },

                onRegisterApi: function(gridApi) {
                    $scope.paymentGrid = gridApi.grid;
                }
            };

            $scope.showApproveButton = function(){
                var r = true;

                var rows = $scope.paymentGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;
                return r;
            };

            $scope.showRejectButton = function(){
                var r = true;

                var rows = $scope.paymentGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;
                return r;
            };

            $scope.showApproveModal = function () {
                $("#approveModal").foundation('open');
            };

            $scope.showRejectModal = function () {
                $("#rejectModal").foundation('open');
            };

            $scope.approveSelected = function() {
                $scope.saving = true;
                var rows = $scope.paymentGrid.api.selection.getSelectedRows();
                var ids = [];
                for(var i = 0; i < rows.length; i++){
                    ids.push(rows[i].payment_id);
                }
                Payments.approve(ids).then(function(){
                    $.notify($translate.instant('notify.payments_approved'), 'success');
                    for(i = 0; i < rows.length; i++){
                        rows[i].status = 'approved';
                    }
                }).finally(function(){
                    $scope.saving = false;
                });
            };

            $scope.rejectSelected = function() {
                $scope.saving = true;
                var rows = $scope.paymentGrid.api.selection.getSelectedRows();
                var ids = [];
                for(var i = 0; i < rows.length; i++){
                    ids.push(rows[i].payment_id);
                }
                Payments.reject(ids).then(function(){
                    $.notify($translate.instant('notify.payments_rejected'), 'success');
                    for(i = 0; i < rows.length; i++){
                        rows[i].status = 'rejected';
                    }
                }).finally(function(){
                    $scope.saving = false;
                });
            };
        }
    ]
);