angular.module("2cp").factory("PartnerColumns",
    [
        "$translate", "$filter", "$timeout", "Partners", "ColumnDefBuilder", "Address",
        function ($translate, $filter, $timeout, Partners, ColumnDefBuilder, Address) {

            var columnOptions = {
                partner_id: {
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                },
                name: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                short_name: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                telephone_number: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                fax_number: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                mobile_number: {
                    visible: false,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                email: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: true
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                deleted_at: {
                    visible: false,
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                country: {
                    selectOptions: {
                        keys: Address.getCountries(),
                        filter: 'countryFilter'
                    },
                },
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Partners.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("partners.")
                .copy();

            return builder;
        }
    ]
);