angular.module("2cp").controller("partners.tenants",
    [
        "$scope", "$timeout", "$window", "Tenants", "$translate", "TenantColumns",
        "Currencies", "Vehicles", "PermissionService", "Address", 'VehicleTypes',
        function ($scope, $timeout, $window, Tenants, $translate, TenantColumns,
                  Currencies, Vehicles, PermissionService, Address, VehicleTypes) {
            $scope.currencies = [];
            $scope.settingDetails = {};
            $scope.vehicleTypes = [];
            $scope.countries = Address.getCountries();
            $scope.inheritOptions = [{value: true, label: 'Yes'}, {value: false, label: 'No'}];
            $scope.mayUpdateSettings = PermissionService.hasPermissions(['updateEntitySettings']);
            $scope.paymentOptions = Tenants.PAYMENT_OPTIONS;

            var inheritSettings = {
                floatInherit: 'allow_free_floating',
                voucherInherit: 'vouchers_only',
                forceInherit: 'force_subscription',
                hideBookingInherit: 'hide_booking',
                hideFromAppInherit: 'hide_from_app'
            };

            Currencies.getCurrencies().then(function (response) {
                $scope.currencies = response;
            });

            $scope.currencyOptions = [];

            $scope.tenantsGridOptions = {
                columnDefs: TenantColumns.addOptionsByName({'currency_id': {'selectOptions': $scope.currencyOptions}}).build(),

                callGridData: {
                    id: "tenant_id",
                    get: function (params) {
                        params.with = 'address';
                        return Tenants.getTenants(params)
                            .then(function (response) {
                                $scope.setCurrencyTypeOptions();
                                return response;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    create: function (data) {
                        data.with = 'address';
                        $('.reveal').foundation('close');
                        return Tenants.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.tenant_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        data.with = 'address';
                        return Tenants.update(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.tenant_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Tenants.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.tenant_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "tenants."
                },
                onRegisterApi: function (gridApi) {
                    $scope.tenantsGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function () {
                var r = true;

                var rows = $scope.tenantsGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };
            $scope.removeSelected = function () {
                $scope.tenantsGrid.api.callGridEditing.removeSelectedRows();
            };

            // Time Zones
            var timeZones = [];
            var timestamp = Date.now();

            moment.tz.names().forEach(function (element) {
                var res = element.split("/");
                var string = ((res[1]) ? res[0] + ' - ' + res[1] : res[0]);

                timeZones.push({
                    value: element,
                    label: '(UTC' + moment.tz(timestamp, element).format('Z') + ') ' + string
                });
            });

            $scope.timeZones = timeZones;
            $scope.selectedTimeZone = {
                value: 'Etc/UTC',
                label: '(UTC+00:00) Etc - UTC'
            };

            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showDetailsModal = function (creationModal, rowEntity) {
                $scope.tenantDetails = rowEntity;
                $scope.tenantCreationModal = creationModal;


                if (rowEntity) {
                    $scope.addressDetails = rowEntity.address || {};
                    timeZones.forEach(function (element) {
                        if (rowEntity.time_zone == element.value) {
                            $scope.selectedTimeZone = {
                                value: element.value,
                                label: element.label
                            };
                        }
                    });
                    VehicleTypes.getVehicleTypes({'limit': 100, 'tenant_id': rowEntity.tenant_id}).then(function(response) {
                        $scope.vehicleTypes = response.data;
                        Tenants.getSettings(rowEntity.tenant_id).then(function (response) {
                            $scope.settingDetails = response;
                            //Arrange vehicle settings so that vehicle_type_id is the index of the vehicle settings
                            var vehicle_settings = [];
                            for (var i = 0; i < $scope.vehicleTypes.length; i++) {
                                for (var j = 0; j < response.vehicle_settings.length; j++) {
                                    if (response.vehicle_settings[j].vehicle_type_id == $scope.vehicleTypes[i].vehicle_type_id) {
                                        vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id] = response.vehicle_settings[j];
                                    }
                                }
                            }
                            for (var key in inheritSettings) {
                                if (inheritSettings.hasOwnProperty(key)) {
                                    if ($scope.settingDetails[inheritSettings[key]] == null) {
                                        $scope[key] = true;
                                    } else {
                                        $scope[key] = false;
                                        $scope.settingDetails[inheritSettings[key]] = ($scope.settingDetails[inheritSettings[key]] == 1);
                                    }
                                }
                            }

                            $scope.settingDetails.vehicle_type_id = $scope.vehicleTypes[0].vehicle_type_id;
                            $scope.settingDetails.vehicle_settings = vehicle_settings;
                        });
                    });
                } else {
                    $scope.settingDetails = {};
                    $scope.addressDetails = {};
                    $scope.selectedTimeZone = {
                        value: 'Etc/UTC',
                        label: '(UTC+00:00) Etc - UTC'
                    };
                    $('.tabs-tenants').foundation('selectTab', $('#detailsPanel'));
                }

                $("#tenantDetailModal").foundation('open');
            };

            $scope.detailsUpdated = function (updatedRow) {
                var address = $scope.addressDetails;
                if (!address.street && !address.house_number && !address.postal_code && !address.country && !address.state && !address.city) {
                    updatedRow.address = {};
                } else {
                    if (address.street && address.house_number && address.postal_code &&
                        address.country && address.state && address.city) {
                        //Is supposed to do nothing
                    } else {
                        $.notify($translate.instant('notify.address_all_fields'));
                        return;
                    }
                }

                if ($scope.mayUpdateSettings && !$scope.tenantCreationModal) {
                    for (var i = 0; i < $scope.vehicleTypes.length; i++) {
                        updateSettings(updatedRow.tenant_id, $scope.settingDetails, $scope.settingDetails.vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id]);
                    }
                }


                updatedRow.address = {};
                updatedRow.address.street = $scope.addressDetails.street;
                updatedRow.address.house_number = $scope.addressDetails.house_number;
                updatedRow.address.postal_code = $scope.addressDetails.postal_code;
                updatedRow.address.country = $scope.addressDetails.country;
                updatedRow.address.state = $scope.addressDetails.state;
                updatedRow.address.city = $scope.addressDetails.city;

                $('.reveal').foundation('close');
                updatedRow.time_zone = $scope.selectedTimeZone.value;

                if ($scope.tenantCreationModal) {
                    $scope.tenantsGrid.api.callGridEditing.addRow(updatedRow);
                } else {
                    updatedRow = Address.setAddress(updatedRow);
                    $scope.tenantsGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }
            };

            function updateSettings(tenant_id, settings, vehicleSettings) {
                var sets = {};
                angular.extend(sets, settings);
                sets.max_booked = vehicleSettings.max_booked;
                sets.max_rented = vehicleSettings.max_rented;
                sets.start_cost = vehicleSettings.start_cost;
                sets.minimum_balance = vehicleSettings.minimum_balance;
                sets.vehicle_type_id = vehicleSettings.vehicle_type_id;

                for (var key in inheritSettings) {
                    if (inheritSettings.hasOwnProperty(key)) {
                        if ($scope[key]) {
                            sets[inheritSettings[key]] = null;
                        } else {
                            if (sets[inheritSettings[key]] === null) {
                                sets[inheritSettings[key]] = false;
                            }
                        }
                    }
                }

                Tenants.updateSettings(tenant_id, sets).then(function () {
                    $.notify($translate.instant('notify.settings_update_success'), 'success');
                }).catch(function () {
                });
            }

            tooltips();

            function tooltips() {
                $('.tooltip-label').hover(function () {
                    // Hover over code
                    var title = $(this).attr('title');
                    $(this).data('tipText', title).removeAttr('title');
                    $('<p class="tooltip"></p>')
                        .text(title)
                        .appendTo('body')
                        .fadeIn('slow');
                }, function () {
                    // Hover out code
                    $(this).attr('title', $(this).data('tipText'));
                    $('.tooltip').remove();
                }).mousemove(function (e) {
                    var mousex = e.pageX + 20; //Get X coordinates
                    var mousey = e.pageY + 10; //Get Y coordinates
                    $('.tooltip')
                        .css({top: mousey, left: mousex, 'z-index': 99999999})
                });
            }

            $scope.setCurrencyTypeOptions = function () {
                Currencies.getCurrencies().then(function (response) {
                    $scope.currencyOptions = [];
                    $scope.currencies = response;
                    for (var i = 0; i < response.length; i++) {
                        $scope.currencyOptions.push({'value': response[i].currency_id, 'label': response[i].name});
                    }
                    for (i = 0; i < $scope.tenantsGrid.columns.length; i++) {
                        if ($scope.tenantsGrid.columns[i].name == 'currency_id') {
                            $scope.tenantsGrid.columns[i].filter.selectOptions = $scope.currencyOptions;
                        }
                    }
                });
            };

        }

    ]
);