angular.module("2cp").controller("vehicles", [
      "$scope", "$timeout", "Vehicles", "Maintenance", "services.uigrid",
      function ($scope, $timeout, Vehicles, Maintenance, uigrid) {
          function initialiseGrid(vehicle_id) {
              var parameters = [
                  'label',
                  'serial',
                  'brand_prefix',
                  'user_label',
                  'rental_start',
                  'rental_end'
              ]; 
              var columnOptions = {
                  id: "vehicle_id",
                  default: {
                      visible: true,
                  },
                  rental_start: {
                      cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"'
                  },
                  rental_end: {
                      cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"'
                  }
              }
              var options = {
                  subScope: "recentRentalsGrid",
                  parameters: parameters,
                  columnOptions: columnOptions,
                  gridOptions: {
                      enableFiltering: false,
                      enableEdit: false,
                  },
                  data: {
                      get: {
                          call: Vehicles.getRecentRentals,
                          params: {
                            vehicle_id: vehicle_id
                          }    
                      }
                  }
              }

              var grid = uigrid.create($scope, options);  
          }
          function initializeMaintenanceGrid(vehicle_id) {
              var parameters =Maintenance.getParameters();
              var columnOptions = {
                  id: "vehicle_id",
                  default: {
                      visible: false,
                  },
                  description: {
                      visible: true
                  },
                  status: {
                      visible: true
                  }
              }
              var options = {
                  subScope: "maintenanceTasksGrid",
                  parameters: parameters,
                  columnOptions: columnOptions,
                  gridOptions: {
                      enableFiltering: false,
                      enableEdit: false,
                  },
                  data: {
                      get: {
                          call: Maintenance.getTasks,
                          params: {
                              vehicle_id: vehicle_id
                          }
                      }
                  }
              }
              var grid = uigrid.create($scope, options);  
          }
          $scope.$watch("vehicleDetails", function() {
            if ($scope.vehicleDetails) {
              console.log($scope.vehicleDetails);
              initialiseGrid($scope.vehicleDetails.vehicle_id);
              initializeMaintenanceGrid($scope.vehicleDetails.vehicle_id);
            }
          });
      }
  ]);    