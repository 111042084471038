angular.module("2cp").factory("VehiclesColumns",
    [
        "$translate", "$filter", "$timeout",
        "Vehicles", "Chains", "Zones", "UserIdentifiers",
        "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, $timeout,
                  Vehicles, Chains, Zones, UserIdentifiers,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                label: {
                    visible: true,
                    enableCellEdit: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.label,
                                value: item.label
                            }
                        }
                    },
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                },
                serial: {
                    enableCellEdit: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.serial,
                                value: item.serial
                            }
                        }
                    }
                },
                brand_prefix: {
                    enableCellEdit: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.brand_prefix,
                                value: item.brand_prefix
                            }
                        }
                    }
                },
                tag: {
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.tag,
                                value: item.tag
                            }
                        }
                    }
                },
                status: {
                    visible: true,
                    cellFilter: "vehicleStatus | translate",
                    selectOptions: {
                        keys: Vehicles.VEHICLE_STATUS,
                        filter: "vehicleStatus"
                    },
                    enableCellEdit: true
                },
                condition: {
                    visible: true,
                    cellFilter: "vehicleCondition | translate",
                    cellClass: function(grid, row, col, rowRenderIndex, colRenderIndex) {
                        var value = grid.getCellValue(row, col);
                        if (value == Vehicles.CONDITION.DEFECT)
                            return "red";
                        else if (value == Vehicles.CONDITION.HEALTHY)
                            return "green";
                        else if (value == Vehicles.CONDITION.KEY_MISSING)
                            return "blue";
                        else
                            return "orange";
                    },
                    selectOptions: {
                        keys: Vehicles.CONDITION,
                        filter: "vehicleCondition"
                    },
                    enableCellEdit: true
                },
                renting_status: {
                    visible: true,
                    cellFilter: "vehicleRentingStatus | translate",
                    selectOptions: {
                        keys: Vehicles.RENTING_STATUS,
                        filter: "vehicleRentingStatus"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: true,
                    visible: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                droppointLabel: {
                    visible: true,
                    enableFiltering: true,
                    cellFilter: 'droppointLabel',
                    enableSorting: false
                },
                vehicleType:{
                    visible: true,
                    enableFiltering: true
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Vehicles.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.vehicles.')
                .copy();

            //Timeout to make sure the awesomplete data is loaded after the main data.
            $timeout(function() {
                Vehicles.getVehicles().then(function(vehicles) {
                    vehicles = vehicles.data;
                    builder.columnDefs.filter(function(c) {
                        return c.name === "label" || c.name === "tag";
                    }).forEach(function(c) {
                        c.awesomplete.suggestions = vehicles;
                    });
                });

                // Zones.getZones().then(function(zones) {
                //     zones = zones.data;
                //     builder.columnDefs.filter(function(c) {
                //         return c.name === "zone_id";
                //     })[0].awesomplete.suggestions = zones;
                // });
            }, 500);
            
            return builder;
        }
    ]
);