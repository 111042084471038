angular.module("2cp").controller("app.messages",
    [
        "$scope", "$q", "$timeout", "$filter",
        "Messages", "Permissions", 'Users', 'OperatorPushMessages', 'PushMessageColumns', '$translate',
        function ($scope, $q, $timeout, $filter, Messages, Permissions, Users, OperatorPushMessages, PushMessageColumns, $translate) {

            $scope.registeredUsers = [];
            Users.getRegisteredUsers().then(function(result){
                $scope.registeredUsers = result;
                $scope.registeredUserData = function (item, input) {
                    return {
                        label: item.username,
                        value: item.user_id
                    }
                };
            });

            $scope.registeredUserSelected = function(evt){
                $timeout(function(){
                    $scope.message.receiver_id = evt.text.value;
                    $scope.message.receiver = evt.text.label;
                });
            };


            // Get questions + answers (history) for one user
            function retrieveThreadForUser(user_id) {
                $scope.thread = [];
                Messages.get({user_id: user_id}).then(function(data) {
                    // data = removeFromCollection({} ,data)
                    $scope.thread = data.data;
                });
            }

            $scope.showMessageModal = function(question) {
                $scope.answer = null;
                $scope.selectedQuestion = question;
                retrieveThreadForUser(question.user_id);
                $("#AnswerModal").foundation('open');
            };
            $scope.showArchiveModal = function(question) {
                $scope.selectedQuestion = question;
                $scope.username = "";
                Users.getUser(question.user_id).then(function(user) {
                    $scope.username = [user.first_name, user.last_name].join(" ");
                });
                retrieveThreadForUser(question.user_id);
                $("#ArchiveModal").foundation('open');
            };

            $scope.sendReply = function() {
                // Create the new message
                $scope.saving = true;
                Messages.create({
                    type: 'answer',
                    is_answered: false,
                    user_id: $scope.selectedQuestion.user_id,
                    tenant_id: $scope.selectedQuestion.tenant_id,
                    body: $scope.answer
                }).then(function(result) {
                    $.notify($translate.instant('notify.message_create_success'), "success");
                    $('.reveal').foundation('close');
                    return result;
                }).catch(function(e) {
                    console.error(e);
                }).finally(function() {
                    $scope.saving = false;
                });

                // Set the question to answered
                $scope.archive();
            };

            // Send message to the archive.
            $scope.archiveThis = function() {
                $scope.saving = true;
                Messages.update(
                    $scope.selectedQuestion.app_message_id, { is_answered: true }
                ).then(function() {
                    $('.reveal').foundation('close');
                    update();
                }).finally(function() {
                    $scope.saving = false;
                });
            }

            $scope.showPushMessageModal = function() {
                $scope.message = {};
                $("#PushMessageModal").foundation('open');
            };

            $scope.showPushMessagesDeleteModal = function() {
                $("#PushMessagesDeleteModal").foundation('open');
            }
            $scope.submitPushMessage = function() {
                $scope.saving = true;
                OperatorPushMessages.create($scope.message).then(function(result) {
                    $.notify($translate.instant('notify.push_message_create_success'), "success");
                    $('.reveal').foundation('close');
                    $scope.pushMessageGrid.api.callGridData.get();
                }).catch(function(e) {
                }).finally(function() {
                    $scope.saving = false;
                });
            };

            $scope.pushMessageGridOptions = {
                columnDefs: PushMessageColumns.build(),

                callGridData: {
                    id: "operator_push_message_id",
                    get: OperatorPushMessages.getAll,
                    delete: function (ids) {
                        return OperatorPushMessages.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.push_message_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                                $.notify($translate.instant('notify.push_message_delete_failed'), "error");
                            });
                    }
                },

                onRegisterApi: function(gridApi) {
                    $scope.pushMessageGrid = gridApi.grid;
                }
            };

            function update() {

                // Get all unanswered questions for this tenant
                Messages.get({type: 'question', is_answered: 0}).then(function (data) {
                    $scope.unanswered = data.data;
                });

                // Get all answered questions (archive) for this tenant
                Messages.get({type: 'question', is_answered: 1}).then(function (data) {
                    $scope.archive = data.data;
                });
            }

            update();

            $scope.removeSelected = function () {
                $scope.pushMessageGrid.api.callGridEditing.removeSelectedRows();
            };
        }
    ]
);