angular.module("2cp").controller("tenants.subscriptions",
    [
        "$scope", 'Subscriptions', 'SubscriptionColumns', 'PermissionService',
        'SubscriptionDiscountColumns', 'SubscriptionFreeColumns', 'SubscriptionBalanceColumns',
        '$timeout', "$q", 'Users', 'UserColumns', "Vehicles", 'Tenants', 'Times', '$translate',
        'VehicleTypes',
        function ($scope, Subscriptions, SubscriptionColumns, PermissionService,
                  SubscriptionDiscountColumns, SubscriptionFreeColumns, SubscriptionBalanceColumns,
                  $timeout, $q, Users, UserColumns, Vehicles, Tenants, Times, $translate,
                  VehicleTypes) {
            $scope.paymentFrequencies = Subscriptions.SUBSCRIPTION_FREQUENCIES;
            $scope.days = Subscriptions.DAYS;
            $scope.balanceFrequecies = Subscriptions.BALANCE_FREQUENCIES;
            $scope.periodDetails = {};
            $scope.subscriptionDetails = {};
            $scope.vehicleTypes = [];
            $scope.weekdays = Subscriptions.getWeekdays();
            $scope.createSubscriptions = PermissionService.hasPermissions(['createSubscriptions']);
            $scope.updateSubscriptions = PermissionService.hasPermissions(['updateSubscriptions']);
            $scope.deleteSubscriptions = PermissionService.hasPermissions(['deleteSubscriptions']);

            $scope.timezone = Times.getZone();

            $scope.subscriptionGridOptions = {
                columnDefs: SubscriptionColumns.build(),

                callGridData: {
                    id: "subscription_id",
                    get: function (data) {
                        data.with = 'periods';
                        if (data.valid_from_max) {
                            data.valid_from_max = Times.getUTCDateTimeString(data.valid_from_max, $scope.timezone);
                        }
                        if (data.valid_from_min) {
                            data.valid_from_min = Times.getUTCDateTimeString(data.valid_from_min, $scope.timezone);
                        }
                        if (data.valid_until_max) {
                            data.valid_until_max = Times.getUTCDateTimeString(data.valid_until_max, $scope.timezone);
                        }
                        if (data.valid_until_min) {
                            data.valid_until_min = Times.getUTCDateTimeString(data.valid_until_min, $scope.timezone);
                        }
                        return Subscriptions.getSubscriptions(data).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateSubscriptions'])) {
                                $scope.subscriptionGrid.api.callGridEditing.disableCellEdits();
                            }
                            for (var i = 0; i < response.data.length; i++) {
                                response.data[i].renew = (response.data[i].renew == 1);
                                response.data[i].pay_upfront = (response.data[i].pay_upfront == 1);
                                response.data[i].hidden = (response.data[i].hidden == 1);
                                response.data[i].valid_from = Times.getTenantDateTimeString(response.data[i].valid_from, $scope.timezone);
                                response.data[i].valid_until = Times.getTenantDateTimeString(response.data[i].valid_until, $scope.timezone);
                            }
                            return response;
                        })
                    },
                    create: function (data) {
                        data.valid_from = Times.getUTCDateTimeString(data.valid_from, $scope.timezone);
                        data.valid_until = Times.getUTCDateTimeString(data.valid_until, $scope.timezone);
                        data.description = CKEDITOR.instances.editor.getData();
                        $('.reveal').foundation('close');
                        return Subscriptions.createSubscription(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.subscription_create_success'), "success");
                                result.valid_from = Times.getTenantDateTimeString(result.valid_from, $scope.timezone);
                                result.valid_until = Times.getTenantDateTimeString(result.valid_until, $scope.timezone);
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        data.valid_from = Times.getUTCDateTimeString(data.valid_from, $scope.timezone);
                        data.valid_until = Times.getUTCDateTimeString(data.valid_until, $scope.timezone);
                        data.description = CKEDITOR.instances.editor.getData();
                        return Subscriptions.updateSubscription(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.subscription_update_success'), "success");
                                data.valid_from = Times.getTenantDateTimeString(data.valid_from, $scope.timezone);
                                data.valid_until = Times.getTenantDateTimeString(data.valid_until, $scope.timezone);
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Subscriptions.deleteSubscriptions(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.subscription_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showSubscriptionDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "subscriptions."
                },
                onRegisterApi: function (gridApi) {
                    $scope.subscriptionGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function (grid) {
                if (grid) {
                    var r = true;
                    var rows = grid.api.selection.getSelectedRows();
                    if (rows.length == 0)
                        r = false;

                    return r;
                } else {
                    return false;
                }
            };

            $scope.removeSelected = function (grid) {
                grid.api.callGridEditing.removeSelectedRows();
            };

            $scope.showSubscriptionDetailsModal = function (creationModal, rowEntity) {
                $scope.subscriptionDetails = rowEntity;
                $scope.subscriptionCreationModal = creationModal;
                if (!creationModal) {
                    $scope.usersGrid.api.callGridData.get();
                    CKEDITOR.instances.editor.setData($scope.subscriptionDetails.description);
                } else {
                    CKEDITOR.instances.editor.setData('');
                }
                
                $("#subscriptionDetailsModal").foundation('open');
            };

            $scope.subscriptionDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');

                //Needed for when a user does not tick the checkbox, otherwise it would be null when creating a subscription
                if (!updatedRow.renew) {
                    updatedRow.renew = false;
                }
                if (!updatedRow.pay_upfront) {
                    updatedRow.pay_upfront = false;
                }
                if (!updatedRow.hidden) {
                    updatedRow.hidden = false;
                }

                if ($scope.subscriptionCreationModal)
                    $scope.subscriptionGrid.api.callGridEditing.addRow(updatedRow);
                else
                    $scope.subscriptionGrid.api.rowEdit.setRowsDirty([updatedRow]);
            };

            $scope.showPeriodDetailsModal = function (creationModal, period) {
                $scope.periodDetails = period;
                $scope.periodCreationModal = creationModal;

                if (!creationModal) {
                    $scope.discountEntriesGrid.api.callGridData.get();
                    $scope.freeEntriesGrid.api.callGridData.get();
                    $scope.balanceEntriesGrid.api.callGridData.get();
                }

                $("#periodDetailsModal").foundation('open');
            };

            $scope.periodDetailsUpdated = function (period) {
                if ($scope.periodCreationModal) {
                    Subscriptions.createSubscriptionPeriod($scope.subscriptionDetails.subscription_id, period).then(function (result) {
                        $scope.subscriptionDetails.periods.push(result);
                        $.notify($translate.instant('notify.subscription_period_create_success'), "success");
                    }).catch(function () {
                    });
                } else {
                    Subscriptions.updateSubscriptionPeriod(period.subscription_period_id, period).then(function (result) {
                        for (var i = 0; i < $scope.subscriptionDetails.periods.length; i++) {
                            if ($scope.subscriptionDetails.periods[i].subscription_period_id == result.subscription_period_id) {
                                $scope.subscriptionDetails.periods[i] = result;
                                break;
                            }
                        }
                        $.notify($translate.instant('notify.subscription_period_update_success'), "success");
                    }).catch(function () {
                    });
                }
            };

            $scope.showDeletePeriodModal = function (period) {
                $scope.deletePeriod = period;
                $("#deletePeriodModal").foundation('open');
            };

            $scope.removePeriod = function () {
                Subscriptions.deleteSubscriptionPeriods([$scope.deletePeriod.subscription_period_id]).then(function () {
                    for (var i = 0; i < $scope.subscriptionDetails.periods.length; i++) {
                        if ($scope.subscriptionDetails.periods[i].subscription_period_id == $scope.deletePeriod.subscription_period_id) {
                            $scope.subscriptionDetails.periods.splice(i, 1);
                            break;
                        }
                    }
                    $scope.deletePeriod = null;
                    $.notify($translate.instant('notify.subscription_period_delete_success'), "success");
                }).catch(function () {
                });
            };

            $scope.discountEntriesGridOptions = {
                columnDefs: SubscriptionDiscountColumns.
                addOptionsByName({'vehicleType': {'selectOptions': $scope.vehicleTypes}}).
                build(),

                callGridData: {
                    id: "discount_subscription_entry_id",
                    get: function (data) {
                        if (!$scope.periodDetails.subscription_period_id)
                            return $q.resolve([]);

                        data.subscription_period_id = $scope.periodDetails.subscription_period_id;
                        return Subscriptions.getSubscriptionDiscounts(data).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateSubscriptions'])) {
                                $scope.discountEntriesGrid.api.callGridEditing.disableCellEdits();
                            }
                            for (var i = 0; i < response.data.length; i++) {
                                response.data[i].start_time = Times.getTenantTimeString(response.data[i].start_time, $scope.timezone);
                                response.data[i].end_time = Times.getTenantTimeString(response.data[i].end_time, $scope.timezone);
                            }
                            return response;
                        });
                    },
                    create: function (data) {
                        data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                        data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                        return Subscriptions.createSubscriptionDiscount(data.subscription_period_id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.discount_entry_create_success'), "success");
                                result.start_time = Times.getTenantTimeString(result.start_time, $scope.timezone);
                                result.end_time = Times.getTenantTimeString(result.end_time, $scope.timezone);
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                        data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                        return Subscriptions.updateSubscriptionDiscount(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.discount_entry_update_success'), "success");
                                data.start_time = Times.getTenantTimeString(data.start_time, $scope.timezone);
                                data.end_time = Times.getTenantTimeString(data.end_time, $scope.timezone);
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Subscriptions.deleteSubscriptionDiscounts(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.discount_entry_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showDiscountDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "subscriptions."
                },
                onRegisterApi: function (gridApi) {
                    $scope.discountEntriesGrid = gridApi.grid;
                }
            };

            $scope.showDiscountDetailsModal = function (creationModal, rowEntity) {
                $scope.discountDetails = rowEntity || {};
                $scope.discountCreationModal = creationModal;

                $("#discountDetailsModal").foundation('open');
            };

            $scope.discountDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');
                $("#periodDetailsModal").foundation('open');
                if ($scope.discountCreationModal) {
                    updatedRow.subscription_period_id = $scope.periodDetails.subscription_period_id;
                    $scope.discountEntriesGrid.api.callGridEditing.addRow(updatedRow);
                } else {
                    $scope.discountEntriesGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }
            };

            $scope.freeEntriesGridOptions = {
                columnDefs: SubscriptionFreeColumns.
                addOptionsByName({'vehicle_type_id': {'selectOptions': $scope.vehicleTypes}}).
                build(),

                callGridData: {
                    id: "free_subscription_entry_id",
                    get: function (data) {
                        if (!$scope.periodDetails.subscription_period_id)
                            return $q.resolve([]);

                        data.subscription_period_id = $scope.periodDetails.subscription_period_id;
                        return Subscriptions.getSubscriptionFrees(data).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateSubscriptions'])) {
                                $scope.freeEntriesGrid.api.callGridEditing.disableCellEdits();
                            }
                            for (var i = 0; i < response.length; i++) {
                                response[i].start_time = Times.getTenantTimeString(response[i].start_time, $scope.timezone);
                                response[i].end_time = Times.getTenantTimeString(response[i].end_time, $scope.timezone);
                            }
                            return response;
                        });
                    },
                    create: function (data) {
                        data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                        data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                        return Subscriptions.createSubscriptionFree(data.subscription_period_id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.free_entry_create_success'), "success");
                                result.start_time = Times.getTenantTimeString(result.start_time, $scope.timezone);
                                result.end_time = Times.getTenantTimeString(result.end_time, $scope.timezone);
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                        data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                        return Subscriptions.updateSubscriptionFrees(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.free_entry_update_success'), "success");
                                data.start_time = Times.getTenantTimeString(data.start_time, $scope.timezone);
                                data.end_time = Times.getTenantTimeString(data.end_time, $scope.timezone);
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Subscriptions.deleteSubscriptionFrees(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.free_entry_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showFreeDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "subscriptions."
                },
                onRegisterApi: function (gridApi) {
                    $scope.freeEntriesGrid = gridApi.grid;
                }
            };

            $scope.showFreeDetailsModal = function (creationModal, rowEntity) {
                $scope.freeDetails = rowEntity || {};
                $scope.freeCreationModal = creationModal;

                $("#freeDetailsModal").foundation('open');
            };

            $scope.freeDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');
                $("#periodDetailsModal").foundation('open');
                if ($scope.freeCreationModal) {
                    updatedRow.subscription_period_id = $scope.periodDetails.subscription_period_id;
                    $scope.freeEntriesGrid.api.callGridEditing.addRow(updatedRow);
                } else {
                    $scope.freeEntriesGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }
            };

            $scope.balanceEntriesGridOptions = {
                columnDefs: SubscriptionBalanceColumns.build(),

                callGridData: {
                    id: "balance_subscription_entry_id",
                    get: function (data) {
                        if (!$scope.periodDetails.subscription_period_id)
                            return $q.resolve([]);

                        data.subscription_period_id = $scope.periodDetails.subscription_period_id;
                        return Subscriptions.getSubscriptionBalances(data).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateSubscriptions'])) {
                                $scope.balanceEntriesGrid.api.callGridEditing.disableCellEdits();
                            }
                            return response;
                        });
                    },
                    create: function (data) {
                        return Subscriptions.createSubscriptionBalance(data.subscription_period_id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.balance_entry_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        return Subscriptions.updateSubscriptionBalance(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.balance_entry_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Subscriptions.deleteSubscriptionBalance(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.balance_entry_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showBalanceDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "subscriptions."
                },
                onRegisterApi: function (gridApi) {
                    $scope.balanceEntriesGrid = gridApi.grid;
                }
            };

            $scope.showBalanceDetailsModal = function (creationModal, rowEntity) {
                $scope.balanceDetails = rowEntity || {};
                $scope.balanceCreationModal = creationModal;

                $("#balanceDetailsModal").foundation('open');
            };

            $scope.balanceDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');
                $("#periodDetailsModal").foundation('open');
                if ($scope.balanceCreationModal) {
                    updatedRow.subscription_period_id = $scope.periodDetails.subscription_period_id;
                    $scope.balanceEntriesGrid.api.callGridEditing.addRow(updatedRow);
                } else {
                    $scope.balanceEntriesGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }
            };

            $scope.usersGridOptions = {
                columnDefs: UserColumns.build(),
                callGridData: {
                    id: "user_id",
                    get: function (params) {
                        params.subscription_id = $scope.subscriptionDetails.subscription_id;
                        return Users.getUsers(params).then(function (result) {
                            return result;
                        });
                    }
                },
                onRegisterApi: function (gridApi) {
                    $scope.usersGrid = gridApi.grid;
                }
            };

            //Set vehicle types for filtering and for in the details modal
            VehicleTypes.getVehicleTypes({limit: 999}).then(function(response){
                for(var i = 0; i < response.data.length; i++){
                    $scope.vehicleTypes.push({
                        'value': response.data[i].name,
                        'label': response.data[i].name,
                        'id': response.data[i].vehicle_type_id
                    });
                }
                for(i = 0; i < $scope.discountEntriesGrid.columns.length; i++){
                    if($scope.discountEntriesGrid.columns[i].name === 'vehicleType'){
                        $scope.discountEntriesGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
                for(i = 0; i < $scope.freeEntriesGrid.columns.length; i++){
                    if($scope.freeEntriesGrid.columns[i].name === 'vehicleType'){
                        $scope.freeEntriesGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
            });
        }
    ]
);