angular.module("2cp").factory("MaintenanceColumns",
    [
        "$translate", "$filter", "$timeout",
        "Maintenance", "Droppoints", "Vehicles",
        "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, $timeout,
                  Maintenance, Droppoints, Vehicles,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                subject: {
                    enableCellEdit: true,
                    enableFiltering: true,
                    visible: true
                },
                description: {
                    enableFiltering: false,
                    enableCellEdit: true
                },
                reporterUsername: {
                    enableFiltering: true,
                    visible: true
                },
                reporterEmail: {
                    enableFiltering: true,
                },
                priority: {
                    cellFilter: "maintenancePriority | translate",
                    enableFiltering: true,
                    enableCellEdit: true,
                    selectOptions: {
                        keys: Maintenance.PRIORITY,
                        filter: "maintenancePriority"
                    },
                    visible: true
                },
                datetime_planned: {
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableFiltering: true,
                    visible: true
                },
                date_accepted: {
                    cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableFiltering: true,
                },
                datetime_closed: {
                    cellFilter: 'closedDate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableFiltering: true,
                    // sort: {
                    //     direction: uiGridConstants.DESC,
                    //     priority: 1
                    // }
                },
                maintenance_task_type_id: {
                    cellFilter: "maintenanceType | translate",
                    enableFiltering: true,
                    enableCellEdit: true,
                    selectOptions: {
                        keys: Maintenance.TYPES,
                        filter: "maintenanceType"
                    },
                    visible: true
                },
                status: {
                    cellFilter: "maintenanceStatus | translate",
                    enableFiltering: true,
                    enableCellEdit: false,
                    selectOptions: {
                        keys: Maintenance.STATUS,
                        filter: "maintenanceStatus"
                    },
                    visible: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                },
                created_at: {
                    enableFiltering: false
                },
                vehicleLabel: {
                    enableFiltering: true,
                    // cellFilter: "vehicleIdToLabel",
                    // awesomplete: {
                    //     suggestions: [],
                    //     dataFunction: function(item, input) {
                    //         return {
                    //             label: item.label,
                    //             value: item.vehicle_id
                    //         }
                    //     }
                    // }
                },
                droppointLabel: {
                    enableFiltering: true,
                    // cellFilter: "droppointIdToLabel",
                    // awesomplete: {
                    //     suggestions: [],
                    //     dataFunction: function(item, input) {
                    //         return {
                    //             label: item.label,
                    //             value: item.droppoint_id
                    //         }
                    //     }
                    // }
                },
                lockDeviceLabel: {
                    enableFiltering: true
                },
                resolution: {
                    enableCellEdit: false,
                    enableFiltering: false
                },
                recurrence: {
                    enableFiltering: true,
                    enableCellEdit: true
                },
                recur_unit: {
                    selectOptions: {
                        keys: Maintenance.RECUR_UNITS,
                        filter: "maintenanceRecurUnits"
                    },
                    enableFiltering: true,
                    enableCellEdit: true
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Maintenance.PARAMETERS)
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.maintenance.')
                .copy();

            // $timeout(function() {
            //     Droppoints.getDroppoints().then(function(droppoints) {
            //         builder.columnDefs.filter(function(d) {
            //             return d.name === "droppoint_id";
            //         })[0].awesomplete.suggestions = droppoints;
            //     });
            //
            //     Vehicles.getVehicles().then(function(vehicles) {
            //         builder.columnDefs.filter(function(c) {
            //             return c.name === "vehicle_id";
            //         })[0].awesomplete.suggestions = vehicles;
            //     });
            // }, 500);

            return builder;
        }
    ]
);