angular.module("2cp").factory("CSV",
    [
        function () {
            return {
                parse: function(text) {
                    var lines = text.replace(new RegExp('\r\n', 'g'), '\n').split('\n');
                    var columns = [];
                    var items = [];
                    for (var i = 0; i < lines.length; i++) {
                        var line = lines[i];
                        if(!line)
                            continue;

                        if(i === 0) {
                            //we have the columns
                            columns = line.split(';');
                            continue;
                        }

                        var values = line.split(';');

                        var item = {};
                        for(var j = 0; j < values.length; j++) {
                            item[columns[j]] = values[j];
                        }

                        items.push(item);
                    }

                    return items;
                }
            };
        }
    ]
);