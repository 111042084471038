angular.module("2cp").directive("callGridMenu", ["translateFilter",
    function (translateFilter) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function($scope, $elm, $attrs, uiGridCtrl) {
                var grid = uiGridCtrl.grid;

                // -------------------------
                // Some options you can set.
                // -------------------------
                //grid.options.exporterCsvFilename = 'output.csv';
                //grid.options.enableSelectAll = true;
                //grid.options.exporterPdfDefaultStyle = {fontSize: 9};
                //grid.options.exporterPdfTableStyle = {margin: [30, 30, 30, 30]};
                //grid.options.exporterPdfTableHeaderStyle = {fontSize: 10, bold: true, italics: true, color: 'red'};
                //grid.options.exporterPdfHeader ={ text: "My Header", style: 'headerStyle' };
                //grid.options.exporterPdfFooter = function ( currentPage, pageCount ) {
                //    return { text: currentPage.toString() + ' of ' + pageCount.toString(), style: 'footerStyle' };
                //};
                //grid.options.exporterPdfCustomFormatter = function ( docDefinition ) {
                //    docDefinition.styles.headerStyle = { fontSize: 22, bold: true };
                //    docDefinition.styles.footerStyle = { fontSize: 10, bold: true };
                //    return docDefinition;
                //};
                //grid.options.exporterPdfOrientation = 'portrait';
                //grid.options.exporterPdfPageSize = 'LETTER';
                //grid.options.exporterPdfMaxGridWidth = 500;
                //grid.options.exporterCsvLinkElement = angular.element(document.querySelectorAll(".custom-csv-link-location")),
                //grid.options.onRegisterApi = function(gridApi){
                //    $scope.gridApi = gridApi;
                //};

                grid.options.enableGridMenu = true;
                grid.options.gridMenuTitleFilter = function(title) {
                    return translateFilter(title);
                };
            }
        };
    }]);