angular.module("2cp").controller("TenantDropdownController",
    [
        "$scope", "Partners", "Times",
        function ($scope, Partners, Times) {
            $scope.currentTenant = null;
            $scope.groups = null;

            $scope.generateRoute = function(tenantId) {
                return laroute.route(calllock.routeName, { tenantId: tenantId });
            };

            //Make one array for the partners and tenants and call them groups.
            //Add a tenant_id to partners as well to allow them to have a link as well.
            Partners.getPartners().then(function(partners) {
                $scope.groups = [];
                partners.data.forEach(function(partner) {
                    partner.partner = true;
                    if(partner.tenants.length > 0) {
                        $scope.groups.push(partner);
                        partner.tenant_id = partner.tenants[0].tenant_id;
                        partner.tenants.forEach(function (tenant) {
                            $scope.groups.push(tenant);
                            if (tenant.tenant_id == calllock.tenantId) {
                                $scope.currentTenant = tenant;
                                Times.setZone(tenant.time_zone);
                            }

                        });
                    }
                });
            });

            $scope.goToTenant = function(tenantID) {
                window.location.href = "/tenants/" + tenantID + "/dashboard";
            }
        }
    ]
);