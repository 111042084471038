angular.module("2cp").factory("FaqColumns",
    [
        "$translate", "$filter", "$timeout",
        "Faq", "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, $timeout, Faq,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                faq_id: {
                    visible: false
                },
                question: {
                    enableCellEdit: true,
                    // cellTemplate: "<textarea ng-class=\"'colt' + col.uid\" ui-grid-editor ng-model=\"MODEL_COL_FIELD\"></textarea>"
                },
                answer: {
                    enableCellEdit: true
                },
                tenant_id: {
                    visible: false
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Faq.parameters)
                .addDefaultOptions({
                    visible: true,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('app.faq.')
                .copy();

            console.log(builder)
            return builder;
        }
    ]
);