angular.module("2cp").factory("VoucherColumns",
    [
        "$translate", "$filter", "uiGridConstants",
        "Vouchers", "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants,
                  Vouchers, ColumnDefBuilder) {
            var columnOptions = {
                voucher_id: {
                    enableFiltering: true,
                },
                voucher_code: {
                    enableFiltering: true,
                    visible: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                },
                valid_from: {
                    visible: true,
                    enableFiltering: true,
                    cellFilter: 'voucherDate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                valid_until: {
                    visible: true,
                    enableFiltering: true,
                    cellFilter: 'voucherDate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                discount_percentage: {
                    enableFiltering: true,
                    cellFilter: 'voucherPercentage'
                },
                discount_time: {
                    enableFiltering: true
                },
                claimed:{
                    enableCellEdit: false,
                    enableFiltering: true
                },
                claims_left:{
                    enableFiltering: true,
                    visible: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    visible: true
                },
                vehicleType: {
                    enableFiltering: true,
                    visible: true,
                    enableCellEdit: false
                },
                
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Vouchers.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.vouchers.')
                .copy();

            return builder;
        }
    ]
);