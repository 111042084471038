'use strict';

angular.module('api').factory('Poi', [
    '$http',
    'clApi',
    '$q',
    function ($http, api, $q) {
        return {
            parameters: [
                'poi_id',
                'name',
                'type',
                'longitude',
                'latitude',
                'rating',
                'raters',
                'title',
                'website',
                'phone',
                'text',
                'photos',
                'reservation_link',
                'tour_marker'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getPois: function (params) {
                var options = api.createIfNotExist(options);
                return api.get('poi', params, options).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('poi/' + id, params).then(function (result) {
                    console.log(result.data);
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('poi', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (ids) {
                console.log(ids);
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('poi/' + id));
                });

                return $q.all(promises);
            },
        };
    }
]);
