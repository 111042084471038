angular.module("2cp")
    .filter('zoneIdToLabel', ["Zones", function (Zones) {
        var waiting = false;
        var valuesArray = [];

        function labelFilter(value) {
            if (value === null || typeof(value) === "undefined") {
                return "";
            }

            var lbl = value;
            if (value in valuesArray) {
                lbl = valuesArray[value].label;
            }
            else {
                if (waiting === false) {
                    waiting = true;
                    Zones.getZone(value).then(function (result) {
                        if (!(value in valuesArray)) {
                            valuesArray[value] = result;
                        }
                        waiting = false;
                    });
                }
            }
            return lbl;
        }

        labelFilter.$stateful = true;
        return labelFilter;
    }])
    .filter('locationBoolean', function () {
        return function (input) {
            if (input == 1) {
                return 'True';
            }
            if (input == 0) {
                return 'False';
            }
            return '';
        }
    })
    .filter('locationBooleanInput', function () {
        return function (input) {
            if (input == 1) {
                return 'True';
            }
            if (input == 0) {
                return 'False';
            }
            return '';
        }
    });