angular.module("2cp")
// Filter for lock mode
    .filter('rentalEvents', ["Events", function (Events) {
        return function (input) {
            for (var key in Events.EVENT_TYPE) {
                if (input === Events.EVENT_TYPE[key])
                    return "tenants.events.type." + key.toLowerCase();
            }

            return input;
        }
    }])
    .filter('droppointLabel', function () {
        return function (input) {
            if (!input) {
                return '';
            }
            if (input.droppoint_device_id) {
                return input.droppoint_device_id;
            }
            return input;
        }
    })
    .filter('rentalDuration', function () {
        return function (input) {
            if (input >= 120) {
                return Math.floor(input / 60) + ' hours ' + input % 60 + ' minutes';
            } else if (input >= 60) {
                return Math.floor(input / 60) + ' hour ' + input % 60 + ' minutes';
            } else {
                return input + ' minutes';
            }
        }
    })
    .filter('rentalStatuses', function () {
        return function (input) {
            switch (input) {
                case 'start_pending':
                    return 'Start Pending';
                case 'started':
                    return 'Started';
                case 'end_pending':
                    return 'End Pending';
                case 'ended':
                    return 'Ended';
                case 'ended_error':
                    return 'Ended Error';
                case 'operator_ended':
                    return 'Operator Rent';
                case 'operator_started':
                    return 'Operator Started';
            }
        }
    });