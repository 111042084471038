'use strict';

angular.module('api').factory('LockDevices', [
    '$http',
    '$q',
    'clApi',
    function ($http, $q, api) {

        return {
            LOCK_DEVICE_TYPES: {
                BLUETOOTHLOCKDEVICE: 'App\\BluetoothLockDevice'
            },

            STATUSES: [
                'unlocked',
                'locked'
            ],

            parameters: [
                'label',
                'status',
                'battery_level',
                'updated_at',
                'created_at',
                'device_type',
                'lock_device_id',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getLocks: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('locks', params, options).then(function (result) {
                    for(var i = 0; i < result.data.data.length; i++){
                        result.data.data[i] = setData(result.data.data[i]);
                    }
                    return result.data;
                });
            },
            getFreeLocks: function(params, options) {
                var options = api.createIfNotExist(options);

                return api.get('locks/free', params, options).then(function (result) {
                    return result.data;
                });
            },

            create: function(data) {
                data.axa_tenant_id = 1;
                return api.post('locks', data).then(function(result) {
                    result.data = setData(result.data);
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('locks/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('locks/' + id));
                });

                return $q.all(promises);
            },

			createEKey: function(id, params) {
				return api.post('locks/' + id + "/slots/0", {}, params).then(function (result) {
					return {
						ekey: result.data.result.ekey,
						passkey: result.data.result.passkey
					};
				});
			}
        };
        function setData(lock){
            lock.status = lock.device.status;
            lock.battery_level = lock.device.bluetooth_device.battery_level;
            return lock;
        }
    },
]);
