'use strict';

angular.module('api').factory('Roles', [
    'clApi',
    function (api) {
        return {
            parameters: [
                'role_id',
                'name',
                'display_name',
                'description',
                'created_at',
                'updated_at',
                'entity_id',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getRoles: function (params) {
                return api.get('roles', params).then(function (result) {
                    return result.data;
                });
            },

            getRole: function (id) {
                return api.get('roles/' + id).then(function (result) {
                    return result.data;
                });
            },

            getCurrentRoles: function(params){
                return api.get('roles/currentUser', params).then(function(result){
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('roles/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('roles', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (id, params) {
                return api.delete('roles/' + id, params).then(function (result) {
                    return result.data;
                });
            },
        };
    },
]);
