angular.module("2cp").factory("BookingColumns",
    [
        "$translate", "$filter", "uiGridConstants", "$timeout",
        "Bookings", "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants, $timeout,
                  Bookings, ColumnDefBuilder) {
            var columnOptions = {
                booking_id: {
                    enableFiltering: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                start_datetime: {
                    visible: true,
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                end_datetime: {
                    visible: true,
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                amount: {
                    enableFiltering: true
                },
                status:{
                    enableFiltering: true,
                    visible: true
                },
                vehicleType:{
                    enableFiltering: true
                },
                droppoint_id:{
                    enableFiltering: true,
                    visible: true
                },
                username: {
                    enableFiltering: true,
                    visible: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Bookings.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.bookings.')
                .copy();

            return builder;
        }
    ]
);