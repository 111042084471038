'use strict';

angular.module('api', [
    'angular-cache',
]).config([
    '$httpProvider',
    function ($httpProvider) {
        $httpProvider.defaults.withCredentials = true;
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    },
]);
