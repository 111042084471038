angular.module("2cp").factory("LockColumns",
    [
        "$translate", "$filter", "$timeout",
        "Locks", "Chains", "Zones",
        "ColumnDefBuilder",
        function ($translate, $filter, $timeout,
                  Locks, Chains, Zones,
                  ColumnDefBuilder) {
            var columnOptions = {
                lock_number: {
                    visible: true,
                },
                mode: {
                    cellFilter: "lockMode | translate",
                    enableCellEdit: true,
                    selectOptions: {
                        keys: Locks.MODE,
                        filter: "lockMode"
                    }
                },
                position: {
                    cellFilter: "lockPosition | translate",
                    // enableCellEdit: true, Not supported by old API
                    selectOptions: {
                        keys: Locks.POSITION,
                        filter: "lockPosition"
                    }
                },
                rent_with_key: {
                    cellFilter: "lockRentWithKey | translate",
                    enableCellEdit: true,
                    selectOptions: {
                        keys: Locks.RENT_WITH_KEY,
                        filter: "lockRentWithKey"
                    }
                },
                usage_status: {
                    visible: true,
                    cellFilter: "lockUsageStatus | translate",
                    enableCellEdit: true,
                    selectOptions: {
                        keys: Locks.USAGE_STATUS,
                        filter: "lockUsageStatus"
                    }
                },
                chain_id: {
                    visible: true,
                    cellFilter: "chainIdToLabel",
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.short_description,
                                value: item.chain_id
                            }
                        }
                    }
                },
                zone_id: {
                    visible: true,
                    cellFilter: "zoneIdToLabel",
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.short_description,
                                value: item.zone_id
                            }
                        }
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Locks.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.system.")
                .copy();

            //Timeout to make sure the awesomplete data is loaded after the main data.
            $timeout(function() {
                Chains.getChains().then(function(chains) {
                    chains = chains.data;
                    builder.columnDefs.filter(function(c) {
                        return c.name === "chain_id";
                    })[0].awesomplete.suggestions = chains;
                });
                Zones.getZones().then(function(zones) {
                    zones = zones.data;
                    builder.columnDefs.filter(function(c) {
                        return c.name === "zone_id";
                    })[0].awesomplete.suggestions = zones;
                });
            }, 500);

            return builder;
        }
    ]
);