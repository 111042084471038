angular.module("2cp").controller("tenants.settings.general",
    [
        "$scope", "$timeout", "Tenants", "Currencies", "Vehicles", "PermissionService",
        "Address", '$translate', 'VehicleTypes',
        function ($scope, $timeout, Tenants, Currencies, Vehicles, PermissionService,
                  Address, $translate, VehicleTypes) {
            $scope.settingDetails = {};
            $scope.vehicleTypes = [];
            $scope.countries = Address.getCountries();
            $scope.inheritOptions = [{value: true, label: 'Yes'}, {value: false, label: 'No'}];
            $scope.paymentOptions = Tenants.PAYMENT_OPTIONS;

            var inheritSettings = {
                floatInherit: 'allow_free_floating',
                voucherInherit: 'vouchers_only',
                forceInherit: 'force_subscription',
                hideBookingInherit: 'hide_booking',
                hideFromAppInherit: 'hide_from_app'
            };

            VehicleTypes.getVehicleTypes({'limit': 100}).then(function(response){
                $scope.vehicleTypes = response.data;
            });

            var timeZones = [];
            var timestamp = Date.now();

            moment.tz.names().forEach(function (element) {
                var res = element.split("/");
                var string = ((res[1]) ? res[0] + ' - ' + res[1] : res[0]);

                timeZones.push({
                    value: element,
                    label: '(UTC' + moment.tz(timestamp, element).format('Z') + ') ' + string
                });
            });

            $scope.timeZones = timeZones;
            $scope.selectedTimeZone = {
                value: 'Etc/UTC',
                label: '(UTC+00:00) Etc - UTC'
            };

            $scope.currencies = [];
            
            Currencies.getCurrencies().then(function(response){
                $scope.currencies = response;
            });

            Tenants.getTenant(calllock.tenantId, {with: 'address'}).then(function (tenant) {
                initTenant(tenant);
            });

            function initTenant(tenant) {
                $scope.tenantDetails = tenant;
             
                $scope.tenantDetails.contact_enabled = ($scope.tenantDetails.contact_enabled == 1);
                $scope.addressDetails = tenant.address || {};
                timeZones.forEach(function (element) {
                    if (tenant.time_zone == element.value) {
                        $scope.selectedTimeZone = {
                            value: element.value,
                            label: element.label
                        };
                    }
                });
            }


            $scope.detailsUpdated = function (updatedTenant) {
                if (updatedTenant.address) {
                    var address = $scope.addressDetails;
                    if (!address.street && !address.house_number && !address.postal_code &&
                        !address.country && !address.state && !address.city) {
                        updatedTenant.address = {};
                    } else {
                        if (address.street && address.house_number && address.postal_code &&
                            address.country && address.state && address.city) {
                            //Is supposed to do nothing
                        } else {
                            $.notify($translate.instant('notify.address_all_fields'));
                            return;
                        }
                    }
                }

				if($scope.mayUpdateSettings) {
                    for (var i = 0; i < $scope.vehicleTypes.length; i++) {
                        updateSettings(updatedTenant.tenant_id, $scope.settingDetails, $scope.settingDetails.vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id]);
                    }
                }

                updatedTenant.address = {};
                updatedTenant.address.street = $scope.addressDetails.street;
                updatedTenant.address.house_number = $scope.addressDetails.house_number;
                updatedTenant.address.postal_code = $scope.addressDetails.postal_code;
                updatedTenant.address.country = $scope.addressDetails.country;
                updatedTenant.address.state = $scope.addressDetails.state;
                updatedTenant.address.city = $scope.addressDetails.city;

                updatedTenant.time_zone = $scope.selectedTimeZone.value;
                updatedTenant.with = 'address';

                $scope.saving = true;
                Tenants.update(updatedTenant.tenant_id, updatedTenant).then(function (tenant) {
                    $.notify($translate.instant('notify.tenant_update_success'), "success");
                    initTenant(tenant);
                }).catch(function () {
                }).finally(function() {
                    $scope.saving = false;
                });
            };

            Tenants.getSettings(calllock.tenantId).then(function(response){
                $scope.settingDetails = response;
                //Arrange vehicle settings so that vehicle_type_id is the index of the vehicle settings
                var vehicle_settings = [];
                for(var i = 0; i < $scope.vehicleTypes.length; i++){
                    for(var j = 0; j < response.vehicle_settings.length; j++){
                        if(response.vehicle_settings[j].vehicle_type_id == $scope.vehicleTypes[i].vehicle_type_id){
                            vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id] = response.vehicle_settings[j];
                        }
                    }
                }

                for(var key in inheritSettings){
                    if(inheritSettings.hasOwnProperty(key)){
                        if ($scope.settingDetails[inheritSettings[key]] == null) {
                            $scope[key] = true;
                        }else {
                            $scope[key] = false;
                            $scope.settingDetails[inheritSettings[key]] = ($scope.settingDetails[inheritSettings[key]] == 1);
                        }
                    }
                }

                $scope.settingDetails.vehicle_type_id = $scope.vehicleTypes[0].vehicle_type_id;
                $scope.settingDetails.vehicle_settings = vehicle_settings;
            });
            
            $scope.mayUpdateSettings = PermissionService.hasPermissions(['updateEntitySettings']);

            function updateSettings(tenant_id, settings, vehicleSettings){
                if(!vehicleSettings){
                    return;
                }
                var sets = {};
                angular.extend(sets, settings);
                sets.max_booked = vehicleSettings.max_booked;
                sets.max_rented = vehicleSettings.max_rented;
                sets.start_cost = vehicleSettings.start_cost;
                sets.minimum_balance = vehicleSettings.minimum_balance;
                sets.vehicle_type_id = vehicleSettings.vehicle_type_id;

                for(var key in inheritSettings){
                    if(inheritSettings.hasOwnProperty(key)){
                        if ($scope[key]) {
                            sets[inheritSettings[key]] = null;
                        }else{
                            if(sets[inheritSettings[key]] === null){
                                sets[inheritSettings[key]] = false;
                            }
                        }
                    }
                }

                Tenants.updateSettings(tenant_id, sets).then(function(){
                    $.notify($translate.instant('notify.settings_update_success'), 'success');
                }).catch(function(){
                });
            }

            tooltips();

            function tooltips() {
                $('.tooltip-label').hover(function () {
                    // Hover over code
                    var title = $(this).attr('title');
                    $(this).data('tipText', title).removeAttr('title');
                    $('<p class="tooltip"></p>')
                        .text(title)
                        .appendTo('body')
                        .fadeIn('slow');
                }, function () {
                    // Hover out code
                    $(this).attr('title', $(this).data('tipText'));
                    $('.tooltip').remove();
                }).mousemove(function (e) {
                    var mousex = e.pageX + 20; //Get X coordinates
                    var mousey = e.pageY + 10; //Get Y coordinates
                    $('.tooltip')
                        .css({top: mousey, left: mousex, 'z-index':99999999})
                });
            }
        },
    ]
);