angular.module("2cp").controller("tenants.settings.registrationFields",
    [
        "$scope", "$timeout", "Tenants", "PermissionService", '$translate',
        function ($scope, $timeout, Tenants, PermissionService, $translate) {
            $scope.settings = {};
            $scope.saving = false;

            $scope.fields = Tenants.getRegistrationFields();

            Tenants.getSettings(calllock.tenantId).then(function(response){
                $scope.settings = response;
                for(var i = 0; i < $scope.fields.length; i++){
                    $scope.settings[$scope.fields[i] + '_visible'] = $scope.settings[$scope.fields[i] + '_visible'] === 1;
                    $scope.settings[$scope.fields[i] + '_required'] = $scope.settings[$scope.fields[i] + '_required'] === 1;
                }
            });

            $scope.fieldChanged = function(field){
                if(!$scope.settings[field + '_visible']){
                    $scope.settings[field + '_required'] = false;
                }
            };

            $scope.getTranslateString = function(field){
                return Tenants.getTranslateRegistrationFieldLabel(field);
            };

            $scope.save = function(){
                $scope.saving = true;
                Tenants.updateRegistrationFields(calllock.tenantId, $scope.settings).then(function(){
                    $.notify($translate.instant('notify.registration_fields_update_success'), "success");
                }).catch(function(){
                    $.notify($translate.instant('notify.registration_fields_update_failed'));
                }).finally(function(){
                    $scope.saving = false;
                });
            };
            
        },
    ]
);