angular.module("2cp").controller("ProfileOverviewController",
    [
        "$scope", "Users",
        function ($scope, Users) {
            $scope.data = { password: '' };

            $scope.send = function() {
                $scope.sending = true;
                $scope.message = null;
                Users.update(calllock.user_id, { password: $scope.data.password }).then(function(result) {
                    $scope.message = 'profile.passwordUpdate';
                }).catch(function(e) {
                    $scope.message = 'profile.passwordUpdateFailed';
                }).finally(function() {
                    $scope.sending = false;
                });
            }
        }
    ]
);