angular.module("2cp").factory("tourColumns",
    [
        "$translate", "$filter", "$timeout",
        "Tours", "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, $timeout, Tours,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                tour_id: {
                    visible: false
                },
                name: {
                    enableCellEdit: true,
                },
                description: {
                    enableCellEdit: true
                },
                waypoints: {
                    enableCellEdit: true
                },
                category: {
                    visible: false
                },
            };

            return new ColumnDefBuilder()
                .addColumnNames(Tours.parameters)
                .addDefaultOptions({
                    visible: true,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('app.tours.')
                .copy();
        }
    ]
);