'use strict';

angular.module('api').factory('OperatorPushMessages', [
    '$http',
    'clApi',
    '$q',
    function ($http, api, $q) {
        return {
            parameters: [
                'operator_push_message_id',
                'message_title',
                'message_text',
                'sender',
                'receiver',
                'created_at',
                'read_at'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getAll: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('operatorPushMessages', params, options).then(function (result) {
                    return result.data;
                });
            },
            getMessagesForUser: function(userId, params, options) {
                var options = api.createIfNotExist(options);

                return api.get('operatorPushMessages/messagesForUser/' + userId, params, options).then(function(result) {
                    return result.data;
                });
            },
            create: function(data) {
                return api.post('operatorPushMessages', data).then(function(result) {
                    return result.data;
                });
            },
            delete: function (messageIds) {
                var promises = [];
                messageIds.forEach(function (id) {
                    promises.push(api.delete('operatorPushMessages/' + id).then(function(result) {
                        return result.data;
                    }));
                });

                return $q.all(promises);
            },
        };
    }
]);
