angular.module("2cp").controller("tenants.settings.operators",
    [
        "$scope", "$timeout", "Users", "UserColumns", "Roles", "RoleColumns", "Address", '$translate',
        function ($scope, $timeout, Users, UserColumns, Roles, RoleColumns, Address, $translate) {
            $scope.genders = Users.GENDER_TYPE;
            $scope.countries = Address.getCountries();

            // Operator grid
            $scope.operatorGridOptions = {
                columnDefs: UserColumns.build(),
                callGridData: {
                    id: "user_id",
                    get: function (params) {
                        return Users.getOperators(params)
                            .then(function (result) {
                                return result;
                            })
                            .catch(function () {
                                console.error(e);
                            });
                    },
                    create: function (data) {
                        return Users.createOperator(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.operator_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                            });
                    },
                    update: function (id, data) {
                        return Users.updateOperator(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.operator_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Users.demoteOperator(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.operator_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                },
                callGridFiltering: {
                    queryParameterPrefix: "users."
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.userDetails = rowEntity;
                        $scope.addressDetails = rowEntity.address || {};

                        $scope.roleGrid.api.grid.clearAllFilters();
                        $scope.roleGrid.api.callGridFiltering.setGridFilters({}, true);

                        $("#operatorDetailsModal").foundation('open');
                    }
                },
                onRegisterApi: function (gridApi) {
                    $scope.operatorGrid = gridApi.grid;
                }
            };

            $scope.createOperator = function () {
                if (!$scope.userDetails.street && !$scope.userDetails.house_number && !$scope.userDetails.postal_code &&
                    !$scope.userDetails.country && !$scope.userDetails.state && !$scope.userDetails.city) {
                    $scope.userDetails.street = null;
                    $scope.userDetails.house_number = null;
                    $scope.userDetails.postal_code = null;
                    $scope.userDetails.country = null;
                    $scope.userDetails.state = null;
                    $scope.userDetails.city = null;
                } else {
                    if ($scope.userDetails.street && $scope.userDetails.house_number && $scope.userDetails.postal_code &&
                        $scope.userDetails.country && $scope.userDetails.state && $scope.userDetails.city) {
                        //Is supposed to do nothing
                    } else {
                        $.notify($translate.instant('notify.address_all_fields'));
                        return;
                    }
                }

                //Merge address details into user details
                $scope.userDetails.street = $scope.addressDetails.street;
                $scope.userDetails.house_number = $scope.addressDetails.house_number;
                $scope.userDetails.postal_code = $scope.addressDetails.postal_code;
                $scope.userDetails.country = $scope.addressDetails.country;
                $scope.userDetails.state = $scope.addressDetails.state;
                $scope.userDetails.city = $scope.addressDetails.city;

                $scope.saving = true;
                $scope.operatorGrid.api.callGridEditing.addRow($scope.userDetails).then(function () {
                    $("#operatorCreationModal").foundation('close');
                }).finally(function () {
                    $scope.saving = false;
                });
            };
            $scope.detailsUpdated = function () {
                if (!$scope.userDetails.street && !$scope.userDetails.house_number && !$scope.userDetails.postal_code &&
                    !$scope.userDetails.country && !$scope.userDetails.state && !$scope.userDetails.city) {
                    $scope.userDetails.street = null;
                    $scope.userDetails.house_number = null;
                    $scope.userDetails.postal_code = null;
                    $scope.userDetails.country = null;
                    $scope.userDetails.state = null;
                    $scope.userDetails.city = null;
                } else {
                    if ($scope.userDetails.street && $scope.userDetails.house_number && $scope.userDetails.postal_code &&
                        $scope.userDetails.country && $scope.userDetails.state && $scope.userDetails.city) {
                        //Is supposed to do nothing
                    } else {
                        $.notify($translate.instant('notify.address_all_fields'));
                        return;
                    }
                }
                $scope.operatorGrid.api.rowEdit.setRowsDirty([$scope.userDetails]);
            };

            $scope.showDemoteButton = function () {
                var r = true;

                var rows = $scope.operatorGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };
            $scope.demoteSelected = function () {
                $scope.operatorGrid.api.callGridEditing.removeSelectedRows();
            };

            // Operator creation form
            $scope.showCreateOperatorForm = function () {
                $timeout(function () {
                    $scope.userDetails = {};
                    $scope.addressDetails = {};
                    $("#operatorCreationModal").foundation('open');
                }, 0);
            };

            // Role grid
            $scope.roleGridOptions = {
                columnDefs: RoleColumns.build(),

                callGridFiltering: {
                    updateUrl: false
                },
                callGridData: {
                    get: Roles.getRoles
                },
                callGridRowState: {
                    classifier: function (row, gridOptions) {
                        var rId = row.entity.role_id;
                        var roles = $scope.userDetails.roles || [];
                        for (var i = 0; i < roles.length; i++) {
                            if (roles[i].role_id == rId) {
                                // Match
                                return 0;
                            }
                        }
                        // No match
                        return 1;
                    },
                    listener: function (rowEntity, newState) {
                        var roles = $scope.userDetails.roles || [];
                        if (newState.name === "granted") {
                            // Grant permission
                            roles.push(rowEntity);
                            $scope.userDetails.roles = roles;
                        } else {
                            // Deny permission
                            for (var i = roles.length-1; i >= 0; i--) {
                                if (roles[i].role_id === rowEntity.role_id) {
                                    roles.splice(i, 1);
                                }
                            }
                            $scope.userDetails.roles = roles;
                        }
                    },
                    states: [
                        {
                            name: "granted",
                            template: '<i class="fa fa-check vertical-center"></i>'
                        },
                        {
                            name: "denied",
                            template: '<i class="fa fa-times vertical-center"></i>'
                        }
                    ]
                },

                onRegisterApi: function (gridApi) {
                    $scope.roleGrid = gridApi.grid;
                }
            };

            // Existing users
            $scope.existingUsersGridOptions = {
                columnDefs: UserColumns.build(),

                callGridData: {
                    id: "user_id",
                    get: Users.getPotentialOperators
                },
                onRegisterApi: function (gridApi) {
                    $scope.existingUsersGrid = gridApi.grid;
                }
            };

            $scope.showPromoteButton = function () {
                var r = true;

                var rows = $scope.existingUsersGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };
            $scope.promoteSelected = function () {
                var selectedIds = [];
                var selectedRows = $scope.existingUsersGrid.api.selection.getSelectedRows();
                var idKey = $scope.existingUsersGridOptions.callGridData.id;
                selectedRows.forEach(function (row) {
                    selectedIds.push(row[idKey]);
                });

                $scope.saving = true;
                Users.promoteOperator(selectedIds).then(function (result) {
                    $.notify($translate.instant('notify.operator_promote_success'), "success");

                    // Sort IDs in descending order
                    selectedRows.sort(function (a, b) {
                        return b - a;
                    });

                    // Remove ids from back to front and add them to operator grid
                    for (var i = selectedRows.length - 1; i >= 0; i--) {
                        var index = $scope.existingUsersGridOptions.data.indexOf(selectedRows[i]);
                        $scope.existingUsersGridOptions.data.splice(index, 1);
                        $scope.operatorGridOptions.data.push(selectedRows[i]);
                    }

                    $("#operatorCreationModal").foundation('close');

                }).catch(function (e) {
                    console.error(e);
                }).finally(function () {
                    $scope.saving = false;
                });
            };
        },
    ]
);