angular.module("2cp").factory("EventsColumns",
    [
        "$translate", "$filter", "uiGridConstants", "$timeout",
        "Events", "Zones", "Vehicles", "Users", "UserIdentifiers",
        "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants, $timeout,
                  Events, Zones, Vehicles, Users, UserIdentifiers,
                  ColumnDefBuilder) {
            var columnOptions = {
                rental_id: {
                    enableCellEdit: false
                },
                log_date_time: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    enableCellEdit: false,
                    visible: true,
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    sort: {
                        direction: uiGridConstants.DESC,
                        priority: 0
                    }
                },
                occur_time: {
                    //enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: false
                },
                event_type: {
                    visible: true,
                    cellFilter: "rentalEvents | translate",
                    enableCellEdit: true,
                    selectOptions: {
                        keys: Events.EVENT_TYPE,
                        filter: "rentalEvents"
                    },
                    enableFiltering: true
                },
                user_label: {
                    visible: true,
                    enableFiltering: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.label,
                                value: item.label
                            }
                        }
                    }
                },
                vehicle_label: {
                    visible: true,
                    enableFiltering: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.label,
                                value: item.label
                            }
                        }
                    }
                },
                rejection_code: {
                    visible: true,
                    enableFiltering: true
                },
                duration: {
                    visible: true,
                    cellClass: 'grid-align-right',
                    enableFiltering: true
                },

                identifier_label: {
                    enableFiltering: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.label,
                                value: item.label
                            }
                        }
                    }
                },
                zone_id: {
                    enableFiltering: true,
                    cellFilter: "zoneIdToLabel",
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.short_description,
                                value: item.zone_id
                            }
                        }
                    }
                },
                departure_lock_number: {
                    enableFiltering: true
                },
                lock_number: {
                    enableFiltering: true
                },
                vehicle_tag: {
                    enableFiltering: true,
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.tag,
                                value: item.tag
                            }
                        }
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Events.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.events.')
                .copy();

            $timeout(function() {
                Vehicles.getVehicles().then(function(vehicles) {
                    vehicles = vehicles.data;
                    builder.columnDefs.filter(function(c) {
                        return c.name === "vehicle_label" || c.name === "vehicle_tag";
                    }).forEach(function(c) {
                        c.awesomplete.suggestions = vehicles;
                    });
                });

                // Users.getUsers().then(function(users) {
                //     users = users.data;
                //     //console.log(users);
                //     builder.columnDefs.filter(function(c) {
                //         return c.name === "user_label";
                //     })[0].awesomplete.suggestions = users;
                // });

                // UserIdentifiers.getAll().then(function(identifiers) {
                //     identifiers = identifiers.data;
                //     builder.columnDefs.filter(function(c) {
                //         return c.name === "identifier_label";
                //     })[0].awesomplete.suggestions = identifiers;
                // });

                // Zones.getZones().then(function(zones) {
                //     builder.columnDefs.filter(function(c) {
                //         return c.name === "zone_id";
                //     })[0].awesomplete.suggestions = zones;
                // });
            });

            return builder;
        }
    ]
);