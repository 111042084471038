angular.module("2cp").factory("LogColumns",
    [
        "$translate", "$filter", "uiGridConstants",
        "Logs", "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants,
                  Logs, ColumnDefBuilder) {
            var columnOptions = {
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                url:{
                    visible: true
                },
                username: {
                    visible: true
                }
                
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Logs.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('logs.')
                .copy();

            return builder;
        }
    ]
);