angular.module("2cp").factory("MaintenanceTaskRemarkColumns",
    [
        "$translate", "$filter", "$timeout", "ColumnDefBuilder",
        function ($translate, $filter, $timeout, ColumnDefBuilder) {

            var columnOptions = {
                author_id: {
                    visible: true,
                    enableCellEdit: false,
                    enableFiltering: true
                },
                remark: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(['maintenance_task_remark_id', 'author_id', 'remark'])
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.")
                .copy();

            return builder;
        }
    ]
);