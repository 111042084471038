angular.module("2cp").controller("tenants.vehicles",
    [
        "$scope", "$q", "$timeout", "CSV",
        "Vehicles", "VehiclesColumns",
        "$location", "ColumnDefBuilder",
        "Maintenance", "MaintenanceColumns",
        "LockDevices", "LockColumns",
        "DroppointDevices", "PermissionService",
        '$translate', 'Rentals', 'RentalColumns',
        'VehicleTypes',
        function ($scope, $q, $timeout, CSV, Vehicles, VehiclesColumns, $location, ColumnDefBuilder,
                  Maintenance, MaintenanceColumns, LockDevices, LockColumns,
                  DroppointDevices, PermissionService, $translate, Rentals, RentalColumns,
                  VehicleTypes) {
            $scope.vehicleDetails = {};
            $scope.vehicleTypes = [];
            var resetSelectedTab = false;

            function commitUpdate(id, data) {
                return Vehicles.update(id, data)
                    .then(function (result) {
                        $.notify($translate.instant('notify.vehicle_update_success'), "success");
                        return result;
                    })
                    .catch(function (e) {
                        console.error(e);
                    });
            }

            if (!PermissionService.hasPermissions(['viewDroppoints'])) {
                VehiclesColumns.removeColumnsByName(['droppointLabel']);
            }
            $scope.gridOptions = {
                columnDefs: VehiclesColumns.addOptionsByName({'vehicleType': {'selectOptions': $scope.vehicleTypes}}).build(),
                callGridData: {
                    id: "vehicle_id",
                    get: function (params) {
                        params.with = 'locks';
                        if(params.vehicleType){
                            params.vehicleType = '"' + params.vehicleType + '"';
                        }
                        return Vehicles.getVehicles(params).then(function (response) {
                            if (!PermissionService.hasPermissions(['updateVehicles'])) {
                                $scope.vehicleGrid.api.callGridEditing.disableCellEdits();
                            }
                            for (var i = 0; i < response.data.length; i++) {
                                response.data[i].oldLabel = response.data[i].label;
                            }
                            return response;
                        })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    create: function (data) {
                        $('.reveal').foundation('close');
                        return Vehicles.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.vehicle_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        //if vehicle is set to stolen
                        if (data.status === Vehicles.VEHICLE_STATUS.STOLEN) {
                            //create maintenance task
                            $scope.showMaintenanceModal(id, data.label);
                            return commitUpdate(id, data);
                        }
                        else {
                            return commitUpdate(id, data);
                        }
                    },
                    delete: function (ids) {
                        return Vehicles.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.vehicle_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "vehicles."
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        if (resetSelectedTab) {
                            $('#vehicleTabs').foundation('selectTab', $('#detailsPanel'));
                            resetSelectedTab = false;
                        }

                        $scope.showDetailsModal(false, rowEntity);
                    }
                },
                callGridIcon: {
                    iconClass: [function (rowEntity) {
                        //Search for bluetooth lock devices
                        if (rowEntity.accessories) {
                            for (var i = 0; i < rowEntity.accessories.length; i++) {
                                if (rowEntity.accessories[i].accessory.device_type === 'App\\BluetoothLockDevice') {
                                    return 'fa-link';
                                }
                            }
                        }

                        //Not found
                        return 'fa-chain-broken';
                    }],
                    clickCallback: function (rowEntity) {
                        $('#vehicleTabs').foundation('selectTab', $('#lockDevicesPanel'));
                        $scope.showDetailsModal(false, rowEntity);

                        resetSelectedTab = true;
                    }
                },
                onRegisterApi: function (gridApi) {
                    $scope.vehicleGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function () {
                var r = true;

                var rows = $scope.vehicleGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                rows.forEach(function (row) {
                    if (row.status != Vehicles.VEHICLE_STATUS.NOT_ACTIVE)
                        r = false;
                });

                return r;
            };
            $scope.showRepairButton = function () {
                var r = true;

                var rows = $scope.vehicleGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                rows.forEach(function (row) {
                    if (row.condition != Vehicles.CONDITION.DEFECT)
                        r = false;
                });

                return r;
            };
            $scope.showStolenButton = function () {
                var r = true;

                var rows = $scope.vehicleGrid.api.selection.getSelectedRows();
                if (rows.length !== 1)
                    r = false;

                rows.forEach(function (row) {
                    if (row.status == Vehicles.VEHICLE_STATUS.STOLEN)
                        r = false;
                });
                return r;
            };
            $scope.showLocationButton = function () {
                var r = true;

                var rows = $scope.vehicleGrid.api.selection.getSelectedRows();
                if (rows.length != 1)
                    r = false;

                return r;
            };
            $scope.showSetLocationModal = function () {
                var row = $scope.vehicleGrid.api.selection.getSelectedRows()[0];
                if (!row.accessories) {
                    $scope.vehicleLocation.lockAttached = false;
                } else {
                    for (var i = 0; i < row.accessories.length; i++) {
                        if (row.accessories[i].accessory.device.lock_device.droppoint_device) {
                            var droppointDevice = row.accessories[i].accessory.device.lock_device.droppoint_device;
                            $scope.vehicleLocation.label = droppointDevice.label;
                            $scope.vehicleLocation.droppoint_device_id = droppointDevice.droppoint_device_id;
                            $scope.vehicleLocation.lockAttached = true;
                            break;
                        }
                    }
                }
                $("#setLocationModal").foundation('open');
            };

            $scope.markRepaired = function () {
                var rows = $scope.vehicleGrid.api.selection.getSelectedRows();
                rows.forEach(function (row) {
                    if (row.condition == Vehicles.CONDITION.DEFECT)
                        row.condition = Vehicles.CONDITION.HEALTHY;
                });
                $scope.vehicleGrid.api.rowEdit.setRowsDirty(rows);
            };
            $scope.markAsStolen = function () {
                var rows = $scope.vehicleGrid.api.selection.getSelectedRows();
                rows.forEach(function (row) {
                    if (row.status != Vehicles.VEHICLE_STATUS.STOLEN)
                        row.status = Vehicles.VEHICLE_STATUS.STOLEN;
                });
                $scope.vehicleGrid.api.rowEdit.setRowsDirty(rows);
                $("#createMaintenanceModal").foundation('open');
            };
            $scope.setLocation = function () {
                var row = $scope.vehicleGrid.api.selection.getSelectedRows()[0];
                $('.reveal').foundation('close');
                Vehicles.setLocation(row.vehicle_id, $scope.vehicleLocation.droppoint_device_id)
                    .then(function (result) {
                        $.notify($translate.instant('notify.vehicle_location_success'), "success");
                        return result;
                    });
            };
            $scope.removeSelected = function () {
                $scope.vehicleGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.showMaintenanceModal = function (vehicleId, vehicleLabel) {
                $scope.newTask = true;
                $scope.taskDetails = {};
                $scope.taskDetails.subject = 'Vehicle ' + vehicleId + ' stolen';
                $scope.taskDetails.datetime_closed = moment().format("YYYY-MM-DD HH:mm:ss");
                $scope.taskDetails.maintenance_task_type_id = 7;
                $scope.taskDetails.vehicle_id = vehicleId;
                $scope.taskDetails.vehicle = vehicleLabel;
                $scope.taskDetails.priority = Maintenance.PRIORITY[0];
                $("#maintenanceDetailsModal").foundation('open');
            };
            $scope.save = function () {
                $scope.saving = true;
                Maintenance.create($scope.taskDetails)
                    .then(function (result) {
                        $.notify($translate.instant('notify.task_create_success'), "success");
                        $("#maintenanceDetailsModal").foundation('close');
                        return result;
                    })
                    .catch(function (e) {
                        console.error(e);
                    })
                    .finally(function () {
                        $scope.saving = false;
                    });
            }

            $scope.vehicleDetails = {};
            $scope.vehicleStatuses = Vehicles.VEHICLE_STATUS;
            $scope.vehicleConditions = Vehicles.CONDITION;
            $scope.vehicleRentingStatuses = Vehicles.RENTING_STATUS;
            $scope.vehicleLocation = {
                label: 'Not attached to a droppoint device.',
                droppoint_device_id: null,
                lockAttached: true
            };

            $scope.importCSV = function (file) {
                if (file === null)
                    return;

                var reader = new FileReader();
                reader.onload = function (e) {
                    var text = reader.result;
                    var items = CSV.parse(text);

                    console.log(items);

                    Vehicles.create({batch: items}).then(function (result) {
                        $.notify($translate.instant('notify.vehicle_batch_create_success_1') + result.length + $translate.instant('notify.vehicle_batch_create_success_2') + items.length, "success");
                        if (result.length < items.length)
                            $.notify($translate.instant('notify.vehicle_batch_create_failed_2'), 'warn');

                        $scope.vehicleGrid.api.callGridData.get();

                        return result;
                    }).catch(function (e) {
                        console.error(e);
                    });
                };
                reader.readAsText(file);
            };
            if (PermissionService.hasPermissions(['updateVehicles', 'viewDroppointDevices'])) {
                DroppointDevices.getDroppointDevices({limit: 99999}).then(function (result) {
                    $scope.droppointDevices = result.data;
                });
            }

            //Details modal
            {
                /**
                 *
                 * @param creationModal True if the modal is for creation, false if it is for editing.
                 * @param rowEntity The rowEntity containing vehicle data
                 */
                $scope.showDetailsModal = function (creationModal, rowEntity) {
                    $scope.vehicleDetails = rowEntity;
                    $scope.vehicleCreationModal = creationModal;

                    if (creationModal) {
                        $('#vehicleTabs').foundation('selectTab', $('#detailsPanel'));
                    } else {
                        if (PermissionService.hasPermissions(['viewTasks'])) {
                            $scope.maintenanceGrid.api.grid.clearAllFilters();
                            $scope.maintenanceGrid.api.callGridFiltering.setGridFilters({
                                vehicleLabel: rowEntity.label
                            }, true);
                        }

                        if (PermissionService.hasPermissions(['viewRentals'])) {
                            $scope.rentalsGrid.api.grid.clearAllFilters();
                            $scope.rentalsGrid.api.callGridFiltering.setGridFilters({
                                vehicle_tag: rowEntity.vehicle_tag
                            }, true);
                        }
                        if (PermissionService.hasPermissions(['viewLockDevices'])) {
                            $scope.lockDeviceGrid.api.grid.clearAllFilters();
                            $scope.lockDeviceGrid.api.callGridData.get().then(function () {
                                var ids = getLockDevicesFromVehicleDetails().map(function (lockDevice) {
                                    return lockDevice.lock_device_id;
                                });

                                $timeout(function () {
                                    console.log($scope.lockDeviceGrid);
                                    $scope.lockDeviceGrid.options.data.forEach(function (lockDevice) {
                                        if (ids.indexOf(lockDevice.lock_device_id) > -1)
                                            $scope.lockDeviceGrid.api.selection.selectRow(lockDevice);
                                    });
                                }, 0);
                            });
                        }

                        if (rowEntity.renting_status === 'rented_with_voucher' || rowEntity.renting_status === 'rented_with_identifier') {
                            Rentals.getRentals({
                                sort: '-created_at',
                                vehicle_id: rowEntity.vehicle_id
                            }).then(function (result) {
                                var rental = result.data[0];
                                if (!rental)
                                    return;
                                $scope.vehicleDetails.since = rental.created_at;
                                $scope.vehicleDetails.username = rental.payment.payment.user_identifier.user.username;
                                $scope.vehicleDetails.user_identifier_id = rental.payment.payment.user_identifier_id;
                            });
                        }
                    }

                    $("#vehicleDetailsModal").foundation('open');
                };
                $scope.detailsUpdated = function (updatedRow) {
                    $('.reveal').foundation('close');
                    if (updatedRow.oldLabel) {
                        if (updatedRow.oldLabel !== updatedRow.label) {
                            showLabelConfirmPopup();
                            return;
                        }
                    }
                    if ($scope.vehicleCreationModal)
                        $scope.vehicleGrid.api.callGridEditing.addRow(updatedRow);
                    else
                        $scope.vehicleGrid.api.rowEdit.setRowsDirty([updatedRow]);
                };

                function showLabelConfirmPopup() {
                    $("#labelConfirmPopup").foundation('open');
                }

                $scope.confirmLabel = function (row) {
                    row.oldLabel = row.label;
                    $scope.detailsUpdated(row);
                }

            }

            //Maintenance grid
            {
                if (PermissionService.hasPermissions(['viewTasks'])) {
                    $scope.createForm = {};
                    $scope.priorities = Maintenance.PRIORITY;
                    $scope.types = Maintenance.TYPES;
                    $scope.maintenanceGridOptions = {
                        columnDefs: MaintenanceColumns.addOptionsByName({
                            vehicle_id: {
                                visible: true
                            }
                        }).build(),

                        callGridFiltering: {
                            updateUrl: false
                        },
                        callGridData: {
                            id: "maintenance_id",
                            get: function (params) {
                                return Maintenance.getTasks(params).then(function (result) {
                                    result.data.forEach(function (item) {
                                        if (item.remarks.length === 0)
                                            return;

                                        item.resolution = item.remarks[item.remarks.length - 1].remark;
                                    });
                                    if (!PermissionService.hasPermissions(['updateTasks'])) {
                                        $scope.maintenanceGrid.api.callGridEditing.disableCellEdits();
                                    }
                                    return result;
                                });
                            },
                        },
                        onRegisterApi: function (gridApi) {
                            $scope.maintenanceGrid = gridApi.grid;
                        }
                    };
                }
            }

            //Rentals grid
            {
                if (PermissionService.hasPermissions(['viewRentals'])) {
                    $scope.rentalsGridOptions = {
                        columnDefs: RentalColumns.build(),

                        callGridData: {
                            id: "rental_id",
                            get: function (params) {
                                params.vehicle_id = '"' + $scope.vehicleDetails.vehicle_id + '"';
                                return Rentals.getRentals(params)
                                    .catch(function (e) {
                                        console.error(e);
                                    });
                            },
                        },
                        callGridFiltering: {
                            queryParameterPrefix: "rentals."
                        },
                        onRegisterApi: function (gridApi) {
                            $scope.rentalsGrid = gridApi.grid;
                        }
                    };
                }
            }

            //Lock Devices grid
            {
                if (PermissionService.hasPermissions(['viewLockDevices'])) {
                    function getLockDevicesFromVehicleDetails() {
                        var r = [];
                        if ($scope.vehicleDetails.accessories) {
                            $scope.vehicleDetails.accessories.forEach(function (accessory) {
                                accessory = accessory.accessory;
                                if (accessory.device && accessory.device.lock_device)
                                    r.push(accessory.device.lock_device);
                            });
                        }
                        return r;
                    }

                    $scope.lockDeviceGridOptions = {
                        columnDefs: LockColumns.build(),
                        callGridData: {
                            id: "lock_device_id",
                            get: function (params) {
                                return LockDevices.getFreeLocks(params).then(function (result) {
                                    //Merge locks in selected vehicle with all locks, to make sure
                                    //the vehicle's locks are always in the list

                                    //Collect lock devices
                                    var lockDevices = getLockDevicesFromVehicleDetails();

                                    //Merge with all lock devices obtained from server
                                    console.log(lockDevices);
                                    result.data.forEach(function (lockDevice) {
                                        //Remove duplicates from the vehicle's lock devices
                                        for (var i = 0; i < lockDevices.length; i++) {
                                            console.log(lockDevices);
                                            if (lockDevice.lock_device_id === lockDevices[i].lock_device_id) {
                                                lockDevices.splice(i, 1);
                                                i--;
                                            }
                                            console.log(lockDevices);
                                        }
                                    });
                                    console.log(lockDevices);

                                    //We now have only unique lock devices in both arrays, so simply concatenate
                                    lockDevices.forEach(function (lockDevice) {
                                        result.data.unshift(lockDevice);
                                    });

                                    console.log(result.data);
                                    console.log($scope.vehicleDetails);

                                    return result;
                                });
                            }
                        },
                        callGridFiltering: {
                            updateUrl: false
                        },
                        onRegisterApi: function (gridApi) {
                            $scope.lockDeviceGrid = gridApi.grid;
                        }
                    };
                    $scope.attachLocks = function (lockIds) {
                        $scope.saving = true;

                        Vehicles.attachLocks($scope.vehicleDetails.vehicle_id, lockIds).then(function () {
                            $.notify($translate.instant('notify.vehicle_attach_locks_success'), "success");
                        }).catch(function (e) {
                            console.error(e);
                        }).finally(function () {
                            $scope.saving = false;
                            $scope.vehicleGrid.api.callGridData.get();
                        });
                    };
                    $scope.getSelectedLockDeviceIds = function () {
                        var selectedIds = [];
                        var selectedRows = $scope.lockDeviceGrid.api.selection.getSelectedRows();
                        var idKey = $scope.lockDeviceGrid.options.callGridData.id;
                        selectedRows.forEach(function (row) {
                            selectedIds.push(row[idKey]);
                        });
                        return selectedIds;
                    };
                }
            }

            //Set vehicle types for filtering and for in the details modal
            VehicleTypes.getVehicleTypes({limit: 999}).then(function (response) {
                for (var i = 0; i < response.data.length; i++) {
                    $scope.vehicleTypes.push({
                        'value': response.data[i].name,
                        'label': response.data[i].name,
                        'id': response.data[i].vehicle_type_id
                    });
                }
                for (i = 0; i < $scope.vehicleGrid.columns.length; i++) {
                    if ($scope.vehicleGrid.columns[i].name === 'vehicleType') {
                        $scope.vehicleGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
            });
        }
    ]);    