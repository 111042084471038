angular.module("2cp").service('callGridRowStateService', ['$log', '$q', '$templateCache',
    function ($log, $q, $templateCache) {
      var service = {
        rowStateRowBuilder: function (row, gridOptions) {
            //add any promises to an array
            var promises = [];

            // Load classifier
            var states = gridOptions.callGridRowState.states;
            var classifier = gridOptions.callGridRowState.classifier || function(row, gridOptions) {
                // Default classifier returns index 0 or -1 if empty array
                var stateIndex = -1;

                if (states.length > 0) {  
                    stateIndex = 0;          
                }
                return stateIndex;
            }
            // Set row state index based on classifier
            row.entity.callGridRowState = { stateIndex: classifier(row, gridOptions) };

            //return all promises
            return $q.all(promises);
        }
     }
     return service;
   }]);

angular.module("2cp").directive('callGridRowState', ['callGridRowStateService', 
    function (rowStateService) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            compile: function(tElem, tAttrs) {
                return {
                    pre: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;

                        // Set defaults
                        if(typeof(grid.options.callGridRowState) === "undefined")
                            grid.options.callGridRowState = {};
                        if(typeof(grid.options.callGridRowState.states) === "undefined")
                            grid.options.callGridRowState.states = [];
                        if(typeof(grid.options.callGridRowState.listener) === "undefined")
                            grid.options.callGridRowState.listener = function(rowEntity, newState){};
                        
                        // Register row builder to set default states
                        uiGridCtrl.grid.registerRowBuilder(rowStateService.rowStateRowBuilder);

                        // Setup default state
                        var states = grid.options.callGridRowState.states;
                        var defaultState = {};

                        // Set default state
                        if (states.length > 0) {                    
                            defaultState = states[0];
                        }
                        grid.options.columnDefs.unshift({
                            name: "state",
                            displayName: "",
                            enableCellEdit: false,
                            enableHiding: false,
                            maxWidth: 30,
                            headerCellTemplate: "<div></div>",
                            cellTemplate: '<div class="ui-grid-state-icon" ng-click="grid.api.callGridRowState.toggle(row.entity); $event.stopPropagation()" ng-bind-html="grid.api.callGridRowState.rowTemplate(row.entity) | unsafe"</div>'
                        });
                    },
                    post: function($scope, iElem, iAttrs, uiGridCtrl) {
                        var grid = uiGridCtrl.grid;
                        var states = grid.options.callGridRowState.states;
                        
                        // Register public api functions
                        var methods = {
                            callGridRowState: {
                                toggle: function(rowEntity) {
                                    // Update state
                                    var stateIndex = rowEntity.callGridRowState.stateIndex;
                                    stateIndex = (stateIndex + 1) % states.length;
                                    rowEntity.callGridRowState.stateIndex = stateIndex;

                                    // Call listener
                                    grid.options.callGridRowState.listener(rowEntity, states[stateIndex]);
                                },
                                rowTemplate: function(rowEntity) {
                                    return states[rowEntity.callGridRowState.stateIndex].template;
                                }
                            }
                        }
                        grid.api.registerMethodsFromObject(methods);
                    }
                }
            },
        };
    }]);