'use strict';

angular.module('api').factory('Subscriptions', [
    'clApi',
    '$q',
    function (api, $q) {
        var weekdayArray = [
            {
                id: 0,
                name: 'sunday'
            },
            {
                id: 1,
                name: 'monday'
            },
            {
                id: 2,
                name: 'tuesday'
            },
            {
                id: 3,
                name: 'wednesday'
            },
            {
                id: 4,
                name: 'thursday'
            },
            {
                id: 5,
                name: 'friday'
            },
            {
                id: 6,
                name: 'saturday'
            }
        ];

        return {

            SUBSCRIPTION_PARAMETERS: [
                'name',
                'valid_from',
                'valid_until',
                'payment_frequency',
                'renew',
                'updated_at',
                'cost',
                'length',
                'created_at',
                'pay_upfront',
                'max_cost_per_day',
                'subscription_id',
                'hidden'
            ],

            USER_SUBSCRIPTIONS_PARAMETERS:[
                'userRenew',
                'userValidFrom',
                'userValidUntil',
                'user_id'
            ],

            SUBSCRIPTION_FREQUENCIES: [
                'weekly',
                'monthly',
                'yearly',
                'unlimited'
            ],

            BALANCE_FREQUENCIES: [
                'daily',
                'weekly',
                'monthly',
                'yearly'
            ],

            PERIOD_PARAMETERS: [
                'subscription_period_id',
                'start_date',
                'end_date',
                'created_at',
                'updated_at'
            ],

            DISCOUNT_PARAMETERS: [
                'discount_subscription_entry_id',
                'start_time',
                'end_time',
                'discount_rental',
                'discount_booking',
                'weekday',
                'vehicleType',
                'created_at',
                'updated_at'
            ],

            FREE_PARAMETERS: [
                'free_subscription_entry_id',
                'minutes_per_day',
                'weekday',
                'vehicleType',
                'created_at',
                'updated_at'
            ],

            BALANCE_PARAMETERS: [
                'balance_subscription_entry_id',
                'amount',
                'frequency',
                'created_at',
                'updated_at'
            ],

            getWeekdays: function () {
                return weekdayArray;
            },

            getSubscriptions: function (params) {
                return api.get('subscriptions', params).then(function (result) {
                    for(var i = 0; i < result.data.data.length; i++){
                    	if(result.data.data[i].periods) {
							for(var j = 0; j < result.data.data[i].periods.length; j++){
								result.data.data[i].periods[j] = setPeriodDates(result.data.data[i].periods[j]);
							}
						}
                    }
                    return result.data;
                });
            },
            createSubscription: function (data) {
                return api.post('subscriptions', data).then(function (result) {
                    return result.data;
                });
            },
            updateSubscription: function (id, params) {
                return api.put('subscriptions/' + id, params).then(function (result) {
                    return result.data;
                });
            },
            deleteSubscriptions: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('subscriptions/' + id));
                });

                return $q.all(promises);
            },

            createSubscriptionPeriod: function (subscription_id, data) {
                return api.post('subscriptions/' + subscription_id + '/periods', data).then(function (result) {
                    result.data = setPeriodDates(result.data);
                    return result.data;
                });
            },
            updateSubscriptionPeriod: function (id, params) {
                return api.put('subscriptions/periods/' + id, params).then(function (result) {
                    result.data = setPeriodDates(result.data);
                    return result.data;
                });
            },
            deleteSubscriptionPeriods: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('subscriptions/periods/' + id));
                });

                return $q.all(promises);
            },

            getSubscriptionDiscounts: function (params) {
                return api.get('subscriptions/discounts', params).then(function (result) {
                    var entries = result.data;
                    for (var i = 0; i < entries.data.length; i++) {
                        entries.data[i] = setWeekday(entries.data[i]);
                    }
                    return entries;
                });
            },

            createSubscriptionDiscount: function (period_id, data) {
                return api.post('subscriptions/periods/' + period_id + '/discount', data).then(function (result) {
                    result.data = setWeekday(result.data);
                    return result.data;
                });
            },
            updateSubscriptionDiscount: function (id, params) {
                return api.put('subscriptions/periods/discounts/' + id, params).then(function (result) {
                    result.data = setWeekday(result.data);
                    return result.data;
                });
            },
            deleteSubscriptionDiscounts: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('subscriptions/periods/discounts/' + id));
                });

                return $q.all(promises);
            },

            getSubscriptionFrees: function (params) {
                return api.get('subscriptions/frees', params).then(function (result) {
                    var entries = result.data;
                    for (var i = 0; i < entries.data.length; i++) {
                        entries.data[i] = setWeekday(entries.data[i]);
                    }
                    return entries;
                });
            },

            createSubscriptionFree: function (period_id, data) {
                return api.post('subscriptions/periods/' + period_id + '/free', data).then(function (result) {
                    result.data = setWeekday(result.data);
                    return result.data;
                });
            },
            updateSubscriptionFrees: function (id, params) {
                return api.put('subscriptions/periods/frees/' + id, params).then(function (result) {
                    result.data = setWeekday(result.data);
                    return result.data;
                });
            },
            deleteSubscriptionFrees: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('subscriptions/periods/frees/' + id));
                });

                return $q.all(promises);
            },

            getSubscriptionBalances: function (params) {
                return api.get('subscriptions/balances', params).then(function (result) {
                    return result.data;
                });
            },

            createSubscriptionBalance: function (period_id, data) {
                return api.post('subscriptions/periods/' + period_id + '/balance', data).then(function (result) {
                    return result.data;
                });
            },
            updateSubscriptionBalance: function (id, params) {
                return api.put('subscriptions/periods/balances/' + id, params).then(function (result) {
                    return result.data;
                });
            },
            deleteSubscriptionBalance: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('subscriptions/periods/balances/' + id));
                });

                return $q.all(promises);
            },


        };

        function setWeekday(subscription) {
            var weekdays = [];
            for (var j = 0; j < subscription.weekday.length; j++) {
                if (subscription.weekday[j] == '1') {
                    weekdays.push(weekdayArray[j]);
                }
            }
            subscription.weekday = weekdays;
            return subscription;
        }

        function setPeriodDates(period){
            period.start_date = period.start_date.slice(5, period.start_date.length);
            period.end_date = period.end_date.slice(5, period.end_date.length);
            return period;
        }
    },
]);
