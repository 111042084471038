angular.module("2cp").directive("callGridSpinner", ["$compile",
    function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function ($scope, iElement, iAttributes, uiGridCtrl) {

                var expression = iAttributes['callGridSpinner'];
                var template = '<div class="spinner spinner-background-overlay" ng-show="' + expression + '" />';
                template = $compile(template)($scope);
                iElement.append(template);
            }
        };
    }]);