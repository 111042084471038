'use strict';

angular.module('api').factory('Events', [
    '$http',
    'clApi',
    '$filter',
    function ($http, api, $filter) {
        return {
            parameters: [
                'event_id',
                'log_date_time',
                'tenant_id',
                'event_type',
                'zone_id',
                'ip_address',
                'lock_number',
                'occur_time',
                'identifier_type',
                'departure_lock_number',
                'identifier_tag',
                'rejection_code',
                'vehicle_label',
                'vehicle_tag',
                'user_label',
                'identifier_label',
                'pay_amount',
                'system_count',
                'failure_moment',
                'actual_count',
                'subscription_type',
                'key_tag',
                'duration',
            ],

            EVENT_TYPE: {
                LOCK_DEFECT: 18,
                LOCK_REPAIRED: 19,
                VEHICLE_DAMAGED: 27,
                VEHICLE_REPAIRED: 29,
                VEHICLE_BLOCKED: 30,
                REQUEST_PARKING_START: 51,
                REQUEST_PARKING_END: 52,
                REQUEST_RENTING_START: 53,
                REQUEST_RENTING_END: 54,
                PROCESS_TIMEOUT: 55,
                PARKING_STARTED: 61,
                PARKING_ENDED: 62,
                RENTING_STARTED: 63,
                RENTING_ENDED: 64,
                RENTING_CANCELLED: 65,
                PLACED_WITH_SERVICECARD: 68,
                REMOVED_WITH_SERVICECARD: 69,
            },

            getParameters: function () {
                return this.parameters;
            },

            getAll: function (params) {
                params.event_type_min = 18;
                params.event_type_max = 69;
                return api.get('events', params).then(function (result) {
                    return result.data;
                });
            },

            getClosedRentals: function (params) {
                return api.get('events?event_type=64', params).then(function (result) {
                    return result.data;
                });
            },

            getOpenRentals: function (params) {
                return api.get('tenants/47/rentals?type=open', params).then(function (result) {
                    return result.data;
                });
            },

            getCancelledRentals: function (params) {
                return api.get('events?event_type=65', params).then(function (result) {
                    return result.data;
                });
            },

            getCancelledRentalsToday: function (params1) {
                var datetime = new Date();
                datetime.setDate(datetime.getDate() - 1);
                var yesterdayStr = $filter('date')(datetime.toISOString(), 'yyyy-MM-ddTHH:mm:ss') + 'Z';
                var params = {
                    log_date_time_min: yesterdayStr,
                    event_type: 65,
                };
                return api.get('events', params).then(function (result) {
                    return result.data;
                });
            },

            getRentalsToday: function () {
                var datetime = new Date();
                datetime.setDate(datetime.getDate() - 1);
                var yesterdayStr = $filter('date')(datetime.toISOString(), 'yyyy-MM-ddTHH:mm:ss') + 'Z';
                var params = {
                    log_date_time_min: yesterdayStr,
                    event_type: 64,
                };
                return api.get('events', params).then(function (result) {
                    return result.data;
                });
            },
        };
    },
]);
