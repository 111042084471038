angular.module("2cp")
// Filter for lock mode
.filter('lockMode', ["Locks", function(Locks) {
    return function(input) {
        if (input == Locks.MODE.PARKING)
            return "tenants.system.modeCell.parking";
        else if (input == Locks.MODE.RENTING)
            return "tenants.system.modeCell.renting";
        else
            return "tenants.system.modeCell.unknown";
    }
}])
// Filter for position mode
.filter('lockPosition', ["Locks", function(Locks) {
    return function(input) {
        if (input == Locks.POSITION.LEFT)
            return "tenants.system.positionCell.left";
        else if (input == Locks.POSITION.RIGHT)
            return "tenants.system.positionCell.right";
        else
            return "tenants.system.positionCell.unknown";
    }
}])
.filter('lockRentWithKey', ["Locks", function(Locks) {
    return function(input) {
        if (input == Locks.RENT_WITH_KEY.FALSE)
            return "no";
        else if (input == Locks.RENT_WITH_KEY.TRUE)
            return "yes";
        else
            return "unknown";
    }
}])
.filter('lockUsageStatus', ["Locks", function(Locks) {
    return function(input) {
        if (input == Locks.USAGE_STATUS.FREE)
            return "tenants.system.usageStatusCell.free";
        else if (input == Locks.USAGE_STATUS.OCCUPIED)
            return "tenants.system.usageStatusCell.occupied";
        else if (input == Locks.USAGE_STATUS.OCCUPIED_UNKNOWN_TAG)
            return "tenants.system.usageStatusCell.occupiedUnknownTag";
        else
            return "tenants.system.usageStatusCell.unknown";
    }
}]);