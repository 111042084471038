angular.module("2cp")
    .filter('maintenancePriority', [function() {
        return function(input) {
            return "tenants.maintenance.priorityCell." + input;
        }
    }])
    .filter('maintenanceStatus', [function() {
        return function(input) {
            return "tenants.maintenance.statusCell." + input;
        }
    }])
    .filter('maintenanceType', [function() {
        return function(input) {
            return "tenants.maintenance.typeCell." + input;
        }
    }])
    .filter('maintenanceRecurUnits', [function() {
        return function(input) {
            return input;
        }
    }])
    .filter('closedDate', ['Times', function(Times){
        return function (date, format) {
            // Here we convert the date to the tenant's timezone (the date changes
            // accordingly). The output is a string.
            var converted = Times.getTenantDateTimeString(date, Times.getZone());
            if (!converted) {
                return '';
            }
            // Then we format this date to the desired format
            return new moment(converted).format(format);
        }
    }]);