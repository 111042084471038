angular.module("2cp").factory("ChainColumns",
    [
        "$translate", "$filter", "$timeout",
        "Locks", "Chains", "Zones",
        "ColumnDefBuilder",
        function ($translate, $filter, $timeout,
                  Locks, Chains, Zones,
                  ColumnDefBuilder) {
            var columnOptions = {
                zone_id: {
                    cellFilter: "zoneIdToLabel"
                },
                description: {
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.description,
                                value: item.description
                            }
                        }
                    }
                },
                short_description: {
                    awesomplete: {
                        suggestions: [],
                        dataFunction: function(item, input) {
                            return {
                                label: item.short_description,
                                value: item.short_description
                            }
                        }
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Chains.getParameters())
                .addDefaultOptions({
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.chains.")
                .copy();

            //Timeout to make sure the awesomplete data is loaded after the main data.
            $timeout(function() {
                Chains.getChains().then(function(chains) {
                    chains = chains.data;
                    builder.columnDefs.filter(function(c) {
                        return c.name === "description" || c.name === "short_description";
                    }).forEach(function(c) {
                        c.awesomplete.suggestions = chains;
                    });
                });
            }, 500);

            return builder;
        }
    ]
);