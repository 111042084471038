angular.module("2cp").controller("tenants.settings.roles",
    [
        "$scope", "$timeout", "Roles", "RoleColumns", "Permissions", "PermissionColumns", "PermissionService", '$translate',
        function ($scope, $timeout, Roles, RoleColumns, Permissions, PermissionColumns, PermissionService, $translate) {
            $scope.permissionTypes = [];
            $scope.showRoleDetailsForm = function(rowEntity) {
                $timeout(function() {
                    $scope.newRole = false;
                    $scope.roleDetails = rowEntity;

                    $scope.permissionGrid.api.grid.clearAllFilters();
                    $scope.permissionGrid.api.callGridFiltering.setGridFilters({
                    }, true);

                    $("#roleDetailsModal").foundation('open');
                }, 0);
            };

            $scope.showCreateRoleForm = function() {
                $timeout(function() {
                    $scope.newRole = true;
                    $scope.roleDetails = {};

                    $scope.permissionGrid.api.grid.clearAllFilters();
                    $scope.permissionGrid.api.callGridFiltering.setGridFilters({
                    }, true);

                    $("#roleDetailsModal").foundation('open');
                }, 0);
            };

            $scope.save = function() {
                if($scope.newRole) {
                    $scope.saving = true;
                    $scope.roleGrid.api.callGridEditing.addRow($scope.roleDetails).then(function() {
                        $("#roleDetailsModal").foundation('close');
                    }).finally(function() {
                        $scope.saving = false;
                    });
                }
                else {
                    $scope.roleGrid.api.rowEdit.setRowsDirty([$scope.roleDetails]);
                    $("#roleDetailsModal").foundation('close');
                }
            };

            // Role grid
            $scope.roleGridOptions = {
                columnDefs: RoleColumns.build(),
                multiSelect: false,
                callGridData: {
                    id: "role_id",
                    get: function(params) {
                        return Roles.getRoles(params).then(function(response){
                            if(!PermissionService.hasPermissions(['updateRoles'])) {
                                $scope.roleGrid.api.callGridEditing.disableCellEdits();
                            }
                            return response;
                        })
                            .catch(function() {
                                console.error(e);
                            });
                    },
                    update: function(id, data) {
                        return Roles.update(id, data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.roles_update_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    },
                    create: function(data) {
                        return Roles.create(data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.roles_create_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.log(e);
                            });
                    },
                    delete: function(ids) {
                        return Roles.delete(ids)
                            .then(function(result) {
                                $.notify($translate.instant('notify.roles_delete_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "roles."
                },
                callGridEditIcon: {
                    clickCallback: $scope.showRoleDetailsForm
                },
                callGridDuplicate: {
                    clickCallback: function(rowEntity) {
                        rowEntity = angular.copy(rowEntity);
                        $timeout(function() {
                            delete rowEntity.role_id;
                            rowEntity.display_name = rowEntity.display_name + " copy";
                            rowEntity.name = rowEntity.name + " copy";

                            $scope.newRole = true;
                            $scope.roleDetails = rowEntity;

                            $scope.permissionGrid.api.grid.clearAllFilters();
                            $scope.permissionGrid.api.callGridFiltering.setGridFilters({}, true);

                            $("#roleDetailsModal").foundation('open');
                        }, 0);
                    }
                },
                onRegisterApi: function(gridApi) {
                    $scope.roleGrid = gridApi.grid;
                }
            };

             // Permission grid
            $scope.permissionGridOptions = {
                columnDefs: PermissionColumns.
                addOptionsByName({'permission_type' : {'selectOptions': $scope.permissionTypes}}).
                build(),

                callGridFiltering: {
                    updateUrl: false
                },
                callGridData: {
                    get: function(params) {
                        params.limit = 9999;
                        return Permissions.getAvailable(params)
                            .then(function(response){
                                $scope.setPermissionTypeOptions();
                                return response;
                            })
                            .catch(function() {
                                console.error(e);
                            });
                    },
                },
                callGridRowState: {
                    classifier: function(row, gridOptions) {
                        var pName = row.entity.name;
                        var perms = $scope.roleDetails.perms ? $scope.roleDetails.perms : [];
                        for (var i = 0; i < perms.length; i++) {
                            if (perms[i].name == pName) {
                                // Match
                                return 0;
                            }
                        }
                        // No match
                        return 1;
                    },
                    listener: function(rowEntity, newState) {
                        if (newState.name === "granted") {
                            // Grant permission
                            var perms = $scope.roleDetails.perms ? $scope.roleDetails.perms : [];
                            perms.push(rowEntity);
                            $scope.roleDetails.perms = perms;
                        } else {
                            // Deny permission
                            var perms = $scope.roleDetails.perms;
                            var index = -1;
                            for (var i = 0; i < perms.length; i++) {
                                if (perms[i].id === rowEntity.id) {
                                    index = i;
                                }
                            }
                            if (index > -1) {
                                perms.splice(index, 1);
                            }
                            $scope.roleDetails.perms = perms;
                        }
                    },
                    states: [
                        {
                            name: "granted",
                            template: '<i class="fa fa-check vertical-center"></i>'
                        },
                        {
                            name: "denied",
                            template: '<i class="fa fa-times vertical-center"></i>'
                        }
                    ]
                },

                onRegisterApi: function(gridApi) {
                    $scope.permissionGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function() {
                var r = true;

                var rows = $scope.roleGrid.api.selection.getSelectedRows();
                if(rows.length == 0)
                    r = false;

                return r;
            };
            $scope.removeSelected = function() {
                $scope.roleGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.setPermissionTypeOptions = function(){
                $scope.permissionTypes = [];
                var types = Permissions.getTypes();
                for(var i = 0; i < types.length; i++){
                    $scope.permissionTypes.push({'value': types[i], 'label': types[i]});
                }
                for(i = 0; i < $scope.permissionGrid.columns.length; i++){
                    if($scope.permissionGrid.columns[i].name == 'permission_type'){
                        $scope.permissionGrid.columns[i].filter.selectOptions = $scope.permissionTypes;
                    }
                }
            };
        },
    ]
);