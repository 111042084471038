'use strict';

angular.module('api').factory('Vehicles', [
    '$q',
    '$http',
    'clApi',
    function ($q, $http, api) {

        var Vehicles = {
            VEHICLE_STATUS: {
                NOT_ACTIVE: 'not_active',
                ACTIVE: 'active',
                STOLEN: 'stolen',
            },

            CONDITION: {
                HEALTHY: 'healthy',
                DEFECT: 'defect',
                KEY_MISSING: 'key_missing',
            },

            RENTING_STATUS: {
                IN_SERVICE: 'in_service',
                IN_LOCK_AVAILABLE: 'in_lock_available',
                RENTED_WITH_VOUCHER: 'rented_with_voucher',
                RENTED_WITH_IDENTIFIER: 'rented_with_identifier',
                IN_LOCK_KEY_MISSING: 'in_lock_key_missing',
                TAKEN_BY_OPERATOR: 'taken_by_operator'
            },

            parameters: [
                'label',
                'vehicleType',
                'droppointLabel',
                'status',
                'condition',
                'renting_status',
                'updated_at',
                'vehicle_id',
                'tag',
                'serial',
                'brand_prefix',
                'created_at',
                // 'created', old API
                // 'last_changed', old API
                // 'tenant_id',
                // 'zone_id',
                // 'stand_id',
                // 'chain_id',
                // 'lock_id',
                // 'since', old API
                // 'user_identifier_id', old API
                // 'key_tag', old API
                // 'voucher_id', old API
            ],

            getParameters: function () {
                return this.parameters;
            },

            getVehicles: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('vehicles', params, options).then(function (result) {
                    return result.data;
                });
            },

            getVehicle: function (id, params) {
                return api.get('vehicles/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            getRecentRentals: function (params) {
                return api.get('vehicles/' + params.vehicle_id + '/getRecentRentals', params);
            },

			getRentStartCosts: function(params) {
				return api.post('vehicles/startCost', params);
			},

            attachLocks: function(vehicleId, lockDeviceIds) {
                if(angular.isArray(lockDeviceIds))
                    lockDeviceIds = lockDeviceIds.join(',');

                return api.post('vehicles/' + vehicleId + '/attachLocks', {
                    lock_device_ids: lockDeviceIds
                }).then(function(result) {
                    return result.data;
                });            },

            create: function(data) {
                data.vehicle_type_id = 1;
                return api.post('vehicles', data).then(function(result) {
                    return result.data;
                });
            },

            update: function (id, data) {
                return api.put('vehicles/' + id, data);
            },

            delete: function (vehicleIds) {
                var promises = [];
                vehicleIds.forEach(function (id) {
                    promises.push(api.delete('vehicles/' + id).then(function(result) {
                        return result.data;
                    }));
                });

                return $q.all(promises);
            },

			createEKey: function(id, data, params) {
				return api.post('vehicles/' + id + "/ekey", data, params).then(function (result) {
					return result.data.result;
				});
			},

            setLocation: function(id, droppoint_device_id, tenantId){
                var data = {
                    'vehicle_id': id,
                    'droppoint_device_id': droppoint_device_id,
                    'tenant_id': tenantId
                }
                return api.post('vehicles/setLocation', data).then(function(result){
                    return result.data;
                });
            },
			updateLockDevice: function(id, status, batteryLevel) {
            	return api.post('vehicles/updateLockDevice', {
            		vehicle_id: id,
					status: status,
					battery_level: batteryLevel
				}).then(function(result) {
					return result.data;
				});
			},

            getTypes: function(){
                return api.get('vehicles/types').then(function(result){
                    return result.data;
                });
            }
        };

        return Vehicles;
    },
]);
