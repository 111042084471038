'use strict';

angular.module('api').factory('Tariffs', [
    'clApi',
    '$q',
    function (api, $q) {
        var cache = api.getOrCreateSessionCache('Tariffs');

        var weekdayArray = [
            {
                id: 0,
                name: 'sunday'
            },
            {
                id: 1,
                name: 'monday'
            },
            {
                id: 2,
                name: 'tuesday'
            },
            {
                id: 3,
                name: 'wednesday'
            },
            {
                id: 4,
                name: 'thursday'
            },
            {
                id: 5,
                name: 'friday'
            },
            {
                id: 6,
                name: 'saturday'
            }
        ];

        return {
            parameters: [
                'origin_id',
                'destination_id',
                'start_time',
                'end_time',
                'weekday',
                'end_date',
                'vehicleType',
                'tariff_id',
                'origin_type',
                'destination_type',
                'bluetooth_fine',
                'max_cost_per_day',
                'start_date',
            ],

            entryParameters: [
                'tariff_time_entry_id',
                'start_minute',
                'cost_per_step',
                'step',
                'entry_owner_id',
            ],
            
            bookingParameters: [
                'origin_id',
                'start_time',
                'end_time',
                'weekday',
                'end_date',
                'vehicleType',
                'booking_type_id',
                'max_cost_per_day',
                'start_date',
                'tariff_id',
                'origin_type',
            ],

            ORIGIN_TYPES: {
                TENANT: 'App\\\\Tenant',
                ZONE: 'App\\\\Zone',
                DROPPOINT: 'App\\\\Droppoint',
            },

            getEntryParameters: function () {
                return this.entryParameters;
            },
            
            getBookingParameters: function () {
                return this.bookingParameters;
            },

            getParameters: function () {
                return this.parameters;
            },
            
            getWeekdays: function (){
                return weekdayArray;
            },

            getTariffs: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('tariffs/rentals', params, options).then(function (result) {
                    var tariffs = result.data;
                    for(var i = 0; i < tariffs.data.length; i++){
                        if(tariffs.data[i].valid_period) {
                            tariffs.data[i] = setValidity(tariffs.data[i]);
                        }
                    }
                    return tariffs;
                });
            },

            getTariff: function (id) {
                return api.get('tariffs/rentals/' + id).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('tariffs/rentals/' + id, params).then(function (result) {
                    result = result.data;
                    if(result.valid_period) {
                        result = setValidity(result);
                    }
                    return result;
                });
            },

            create: function (params) {
                params.bluetooth_fine = 10;
                return api.post('tariffs/rentals', params).then(function (result) {
                    result = result.data;
                    if(result.valid_period) {
                        result = setValidity(result);
                    }
                    return result;
                });
            },
            
            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('tariffs/rentals/' + id));
                });

                return $q.all(promises);
            },

            getEntries: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('tariffs', params, options).then(function (result) {
                    return result.data;
                });
            },

            createEntry: function (params) {
                return api.post('tariffs', params).then(function (result) {
                    return result.data;
                });
            },

            updateEntry: function (id, params) {
                return api.put('tariffs/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            deleteEntries: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('tariffs/' + id));
                });

                return $q.all(promises);
            },

            getBookingTariffs: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('tariffs/bookings', params, options).then(function (result) {
                    var tariffs = result.data;
                    for(var i = 0; i < tariffs.data.length; i++){
                        if(tariffs.data[i].valid_period) {
                            tariffs.data[i] = setValidity(tariffs.data[i]);
                        }
                    }
                    return tariffs;
                });
            },

            getBookingTariff: function (id) {
                return api.get('tariffs/bookings/' + id).then(function (result) {
                    return result.data;
                });
            },

            updateBookingTariff: function (id, params) {
                return api.put('tariffs/bookings/' + id, params).then(function (result) {
                    result = result.data;
                    if(result.valid_period) {
                        result = setValidity(result);
                    }
                    return result;
                });
            },

            createBookingTariff: function (params) {
                params.booking_type_id = 4;
                return api.post('tariffs/bookings', params).then(function (result) {
                    result = result.data;
                    if(result.valid_period) {
                        result = setValidity(result);
                    }
                    return result;
                });
            },

            deleteBookingTariff: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('tariffs/bookings/' + id));
                });

                return $q.all(promises);
            },
        };

        function setValidity(tariff){
            tariff.start_time = tariff.valid_period.start_time;
            tariff.end_time = tariff.valid_period.end_time;
            tariff.start_date = tariff.valid_period.start_date;
            tariff.end_date = tariff.valid_period.end_date;
            tariff.weekday = [];
            for(var j = 0; j < tariff.valid_period.weekday.length; j++){
                if(tariff.valid_period.weekday[j] == '1'){
                    tariff.weekday.push(weekdayArray[j]);
                }
            }
            return tariff;
        }
    },
]);
