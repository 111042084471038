angular.module("2cp").directive("callGridNoData", ["$compile",
    function ($compile) {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function ($scope, iElement, iAttributes, uiGridCtrl) {

                var expression = iAttributes['callGridNoData'];
                // var template = '<div class="no-rows" ><div class="msg" ng-show="' + expression + '"><span>No Rows</span></div></div>';
                var template = '<div class="msg" ng-show="' + expression + '"><span>No Data</span></div>';
                template = $compile(template)($scope);
                iElement.append(template);
            }
        };
    }]);