angular.module("2cp").controller("ForgotPasswordController",
    [
        "$scope", "Users",
        function ($scope, Users) {
            $scope.data = { email: '' };

            $scope.send = function() {
                $scope.sending = true;
                $scope.message = null;
                Users.forgotPasswordEmail($scope.data.email).then(function(result) {
                    $scope.message = 'auth.passwordResetSent';
                }).catch(function(e) {
                    $scope.message = 'auth.passwordResetSendingFailed';
                }).finally(function() {
                    $scope.sending = false;
                });
            }
        }
    ]
);