angular.module("2cp").controller("app.faq",
    [
        "$scope", "$q", "$timeout", "$filter",
        "Faq", "FaqColumns", "Permissions", '$translate',
        function ($scope, $q, $timeout, $filter, Faq, FaqColumns, Permissions, $translate) {
            // Popup FAQ modal
            $scope.showFAQForm = function(rowEntity) {
                $timeout(function() {
                    $scope.faqDetails = rowEntity;
                    $("#FAQModal").foundation('open');

                    $scope.FAQGrid.api.grid.clearAllFilters();
                    // $scope.FAQGrid.api.callGridFiltering.setGridFilters({
                    //     label: rowEntity.label
                    // }, true);
                }, 0);
            };

            // Popup FAQ creation modal
            $scope.showFAQCreationForm = function(rowEntity) {
                $timeout(function() {
                    $scope.faqDetails = rowEntity;
                    $("#FAQCreationModal").foundation('open');

                    $scope.FAQGrid.api.grid.clearAllFilters();
                    // $scope.FAQGrid.api.callGridFiltering.setGridFilters({
                    //     label: rowEntity.label
                    // }, true);
                }, 0);
            };

            // Save update to Q&A pair
            $scope.save = function() {
                $scope.FAQGrid.api.rowEdit.setRowsDirty([$scope.faqDetails]);
                $('.reveal').foundation('close')
            };

            // Add new Q&A pair
            $scope.add = function() {
                $scope.FAQGrid.api.callGridEditing.addRow($scope.faqDetails);
                $('.reveal').foundation('close')
            };

            $scope.removeSelected = function() {
                $scope.FAQGrid.api.callGridEditing.removeSelectedRows();
            };


            // Grid options
            $scope.gridOptions = {
                columnDefs: FaqColumns.build(),
                callGridData: {
                    id: "faq_id",
                    get: function (params) {
                        return Faq.getFaq(params);
                    },
                    update: function(id, data) {
                        return Faq.update(id, data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.faq_update_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    },
                    create: function(data) {
                        return Faq.create(data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.faq_create_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    },
                    delete: function(ids) {
                        return Faq.delete(ids)
                            .then(function(result) {
                                $.notify($translate.instant('notify.faq_delete_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    }

                },
                callGridEditIcon: {
                    clickCallback: $scope.showFAQForm
                },
                onRegisterApi: function(gridApi) {
                    $scope.FAQGrid = gridApi.grid;
                }
            };
        }]
);