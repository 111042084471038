angular.module("2cp").controller("tenants.users",
    [
        "$scope", "$timeout", "Users", "UserColumns", "$filter", "ColumnDefBuilder",
        "Payments", "PaymentColumns", "CSV", "$window", "Tenants", "RentalColumns", "Rentals",
        "PermissionService", "Vehicles", "Address", 'Subscriptions', 'UserSubscriptionsColumns',
        '$translate', 'VehicleTypes',
        function ($scope, $timeout, Users, UserColumns, $filter, ColumnDefBuilder,
                  Payments, PaymentColumns, CSV, $window, Tenants, RentalColumns, Rentals,
                  PermissionService, Vehicles, Address, Subscriptions, UserSubscriptionsColumns,
                  $translate, VehicleTypes) {
            $scope.userDetails = {};
            $scope.blockStatus = Users.BLOCK_STATUS;
            $scope.userTypes = Users.USER_TYPE;
            $scope.subscriptionTypes = Users.SUBSCRIPTION_TYPE;
            $scope.genders = Users.GENDER_TYPE;
            $scope.vehicleTypes = [];
            $scope.settingDetails = {};
            $scope.subscriptions = [];
            $scope.countries = Address.getCountries();
            $scope.mayUpdateSettings = PermissionService.hasPermissions(['updateUsers']);
            $scope.mayGiveSubscriptions = PermissionService.hasPermissions(['giveSubscriptions']);
            $scope.inheritOptions = [{value: true, label: 'yes'}, {value: false, label: 'no'}];
            $scope.paymentOptions = Users.PAYMENT_OPTIONS;

            if (PermissionService.hasPermissions(['viewSubscriptions'])) {
                Subscriptions.getSubscriptions().then(function (result) {
                    $scope.subscriptions = result.data;
                });
            }

            VehicleTypes.getVehicleTypes({'limit': 100}).then(function(response){
                $scope.vehicleTypes = response.data;
            });

            $scope.showCreateUserForm = function () {
                $timeout(function () {
                    $scope.newUser = true;
                    $scope.userDetails = {};
                    $scope.addressDetails = {};
                    $("#detailsTabButton").click();
                    $("#userDetailsModal").foundation('open');
                }, 0);
            };

            $scope.showBlockButton = function () {
                var r = true;

                var rows = $scope.userGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };
            $scope.showDeleteButton = function () {
                var r = true;

                var rows = $scope.userGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };
            $scope.showUnregisterButton = function () {
                var r = true;

                var rows = $scope.userGrid.api.selection.getSelectedRows();
                if (rows.length != 1)
                    r = false;

                return r;
            };
            $scope.showSubscribeButton = function () {
                var r = true;

                var rows = $scope.userGrid.api.selection.getSelectedRows();
                if (rows.length != 1)
                    r = false;

                return r;
            };

            $scope.showUserDetailsForm = function (rowEntity) {
                $timeout(function () {
                    $scope.newUser = false;
                    $scope.userDetails = rowEntity;
                    if (PermissionService.hasPermissions(['viewSubscriptions'])) {
                        var params = {};
                        var now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                        params.user_id = '"' + $scope.userDetails.user_id + '"';
                        params.with = 'userSubscriptions';
                        params.userValidUntil_min = now;
                        params.userValidFrom_max = now;
                        Subscriptions.getSubscriptions(params).then(function (result) {
                            $scope.subscriptions = result.data;
                            $scope.subscriptionsGrid.api.callGridData.get();
                        });

                    }
                    $scope.addressDetails = rowEntity.address || {};
                    if (PermissionService.hasPermissions(['viewRentals'])) {
                        $scope.rentalsGrid.api.callGridData.get();
                    }
                    $("#userDetailsModal").foundation('open');

                    if (PermissionService.hasPermissions(['viewPayments'])) {
                        $scope.paymentGrid.api.grid.clearAllFilters();
                        $scope.paymentGrid.api.callGridFiltering.setGridFilters({
                            username: '"' + rowEntity.username + '"'
                        }, true);
                    }
                    Users.getSettings(rowEntity.user_id).then(function (response) {
                        $scope.settingDetails = response;
                        var vehicle_settings = [];
                        if(!response){
                            return;
                        }
                        for (var i = 0; i < $scope.vehicleTypes.length; i++) {
                            for (var j = 0; j < response.vehicle_settings.length; j++) {
                                if (response.vehicle_settings[j].vehicle_type_id == $scope.vehicleTypes[i].vehicle_type_id) {
                                    vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id] = response.vehicle_settings[j];
                                }
                            }
                        }

                        if ($scope.settingDetails.vouchers_only == null) {
                            $scope.voucherInherit = true;
                        } else {
                            $scope.voucherInherit = false;
                            $scope.settingDetails.vouchers_only = ($scope.settingDetails.vouchers_only == 1);
                        }

                        if ($scope.settingDetails.allow_free_floating == null) {
                            $scope.floatInherit = true;
                        } else {
                            $scope.floatInherit = false;
                            $scope.settingDetails.allow_free_floating = ($scope.settingDetails.allow_free_floating == 1);
                        }

                        $scope.settingDetails.blockedForAll = ($scope.userDetails.blocked == 1);
                        $scope.settingDetails.blocked = ($scope.settingDetails.blocked == 1);
                        $scope.settingDetails.vehicle_type_id = $scope.vehicleTypes[0].vehicle_type_id;
                        $scope.settingDetails.vehicle_settings = vehicle_settings;
                    });
                }, 0);
            };

            $scope.save = function () {
                if (!$scope.addressDetails.street && !$scope.addressDetails.house_number && !$scope.addressDetails.postal_code && !$scope.addressDetails.country && !$scope.addressDetails.state && !$scope.addressDetails.city) {
                    $scope.addressDetails.street = null;
                    $scope.addressDetails.house_number = null;
                    $scope.addressDetails.postal_code = null;
                    $scope.addressDetails.country = null;
                    $scope.addressDetails.state = null;
                    $scope.addressDetails.city = null;
                } else {
                    if ($scope.addressDetails.street && $scope.addressDetails.house_number && $scope.addressDetails.postal_code &&
                        $scope.addressDetails.country && $scope.addressDetails.state && $scope.addressDetails.city) {
                    } else {
                        $.notify($translate.instant('notify.address_all_fields'));
                        return;
                    }
                }

                //Merge address details into user details
                $scope.userDetails.street = $scope.addressDetails.street;
                $scope.userDetails.house_number = $scope.addressDetails.house_number;
                $scope.userDetails.postal_code = $scope.addressDetails.postal_code;
                $scope.userDetails.country = $scope.addressDetails.country;
                $scope.userDetails.state = $scope.addressDetails.state;
                $scope.userDetails.city = $scope.addressDetails.city;

                if ($scope.newUser)
                    $scope.userGrid.api.callGridEditing.addRow($scope.userDetails);
                else
                    $scope.userGrid.api.rowEdit.setRowsDirty([$scope.userDetails]);

                $('.reveal').foundation('close');
            };

            $scope.removeSelected = function () {
                var selectedRows = $scope.userGrid.api.selection.getSelectedRows();
                for (var i = 0; i < selectedRows.length; i++) {
                    var user = selectedRows[i];
                    if (user.user_id === 1) {
                        $.notify($translate.instant('notify.user_delete_admin_failed'), 'error');
                        return;
                    }
                    //
                    // if(!user.roles)
                    //     continue;
                    //
                    // for(var j = 0; j < user.roles.length; j++) {
                    //     if(user.roles[j].name === 'super-admin') {
                    //     }
                    // }
                }

                $scope.userGrid.api.callGridEditing.removeSelectedRows();
            };

            $scope.unregisterSelected = function () {
                var selectedRows = $scope.userGrid.api.selection.getSelectedRows();
                for (var i = 0; i < selectedRows.length; i++) {
                    Users.unregister(selectedRows[i].user_id).then(function () {
                        $.notify($translate.instant('notify.user_unregister_success'), 'success');
                    }).catch(function () {
                    });
                }
            };

            $scope.blockUser = function () {
                $("#blockModal").foundation('open');
            };

            $scope.blockForCurrent = function () {
                $scope.saving = true;
                var rows = $scope.userGrid.api.selection.getSelectedRows();
                rows.forEach(function (user) {
                    Users.blockForTenant(user.user_id).then(function () {
                        user.blockedForCurrent = Users.BLOCK_STATUS.BLOCKED;
                        $.notify($translate.instant('notify.user_block_current_success'), "success");
                        $scope.saving = false;
                    }).catch(function () {
                        $scope.saving = false;
                    });
                });
            };

            $scope.blockForAll = function () {
                $scope.saving = true;
                var rows = $scope.userGrid.api.selection.getSelectedRows();
                rows.forEach(function (user) {
                    user.blocked = Users.BLOCK_STATUS.BLOCKED;
                    $scope.userGrid.api.rowEdit.setRowsDirty([user]);
                    $scope.saving = false;
                });
            };

            /**
             * Grid for registering users with tenants
             */
            $scope.registerGridOptions = {
                columnDefs: UserColumns.build(),
                callGridData: {
                    id: "user_id",
                    get: function (params) {
                        params.with = 'settings';
                        return Users.getUsers(params).then(function (result) {
                            return result;
                        });
                    }
                },
                onRegisterApi: function (gridApi) {
                    $scope.registerGrid = gridApi.grid;
                }
            };

            $scope.registerWithTenant = function () {
                $scope.saving = true;
                var rows = $scope.registerGrid.api.selection.getSelectedRows();
                rows.forEach(function (user) {
                    Users.registerWithTenant(user.user_id).then(function () {
                        $.notify($translate.instant('notify.user_register_success'), "success");
                        $scope.saving = false;
                    }).catch(function () {
                        $scope.saving = false;
                    });
                });
            };


            $scope.gridOptions = {
                columnDefs: UserColumns.copy().build(),

                enableHorizontalScrollbar: 1,
                paginationPageSizes: [10, 20, 30],
                paginationPageSize: 10,
                //useExternalPagination: false,

                callGridEditIcon: {
                    clickCallback: $scope.showUserDetailsForm
                },

                callGridData: {
                    id: "user_id",
                    get: function (params) {
                        params.with = 'settings';
                        return Users.getUsers(params).then(function (result) {
                            return result;
                        });
                    },
                    create: function (data) {
                        data.register = true;
                        return Users.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.user_create_success'), "success");
                                return result;
                            });
                    },
                    update: function (id, data) {
                        return Users.update(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.user_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Users.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.user_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridIcon: {
                    iconClass: function (rowEntity) {
                        if (rowEntity.balance !== null) {
                            return 'fa-cc-stripe';
                        } else {
                            return '';
                        }
                    },
                    clickCallback: function (rowEntity) {
                        if (rowEntity.balance !== null) {
                            $window.open('https://dashboard.stripe.com/test/customers/' + rowEntity.provider_handle, '_blank');
                        } else {
                            $.notify($translate.instant('notify.user_no_payment_details'));
                        }
                    }
                },
                onRegisterApi: function (gridApi) {
                    $scope.userGrid = gridApi.grid;
                }
            };

            if (PermissionService.hasPermissions(['viewPayments'])) {
                $scope.paymentGridOptions = {
                    columnDefs: PaymentColumns.build(),

                    callGridData: {
                        id: "payment_id",
                        get: Payments.getPayments
                    },
                    callGridFiltering: {
                        updateUrl: false
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.paymentGrid = gridApi.grid;
                    }
                };
            }

            $scope.importCSV = function (file) {
                if (file === null)
                    return;

                var reader = new FileReader();
                reader.onload = function (e) {
                    var text = reader.result;
                    var items = CSV.parse(text);

                    Users.create({batch: items, register: true}).then(function (result) {
                        $.notify($translate.instant('notify.user_batch_create_success_1') + result.length + $translate.instant('notify.user_batch_create_success_2') + items.length, "success");
                        if (result.length < items.length)
                            $.notify($translate.instant('notify.user_batch_create_failed_2'), 'warn');

                        $scope.userGrid.api.callGridData.get();

                        return result;
                    }).catch(function (e) {
                        console.error(e);
                    });
                };
                reader.readAsText(file);

                //
                // $scope.f = file;
                // $scope.errFile = errFiles && errFiles[0];
                // if (file) {
                // 	Users.importCSV(file).then(function(result) {
                // 		$.notify("Imported CSV.", "success");
                // 		return result;
                // 	}).catch(function(e) {
                // 		$.notify("An error occurred, import failed");
                // 		console.error(e);
                // 	});
                // }
            };


            $scope.showCreatePaymentDetailsModal = function () {
                $("#createPaymentDetailsModal").foundation('open');
            };

            $scope.details = {
                user_id: null,
                tenant_id: null
            };

            $scope.createPaymentDetails = function () {
                var $form = $('#payment-form');
                $form.find('.submit').prop('disabled', true);
                Stripe.card.createToken($form, stripeResponseHandler);
            };

            function stripeResponseHandler(status, response) {
                var $form = $('#payment-form');
                if (response.error) { // Problem!
                    $.notify(response.error.message);
                    $form.find('.submit').prop('disabled', false);
                } else {
                    Users.createUserPaymentDetails($scope.details.user_id, $scope.details.tenant_id, response.id).then(function () {
                        $.notify($translate.instant('notify.user_payment_create_success'), "success");
                        $form.find('.submit').prop('disabled', false);
                    }).catch(function () {
                        $form.find('.submit').prop('disabled', false);
                    });
                }
            }

            $scope.showBalanceUpgradeModal = function () {
                $("#balanceUpgradeModal").foundation('open');
            };

            $scope.addBalance = function (user_id, tenant_id, amount) {
                Users.addBalance(user_id, tenant_id, amount).then(function () {
                    $.notify($translate.instant('notify.user_balance_add_success'), "success");
                }).catch(function () {
                });
            };

            $scope.userDetails.user_id = 0;

            if (PermissionService.hasPermissions(['viewRentals'])) {
                $scope.rentalsGridOptions = {
                    columnDefs: RentalColumns.build(),

                    callGridData: {
                        id: "rental_id",
                        get: function (params) {
                            params.user_id = $scope.userDetails.user_id;
                            return Rentals.getRentals(params)
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                    },
                    callGridFiltering: {
                        queryParameterPrefix: "rentals."
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.rentalsGrid = gridApi.grid;
                    }
                };
            }

            $scope.updateUserSettings = function () {
                var rows = $scope.userGrid.rows;
                for (var i = 0; i < rows.length; i++) {
                    if (rows[i].entity.user_id == $scope.userDetails.user_id) {
                        var user = rows[i].entity;
                        user.blocked = $scope.settingDetails.blockedForAll;
                        user.blockedForCurrent = $scope.settingDetails.blocked;
                        break;
                    }
                }
                $scope.userGrid.api.rowEdit.setRowsDirty([user]);
                for (i = 0; i < $scope.vehicleTypes.length; i++) {
                    updateSettings($scope.settingDetails, $scope.settingDetails.vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id]);
                }
            };

            function updateSettings(settings, vehicleSettings) {
                var sets = {};
                angular.extend(sets, settings);
                sets.max_booked = vehicleSettings.max_booked;
                sets.max_rented = vehicleSettings.max_rented;
                sets.vehicle_type_id = vehicleSettings.vehicle_type_id;
                if ($scope.floatInherit) {
                    sets.allow_free_floating = null;
                }
                if ($scope.voucherInherit) {
                    sets.vouchers_only = null;
                }
                Users.updateSettings($scope.userDetails.user_id, sets).then(function () {
                    $.notify($translate.instant('notify.settings_update_success'), 'success');
                }).catch(function () {
                });
            }

            tooltips();

            function tooltips() {
                $('.tooltip-label').hover(function () {
                    // Hover over code
                    var title = $(this).attr('title');
                    $(this).data('tipText', title).removeAttr('title');
                    $('<p class="tooltip"></p>')
                        .text(title)
                        .appendTo('body')
                        .fadeIn('slow');
                }, function () {
                    // Hover out code
                    $(this).attr('title', $(this).data('tipText'));
                    $('.tooltip').remove();
                }).mousemove(function (e) {
                    var mousex = e.pageX + 20; //Get X coordinates
                    var mousey = e.pageY + 10; //Get Y coordinates
                    $('.tooltip')
                        .css({top: mousey, left: mousex, 'z-index': 99999999})
                });
            }

            if (PermissionService.hasPermissions(['viewSubscriptions'])) {
                $scope.subscriptionsGridOptions = {
                    columnDefs: UserSubscriptionsColumns.addOptionsByName({
                        userValidFrom: {
                            enableFiltering: false,
                            enableSorting: false
                        },
                        userValidUntil: {
                            enableFiltering: false,
                            enableSorting: false
                        },
                        userRenew: {
                            enableFiltering: false,
                            enableSorting: false
                        }
                    }).build(),

                    callGridData: {
                        id: "subscription_id",
                        get: function (params) {
                            var now = moment.utc().format('YYYY-MM-DD HH:mm:ss');
                            params.valid_until_min = now;
                            params.valid_from_max = now;
                            return Subscriptions.getSubscriptions(params)
                                .then(function (result) {
                                    return result.data;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                }).finally(function () {
                                    $timeout(function () {
                                        $scope.subscriptionsGrid.options.data.forEach(function (subscription) {
                                            for(var i = 0; i < $scope.subscriptions.length; i++){
                                                if(subscription.subscription_id === $scope.subscriptions[i].subscription_id){
                                                    $scope.subscriptionsGrid.api.selection.selectRow(subscription);
                                                    ['userValidUntil', 'userValidFrom', 'userRenew'].forEach(function(property){
                                                        subscription[property] = $scope.subscriptions[i][property];
                                                    });
                                                }
                                            }
                                        });
                                    }, 0);
                                });
                        },
                    },
                    callGridFiltering: {
                        queryParameterPrefix: "subscriptions."
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.subscriptionsGrid = gridApi.grid;
                    }
                };
            }

            $scope.giveSubscriptions = function () {
                var selectedIds = [];
                var selectedRows = $scope.subscriptionsGrid.api.selection.getSelectedRows();
                var idKey = $scope.subscriptionsGrid.options.callGridData.id;
                selectedRows.forEach(function (row) {
                    selectedIds.push(row[idKey]);
                });
                Users.setSubscriptions($scope.userDetails.user_id, moment.utc().format('YYYY-MM-DD HH:mm:ss'), selectedIds).then(function () {
                    $.notify($translate.instant('notify.user_subscriptions_success'), "success");
                }).catch(function () {
                });
            }

        }
    ]
);