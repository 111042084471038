angular.module("2cp")
    .filter('chainIdToLabel', ["Chains", function (Chains) {
        var waiting = false;
        var valuesArray = [];

        function labelFilter(value) {
            if (value === null || typeof(value) === "undefined") {
                return "";
            }

            var lbl = value;
            if (value in valuesArray) {
                lbl = valuesArray[value].short_description;
            }
            else {
                if (waiting === false) {
                    waiting = true;
                    Chains.getChain(value).then(function (result) {
                        if (!(value in valuesArray)) {
                            valuesArray[value] = result;
                        }
                        waiting = false;
                    });
                }
            }
            return lbl;
        }

        labelFilter.$stateful = true;
        return labelFilter;
    }]);