angular.module("2cp")
// Filter for time
    .filter('commonSeconds', function (Vouchers) {
        return function (seconds) {
            return seconds + " s";
        };
    })

    .filter('tenantdate', ['Times', function (Times) {
        return function (date, format) {
            // Here we convert the date to the tenant's timezone (the date changes
            // accordingly). The output is a string.
            var converted = Times.getTenantDateTimeString(date, Times.getZone());
            if (!converted) {
                return '';
            }
            // Then we format this date to the desired format
            return new moment(converted).format(format);
        }
    }])

    .filter('rentaldate', ['Times', function (Times) {
        return function (date, format) {
            // Here we convert the date to the tenant's timezone (the date changes
            // accordingly). The output is a string.
            var converted = Times.getTenantDateTimeString(date, Times.getZone());
            if (converted == 'Invalid date') {
                return 'Rental in progress';
            }
            // Then we format this date to the desired format
            return new moment(converted).format(format);
        }
    }])

    .filter('countryFilter', function () {
        return function (input) {
            return input;
        }
    })

    .filter('voucherDate', ['Times', function (Times) {
        return function (date, format) {
            // Here we convert the date to the tenant's timezone (the date changes
            // accordingly). The output is a string.
            var converted = Times.getTenantDateTimeString(date, Times.getZone());
            if (converted == 'Invalid date' || date === null) {
                return 'No expiration date';
            }
            // Then we format this date to the desired format
            return new moment(converted).format(format);
        }
    }])

    .filter('empty', function(){
        return function(input){
            return input;
        }
    })

    .filter('utcdate', function () {
        return function (date) {
            // Here we swap the browser's timezone out for the tenant's timezone,
            // without changing the date itself.
            var tenantDate = forceTimezone(date)

            // Here we convert the date in the tenant's timezone to UTC time,
            // and the date changes accordingly.
            var utcDate = convertToUTC(tenantDate)

            // Then, we format the moment object to ISO format.
            var isoDate = utcDate.format()

            // And finally, we replace the "+00:00" by a "Z"
            return isoDate.replace(/\+.*/, "Z")
        }
    })

    .filter('unsafe', ["$sce", function ($sce) {
        function filter(value) {
            return $sce.trustAsHtml(value);
        }

        return filter;
    }])
    .filter('percentage', [function () {
        return function (value) {
            if (value !== null) {
                return value + '%';
            } else {
                return 'Unknown';
            }
        };
    }]);

/**
 * Set the tenant's timezone
 * @param zone any name in moment.tz.names()
 */
function setZone(zone) {
    window.tenant_zone = zone
}

/**
 * Get tenant's timezone
 * @returns {string}
 */
function getZone() {
    return window.tenant_zone || "UTC"
}

/**
 * Convert a date to the tenant's timezone
 * @param date
 * @returns {moment object}
 */
function convertToTenantTimezone(date) {
    return moment.tz(date, getZone())
}

/**
 * Convert a date to UTC time
 * @param date
 * @returns {moment object}
 */
function convertToUTC(date) {
    return moment.tz(date, "UTC")
}

/**
 * Changes the timezone offset of the date without changing the date itself.
 * @param date {date object}
 */
function forceTimezone(date) {
    var clean = moment(date).format().replace("T", " ").replace(/\+.*/g, "")
    return moment.tz(clean, getZone())
}

