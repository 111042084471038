/**
 * Toggle all checkboxes in group.
 *
 * @param source The source checkbox
 * @param group The name of the group
 */
function toggle(source, selector) {
    var checked = source.checked;
    $(selector).each(function(i, e) {
        e.checked = checked;
    });
}