angular.module("2cp")
    .filter('tenantIdToLabel', ["Tenants", function (Tenants) {
        var waiting = false;
        var valuesArray = [];

        function labelFilter(value) {
            if (value === null || typeof(value) === "undefined") {
                return "";
            }

            var lbl = value;
            if (value in valuesArray) {
                lbl = valuesArray[value].short_name;
            }
            else {
                if (waiting === false) {
                    waiting = true;
                    Tenants.getTenant(value).then(function (result) {
                        if (!(value in valuesArray)) {
                            valuesArray[value] = result;
                        }
                        waiting = false;
                    });
                }
            }
            return lbl;
        }

        labelFilter.$stateful = true;
        return labelFilter;
    }])
    .filter('currencies', function () {
        return function(input, scope){
            return scope.row.entity.currency.name;
        }
    });