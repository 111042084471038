angular.module("2cp").controller("tenants.lockdevices",
    [
        "$scope", "$timeout", "$window", "LockDevices", "Chains", "Zones", "$translate", "PermissionService",
        "LockColumns", "CSV", "Maintenance", "MaintenanceColumns",
        function ($scope, $timeout, $window, LockDevices, Chains, Zones, $translate, PermissionService,
                  LockColumns, CSV, Maintenance, MaintenanceColumns) {
            $scope.lockDeviceGridOptions = {
                columnDefs: LockColumns.build(),

                callGridData: {
                    id: "lock_device_id",
                    get: function(data){
                        return LockDevices.getLocks(data).then(function(response){
                            if (!PermissionService.hasPermissions(['updateLockDevices'])) {
                                $scope.lockDeviceGrid.api.callGridEditing.disableCellEdits();
                            }
                            return response;
                        })
                    },
                    create: function(data) {
                        data.claim_code = data.device.lock_brand.claim_code;
                        data.uid = data.device.bluetooth_device.uid;
                        $('.reveal').foundation('close');
                        return LockDevices.create(data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.lock_device_create_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    },
                    update: function(id, data) {
                        data.claim_code = data.device.lock_brand.claim_code;
                        data.uid = data.device.bluetooth_device.uid;
                        return LockDevices.update(id, data)
                            .then(function(result) {
                                $.notify($translate.instant('notify.lock_device_update_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    },
                    delete: function(ids) {
                        return LockDevices.delete(ids)
                            .then(function(result) {
                                $.notify($translate.instant('notify.lock_device_delete_success'), "success");
                                return result;
                            })
                            .catch(function(e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function(rowEntity) {
                        $scope.showDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "lockdevices."
                },
                onRegisterApi: function(gridApi) {
                    $scope.lockDeviceGrid = gridApi.grid;
                }
            };
            $scope.lockDeviceTypes = LockDevices.LOCK_DEVICE_TYPES;

            $scope.showDeleteButton = function() {
                var r = true;

                var rows = $scope.lockDeviceGrid.api.selection.getSelectedRows();
                if(rows.length == 0)
                    r = false;

                return r;
            };
            $scope.removeSelected = function() {
                $scope.lockDeviceGrid.api.callGridEditing.removeSelectedRows();
            };

            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showDetailsModal = function(creationModal, rowEntity) {
                $scope.lockDeviceDetails = rowEntity;
                $scope.lockDeviceCreationModal = creationModal;
                if(!creationModal) {
                    $scope.maintenanceGrid.api.callGridFiltering.setGridFilters({
                        lockDeviceLabel: rowEntity.label
                    }, true);
                }

                $("#lockDevicesDetailsModal").foundation('open');
            };

            $scope.detailsUpdated = function(updatedRow) {
                $('.reveal').foundation('close')

                if($scope.lockDeviceCreationModal)
                    $scope.lockDeviceGrid.api.callGridEditing.addRow(updatedRow);
                else
                    $scope.lockDeviceGrid.api.rowEdit.setRowsDirty([updatedRow]);
            };

            $scope.importCSV = function(file) {
                if(file === null)
                    return;

                var reader = new FileReader();
                reader.onload = function(e) {
                    var text = reader.result;
                    var items = CSV.parse(text);


                    LockDevices.create({batch: items}).then(function(result) {
                        $.notify($translate.instant('notify.lock_device_batch_create_success_1') + result.length + $translate.instant('notify.lock_device_batch_create_success_2') + items.length, "success");
                        if(result.length < items.length)
                            $.notify($translate.instant('notify.lock_device_batch_create_failed_2'), 'warn');

                        return result;
                    }).catch(function(e) {
                    });
                };
                reader.readAsText(file);
            };

            if(PermissionService.hasPermissions(['viewTasks'])) {
                $scope.maintenanceGridOptions = {
                    columnDefs: MaintenanceColumns.build(),
                    callGridData: {
                        id: "maintenance_id",
                        get: function (params) {
                            return Maintenance.getTasks(params).then(function (result) {
                                result.data.forEach(function (item) {
                                    if (item.remarks.length === 0)
                                        return;

                                    item.resolution = item.remarks[item.remarks.length - 1].remark;
                                });
                                return result;
                            });
                        },
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.maintenanceGrid = gridApi.grid;
                    }
                };
            }
        }
    ]
);