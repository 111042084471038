'use strict';

angular.module('api').factory('Users', [
    '$q',
    'clApi',
    'clApiConfig',
    function ($q, api, ApiConfig) {
        var activeCache = api.getOrCreateSessionCache('ActiveUser', 10000);
        var cache = api.getOrCreateSessionCache('Users');

        return {
            BLOCK_STATUS: {
                NOT_BLOCKED: 0,
                BLOCKED: 1,
            },

            LIVEMODES: {
                LIVE: 1,
                TEST: 0
            },

            REGISTERED: {
                NOT_REGISTERED: 0,
                REGISTERED: 1,
            },

            USER_TYPE: {
                UNKNOWN: 0,
                PERSONAL: 1,
                BUSINESS: 2,
                TEMPORARY: 3,
            },

            GENDER_TYPE: {
                MALE: 'm',
                FEMALE: 'f',
                TRANSGENDER: 't'
            },

            SUBSCRIPTION_TYPE: {
                UNKNOWN: 0,
                PREPAID_MANUAL_UPGRADE: 1,
                PREPAID_AUTO_UPGRADE: 2,
                ON_CREDIT_PAYMENT: 3,
                EXTERNAL_PAYMENT: 4,
            },

            PAYMENT_OPTIONS: [
                'balance',
                'after_pay',
                'on_credit'
            ],

            parameters: [
                'created_at',
                'username',
                'first_name',
                'last_name',
                'postal_code',
                'company',
                'balance',
                'blockedForCurrent',
                'address_id',
                'birth_date',
                'blocked',
                'city',
                'city_id',
                'country',
                'deleted_at',
                'email',
                'gender',
                'house_number',
                'initials',
                'livemode',
                'mobile_number',
                'passport',
                'preposition',
                'registered',
                'state',
                'state_id',
                'street',
                'telephone_number',
                'user_id',
                'updated_at',
                'vat_number'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getActive: function (params, options) {
                return api.get('users/current', params, options, false).then(function (result) {
                    return result.data;
                });
            },

            getUsers: function (params, options) {
                options = api.createIfNotExist(options);
                options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('users', params, options).then(function (result) {
                    var users = result.data;
                    for (var i = 0; i < users.data.length; i++) {
                        if (users.data[i].tenant_user_settings && users.data[i].tenant_user_settings[0]) {
                            users.data[i].blockedForCurrent = users.data[i].tenant_user_settings[0].blocked;
                            users.data[i].registered = users.data[i].tenant_user_settings[0].registered === 1;
                        }else{
                            users.data[i].registered = false;
                        }
                        if(users.data[i].balance === null) {
                            if (users.data[i].user_payment_details && users.data[i].user_payment_details[0]) {
                                users.data[i].balance = users.data[i].user_payment_details[0].balance;
                            } else {
                                users.data[i].balance = null;
                            }
                        }
                    }
                    return users;
                });
            },

            getUser: function (id, params) {
                return api.get('users/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('users/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            forgotPasswordEmail: function (email, tenant_id, params) {
                params = api.createIfNotExist(params);
                params.email = email;
                params.tenant_id = tenant_id;
                return api.post('forgotPasswordEmail', params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('users', params).then(function (result) {
                    return result.data;
                });
            },

            getOperators: function (params) {
                return api.get('users/operators', params).then(function (result) {
                    for (var i = 0; i < result.data.length; i++) {
                        if (result.data[i].address) {
                            result.data[i].street = result.data[i].address.street;
                            result.data[i].country = result.data[i].address.country;
                            result.data[i].house_number = result.data[i].address.house_number;
                            result.data[i].state = result.data[i].address.state;
                            result.data[i].postal_code = result.data[i].address.postal_code;
                            result.data[i].city = result.data[i].address.city;
                        }
                    }
                    return result.data;
                });
            },

            getPotentialOperators: function (params) {
                return api.get('users/potentialOperators', params).then(function (result) {
                    return result.data;
                });
            },

            createOperator: function (params) {
                return api.post('users/operators', params).then(function (result) {
                    return result.data;
                });
            },
            updateOperator: function (id, params) {
                return api.put('users/operators/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            demoteOperator: function (userIds) {
                var promises = [];
                userIds.forEach(function (id) {
                    promises.push(api.put('users/' + id + '/demoteOperator'));
                });

                return $q.all(promises);
            },

            promoteOperator: function (userIds) {
                var promises = [];
                userIds.forEach(function (id) {
                    promises.push(api.put('users/' + id + '/promoteToOperator'));
                });

                return $q.all(promises);
            },

            delete: function (userIds) {
                var promises = [];
                userIds.forEach(function (id) {
                    promises.push(api.delete('users/' + id));
                });

                return $q.all(promises);
            },

            createUserPaymentDetails: function (user_id, tenant_id) {
                var params = {
                    user_id: user_id,
                    tenant_id: tenant_id
                };
                return api.post('users/paymentDetails', params).then(function (result) {
                    return result.data;
                });
            },

            addBalance: function (user_id, tenant_id, amount, source, ideal) {
                var params = {
                    user_id: user_id,
                    tenant_id: tenant_id,
                    amount: amount,
                    source: source,
					ideal: ideal
                };
                return api.post('users/addBalance', params).then(function (result) {
                    return result.data;
                });
            },

            getSettings: function (user_id) {
                return api.get('users/' + user_id + '/settings').then(function (result) {
                    return result.data;
                });
            },

            updateSettings: function (user_id, params) {
                return api.put('users/' + user_id + '/settings', params).then(function (result) {
                    return result.data;
                });
            },

            blockForTenant: function (user_id) {
                return api.put('users/' + user_id + '/blockForTenant').then(function (result) {
                    return result.data;
                });
            },

            registerWithTenant: function (user_id, tenant_id) {
                var params = {
                    user_id: user_id,
                    tenant_id: tenant_id
                };
                return api.post('users/registerWithTenant', params).then(function (result) {
                    return result.data;
                });
            },

            addSubscription: function (user_id, start_date, subscription_id, tenant_id, source) {
                var params = {
                    user_id: user_id,
                    start_date: start_date,
                    subscription_id: subscription_id,
                    source: source,
                    tenant_id: tenant_id
                };
                return api.post('users/addSubscription', params).then(function (result) {
                    return result.data;
                });
            },

            setSubscriptions: function (user_id, start_date, subscription_ids) {
                var params = {
                    user_id: user_id,
                    start_date: start_date,
                    subscription_ids: subscription_ids
                };
                return api.post('users/setSubscriptions', params).then(function (result) {
                    return result.data;
                });
            },

            setRenew: function(user_id, tenant_id, subscription_id, renew){
                var params = {
                    user_id: user_id,
                    tenant_id: tenant_id,
                    subscription_id: subscription_id,
                    renew: renew
                };
                return api.post('users/setRenew', params).then(function(result){
                    return result.data;
                });
            },

            cancelSubscription: function(user_id, tenant_id, subscription_id) {
                var params = {
                    user_id: user_id,
                    tenant_id: tenant_id,
                    subscription_id: subscription_id
                };
                return api.post('users/cancelSubscription', params).then(function (result) {
                    return result.data;
                });
            },

            getRegisteredUsers: function(tenant_id){
                return api.get('users/registeredUsers', {tenant_id: tenant_id}).then(function(result){
                    return result.data;
                });
            },

			resendEmail: function(username, tenant_id){
            	return api.get('user/' + username + '/resend?tenant_id=' + tenant_id).then(function(result){
            		return result.data;
				})
			},

            unregister: function(user_id, data){
                if(!data){
                    data = {};
                }
                data.user_id = user_id;
                return api.post('users/unregister', data).then(function(result){
                    return result.data;
                });
			},

			attachCard: function(user_id, tenant_id, source){
            	var data = {
            		'user_id': user_id,
					'tenant_id': tenant_id,
					'source': source
				};
				return api.post('users/attachCard', data).then(function(result){
					return result.data;
				});
			},

			removeCard: function(user_id, tenant_id, card_id){
            	var data = {
            		'user_id': user_id,
					'tenant_id': tenant_id,
					'card_id': card_id
				};
				return api.post('users/removeCard', data).then(function(result){
					return result.data;
				});
			},

			getCards: function(user_id, tenant_id){
				return api.get('users/' + user_id + '/getCards?tenant_id=' + tenant_id).then(function(result){
					return result.data;
				});
			},

            checkCustomerId: function(user_id){
			    return api.get('users/' + user_id + '/checkCustomerId').then(function(result){
			        return result.data;
                });
            }
        };
    },
]);
