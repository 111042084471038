angular.module("2cp").factory("UserSubscriptionsColumns",
    [
        "$translate", "$filter", 'Subscriptions',
        "ColumnDefBuilder",
        function ($translate, $filter, Subscriptions,
                  ColumnDefBuilder) {
            var columnOptions = {
                name: {
                    visible: true
                },
                cost: {
                    visible: true
                },
                length: {
                    visible: true
                },
                subscription_length: {
                    visible: true
                },
                payment_frequency: {
                    visible: true,
                    selectOptions: {
                        keys: Subscriptions.SUBSCRIPTION_FREQUENCIES,
                        filter: 'subscriptionFrequencies'
                    }
                },
                valid_from: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                valid_until: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                created_at:{
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at:{
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                renew: {
                    cellFilter: 'subscriptionBooleanInput',
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'subscriptionBoolean'
                    }
                },
                pay_upfront: {
                    cellFilter: 'subscriptionBooleanInput',
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'subscriptionBoolean'
                    }
                },
                userRenew: {
                    cellFilter: 'subscriptionBooleanInput',
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'subscriptionBoolean'
                    }
                },
                userValidFrom: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                userValidUntil: {
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                user_id:{
                    enableFiltering: false
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Subscriptions.USER_SUBSCRIPTIONS_PARAMETERS.concat(Subscriptions.SUBSCRIPTION_PARAMETERS))
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.subscriptions.")
                .copy();


            return builder;
        }
    ]
);