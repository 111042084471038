angular.module("2cp").controller("app.tours",
    [
        "$scope", "$q", "$timeout", "$filter",
        "tourColumns", '$translate',"Tours", "Poi", "GoogleMapsLoader",
        function ($scope, $q, $timeout, $filter, tourColumns, $translate, Tours, Poi, GoogleMapsLoader) {
            var map = null,
                poly = null,
                waypoints = [],
                markers = [],
                infowindow = null;

            var poiMarkerIcon = null;
            var nonPoiMarkerIcon = null;

            $scope.tourDetails = null;
            $scope.selectedWaypoint = null;
            $scope.pois = [];

            function initMap() {
                map = new google.maps.Map(document.getElementById('map'), {
                    zoom: 7,
                    center: {lat: 51.879, lng: 6.624}
                });

                // Set up polyline
                poly = new google.maps.Polyline({
                    strokeColor: '#000',
                    strokeOpacity: 1.0,
                    strokeWeight: 2
                });
                poly.setMap(map);

                // Create click handler for poly
                map.addListener('click', function(event) {
                    var newWaypoint = addWaypoint(event.latLng.lat(), event.latLng.lng());
                    editWaypoint(newWaypoint);
                    console.log('Map Clicked', newWaypoint);
                });

                infowindow = new google.maps.InfoWindow({
                    maxWidth: 200
                });

                nonPoiMarkerIcon = {
                    path: google.maps.SymbolPath.CIRCLE,
                    fillColor: 'blue',
                    fillOpacity: 1,
                    strokeColor: 'black',
                    strokeWeight: 1,
                    scale: 7
                };
            }
            function clearMap() {
                markers.forEach(function(marker) {
                    marker.setMap(null);
                });
                markers = [];

                poly.setPath([]);
            }
            function getMapMarker(waypoint) {
                // Find marker
                return markers.find(function(marker) {
                    return marker.waypoint === waypoint;
                });
            }
            function addMapMarker(waypoint) {
                if(waypoint.latitude === '' || waypoint.longitude === '' || isNaN(waypoint.latitude) || isNaN(waypoint.longitude)) {
                    waypoint.latitude = 0;
                    waypoint.longitude = 0;
                }

                waypoint.latitude = parseFloat(waypoint.latitude);
                waypoint.longitude = parseFloat(waypoint.longitude);

                // Add to path
                var path = poly.getPath();
                path.push(new google.maps.LatLng(waypoint.latitude, waypoint.longitude));
                poly.setPath(path);

                // Add marker to the map
                var marker = new google.maps.Marker({
                    draggable: true,
                    position: {
                        lat: waypoint.latitude,
                        lng: waypoint.longitude
                    },
                    waypoint: waypoint,
                    map: map
                });

                // Set the default marker icon (if it hasnt been set yet). This is the default
                // 'pin' marker
                if(!poiMarkerIcon) {
                    poiMarkerIcon = marker.getIcon();
                }
                
                // If the waypoint has no poi, show a simple dot marker
                if(!waypoint.poi_id) {
                    marker.setIcon(nonPoiMarkerIcon);
                }

                markers.push(marker);

                // Create click handler for marker
                google.maps.event.addListener(marker,'click', function() {
                    editWaypoint(marker.waypoint);
                });

                google.maps.event.addListener(marker, 'dragend', function() {
                    console.log('Waypoint (before)', waypoint);
                    waypoint.latitude = marker.getPosition().lat();
                    waypoint.longitude = marker.getPosition().lng();
                    console.log('Waypoint (after)', waypoint);

                    refreshPolyLine();
                });
            }
            function removeMapMarker(waypoint) {
                var marker = getMapMarker(waypoint);
                // Remove from array
                var index = markers.indexOf(marker);
                markers.splice(index, 1);
                poly.getPath().removeAt(index);
                // Remove from map
                marker.setMap(null);
            }
            function refreshPolyLine() {
                var path = [];

                $scope.tourDetails.waypoints.forEach(function(waypoint) {
                    path.push(new google.maps.LatLng(waypoint.latitude, waypoint.longitude));
                });

                poly.setPath(path);
            }
            function loadTour(tour) {
                clearMap();
                tour.waypoints.forEach(addMapMarker);
            }
            function addWaypoint(latitude, longitude) {
                var r = {
                    name: '<no name>',
                    description: '<no description>',
                    latitude: latitude,
                    longitude: longitude,
                    tour_id: $scope.tourDetails.tour_id
                };
                addMapMarker(r);
                if($scope.tourDetails.waypoints) {
                    $scope.tourDetails.waypoints.push(r);
                } else {
                    $scope.tourDetails.waypoints = [];
                    $scope.tourDetails.waypoints.push(r);
                }
                return r;
            }
            function editWaypoint(waypoint) {
                $timeout(function() {
                    $scope.selectedWaypoint = waypoint;
                });
                console.log('Edit Waypoint', waypoint);
            }
            $scope.deleteWaypoint = function() {
                console.log("Removing waypoint", $scope.tourDetails.waypoints[$scope.tourDetails.waypoints.length - 1]);
                console.log($scope.tourDetails.waypoints);
                removeMapMarker($scope.tourDetails.waypoints[$scope.tourDetails.waypoints.length - 1]);
                //Remove waypoint
                $scope.tourDetails.waypoints.splice(-1, 1);
            };
            $scope.poiChanged = function() {
                var marker = getMapMarker($scope.selectedWaypoint);
                if($scope.selectedWaypoint.poi_id) {
                    marker.setIcon(poiMarkerIcon);
                }
                else {
                    marker.setIcon(nonPoiMarkerIcon)
                }
            };

            $scope.removeTourPhoto = function(photo) {
                var index = $scope.tourDetails.photos.indexOf(photo);
                $scope.tourDetails.photos.splice(index,1);
            };

            $scope.addTourPhoto = function(photo) {
                var newTourPhoto = {"tour_id": $scope.tourDetails.tour_id,"photo": photo};

                if($scope.tourDetails.photos) {
                    $scope.tourDetails.photos.push(newTourPhoto);
                } else {
                    $scope.tourDetails.photos = [];
                    $scope.tourDetails.photos.push(newTourPhoto);

                }
            };

            GoogleMapsLoader.mapsInitialized.then(initMap);

            Poi.getPois().then(function(response) {
                $timeout(function() {
                    $scope.pois = response.data;
                });
                console.log(response);
            });

            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showTourDetailsModal = function (creationModal, rowEntity) {
                clearMap();
                $scope.tourDetails = rowEntity ? rowEntity : {};
                $scope.tourCreationModal = creationModal;

                $scope.selectedWaypoint = null;

                if (!creationModal && $scope.tourDetails) {
                    loadTour($scope.tourDetails);
                }
                $("#tourMapsDialog").foundation('open');
            };
            $scope.detailsUpdated = function () {
                $('.reveal').foundation('close');

                if ($scope.tourCreationModal)
                    $scope.tourGrid.api.callGridEditing.addRow($scope.tourDetails);
                else
                    $scope.tourGrid.api.rowEdit.setRowsDirty([$scope.tourDetails]);
            };
            $scope.removeSelected = function () {
                $scope.tourGrid.api.callGridEditing.removeSelectedRows();
            };
            $scope.gridOptions = {
                columnDefs: tourColumns.build(),
                callGridData: {
                    id: 'tour_id',

                    get: function (params) {
                        params.with = 'waypoints';
                        return Tours.getTours(params);
                    },
                    create: function(data) {
                        return Tours.create(data).then(function (result) {
                            $.notify($translate.instant('app.tours.tour_created_success'), "success");
                            return result;
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                    },
                    update: function (id, data) {
                        return Tours.update(id, data).then(function (result) {
                            $.notify($translate.instant('app.tours.tour_update_success'), "success");
                            return result;
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                    },
                    delete: function(ids) {
                        return Tours.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('app.tours.tour_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function(rowEntity) {
                        $scope.showTourDetailsModal(false, rowEntity)
                    }
                },
                onRegisterApi: function(gridApi) {
                    $scope.tourGrid = gridApi.grid;
                }
            };
        }
    ]
);