'use strict';

angular.module('api').service('clAuthentication', [
	'$q',
    'CacheFactory',
    'clApiConfig',
    '$http',
    '$log',
    function ($q, CacheFactory, ApiConfig, $http, $log) {
        var authUrl = ApiConfig.url + '/' + ApiConfig.authUrl;

        function requestToken(tokenType, username, password) {
			$log.debug('Requesting token: ' + tokenType);
            return $http({
                method: 'POST',
                url: authUrl,
                data: {
                    grant_type: 'password',
                    username: username,
                    password: password,
                    client_id: ApiConfig.secrets[tokenType].id,
                    client_secret: ApiConfig.secrets[tokenType].secret
                },
            }).then(function (result) {
                if (result && result.data) {
                    var token = result.data;
                    token.expires = Math.round(+new Date() / 1000) + token.expires_in;
                    delete token.expires_in;
                    tokenCache.put(tokenType, token);
                    return true;
                } else {
                    throw 'Error in response';
                }
            });
        }
        function refresh(tokenKey, tokenValue) {
        	$log.debug('Refreshing token: ' + tokenKey);
			return $http.post(authUrl, {
				refresh_token: tokenValue.refresh_token,
				grant_type: 'refresh_token',
				client_id: ApiConfig.secrets[tokenKey].id,
				client_secret: ApiConfig.secrets[tokenKey].secret,
			}).then(function (result) {
				var token = result.data;
				token.expires = Math.round(+new Date() / 1000) + token.expires_in;
				delete token.expires_in;
				tokenCache.put(tokenKey, token);
				$log.log('works');
			});
		}

        // Check if we have a cache.
		var tokenCache = CacheFactory.get('tokenCache');
        if (!tokenCache) {
            tokenCache = CacheFactory.createCache('tokenCache', {
                //Token is valid for 59 minutes
                maxAge: 59 * 60 * 1000,
                deleteOnExpire: 'aggressive',
                storageMode: 'localStorage',
                onExpire: refresh
            });
        }

        var Authentication = {
			initialize: function() {
				var a = $q.resolve();

				//Request a new public token if necessary
				if (ApiConfig.useAuthentication) { //only get the public token when we actually use authentication
					a = a.then(function () {
						return requestToken('publicToken', 'App', 'c819ce99-1e27-4e78-8322-40d57098f32a');
					});
				}

				//If we were already logged in, immediately get a new refresh token
				if(tokenCache.get('token') && tokenCache.get('token').access_token) {
					a = a.then(function () {
						return refresh('token', tokenCache.get('token'));
					});
				}

				return a;
			},

			clearCache: function() {
				tokenCache.removeAll();
			},

            authenticate: function (username, password) {
                return requestToken('token', username, password);
            },
			logout: function() {
				tokenCache.remove('token');
			},

			refresh: function() {
				return refresh('token', tokenCache.get('token'));
			},

            isAuthenticated: function () {
                if (typeof tokenCache.get('token') === 'undefined' || typeof tokenCache.get('token').access_token === 'undefined') {
                    return false;
                }

                if (typeof tokenCache.get('token').expires === 'undefined') {
                    return false;
                }

                if (+Math.round(+new Date() / 1000) >= tokenCache.get('token').expires) {
                    return false;
                }

                return true;
            },

            getUserToken: function () {
                return tokenCache.get('token');
            },

            getPublicToken: function () {
                return tokenCache.get('publicToken');
            },
        };

        return Authentication;
    }
]);
