angular.module("2cp").factory("PaymentColumns",
    [
        "$translate", "$filter", "uiGridConstants",
        "Payments", "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants,
                  Payments, ColumnDefBuilder) {
            var columnOptions = {
                amount: {
                    visible: true,
                    cellFilter: 'moneyFilter'
                },
                username: {
                    visible: true
                },
                status: {
                    visible: true
                },
                reason_type: {
                    cellFilter: 'reasonType',
                    selectOptions:{
                        keys: Payments.reasonTypes,
                        filter: 'paymentTypes'
                    }
                },
                paymentType: {
                    cellFilter: 'paymentTypeTranslation|translate',
                    selectOptions:{
                        keys: Payments.paymentTypes.concat(Payments.extraPaymentTypes),
                        filter: 'paymentTypeTranslation'
                    }
                },
                name: {
                    cellFilter: 'paymentSubscriptionName'
                },
                payable_type: {
                    cellFilter: 'payableType',
                    visible: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    visible: true,
                    sort: {
                        direction: uiGridConstants.DESC,
                        priority: 0
                    }
                },
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Payments.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.payments.')
                .copy();

            return builder;
        }
    ]
);