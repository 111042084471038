'use strict';

angular.module('api').factory('Tenants', [
    'clApi',
    '$q',
    'Address',
    function (api, $q, Address) {
        //var cache = api.getOrCreateSessionCache('Tenants', 10000);

        return {

            PAYMENT_OPTIONS: [
                'balance',
                'after_pay',
                'on_credit'
            ],

            parameters: [
                'tenant_id',
                'created_at',
                'updated_at',
                'deleted_at',
                'legacy',
                'name',
                'short_name',
                'telephone_number',
                'fax_number',
                'mobile_number',
                'email',
                'partner_id',
                'currency_id',
                'address_id',
                'time_zone',
                'logo_url',
                'header_url',
                'street',
                'country',
                'state',
                'city',
                'house_number',
                'postal_code'
            ],

            registrationFields: [
                'address',
                'birth_date',
                'company',
                'first_name',
                'gender',
                'initials',
                'last_name',
                'mobile_number',
                'passport',
                'telephone_number',
                'preposition',
                'vat_number'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getRegistrationFields: function () {
                return this.registrationFields;
            },

            getTranslateRegistrationFieldLabel: function(field){
                return 'tenants.settings.registration_fields.' + field;
            },

            getTenants: function (params, options) {
                //options = api.createIfNotExist(options);
                //options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('tenants', params, options).then(function (result) {
                    for(var i = 0; i < result.data.length; i++){
                        result.data[i].payment_provider_name = result.data[i].payment_provider.name;
                        result.data[i].publishable_key = result.data[i].payment_provider.public_key;
                        result.data[i] = Address.setAddress(result.data[i]);
                    }
                    return result.data;
                });
            },

            getAvailable: function (params, options) {
                // options = api.createIfNotExist(options);
                // options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('tenants/available', params, options).then(function (result) {
                    return result.data;
                });
            },

            getTenant: function (id, params, options) {
                //options = api.createIfNotExist(options);
                //options.cache = api.extendIfNotExist(options.cache, cache);

                return api.get('tenants/' + id, params, options).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('tenants', params).then(function (result) {
                    result.data = Address.setAddress(result.data);
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('tenants/' + id, params).then(function (result) {
                    result.data = Address.setAddress(result.data);
                    return result.data;
                });
            },

            delete: function (ids) {
                var promises = [];
                ids.forEach(function (id) {
                    promises.push(api.delete('tenants/' + id));
                });

                return $q.all(promises);
            },

            getSettings: function(id){
                return api.get('tenants/' + id + '/settings').then(function(result){
                    return result.data;
                });
            },

            updateSettings: function(id, params){
                return api.put('tenants/' + id + '/settings', params).then(function (result) {
                    return result.data;
                });
            },

            updateRegistrationFields: function(id, params){
                return api.put('tenants/' + id + '/registrationFields', params).then(function (result) {
                    return result.data;
                });
            },

            updateAppColors: function(id, params){
                return api.put('tenants/' + id + '/appColors', params).then(function (result) {
                    return result.data;
                });
            }
        };
    },
]);
