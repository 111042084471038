angular.module("2cp").controller("app.app-colors",
    [
        "$scope", "$q", "$timeout", "$log", "$filter", "Tenants", '$translate',
        function ($scope, $q, $timeout, $log, $filter, Tenants, $translate, ) {

            $scope.colorOptions = {
                placeholder: $translate.instant("app.appColors.click_to_pick"),
                format: 'hex',
                clear: {
                    show: [false, true],
                    label: $translate.instant('app.appColors.clear'),
                    class: 'button icon-trash',
                },
            };

            $scope.save = function() {
                $scope.saving = true;
                if(!$scope.colors.primary){
                    $scope.colors.primary = null;
                }
                if(!$scope.colors.text){
                    $scope.colors.text = null;
                }
                Tenants.updateAppColors(calllock.tenantId, $scope.colors).then(function() {
                    $.notify($translate.instant('notify.app_colors_update_success'), 'success');
                }).catch(function() {
                    $.notify($translate.instant('notify.app_colors_update_failed'));
                }).finally(function() {
                    $scope.saving = false;
                });
            };

            $scope.tenant = null;
            var params = {
                with: 'entity.entitySettings.appColors'
            };
            Tenants.getTenant(calllock.tenantId, params).then(function(result) {
                $scope.colors = result.entity.entity_settings.app_colors;
                if(!$scope.colors){
                    $scope.colors = {};
                    $scope.colors.primary = "";
                    $scope.colors.text = "";
                    $scope.colors.button_text = "";
                }
            });
        }
    ]
);