angular.module("2cp").factory("RoleColumns",
    [
        "$translate", "$filter",
        "Roles",
        "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter,
                  Roles,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                display_name: {
                    visible: true,
                    enableCellEdit: true
                },
                description: {
                    visible: true,
                    enableCellEdit: true
                }
            }

            var builder = new ColumnDefBuilder()
                .addColumnNames(Roles.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.settings.roles.')
                .copy();

            return builder;
        }
    ]
);