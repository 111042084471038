angular.module("2cp")
    .filter('userIdentifierIdToUserId', ["UserIdentifiers", function (UserIdentifiers) {
        var waiting = false;
        var valuesArray = [];

        function labelFilter(value) {
            if (value === null || typeof(value) === "undefined") {
                return "";
            }

            var lbl = value;

            if (value in valuesArray) {
                lbl = valuesArray[value].user_id;
            }
            else {
                if (waiting === false) {
                    waiting = true;
                    UserIdentifiers.get(value).then(function (result) {
                        if (!(value in valuesArray)) {
                            valuesArray[value] = result ;
                        }
                        waiting = false;
                    });
                }
            }
            return lbl;
        }
        labelFilter.$stateful = true;
        return labelFilter;
    }]);