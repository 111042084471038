angular.module("2cp").factory("DroppointDeviceColumns",
    [
        "$translate", "$filter", "uiGridConstants", "$timeout",
        "Users", "UserIdentifiers", "DroppointDevices",
        "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants, $timeout,
                  Users, UserIdentifiers, DroppointDevices,
                  ColumnDefBuilder) {
            var columnOptions = {
                droppoint_device_id: {
                    enableFiltering: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                label: {
                    enableFiltering: true,
                    visible: true
                },
                radius: {
                    enableFiltering: true
                },
                uid:{
                    enableFiltering: true,
                    visible: true
                },
                droppoint_id:{
                    enableFiltering: true,
                    visible: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(DroppointDevices.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.droppointDevices.')
                .copy();

            return builder;
        }
    ]
);