'use strict';

angular.module('api').service('App', [
    'clApi',
    function (api) {
        return {
            parameters: [],

            getParameters: function () {
                return this.parameters;
            },

            getSettings: function(id) {
                return api.get('app/settings/' + id).then(function(result) {
                    return result.data;
                })
            },

            getAllSettings: function(params) {
                return api.get('app/settings').then(function(result) {
                    return result.data;
                })
            }

        }
    }
]);
