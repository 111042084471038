angular.module("2cp")
    // User blocked status filter
    .filter('blocked', ["Users", function(Users) {
        return function(input) {
            if (input == Users.BLOCK_STATUS.NOT_BLOCKED)
                return "tenants.users.blockTypeCell.notBlocked";
            else if (input == Users.BLOCK_STATUS.BLOCKED)
                return "tenants.users.blockTypeCell.blocked";
            else
                return "tenants.users.blockTypeCell.unknown";
        }
    }])
    .filter('registered', ["Users", function(Users) {
        return function(input) {
            if (input == Users.REGISTERED.NOT_REGISTERED)
                return "tenants.users.registeredTypeCell.notRegistered";
            else if (input == Users.REGISTERED.REGISTERED)
                return "tenants.users.registeredTypeCell.registered";
            else
                return "tenants.users.registeredTypeCell.unknown";
        }
    }])
    .filter('blockedForCurrent', ["Users", function(Users) {
        return function(input) {
            if (input == Users.BLOCK_STATUS.NOT_BLOCKED)
                return "tenants.users.blockTypeCell.notBlocked";
            else if (input == Users.BLOCK_STATUS.BLOCKED)
                return "tenants.users.blockTypeCell.blocked";
            else
                return "tenants.users.blockTypeCell.notRegistered";
        }
    }])
    // User type filter
    .filter('userType', ["Users", function(Users) {
        return function(input) {
            if (input == Users.USER_TYPE.PERSONAL)
                return "tenants.users.userTypeCell.personal";
            else if (input == Users.USER_TYPE.BUSINESS)
                return "tenants.users.userTypeCell.business";
            else if (input == Users.USER_TYPE.TEMPORARY)
                return "tenants.users.userTypeCell.temporary";
            else
                return "tenants.users.userTypeCell.unknown";
        }
    }])
    // User subscription type filter
    .filter('subscriptionType', ["Users", function(Users) {
        return function(input) {
            if (input == Users.SUBSCRIPTION_TYPE.PREPAID_MANUAL_UPGRADE)
                return "tenants.users.subscriptionTypeCell.prepaidManualUpgrade";
            else if (input == Users.SUBSCRIPTION_TYPE.PREPAID_AUTO_UPGRADE)
                return "tenants.users.subscriptionTypeCell.prepaidAutoUpgrade";
            else if (input == Users.SUBSCRIPTION_TYPE.ON_CREDIT_PAYMENT)
                return "tenants.users.subscriptionTypeCell.onCreditPayment";
            else if (input == Users.SUBSCRIPTION_TYPE.EXTERNAL_PAYMENT)
                return "tenants.users.subscriptionTypeCell.externalPayment";
            else
                return "tenants.users.subscriptionTypeCell.unknown";
        }
    }])
    // User gender filter
    .filter('gender', ["Users", function(Users) {
        return function(input) {
            if (input == Users.GENDER_TYPE.MALE)
                return "tenants.users.genderTypeCell.male";
            else if (input == Users.GENDER_TYPE.FEMALE)
                return "tenants.users.genderTypeCell.female";
            else if (input == Users.GENDER_TYPE.TRANSGENDER)
                return "tenants.users.genderTypeCell.transgender";
            else
                return "tenants.users.genderTypeCell.unknown";
        }
    }])
    .filter('userIdToLabel', ["Users", function (Users) {
        var waiting = false;
        var valuesArray = [];

        function labelFilter(value) {
            if (value === null || value === "" || typeof(value) === "undefined") {
                return "";
            }

            var lbl = value;

            if (value in valuesArray) {
                lbl = valuesArray[value].label;
            }
            else {
                if (waiting === false) {
                    waiting = true;
                    Users.getUser(value).then(function (result) {
                        if (!(value in valuesArray)) {
                            valuesArray[value] = result ;
                        }
                        waiting = false;
                    });
                }
            }
            return lbl;
        }
        labelFilter.$stateful = true;
        return labelFilter;
    }])
    .filter('userBalance', ['Tenants', '$filter', function(Tenants, $filter){
        var currencySymbol = null;

        Tenants.getTenant(calllock.tenantId).then(function(tenant){
            currencySymbol = tenant.currency.symbol;
        });

        return function(input){
            if(input === null){
                return 'No user payment details';
            }
            return $filter('currency')(input/100, currencySymbol);
        };
    }]);