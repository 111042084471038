angular.module("2cp").controller("tenants.settings.permissions",
    [
        "$scope", "PermissionColumns", "Permissions", '$translate',
            function ($scope, PermissionColumns, Permissions, $translate) {
            $scope.roleGridOptions = {
                columnDefs: PermissionColumns.build(),

                callGridData: {
                    id: "role_id",
                    get: function(params) {
                        return Permissions.getAvailable(params)
                            .catch(function() {
                                console.error(e);
                            });
                    },
                },
                callGridFiltering: {
                    queryParameterPrefix: "permissions."
                },
                onRegisterApi: function(gridApi) {
                    $scope.roleGrid = gridApi.grid;
                }
            };
        },
    ]
);