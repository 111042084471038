angular.module("2cp").factory("PoiColumns",
    [
        "$translate", "$filter", "$timeout",
        "Poi", "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, $timeout, Poi,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                poi_id: {
                    visible: false
                },
                name: {
                    enableCellEdit: true,
                },
                type: {
                    enableCellEdit: true
                },
                text: {
                    visible: false
                },
                website: {
                    visible: false
                },
                phone: {
                    visible: false
                },
                longitude: {
                    visible: false
                },
                latitude: {
                    visible: false
                },
                rating: {
                    visible: false
                },
                raters: {
                    visible: false
                },
                reservation_link: {
                    visible: false
                },
                tour_marker: {
                    visible: true
                }
            };

            return new ColumnDefBuilder()
                .addColumnNames(Poi.parameters)
                .addDefaultOptions({
                    visible: true,
                    enableFiltering: false,
                    enableCellEdit: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('app.poi.')
                .copy();
        }
    ]
);