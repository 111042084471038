angular.module("2cp").controller("partners",
    [
        "$scope", "$timeout", "$window", "Partners", "Tenants", "$translate",
        "PartnerColumns", "TenantColumns", "Currencies", "Vehicles", "Address",
        'VehicleTypes', 'VehicleTypeColumns',
        function ($scope, $timeout, $window, Partners, Tenants, $translate,
                  PartnerColumns, TenantColumns, Currencies, Vehicles, Address,
                  VehicleTypes, VehicleTypeColumns) {
            $scope.vehicleTypes = [];
            $scope.settingDetails = {};
            $scope.countries = Address.getCountries();

            VehicleTypes.getVehicleTypes({'limit': 100}).then(function (response) {
                $scope.vehicleTypes = response.data;
            });

            $scope.registrationFields = Tenants.getRegistrationFields();

            $scope.currencies = [];

            Currencies.getCurrencies().then(function (response) {
                $scope.currencies = response;
            });

            $scope.partnersGridOptions = {
                columnDefs: PartnerColumns.build(),

                callGridData: {
                    id: "partner_id",
                    get: function (params) {
                        params.with = 'address';
                        return Partners.getPartners(params).then(function (result) {
                            return result;
                        }).catch(function () {
                        });
                    },
                    create: function (data) {
                        $('.reveal').foundation('close');
                        return Partners.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.partner_create_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    update: function (id, data) {
                        return Partners.update(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.partner_update_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        return Partners.delete(ids)
                            .then(function (result) {
                                $.notify($translate.instant('notify.partner_delete_success'), "success");
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $('.tabs').foundation('selectTab', $('#detailsPanel'));
                        $scope.showDetailsModal(false, rowEntity);
                    }
                },
                callGridIcon: {
                    iconClass: function (rowEntity) {
                        //Search for tenants
                        if (rowEntity.partner_id) {
                            for (var i = 0; i < rowEntity.tenants.length; i++) {
                                if (rowEntity.partner_id == rowEntity.tenants[i].partner_id && rowEntity.tenants[i].deleted_at == null) {
                                    return 'fa-link';
                                }
                            }
                        }
                        //Not found
                        return 'fa-chain-broken';
                    },
                    clickCallback: function (rowEntity) {
                        $('.tabs').foundation('selectTab', $('#tenantsPanel'));
                        $scope.showDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    queryParameterPrefix: "partners."
                },
                onRegisterApi: function (gridApi) {
                    $scope.partnersGrid = gridApi.grid;
                }
            };

            $scope.showDeleteButton = function () {
                var r = true;

                var rows = $scope.partnersGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };
            $scope.removeSelected = function () {
                $scope.partnersGrid.api.callGridEditing.removeSelectedRows();
            };

            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showDetailsModal = function (creationModal, rowEntity) {
                $scope.partnerDetails = rowEntity;
                $scope.partnerCreationModal = creationModal;

                $scope.vehicleTypesGrid.api.callGridData.get();

                if (creationModal) {
                    $('.tabs').foundation('selectTab', $('#detailsPanel'));
                    $scope.addressDetails = {};
                }

                if (!creationModal) {
                    $scope.addressDetails = rowEntity.address || {};
                    getSettings();

                    $scope.tenantGrid.api.grid.clearAllFilters();
                    $scope.tenantGrid.api.callGridData.get().then(function () {
                        var ids = getTenantsPartnerDetails().map(function (tenant) {
                            return tenant.tenant_id;
                        });
                        $timeout(function () {
                            $scope.tenantGrid.options.data.forEach(function (tenant) {
                                if (ids.indexOf(tenant.tenant_id) > -1)
                                    $scope.tenantGrid.api.selection.selectRow(tenant);
                            });
                        }, 0);
                    });
                }

                $("#partnerDetailModal").foundation('open');
            };

            $scope.detailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');
                var address = $scope.addressDetails;
                if (!address.street && !address.house_number && !address.postal_code && !address.country && !address.state && !address.city) {
                    updatedRow.address = {};
                } else {
                    if (address.street && address.house_number && address.postal_code &&
                        address.country && address.state && address.city) {
                        //Is supposed to do nothing
                    } else {
                        $.notify($translate.instant('notify.address_all_fields'));
                        return;
                    }
                }

                updatedRow.address = {};
                updatedRow.address.street = $scope.addressDetails.street;
                updatedRow.address.house_number = $scope.addressDetails.house_number;
                updatedRow.address.postal_code = $scope.addressDetails.postal_code;
                updatedRow.address.country = $scope.addressDetails.country;
                updatedRow.address.state = $scope.addressDetails.state;
                updatedRow.address.city = $scope.addressDetails.city;

                if ($scope.partnerCreationModal)
                    $scope.partnersGrid.api.callGridEditing.addRow(updatedRow);
                else {
                    for (var i = 0; i < $scope.vehicleTypes.length; i++) {
                        updateSettings(updatedRow.partner_id, $scope.settingDetails, $scope.settingDetails.vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id]);
                    }
                    updatedRow = Address.setAddress(updatedRow);
                    $scope.partnersGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }
            };

            function getSettings(){
                VehicleTypes.getVehicleTypes({'partner_id': $scope.partnerDetails.partner_id}).then(function(response){
                    $scope.vehicleTypes = response.data;
                    Partners.getSettings($scope.partnerDetails.partner_id).then(function (response) {
                        $scope.registrationFieldSettings = {};
                        $scope.settingDetails = response;
                        //Arrange vehicle settings so that vehicle_type_id is the index of the vehicle settings
                        var vehicle_settings = [];
                        for (var i = 0; i < $scope.vehicleTypes.length; i++) {
                            for (var j = 0; j < response.vehicle_settings.length; j++) {
                                if (response.vehicle_settings[j].vehicle_type_id == $scope.vehicleTypes[i].vehicle_type_id) {
                                    vehicle_settings[$scope.vehicleTypes[i].vehicle_type_id] = response.vehicle_settings[j];
                                }
                            }
                        }
                        $scope.settingDetails.vouchers_only = ($scope.settingDetails.vouchers_only == 1);
                        $scope.settingDetails.allow_free_floating = ($scope.settingDetails.allow_free_floating == 1);
                        $scope.settingDetails.force_subscription = ($scope.settingDetails.force_subscription == 1);
                        $scope.settingDetails.hide_booking = ($scope.settingDetails.hide_booking == 1);
                        $scope.settingDetails.vehicle_type_id = $scope.vehicleTypes[0].vehicle_type_id;
                        $scope.settingDetails.vehicle_settings = vehicle_settings;

                        for(var i = 0; i < $scope.registrationFields.length; i++){
                            $scope.registrationFieldSettings[$scope.registrationFields[i] + '_visible'] = $scope.settingDetails[$scope.registrationFields[i] + '_visible'] === 1;
                            $scope.registrationFieldSettings[$scope.registrationFields[i] + '_required'] = $scope.settingDetails[$scope.registrationFields[i] + '_required'] === 1;
                        }
                    });
                });
            }

            function updateSettings(partner_id, settings, vehicleSettings) {
                var sets = {};
                angular.extend(sets, settings);
                sets.max_booked = vehicleSettings.max_booked;
                sets.max_rented = vehicleSettings.max_rented;
                sets.start_cost = vehicleSettings.start_cost;
                sets.minimum_balance = vehicleSettings.minimum_balance;
                sets.vehicle_type_id = vehicleSettings.vehicle_type_id;
                Partners.updateSettings(partner_id, sets).then(function () {
                    $.notify($translate.instant('notify.settings_update_success'), 'success');
                }).catch(function () {
                });
            }

            //Tenants grid
            {
                function getTenantsPartnerDetails() {
                    var r = [];
                    if ($scope.partnerDetails.tenants) {
                        $scope.partnerDetails.tenants.forEach(function (tenants) {
                            r.push(tenants);
                        });
                    }
                    return r;
                }

                $scope.tenantGridOptions = {
                    columnDefs: TenantColumns.build(),
                    callGridData: {
                        id: "tenant_id",
                        //get: Tenants.getAvailable
                        get: function (params) {
                            return Tenants.getAvailable(params).then(function (result) {
                                //Collect tenant
                                var tenants = getTenantsPartnerDetails();
                                //We now have only unique tenants in both arrays, so simply concatenate
                                tenants.forEach(function (tenant) {
                                    result.data.unshift(tenant);
                                });
                                return result;
                            });
                        }
                    },
                    callGridFiltering: {
                        updateUrl: false
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.tenantGrid = gridApi.grid;
                    }
                };
                $scope.attachTenants = function (tenantIds) {
                    $scope.saving = true;

                    Partners.attachTenants($scope.partnerDetails.partner_id, tenantIds).then(function () {
                        $.notify($translate.instant('notify.attach_tenants_success'), "success");
                    }).catch(function (e) {
                        console.error(e);
                    }).finally(function () {
                        $scope.saving = false;
                        $scope.partnerGrid.api.callGridData.get();
                        $('.reveal').foundation('close');
                    });
                };
                $scope.getSelectedTenantIds = function () {
                    var selectedIds = [];
                    var selectedRows = $scope.tenantGrid.api.selection.getSelectedRows();
                    var idKey = $scope.tenantGrid.options.callGridData.id;
                    selectedRows.forEach(function (row) {
                        selectedIds.push(row[idKey]);
                    });
                    return selectedIds;
                };
            }

            $scope.vehicleTypesGridOptions = {
                columnDefs: VehicleTypeColumns.build(),
                callGridData: {
                    id: "vehicle_type_id",
                    get: function (params) {
                        if(!$scope.partnerCreationModal) {
                            params.partner_id = $scope.partnerDetails.partner_id;
                        }
                        return VehicleTypes.getVehicleTypes(params)
                            .then(function (response) {
                                return response;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    create: function (data) {
                        data.partner_id = $scope.partnerDetails.partner_id;
                        $scope.saving = true;
                        return VehicleTypes.create(data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.vehicle_type_create_success'), "success");
                                getSettings();
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            }).finally(function(){
                                $scope.saving = false;
                            });
                    },
                    update: function (id, data) {
                        data.partner_id = $scope.partnerDetails.partner_id;
                        return VehicleTypes.update(id, data)
                            .then(function (result) {
                                $.notify($translate.instant('notify.vehicle_type_update_success'), "success");
                                getSettings();
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            });
                    },
                    delete: function (ids) {
                        $scope.saving = true;
                        return VehicleTypes.delete(ids, {partner_id: $scope.partnerDetails.partner_id})
                            .then(function (result) {
                                $.notify($translate.instant('notify.vehicle_type_delete_success'), "success");
                                getSettings();
                                return result;
                            })
                            .catch(function (e) {
                                console.error(e);
                            }).finally(function(){
                                $scope.saving = false;
                            });;
                    }
                },
                callGridEditIcon: {
                    clickCallback: function (rowEntity) {
                        $scope.showVehicleTypeDetailsModal(false, rowEntity);
                    }
                },
                callGridFiltering: {
                    updateUrl: false
                },
                onRegisterApi: function (gridApi) {
                    $scope.vehicleTypesGrid = gridApi.grid;
                }
            };

            $scope.showVehicleTypeDetailsModal = function (creationModal, rowEntity) {
                $scope.vehicleTypeDetails = rowEntity;
                $scope.vehicleTypeCreationModal = creationModal;
                $("#vehicleTypeDetailModal").foundation('open');
            };

            $scope.closeVehicleType = function(){
                $("#partnerDetailModal").foundation('open');
            };

            $scope.vehicleTypeDetailsUpdated = function (updatedRow) {
                if ($scope.vehicleTypeCreationModal) {
                    $scope.vehicleTypesGrid.api.callGridEditing.addRow(updatedRow);
                } else {
                    $scope.vehicleTypesGrid.api.rowEdit.setRowsDirty([updatedRow]);
                }
                $("#vehicleTypeDetailModal").foundation('close');
                $("#partnerDetailModal").foundation('open');
            };

            $scope.showVehicleTypeDeleteButton = function () {
                var r = true;

                var rows = $scope.vehicleTypesGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelectedVehicleTypes = function () {
                $scope.vehicleTypesGrid.api.callGridEditing.removeSelectedRows();
                $("#partnerDetailModal").foundation('open');
            };

            $scope.closeVehicleTypeDelete = function(){
                $("#vehicleTypeDetailModal").foundation('open');
            };

            $scope.setRegistrationFields = function(settings) {
                $scope.saving = true;
                Partners.updateRegistrationFields($scope.partnerDetails.partner_id, settings).then(function(){
                    $.notify($translate.instant('notify.registration_fields_update_success'), "success");
                }).catch(function(){
                    $.notify($translate.instant('notify.registration_fields_update_failed'));
                }).finally(function(){
                    $scope.saving = false;
                });
            }

            $scope.getTranslateRegistrationFieldLabel = function(field){
                return Tenants.getTranslateRegistrationFieldLabel(field);
            };

            $scope.registrationFieldChanged = function(field){
                if(!$scope.registrationFieldSettings[field + '_visible']){
                    $scope.registrationFieldSettings[field + '_required'] = false;
                }
            };
        }
    ]
);