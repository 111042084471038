'use strict';

angular.module('api').factory('Droppoints', [
    '$q',
    '$http',
    'clApi',
    'Address',
    function ($q, $http, api, Address) {

        return {
            parameters: [
                'zoneLabel',
                'label',
                'free_vehicles',
                'free_spots',
                'amount_of_bookings',
                'droppoint_id',
                'zone_id',
                'longitude',
                'latitude',
                'created_at',
                'updated_at',
                'maximum_vehicles',
                'minimum_vehicles_for_booking',
                'amount_of_vehicles',
                'street',
                'country',
                'state',
                'city',
                'house_number',
                'postal_code',
            ],

            OPENING_TYPES:[
                'closed_for_rental',
                'closed_for_return',
                'closed_for_actions'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getDroppoints: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('droppoints', params, options).then(function (result) {
                    for(var i = 0; i < result.data.data.length; i++){
                        result.data.data[i] = Address.setAddress(result.data.data[i]);
                    }
                    return result.data;
                });
            },
            getDroppoint: function (id) {
                return api.get('droppoints/' + id).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('droppoints/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('droppoints', params).then(function (result) {
                    result.data = Address.setAddress(result.data);
                    return result.data;
                });
            },

            delete: function (droppointIds) {
                var promises = [];
                droppointIds.forEach(function (id) {
                    promises.push(api.delete('droppoints/' + id));
                });

                return $q.all(promises);
            },

            setForbidden: function(droppointId, forbiddenIds) {
                if(angular.isArray(forbiddenIds))
                    forbiddenIds = forbiddenIds.join(',');

                return api.put('droppoints/' + droppointId + '/forbidden', {
                    forbiddenIds: forbiddenIds
                }).then(function(result) {
                    return result.data;
                });
            },

            addOpeningEntry: function (id, params) {
                return api.post('droppoints/' + id + '/scheduleEntry', params).then(function (result) {
                    return result.data;
                });
            },

            removeOpeningEntry: function (id, params) {
                return api.delete('droppoints/' + id + '/scheduleEntry', params).then(function (result) {
                    return result.data;
                });
            },


        };
    }
]);
