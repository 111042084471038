angular.module("2cp")
// Vehicle status filter
    .filter('vehicleStatus', ["Vehicles", function (Vehicles) {
        return function (input) {
            if (input == Vehicles.VEHICLE_STATUS.NOT_ACTIVE)
                return "tenants.vehicles.vehicleStatusCell.notActive";
            else if (input == Vehicles.VEHICLE_STATUS.ACTIVE)
                return "tenants.vehicles.vehicleStatusCell.active";
            else if (input == Vehicles.VEHICLE_STATUS.STOLEN)
                return "tenants.vehicles.vehicleStatusCell.stolen";
            else
                return "tenants.vehicles.vehicleStatusCell.unknown";
        }
    }])
    // Vehicle condition filter
    .filter('vehicleCondition', ["Vehicles", function (Vehicles) {
        return function (input) {
            if (input == Vehicles.CONDITION.HEALTHY)
                return "tenants.vehicles.conditionCell.healthy";
            else if (input == Vehicles.CONDITION.DEFECT)
                return "tenants.vehicles.conditionCell.defect";
            else if (input == Vehicles.CONDITION.MISSING)
                return "tenants.vehicles.conditionCell.missing";
            else
                return "tenants.vehicles.conditionCell.unknown";
        }
    }])
    // Vehicle renting status filter
    .filter('vehicleRentingStatus', ["Vehicles", function (Vehicles) {
        return function (input) {
            if (input == Vehicles.RENTING_STATUS.IN_SERVICE)
                return "tenants.vehicles.rentingStatusCell.inService";
            else if (input == Vehicles.RENTING_STATUS.IN_LOCK_AVAILABLE)
                return "tenants.vehicles.rentingStatusCell.inLockAvailable";
            else if (input == Vehicles.RENTING_STATUS.RENTED_WITH_VOUCHER)
                return "tenants.vehicles.rentingStatusCell.rentedWithVoucher";
            else if (input == Vehicles.RENTING_STATUS.RENTED_WITH_IDENTIFIER)
                return "tenants.vehicles.rentingStatusCell.rentedWithIdentifier";
            else if (input == Vehicles.RENTING_STATUS.IN_LOCK_KEY_MISSING)
                return "tenants.vehicles.rentingStatusCell.inLockKeyMissing";
            else if (input == Vehicles.RENTING_STATUS.TAKEN_BY_OPERATOR)
                return "tenants.vehicles.rentingStatusCell.takenByOperator";
            else
                return "tenants.vehicles.rentingStatusCell.unknown";
        }
    }])
    .filter('droppointLabel', function () {
        return function(value) {
            if(!value){
                return 'Not at droppoint';
            }else{
                return value;
            }
        }
    });