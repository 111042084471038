'use strict';

angular.module('api').service('clApi', [
    '$http',
    'CacheFactory',
    'clAuthentication',
    '$log',
    'clApiConfig',
    function ($http, CacheFactory, Authentication, $log, ApiConfig) {
        function getHeaders(useUserToken) {
            var headers = {};

            if (!ApiConfig.useAuthentication)
                return headers;

            if (typeof (useUserToken) === 'undefined')
                useUserToken = true;

            var token;
            if (useUserToken === true && Authentication.isAuthenticated())
				token = Authentication.getUserToken();
            else
				token = Authentication.getPublicToken();

            if (token && token.access_token)
				headers.Authorization = 'Bearer ' + token.access_token;
            else {
				$log.log('Error in user token');
			}

            return headers;
        }

        return {
            baseUrl: ApiConfig.url + '/' + ApiConfig.apiBase + '/',
            tenantId: null,

            createIfNotExist: function (object) {
                return typeof (object) !== 'undefined' ? object : {};
            },
            /***
             * Extend object1 with object2, but object1's keys are not overwritten.
             * @param object1
             * @param object2
             */
            extendIfNotExist: function (object1, object2) {
                return angular.merge({}, object1, object2);
            },

            _includeTenantId: function (object, includeTenant) {
                if (typeof (includeTenant) === 'undefined')
                    includeTenant = true;
                if (includeTenant && this.tenantId !== null && typeof (object.tenant_id) === 'undefined') {
                    object.tenant_id = this.tenantId;
                }

                return object;
            },

            /**
             * Get or create a session cache object, for use with $http.
             * @param name The name of the cache.
             * @param duration The duration of the cache in milliseconds. If not defined, defaults to one second.
             */
            getOrCreateSessionCache: function (name, duration) {
                if (typeof (duration) === 'undefined')
                    duration = 1000; //one second by default

                if (!CacheFactory.get(name)) {
                    CacheFactory.createCache(name, {
                        cacheFlushInterval: duration, //milliseconds
                        storageMode: 'sessionStorage',
                    });
                }

                return CacheFactory.get(name);
            },

            get: function (url, params, options, includeTenant, includeUserAuthorization) {
                var headers = getHeaders(includeUserAuthorization);
                params = this.createIfNotExist(params);
                params = this._includeTenantId(params, includeTenant);

                options = this.createIfNotExist(options);
                options = this.extendIfNotExist(options, { params: params, headers: headers });

                return $http.get(this.baseUrl + url, options);
            },

            put: function (url, data, options, includeTenant, includeUserAuthorization) {
                var headers = getHeaders(includeUserAuthorization);
                data = this.createIfNotExist(data);
                data = this._includeTenantId(data, includeTenant);

                options = this.createIfNotExist(options);
                options = this.extendIfNotExist(options, { headers: headers });

                return $http.put(this.baseUrl + url, data, options);
            },

            post: function (url, data, options, includeTenant, includeUserAuthorization) {
                var headers = getHeaders(includeUserAuthorization);
                data = this.createIfNotExist(data);
                data = this._includeTenantId(data, includeTenant);

                options = this.createIfNotExist(options);
                options = this.extendIfNotExist(options, { headers: headers });

                return $http.post(this.baseUrl + url, data, options);
            },

            delete: function (url, params, options, includeTenant, includeUserAuthorization) {
                var headers = getHeaders(includeUserAuthorization);
                params = this.createIfNotExist(params);
                params = this._includeTenantId(params, includeTenant);

                options = this.createIfNotExist(options);
                options = this.extendIfNotExist(options, { params: params, headers: headers });
                return $http.delete(this.baseUrl + url, options);
            },
        };
    },
]);
