angular.module("2cp").controller("tenants.tariffs",
    [
        "$scope", "$timeout", "$window", "Tariffs", "Zones", "Droppoints", "$translate",
        "TariffColumns", "Tenants", "TimeEntryColumns", "BookingTariffColumns", "PermissionService",
        'Times', "VehicleTypes",
        function ($scope, $timeout, $window, Tariffs, Zones, Droppoints, $translate,
                  TariffColumns, Tenants, TimeEntryColumns, BookingTariffColumns, PermissionService,
                  Times, VehicleTypes) {
            $scope.vehicleTypes = [];
            $scope.weekdays = [
                {
                    id: 0,
                    name: 'sunday'
                },
                {
                    id: 1,
                    name: 'monday'
                },
                {
                    id: 2,
                    name: 'tuesday'
                },
                {
                    id: 3,
                    name: 'wednesday'
                },
                {
                    id: 4,
                    name: 'thursday'
                },
                {
                    id: 5,
                    name: 'friday'
                },
                {
                    id: 6,
                    name: 'saturday'
                }
            ];

            $scope.allowRentalTariffs = PermissionService.hasPermissions(['viewRentalTariffs']);
            $scope.allowBookingTariffs = PermissionService.hasPermissions(['viewBookingTariffs']);

            $scope.currentTariff = {
                tariff_id: 1,
                tariff_type: 'App\\RentalTariff'
            };
            $scope.timezone = Times.getZone();

            /************************************************************************************
             ********************************** Tariffs *****************************************
             ************************************************************************************/
            if (PermissionService.hasPermissions(['viewRentalTariffs'])) {
                $scope.tariffDetails = {};
                $scope.tariffsGridOptions = {
                    columnDefs: TariffColumns.
                    addOptionsByName({'vehicleType' : {'selectOptions': $scope.vehicleTypes}}).
                    build(),

                    callGridData: {
                        id: "rental_tariff_id",
                        get: function (data) {
                            if(data.vehicleType){
                                data.vehicleType = '"' + data.vehicleType + '"';
                            }
                            return Tariffs.getTariffs(data).then(function (result) {
                                result = result.data;
                                for (var i = 0; i < result.length; i++) {
                                    result[i].start_time = Times.getTenantTimeString(result[i].start_time, $scope.timezone);
                                    result[i].end_time = Times.getTenantTimeString(result[i].end_time, $scope.timezone);
                                }
                                return result;
                            });
                        },
                        create: function (data) {
                            $('.reveal').foundation('close');
                            data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                            data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                            return Tariffs.create(data)
                                .then(function (result) {
                                    result.start_time = Times.getTenantTimeString(result.start_time, $scope.timezone);
                                    result.end_time = Times.getTenantTimeString(result.end_time, $scope.timezone);
                                    if ($scope.rentalDuplicate) {
                                        $scope.copyRentalEntries(result);
                                    }
                                    $.notify($translate.instant('notify.tariff_rental_create_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        update: function (id, data) {
                            data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                            data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                            return Tariffs.update(id, data)
                                .then(function (result) {
                                    data.start_time = Times.getTenantTimeString(data.start_time, $scope.timezone);
                                    data.end_time = Times.getTenantTimeString(data.end_time, $scope.timezone);
                                    $.notify($translate.instant('notify.tariff_rental_update_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        delete: function (ids) {
                            return Tariffs.delete(ids)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_rental_delete_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        }
                    },
                    callGridEditIcon: {
                        clickCallback: function (rowEntity) {
                            $scope.showDetailsModal(false, rowEntity);
                        }
                    },
                    callGridIcon: {
                        iconClass: function () {
                            return 'fa-list-ul';
                        },
                        clickCallback: function (rowEntity) {
                            $('.tabs-rental-tariffs').foundation('selectTab', $('#entriesPanel'));
                            $scope.showDetailsModal(false, rowEntity);
                        }
                    },
                    callGridDuplicate: {
                        clickCallback: function (rowEntity) {
                            $('.tabs-rental-tariffs').foundation('selectTab', $('#detailsPanel'));
                            $scope.showDetailsModal(true, rowEntity);
                        }
                    },
                    callGridFiltering: {
                        queryParameterPrefix: "tariffs."
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.tariffsGrid = gridApi.grid;
                    }
                };

                $scope.showDeleteButton = function () {
                    var r = true;

                    var rows = $scope.tariffsGrid.api.selection.getSelectedRows();
                    if (rows.length == 0)
                        r = false;

                    return r;
                };
                $scope.removeSelected = function () {
                    $scope.tariffsGrid.api.callGridEditing.removeSelectedRows();
                };

                /**
                 *
                 * @param creationModal True if the modal is for creation, false if it is for editing.
                 * @param rowEntity The rowEntity containing lock device data
                 */
                $scope.showDetailsModal = function (creationModal, rowEntity) {
                    if (rowEntity) {
                        for (var i = 0; i < $scope.locations.length; i++) {
                            if ($scope.locations[i].loc.id == rowEntity.origin_id && $scope.locations[i].loc.type == rowEntity.origin_type) {
                                $scope.selectedOrigin = $scope.locations[i].loc;
                            }
                            if ($scope.locations[i].loc.id == rowEntity.destination_id && $scope.locations[i].loc.type == rowEntity.destination_type) {
                                $scope.selectedDestination = $scope.locations[i].loc;
                            }
                        }
                    } else {
                        $scope.selectedOrigin = null;
                        $scope.selectedDestination = null;
                    }
                    $scope.tariffDetails = rowEntity;
                    if (!rowEntity) {
                        $scope.tariffDetails = {};
                    }
                    $scope.tariffCreationModal = creationModal;
                    $scope.rentalDuplicate = creationModal && rowEntity;

                    if (!creationModal || $scope.rentalDuplicate) {
                        $scope.currentTariff.tariff_id = $scope.tariffDetails.rental_tariff_id;
                        $scope.currentTariff.tariff_type = 'App\\RentalTariff';
                        $scope.rentalEntriesGrid.api.callGridData.get().then(function () {
                            $("#tariffsDetailsModal").foundation('open');
                        });
                    } else {
                        $('.tabs-rental-tariffs').foundation('selectTab', $('#detailsPanel'));
                        $("#tariffsDetailsModal").foundation('open');
                    }
                };

                $scope.detailsUpdated = function (updatedRow) {
                    $('.reveal').foundation('close');
                    updatedRow.origin_id = $scope.selectedOrigin.id;
                    updatedRow.origin_type = $scope.selectedOrigin.type;
                    updatedRow.destination_id = $scope.selectedDestination.id;
                    updatedRow.destination_type = $scope.selectedDestination.type;
                    if ($scope.tariffCreationModal) {
                        $scope.tariffsGrid.api.callGridEditing.addRow(updatedRow);
                    } else {
                        updatedRow.origin.label = $scope.selectedOrigin.label;
                        updatedRow.origin.name = $scope.selectedOrigin.label;
                        updatedRow.specialized.destination.label = $scope.selectedDestination.label;
                        updatedRow.specialized.destination.name = $scope.selectedDestination.label;
                        $scope.tariffsGrid.api.rowEdit.setRowsDirty([updatedRow]);
                    }
                };
            }

            $scope.locations = [];

            Tenants.getTenant(calllock.tenantId).then(function (tenant) {
                tenant.label = tenant.name;
                setLocations([tenant], 'App\\Tenant', 'tenant_id');
                Zones.getZones({limit: 9999}).then(function (zones) {
                    setLocations(zones.data, 'App\\Zone', 'zone_id');
                    Droppoints.getDroppoints({limit: 9999}).then(function (droppoints) {
                        setLocations(droppoints.data, 'App\\Droppoint', 'droppoint_id');
                    });
                });
            });

            function setLocations(locations, type, id) {
                for (var i = 0; i < locations.length; i++) {
                    locations[i].loc = {
                        id: locations[i][id],
                        type: type,
                        label: locations[i].label
                    }
                }
                $scope.locations = $scope.locations.concat(locations);
            }

            /************************************************************************************
             **************************** Rental Time Entries ***********************************
             ************************************************************************************/
            {
                $scope.rentalEntriesGridOptions = {
                    columnDefs: TimeEntryColumns.build(),

                    callGridData: {
                        id: "tariff_time_entry_id",
                        get: function (params) {
                            params.tariff_id = $scope.currentTariff.tariff_id;
                            params.tariff_type = $scope.currentTariff.tariff_type;
                            return Tariffs.getEntries(params)
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        create: function (data) {
                            data.entry_owner_id = $scope.currentTariff.tariff_id;
                            data.entry_owner_type = $scope.currentTariff.tariff_type;
                            $('.reveal').foundation('close');
                            return Tariffs.createEntry(data)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_entry_create_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        update: function (id, data) {
                            return Tariffs.updateEntry(id, data)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_entry_update_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        delete: function (ids) {
                            return Tariffs.deleteEntries(ids)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_entry_delete_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        }
                    },
                    callGridEditIcon: {
                        clickCallback: function (rowEntity) {
                            $scope.showRentalEntryDetailsModal(false, rowEntity);
                        }
                    },
                    callGridFiltering: {
                        queryParameterPrefix: "tariffs."
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.rentalEntriesGrid = gridApi.grid;
                    }
                };
            }

            $scope.showRentalEntryDeleteButton = function () {
                var r = true;
                var rows = $scope.rentalEntriesGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelectedRentalEntries = function () {
                $scope.rentalEntriesGrid.api.callGridEditing.removeSelectedRows();
                $('.reveal').foundation('close');
                $("#tariffsDetailsModal").foundation('open');
            };

            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showRentalEntryDetailsModal = function (creationModal, rowEntity) {
                $scope.rentalEntryDetails = rowEntity;
                $scope.rentalEntryCreationModal = creationModal;

                $("#rentalEntriesDetailsModal").foundation('open');
            };

            $scope.rentalEntryDetailsUpdated = function (updatedRow) {

                $('.reveal').foundation('close');
                if ($scope.rentalEntryCreationModal) {
                    $scope.rentalEntriesGrid.api.callGridEditing.addRow(updatedRow);
                    $("#tariffsDetailsModal").foundation('open');
                } else {
                    $scope.rentalEntriesGrid.api.rowEdit.setRowsDirty([updatedRow]);
                    $("#tariffsDetailsModal").foundation('open');
                }
            };

            $scope.openModal = function (modal) {
                $(modal).foundation('open');
            };

            $scope.copyRentalEntries = function (tariff) {
                for (var i = 0; i < $scope.rentalEntriesGrid.api.grid.rows.length; i++) {
                    var row = $scope.rentalEntriesGrid.api.grid.rows[i].entity;
                    row.entry_owner_id = tariff.specialized_id;
                    row.entry_owner_type = tariff.specialized_type;
                    Tariffs.createEntry(row)
                        .then(function (result) {
                            $.notify($translate.instant('notify.tariff_entry_create_success'), "success");
                            return result;
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                }
            };

            /************************************************************************************
             **************************** Booking Time Entries **********************************
             ************************************************************************************/

            {
                $scope.bookingEntriesGridOptions = {
                    columnDefs: TimeEntryColumns.copy().build(),

                    callGridData: {
                        id: "tariff_time_entry_id",
                        get: function (params) {
                            params.tariff_id = $scope.currentTariff.tariff_id;
                            params.tariff_type = $scope.currentTariff.tariff_type;
                            return Tariffs.getEntries(params)
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        create: function (data) {
                            data.entry_owner_id = $scope.currentTariff.tariff_id;
                            data.entry_owner_type = $scope.currentTariff.tariff_type;
                            $('.reveal').foundation('close');
                            return Tariffs.createEntry(data)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_entry_create_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        update: function (id, data) {
                            return Tariffs.updateEntry(id, data)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_entry_update_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        delete: function (ids) {
                            return Tariffs.deleteEntries(ids)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_entry_delete_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        }
                    },
                    callGridEditIcon: {
                        clickCallback: function (rowEntity) {
                            $scope.showBookingEntryDetailsModal(false, rowEntity);
                        }
                    },
                    callGridFiltering: {
                        queryParameterPrefix: "tariffs."
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.bookingEntriesGrid = gridApi.grid;
                    }
                };
            }

            $scope.showBookingEntryDeleteButton = function () {
                var r = true;
                var rows = $scope.bookingEntriesGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                return r;
            };

            $scope.removeSelectedBookingEntries = function () {
                $scope.bookingEntriesGrid.api.callGridEditing.removeSelectedRows();
                $('.reveal').foundation('close');
                $("#bookingsDetailsModal").foundation('open');
            };

            /**
             *
             * @param creationModal True if the modal is for creation, false if it is for editing.
             * @param rowEntity The rowEntity containing lock device data
             */
            $scope.showBookingEntryDetailsModal = function (creationModal, rowEntity) {
                $scope.bookingEntryDetails = rowEntity;
                $scope.bookingEntryCreationModal = creationModal;

                $("#bookingEntriesDetailsModal").foundation('open');
            };

            $scope.bookingEntryDetailsUpdated = function (updatedRow) {
                $('.reveal').foundation('close');
                if ($scope.bookingEntryCreationModal) {
                    $scope.bookingEntriesGrid.api.callGridEditing.addRow(updatedRow);
                    $("#bookingsDetailsModal").foundation('open');
                } else {
                    $scope.bookingEntriesGrid.api.rowEdit.setRowsDirty([updatedRow]);
                    $("#bookingsDetailsModal").foundation('open');
                }
            };

            $scope.copyBookingEntries = function (tariff) {
                for (var i = 0; i < $scope.bookingEntriesGrid.api.grid.rows.length; i++) {
                    var row = $scope.bookingEntriesGrid.api.grid.rows[i].entity;
                    row.entry_owner_id = tariff.specialized_id;
                    row.entry_owner_type = tariff.specialized_type;
                    Tariffs.createEntry(row)
                        .then(function (result) {
                            $.notify($translate.instant('notify.tariff_entry_create_success'), "success");
                            return result;
                        })
                        .catch(function (e) {
                            console.error(e);
                        });
                }
            };


            /************************************************************************************
             ***************************** Booking Tariffs **************************************
             ************************************************************************************/
            if (PermissionService.hasPermissions(['viewBookingTariffs'])) {
                $scope.bookingDetails = {};
                $scope.bookingsGridOptions = {
                    columnDefs: BookingTariffColumns.
                    addOptionsByName({'vehicleType' : {'selectOptions': $scope.vehicleTypes}}).
                    build(),

                    callGridData: {
                        id: "booking_tariff_id",
                        get: function (data) {
                            if(data.vehicleType){
                                data.vehicleType = '"' + data.vehicleType + '"';
                            }
                            return Tariffs.getBookingTariffs(data).then(function (result) {
                                result = result.data;
                                for (var i = 0; i < result.length; i++) {
                                    result[i].start_time = Times.getTenantTimeString(result[i].start_time, $scope.timezone);
                                    result[i].end_time = Times.getTenantTimeString(result[i].end_time, $scope.timezone);
                                }
                                return result;
                            });
                        },
                        create: function (data) {
                            data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                            data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                            $('.reveal').foundation('close');
                            return Tariffs.createBookingTariff(data)
                                .then(function (result) {
                                    result.start_time = Times.getTenantTimeString(result.start_time, $scope.timezone);
                                    result.end_time = Times.getTenantTimeString(result.end_time, $scope.timezone);
                                    if ($scope.bookingDuplicate) {
                                        $scope.copyBookingEntries(result);
                                    }
                                    $.notify($translate.instant('notify.tariff_booking_create_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        update: function (id, data) {
                            data.start_time = Times.getUTCTimeString(data.start_time, $scope.timezone);
                            data.end_time = Times.getUTCTimeString(data.end_time, $scope.timezone);
                            return Tariffs.updateBookingTariff(id, data)
                                .then(function (result) {
                                    data.start_time = Times.getTenantTimeString(result.start_time, $scope.timezone);
                                    data.end_time = Times.getTenantTimeString(result.end_time, $scope.timezone);
                                    $.notify($translate.instant('notify.tariff_booking_update_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        },
                        delete: function (ids) {
                            return Tariffs.deleteBookingTariff(ids)
                                .then(function (result) {
                                    $.notify($translate.instant('notify.tariff_booking_delete_success'), "success");
                                    return result;
                                })
                                .catch(function (e) {
                                    console.error(e);
                                });
                        }
                    },
                    callGridEditIcon: {
                        clickCallback: function (rowEntity) {
                            $scope.showBookingDetailsModal(false, rowEntity);
                        }
                    },
                    callGridIcon: {
                        iconClass: function () {
                            return 'fa-list-ul';
                        },
                        clickCallback: function (rowEntity) {
                            $('.tabs-booking-tariffs').foundation('selectTab', $('#bookingEntriesPanel'));
                            $scope.showBookingDetailsModal(false, rowEntity);
                        }
                    },
                    callGridDuplicate: {
                        clickCallback: function (rowEntity) {
                            $('.tabs-booking-tariffs').foundation('selectTab', $('#bookingsPanel'));
                            $scope.showBookingDetailsModal(true, rowEntity);
                        }
                    },
                    callGridFiltering: {
                        queryParameterPrefix: "tariffs."
                    },
                    onRegisterApi: function (gridApi) {
                        $scope.bookingsGrid = gridApi.grid;
                    }
                };

                $scope.showBookingDeleteButton = function () {
                    var r = true;

                    var rows = $scope.bookingsGrid.api.selection.getSelectedRows();
                    if (rows.length == 0)
                        r = false;

                    return r;
                };
                $scope.removeSelectedBookings = function () {
                    $scope.bookingsGrid.api.callGridEditing.removeSelectedRows();
                };

                /**
                 *
                 * @param creationModal True if the modal is for creation, false if it is for editing.
                 * @param rowEntity The rowEntity containing lock device data
                 */
                $scope.showBookingDetailsModal = function (creationModal, rowEntity) {
                    if (rowEntity) {
                        for (var i = 0; i < $scope.locations.length; i++) {
                            if ($scope.locations[i].loc.id == rowEntity.origin_id && $scope.locations[i].loc.type == rowEntity.origin_type) {
                                $scope.bookingLocation = $scope.locations[i].loc;
                            }
                        }
                    } else {
                        $scope.bookingLocation = null;
                    }
                    $scope.bookingDetails = rowEntity;
                    if (!rowEntity) {
                        $scope.bookingDetails = {};
                    }
                    $scope.bookingCreationModal = creationModal;
                    $scope.bookingDuplicate = (creationModal && rowEntity);

                    if (!creationModal || $scope.bookingDuplicate) {
                        $scope.currentTariff.tariff_id = $scope.bookingDetails.booking_tariff_id;
                        $scope.currentTariff.tariff_type = 'App\\BookingTariff';
                        $scope.bookingEntriesGrid.api.callGridData.get().then(function () {
                            $("#bookingsDetailsModal").foundation('open');
                        });
                    } else {
                        $('.tabs-booking-tariffs').foundation('selectTab', $('#bookingsPanel'));
                        $("#bookingsDetailsModal").foundation('open');
                    }
                };

                $scope.bookingDetailsUpdated = function (updatedRow) {
                    $('.reveal').foundation('close');
                    updatedRow.origin_id = $scope.bookingLocation.id;
                    updatedRow.origin_type = $scope.bookingLocation.type;
                    if ($scope.bookingCreationModal) {
                        $scope.bookingsGrid.api.callGridEditing.addRow(updatedRow);
                    } else {
                        updatedRow.origin.label = $scope.bookingLocation.label;
                        updatedRow.origin.name = $scope.bookingLocation.label;
                        $scope.bookingsGrid.api.rowEdit.setRowsDirty([updatedRow]);
                    }
                };
            }

            //Set vehicle types for filtering and for in the details modal
            VehicleTypes.getVehicleTypes({limit: 999}).then(function(response){
                for(var i = 0; i < response.data.length; i++){
                    $scope.vehicleTypes.push({
                        'value': response.data[i].name,
                        'label': response.data[i].name,
                        'id': response.data[i].vehicle_type_id
                    });
                }
                for(i = 0; i < $scope.bookingsGrid.columns.length; i++){
                    if($scope.bookingsGrid.columns[i].name === 'vehicleType'){
                        $scope.bookingsGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
                for(i = 0; i < $scope.tariffsGrid.columns.length; i++){
                    if($scope.tariffsGrid.columns[i].name === 'vehicleType'){
                        $scope.tariffsGrid.columns[i].filter.selectOptions = $scope.vehicleTypes;
                    }
                }
            });
        }
    ]
);