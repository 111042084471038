angular.module("2cp").factory("DroppointColumns",
    [
        "$translate", "$filter", "uiGridConstants", "$timeout",
        "Zones", "Users", "UserIdentifiers", "Droppoints",
        "ColumnDefBuilder", "Address",
        function ($translate, $filter, uiGridConstants, $timeout,
                  Zones, Users, UserIdentifiers, Droppoints,
                  ColumnDefBuilder, Address) {
            var columnOptions = {
                droppoint_id: {
                    enableFiltering: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                label: {
                    enableFiltering: true,
                    visible: true
                },
                longitude: {
                    enableFiltering: true
                },
                latitude: {
                    enableFiltering: true
                },
                maximum_vehicles: {
                    enableFiltering: true
                },
                amount_of_vehicles: {
                    enableFiltering: true,
                    enableSorting: false
                },
                free_spots: {
                    enableFiltering: true,
                    enableSorting: false,
                    visible: true
                },
                amount_of_bookings: {
                    enableFiltering: true,
                    enableSorting: false,
                    visible: true
                },
                free_vehicles: {
                    enableFiltering: true,
                    enableSorting: false,
                    visible: true
                },
                minimum_vehicles_for_booking: {
                    enableFiltering: true
                },
                zone_id: {
                    enableFiltering: true
                },
                city: {
                    enableFiltering: true,
                },
                country: {
                    enableFiltering: true,
                    selectOptions: {
                        keys: Address.getCountries(),
                        filter: 'countryFilter'
                    },
                },
                state: {
                    enableFiltering: true,
                },
                street: {
                    enableFiltering: true,
                },
                house_number: {
                    enableFiltering: true,
                },
                postal_code: {
                    enableFiltering: true,
                },
                hidden: {
                    cellFilter: 'locationBooleanInput',
                    enableCellEdit: true,
                    selectOptions: {
                        keys: [1, 0],
                        filter: 'locationBoolean'
                    },
                    enableFiltering: true
                },
                zoneLabel:{
                    enableFiltering: true,
                    visible: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Droppoints.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.droppoints.')
                .copy();

            return builder;
        }
    ]
);