'use strict';

angular.module('api').factory('Chains', [
    'clApi',
    '$filter',
    function (api, $filter) {

        return {
            parameters: [
                'chain_id',
                'short_description',
                'description',
                'gps_latitude',
                'gps_longitude',
                'street',
                'house_number',
                'postal_code',
                'city',
                'stands',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getChains: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('chains', params, options).then(function (result) {
                    return result.data;
                });
            },

            getChain: function (id) {
                return api.get('chains/' + id).then(function (result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('chains/' + id, params).then(function (result) {
                    return result.data;
                });
            },

            create: function (params) {
                return api.post('chains', params).then(function (result) {
                    return result.data;
                });
            },
        };
    },
]);
