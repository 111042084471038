angular.module("2cp").directive('callGridIcon', [
    function () {
        return {
            restrict: 'A',
            replace: true,
            priority: 100, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function ($scope, iElement, iAttributes, uiGridCtrl) {
                //clickCallback
                //iconClass

                var grid = uiGridCtrl.grid;

                //The icon class can be a function, so make sure it is always a function
                grid.options.callGridIcon.iconClass = [].concat(grid.options.callGridIcon.iconClass);
                grid.options.callGridIcon.clickCallback = [].concat(grid.options.callGridIcon.clickCallback);
                for(var i = grid.options.callGridIcon.iconClass.length-1; i >= 0; i--){
                    if(!angular.isFunction(grid.options.callGridIcon.iconClass[i])) {
                        var a = grid.options.callGridIcon.iconClass[i]; //string
                        grid.options.callGridIcon.iconClass[i] = function() {
                            return a;
                        };
                    }
                    grid.options.columnDefs.unshift({
                        name: "callGridIcon" + i,
                        displayName: "",
                        enableCellEdit: false,
                        enableHiding: false,
                        enableColumnMoving: false,
                        maxWidth: 30,
                        headerCellTemplate: "<div></div>",
                        cellTemplate: '<div class="ui-grid-icon"><i ng-click="grid.options.callGridIcon.clickCallback['+i+'](row.entity)" class="fa vertical-center" ng-class="grid.options.callGridIcon.iconClass['+i+'](row.entity)"></i></div>'
                    });
                }

            }
        };
    }]);