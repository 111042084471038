angular.module("2cp").directive("callGridEditing", [
    function () {
        return {
            restrict: 'A',
            replace: true,
            priority: 0, // this could be tweaked to fire your directive before/after other features
            require: 'uiGrid',
            scope: false,
            link: function ($scope, $elm, $attrs, uiGridCtrl) {
                var grid = uiGridCtrl.grid;
                grid.options.enableEdit = false;

                // Add row to grid
                var addRow = function(parameters) {
                    return grid.api.callGridData.create(parameters).then(function(result) {
                        grid.options.data.push(result);
                    });
                };

                var disableCellEdits = function(){
                    var columns = grid.columns;
                    for(var i = 0; i < columns.length; i++){
                        columns[i].colDef.enableCellEdit = false;
                    }
                };

                var updateRow = function (rowEntity) {
                    // Create a promise from set call
                    var idKey = grid.options.callGridData.id;
                    var promise = grid.api.callGridData.update(rowEntity[idKey], rowEntity);
                    grid.api.rowEdit.setSavePromise(rowEntity, promise);
                    return promise;
                };

                // Remove selected rows
                var removeSelectedRows = function() {
                    var selectedIds = [];
                    var selectedRows = grid.api.selection.getSelectedRows();
                    var idKey = grid.options.callGridData.id;
                    selectedRows.forEach(function(row) {
                        selectedIds.push(row[idKey]);
                    });

                    return grid.api.callGridData.delete(selectedIds).then(function(result) {
                        // Sort IDs in descending order
                        selectedRows.sort(function(a,b){ return b - a; });

                        // Remove ids from back to front
                        for (var i = selectedRows.length -1; i >= 0; i--) {
                            var index = grid.options.data.indexOf(selectedRows[i]);
                            grid.options.data.splice(index, 1);
                        }
                    });
                };

                grid.api.rowEdit.on.saveRow($scope, function (entity) {
                    updateRow(entity);
                });

                //Register public api functions
                var methods = {
                    callGridEditing: {
                        addRow: addRow,
                        updateRow: updateRow,
                        removeSelectedRows: removeSelectedRows,
                        disableCellEdits: disableCellEdits
                    }
                };
                grid.api.registerMethodsFromObject(methods);
            }
        };
    }]);