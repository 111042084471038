angular.module("2cp").factory("BookingTariffColumns",
    [
        "$translate", "$filter", "$timeout",
        "Tariffs", "uiGridConstants",
        "ColumnDefBuilder",
        function ($translate, $filter, $timeout,
                  Tariffs, uiGridConstants,
                  ColumnDefBuilder) {
            var columnOptions = {
                origin_id: {
                    visible: true,
                    enableFiltering: false,
                    cellFilter: 'originIdToLabel:this',
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                },
                origin_type: {
                    cellFilter: 'typeToName',
                    selectOptions: {
                        keys: Tariffs.ORIGIN_TYPES,
                        filter: 'typeToName'
                    }
                },
                max_cost_per_day: {
                    enableCellEdit: true,
                    cellFilter: "balance"
                },
                start_time: {
                    visible: true,
                    enableFiltering: false
                },
                end_time: {
                    visible: true,
                    enableFiltering: false
                },
                weekday: {
                    visible: true,
                    selectOptions: {
                        keys: Tariffs.getWeekdays(),
                        filter: 'weekOptions'
                    },
                    cellFilter: 'weekday'
                },
                start_date: {
                    dateTimeOptions: {
                        headerDirective: "header-date-picker"
                    },
                    enableFiltering: true,
                    cellFilter: 'tariffDate'
                },
                end_date: {
                    visible: true,
                    dateTimeOptions: {
                        headerDirective: "header-date-picker"
                    },
                    enableFiltering: true,
                    cellFilter: 'tariffDate'
                },
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Tariffs.getBookingParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.tariffs.")
                .copy();
            return builder;
        }
    ]
);