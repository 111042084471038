angular.module("2cp").factory("RentalColumns",
    [
        "$translate", "$filter", "uiGridConstants", "$timeout",
        "Rentals", "Vehicles", "Users", "UserIdentifiers",
        "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants, $timeout,
                  Rentals, Vehicles, Users, UserIdentifiers,
                  ColumnDefBuilder) {
            var columnOptions = {
                rental_id: {
                    enableFiltering: true
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                updated_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                start_datetime: {
                    enableFiltering: true,
                    visible: true,
                    cellFilter: 'tenantdate:"YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                end_datetime: {
                    enableFiltering: true,
                    visible: true,
                    cellFilter: 'rentaldate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    sort: {
                        direction: uiGridConstants.DESC,
                        priority: 0
                    }
                },
                status: {
                    enableFiltering: true,
                    selectOptions: {
                        keys: Rentals.statuses,
                        filter: 'rentalStatuses'
                    },
                    cellFilter: 'rentalStatuses'
                },
                vehicle_id: {
                    enableFiltering: true
                },
                vehicleLabel: {
                    enableFiltering: true
                },
                startLabel: {
                    enableFiltering: true,
                    visible: true
                },
                endLabel:{
                    enableFiltering: true,
                    visible: true
                },
                payment_amount:{
                    enableFiltering: true,
                    visible: true
                },
                payment_user_identifier_user_username:{
                    enableFiltering: true,
                    visible: true
                },
                duration:{
                    enableFiltering: true,
                    cellFilter: 'rentalDuration',
                    visible: true
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Rentals.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableFiltering: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('tenants.rentals.')
                .copy();

            return builder;
        }
    ]
);