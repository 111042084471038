'use strict';

angular.module('api').factory('Payments', [
    '$q',
    'clApi',
    function ($q, api) {
        return {

            parameters: [
                'updated_at',
                'username',
                'amount',
                'status',
                'payable_type',
                'payment_id',
                'reason_type',
                'name',
                'created_at',
                'paymentType'
            ],

            reasonTypes: [
                'subscriptionPayment',
                'balance',
                'action',
                'subscriptionBalance'
            ],

            paymentTypes: [
                'balance',
                'after_pay',
                'on_credit',
                'ideal'
            ],

            extraPaymentTypes: [
                'credit'
            ],

            getParameters: function () {
                return this.parameters;
            },

            getPendingPayments: function (params) {
                return api.get('payments/pending', params).then(function (result) {
                    return result.data;
                });
            },

            delete: function (paymentIds) {
                var promises = [];
                paymentIds.forEach(function (id) {
                    promises.push(api.delete('payments/' + id));
                });

                return $q.all(promises);
            },

            getPayments: function (params, options) {
                options = api.createIfNotExist(options);

                return api.get('payments', params, options).then(function (result) {
                    var payments = result.data;
                    for(var i = 0; i < payments.data.length; i++){
                        if(payments.data[i].subscriptionName){
                            payments.data[i].name = payments.data[i].subscriptionName;
                        }else{
                            payments.data[i].name = payments.data[i].subscriptionUpgradeName;
                        }
                    }
                    return result.data;
                });
            },

            approve: function(ids, data){
                if(!data){
                    data = {}
                }
                data.payment_ids = ids;
                return api.post('payments/approve', data).then(function(result){
                    return result.data;
                });
            },

            reject: function(ids, data){
                if(!data){
                    data = {}
                }
                data.payment_ids = ids;
                return api.post('payments/reject', data).then(function(result){
                    return result.data;
                });
            },

			addIdealSource: function(payment_id, source){
            	var data = {
					ideal_source: source,
					payment_id: payment_id
				};

            	return api.post('payments/addIdealSource', data).then(function(result){
            		return result.data;
				});
			},

			pay: function(payment_id, card, tenant_id, user_id){
				var data = {
					payment_id: payment_id,
					card: card,
					tenant_id: tenant_id,
					user_id: user_id
				};

				return api.post('payments/pay', data).then(function(result){
					return result.data;
				});
			},

			openPayments: function(user_id, tenant_ids){
				var data = {
					user_id: user_id,
					tenant_ids: tenant_ids
				};
				return api.post('payments/openPayments', data).then(function(result){
					return result.data;
				});
			}

        };
    },
]);
