angular.module("2cp").factory("VehicleTypeColumns",
    [
        "$translate", "$filter", "uiGridConstants",
        "VehicleTypes", "ColumnDefBuilder",
        function ($translate, $filter, uiGridConstants,
                  VehicleTypes, ColumnDefBuilder) {
            var columnOptions = {
                name: {
                    visible: true
                }
                
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(VehicleTypes.getParameters())
                .addDefaultOptions({
                    visible: false
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties('partners.vehicleTypes.')
                .copy();

            return builder;
        }
    ]
);