angular.module("2cp").controller("UserInfoController",
    [
        "$scope", "Users",
        function ($scope, Users) {
            $scope.activeUser = null;

            Users.getActive().then(function(user) {
                $scope.activeUser = user;
            });
        }
    ]
);