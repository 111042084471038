'use strict';

angular.module('api').provider('clApiConfig', function () {
    this.url = '';
    this.apiBase = 'api/v3';

    this.useAuthentication = true;
    this.authUrl = 'oauth/access_token';

    this.secrets = {
        token: {
            id: '54924b7d-c71f-4a40-a106-d59755647350',
            secret: 'b138f4ad-700f-41c7-b142-ffc26f9843fc',
        },
        publicToken: {
            id: '09b77dba-ef47-4d64-b34c-90ab4c1d66eb',
            secret: '41b9689c-f4d4-49fd-96ba-7bec63b0cbbb',
        },
    };

    this.$get = function () {
        return {
            url: this.url,
            apiBase: this.apiBase,

            useAuthentication: this.useAuthentication,
            authUrl: this.authUrl,

            secrets: this.secrets,
        };
    };
});
