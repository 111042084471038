angular.module("2cp").factory("Dropdowns",
	[
	"$translate", "$filter", "uiGridConstants",
	function($translate, $filter, uiGridConstants) {
    	return {
    		createFilter: function(options, filter) {
                var selectOptions = [];
                options.forEach(function(option) {
                    selectOptions.push({
                        value: option,
                        label: $translate.instant($filter(filter)(option))
                    });
                });

                return {
                    type: uiGridConstants.filter.SELECT,
                    selectOptions: selectOptions
                }
            },

            createClass: function() {
                return " ng-class=\"'colt' + col.uid\" "
            },

            createModel: function(entity) {
                return " ng-model=\"row.entity['" + entity  + "']\" ";
            },

            createDateTemplate: function(entity, directive, filter) {
                var ngModel = this.createModel(entity)
                var ngClass = this.createClass()
                var directive = directive
                return '<div class="ng-scope"><form name="inputForm"><input ' + directive + ngClass + 'ui-grid-editor ' + ngModel + '></form></div>'
            },

            createCellTemplate: function(filter) {
                var ngClass = this.createClass();
                return '<div> ' +
                    '<form name="inputForm"> ' +
                    "<select" + ngClass +
                    'ui-grid-edit-dropdown ' +
                    'ng-model="MODEL_COL_FIELD" ' +
                    'ng-options=" ' +
                    'field | ' + filter + ' | translate ' +
                    'CUSTOM_FILTERS ' +
                    'for field in editDropdownOptionsArray"> ' +
                    '</select> ' +
                    '</form> ' +
                    '</div> ';
            }
    	}
    }
]);