angular.module("2cp").factory("LockColumns",
    [
        "$translate", "$filter", "$timeout",
        "LockDevices", "Chains", "Zones",
        "ColumnDefBuilder", "uiGridConstants",
        function ($translate, $filter, $timeout,
                  LockDevices, Chains, Zones,
                  ColumnDefBuilder, uiGridConstants) {
            var columnOptions = {
                label: {
                    visible: true,
                    enableCellEdit: true,
                    enableFiltering: true,
                    sort: {
                        direction: uiGridConstants.ASC,
                        priority: 0
                    }
                },
                created_at: {
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    },
                    enableCellEdit: true
                },
                updated_at: {
                    visible: true,
                    enableFiltering: true,
                    cellFilter: 'tenantdate : "YYYY-MM-DD HH:mm"',
                    dateTimeOptions: {
                        headerDirective: "header-date-time-picker",
                        cellDirective: "grid-date-time-picker"
                    }
                },
                device_type: {
                    cellFilter: "lockDeviceType | translate",
                    selectOptions: {
                        keys: LockDevices.LOCK_DEVICE_TYPES,
                        filter: "lockDeviceType"
                    }
                },
                status:{
                    visible: true,
                    selectOptions: {
                        keys: LockDevices.STATUSES,
                        filter: 'permissionType'
                    }
                },
                battery_level: {
                    visible: true,
                    cellFilter: 'percentage'
                }
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(LockDevices.getParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.lockDevices.")
                .copy();

            // //Timeout to make sure the awesomplete data is loaded after the main data.
            // $timeout(function() {
            //     Chains.getChains().then(function(chains) {
            //         chains = chains.data;
            //         builder.columnDefs.filter(function(c) {
            //             return c.name === "chain_id";
            //         })[0].awesomplete.suggestions = chains;
            //     });
            //     Zones.getZones().then(function(zones) {
            //         zones = zones.data;
            //         builder.columnDefs.filter(function(c) {
            //             return c.name === "zone_id";
            //         })[0].awesomplete.suggestions = zones;
            //     });
            // }, 500);

            return builder;
        }
    ]
);