angular.module("2cp").factory("TimeEntryColumns",
    [
        "$translate", "$filter", "$timeout",
        "Tariffs",
        "ColumnDefBuilder",
        function ($translate, $filter, $timeout,
                  Tariffs,
                  ColumnDefBuilder) {
            var columnOptions = {
                tariff_time_entry_id: {
                    visible: true,
                },
                start_minute: {
                    visible: true,
                },
                cost_per_step: {
                    visible: true,
                },
                step: {
                    visible: true,
                },
            };

            var builder = new ColumnDefBuilder()
                .addColumnNames(Tariffs.getEntryParameters())
                .addDefaultOptions({
                    visible: false,
                    enableCellEdit: false,
                    enableFiltering: true
                })
                .addOptionsByName(columnOptions)
                .addTranslateProperties("tenants.tariffs.")
                .copy();
            return builder;
        }
    ]
);