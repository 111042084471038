angular.module("2cp")
// Filter for lock mode
    .filter('originIdToLabel', function () {
        return function (input, scope) {
            switch (scope.row.entity.origin_type) {
                case 'App\\Tenant':
                    return scope.row.entity.origin.name;
                case 'App\\Zone':
                    return scope.row.entity.origin.label;
                case 'App\\Droppoint':
                    return scope.row.entity.origin.label;
                default:
                    return 'Unknown';
            }
        }
    })
    .filter('destinationIdToLabel', function () {
        return function (input, scope) {
            switch (scope.row.entity.specialized.destination_type) {
                case 'App\\Tenant':
                    return scope.row.entity.specialized.destination.name;
                case 'App\\Zone':
                    return scope.row.entity.specialized.destination.label;
                case 'App\\Droppoint':
                    return scope.row.entity.specialized.destination.label;
                default:
                    return 'Unknown';
            }
        }
    })
    .filter('typeToName', function () {
        return function (input) {
            switch (input) {
                case 'App\\Tenant':
                    return "Tenant";
                case 'App\\Zone':
                    return "Zone";
                case 'App\\Droppoint':
                    return "Droppoint";
                case 'App\\\\Tenant':
                    return "Tenant";
                case 'App\\\\Zone':
                    return "Zone";
                case 'App\\\\Droppoint':
                    return "Droppoint";
                default:
                    return 'Unknown';
            }
        }
    })
    .filter('type', function() {
        return function (input) {
            switch(input){
                case 'App\\Tenant':
                    return "Tenants";
                case 'App\\Zone':
                    return "Zones";
                case 'App\\Droppoint':
                    return "Droppoints";
                default:
                    return 'Unknown';
            }
        }
    })
    .filter('balance', ['Tenants', '$filter', function(Tenants, $filter){
        var currencySymbol = null;

        Tenants.getTenant(calllock.tenantId).then(function(tenant){
            currencySymbol = tenant.currency.symbol;
        });

        return function(input){
            if(input === null){
                return 'No maximum cost';
            }
            return $filter('currency')(input/100, currencySymbol);
        };
    }])
    .filter('weekday', function(){
        return function(input){
            var output = '';
            if(input.length == 0){
                return 'Never valid';
            }
            for(var i = 0; i < input.length; i++){
                switch(input[i].id){
                    case 0:
                        output += 'Su';
                        break;
                    case 1:
                        output += 'Mo';
                        break;
                    case 2:
                        output += 'Tu';
                        break;
                    case 3:
                        output += 'We';
                        break;
                    case 4:
                        output += 'Th';
                        break;
                    case 5:
                        output += 'Fr';
                        break;
                    case 6:
                        output += 'Sa';
                        break;
                }
                if(i < input.length -1){
                    output+= '-';
                }
            }
            return output;
        };
    })
    .filter('weekOptions', function(){
        return function(input){
            return input.name;
        }
    })
    .filter('tariffDate', function(){
        return function(input){
            if(input == '0000-00-00'){
                return 'Unknown Date';
            }else{
                return input;
            }
        }
    });