angular.module("2cp").directive("tabDisabled", ['$log',
    function ($log) {
        return {
            restrict: 'A',
            scope: {
                tabDisabled: '='
            },
            link: function($scope, iElem, iAttrs) {
                $scope.$watch('tabDisabled', function(val){
                    if(!val)
                        iElem.removeClass("tabs-title-disabled");
                    else
                        iElem.addClass("tabs-title-disabled");
                });

                var onClick = function($event) {
                    if($scope.tabDisabled) {
                        $event.stopPropagation();
                        $event.preventDefault();
                    }
                };
                iElem.on('click', onClick);
            }
        };
    }]);