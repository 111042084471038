angular.module("2cp").controller("tenants.rentals",
    [
        "$scope", "RentalColumns", "Rentals", '$translate',
        function ($scope, RentalColumns, Rentals, $translate) {
            $scope.rentalsGridOptions = {
                columnDefs: RentalColumns.build(),

                callGridData: {
                    id: "rental_id",
                    get: Rentals.getRentals,
                },
                callGridFiltering: {
                    queryParameterPrefix: "rentals."
                },
                onRegisterApi: function (gridApi) {
                    $scope.rentalsGrid = gridApi.grid;
                }
            };

            $scope.endRental = function () {
                $scope.ending = true;
                var selectedRows = $scope.rentalsGrid.api.selection.getSelectedRows();
                selectedRows.forEach(function (row) {
                    Rentals.endRental(calllock.tenantId, row.vehicle_id, null, '', {user_id: row.payment.payment.user_identifier.user_id, operator: true})
                        .then(function (result) {
                            $.notify($translate.instant('notify.rent_end_success'), "success");
                            $scope.ending = false;
                            return result;
                        })
                        .catch(function (e) {
                            $scope.ending = false;
                            console.error(e);
                        });
                });
            };
            
            $scope.showEndButton = function () {
                var r = true;

                var rows = $scope.rentalsGrid.api.selection.getSelectedRows();
                if (rows.length == 0)
                    r = false;

                rows.forEach(function (row) {
                    if (row.status == 'ended' || row.status == 'ended_error')
                        r = false;
                });

                return r;
            };
        },
    ]
);