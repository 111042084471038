'use strict';

angular.module('api').factory('Locks', [
    '$http',
    'clApi',
    function ($http, api) {

        return {
            MODE: {
                UNKNOWN: 0,
                PARKING: 1,
                RENTING: 2,
            },

            POSITION: {
                UNKNOWN: 0,
                LEFT: 1,
                RIGHT: 2,
            },

            RENT_WITH_KEY: {
                FALSE: 0,
                TRUE: 1,
            },

            USAGE_STATUS: {
                UNKNOWN: 0,
                FREE: 1,
                OCCUPIED: 2,
                OCCUPIED_UNKNOWN_TAG: 3,
            },

            parameters: [
                'lock_number',
                'mode',
                'position',
                'rent_with_key',
                'usage_status',
                'lock_id',
                'vehicle_id',
                'identifier_id',
                'tenant_id',
                'zone_id',
                'chain_id',
                'stand_id',
            ],

            getParameters: function () {
                return this.parameters;
            },

            getLocks: function (params, options) {
                var options = api.createIfNotExist(options);

                return api.get('locks', params, options).then(function (result) {
                    return result.data;
                });
            },

            create: function(data) {
                data.vehicle_type_id = 1;
                return api.post('vehicles', data).then(function(result) {
                    return result.data;
                });
            },

            update: function (id, params) {
                return api.put('locks/' + id, params).then(function (result) {
                    return result.data;
                });
            }
        };
    },
]);
